import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Icon,
  Typography,
  Tooltip,
  Box,
  IconButton,
  Collapse,
} from '@mui/material'
import { formatCurrency } from 'lib'
import { IRefundRequest } from 'marketplace-common'
import { RefundHistory } from './RefundProductTable'
import React from 'react'

type Props = {
  requests: IRefundRequest[]
}

export const REFUND_COLUMNS = [
  {
    label: '',
    align: 'left',
  },
  {
    label: '# Factura',
    align: 'left',
  },
  {
    label: 'Estado',
    align: 'center',
  },
  {
    label: 'Comentario',
    align: 'left',
  },
  {
    label: 'Respuesta',
    align: 'left',
  },
  {
    label: 'Fecha de solicitud',
    align: 'right',
  },
  {
    label: 'Cantidad (USD)',
    align: 'right',
  },
]
type RowSelected = {
  refundRequestId: number
}
export const RefundsTable = (props: Props) => {
  const { requests } = props
  const [selectedRowInformation, setSelectedRowInformation] = React.useState<RowSelected>({
    refundRequestId: 0,
  })

  const isProductsActive = React.useCallback(
    (refund: IRefundRequest) => {
      return selectedRowInformation.refundRequestId === refund.refundRequestId
    },
    [selectedRowInformation]
  )

  const handleDetailOpen = (refund: IRefundRequest) => {
    setSelectedRowInformation((prev) => {
      const refundRequestId = refund.refundRequestId
      const yetSelected = prev.refundRequestId === refundRequestId
      if (yetSelected) {
        return {
          ...prev,
          refundRequestId: 0,
        }
      }
      return {
        refundRequestId,
      }
    })
  }
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        minHeight: '350px',
        height: 'auto',
        overflowY: 'auto',
        mb: 2,
      }}
    >
      {requests.length === 0 ? (
        <Stack
          p={4}
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{
            height: '100%',
          }}
        >
          <Icon color="action" fontSize="medium">
            info
          </Icon>
          <Typography variant="h6" color="textSecondary">
            {'No tienes solicitudes enviadas por el momento'}
          </Typography>
        </Stack>
      ) : (
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              {REFUND_COLUMNS.map((column) => (
                <TableCell align={column.align as any} key={column.label}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((row) => (
              <React.Fragment>
                <TableRow
                  key={`${row} + ${Math.random()}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Tooltip title="Mostrar Productos" arrow>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                          handleDetailOpen(row)
                        }}
                      >
                        <Icon>
                          {isProductsActive(row) &&
                          selectedRowInformation.refundRequestId === row.refundRequestId
                            ? 'expand_less'
                            : 'expand_more'}
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.invoiceContract.invoiceNumber}
                  </TableCell>
                  <TableCell align="center">{row.refundStatus.refundStatusName}</TableCell>
                  <TableCell
                    sx={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: 200,
                    }}
                  >
                    <Box>{row.comment}</Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: 200,
                    }}
                  >
                    <Box>{row.responseRefundRequest}</Box>
                  </TableCell>
                  <TableCell align="right">{row.requestDate}</TableCell>
                  <TableCell align="right">
                    {formatCurrency(row.invoiceContract.totalPayment ?? 0)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={isProductsActive(row)} timeout="auto" unmountOnExit>
                      <RefundHistory list={row} />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  )
}
