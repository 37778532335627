import { useMediaQuery, useTheme } from '@mui/material'
import { useAuth } from 'hooks'
import React from 'react'
import { Freshchat } from 'reactjs-freshchat'
import 'reactjs-freshchat/dist/index.css'

export interface ISupportChatRef {
  openChat: (message: string) => void
}

interface Props {}

export const SupportChat = React.forwardRef<ISupportChatRef, Props>((props, ref) => {
  const { user } = useAuth()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  React.useImperativeHandle(ref, () => ({
    openChat: (message: string) => {
      ;(window as any)?.fcWidget?.open({ name: 'Checkout', replyText: message })
    },
  }))

  return (
    <Freshchat
      token={process.env.REACT_APP_FRESH_CHAT ?? ''}
      externalId={user?.userId?.toString() ?? 'Cliente'}
      firstName={user?.name ?? 'Cliente'}
      config={{
        cssNames: {
          widget: 'custom_fc_frame',
        },
        fullscreen: { isMobile },
        headerProperty: {
          backgroundColor: theme.palette.secondary.main,
          foregroundColor: theme.palette.secondary.contrastText,
          fontName: 'Montserrat',
        },
        content: {
          placeholders: {
            search_field: '',
            reply_field: '_',
          },
        },
      }}
    />
  )
})
