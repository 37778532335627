import {
  Box,
  Icon,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import React from 'react'
import { MAIN_COLORS } from 'styles'

type Props = {
  open: boolean
  children: React.ReactElement
  strength: string
  contains: string[]
  placement: TooltipProps['placement']
  diversity: string[]
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}))

export const PasswordStrengthIndicator = (props: Props) => {
  const { children, open, contains, strength, placement, diversity } = props
  return (
    <BootstrapTooltip
      arrow
      open={open}
      sx={{
        '&.Mui-tooltip': {
          bgcolor: MAIN_COLORS.primary.main,
        },
      }}
      title={
        <Stack spacing={1} p={1} minWidth={200}>
          <Typography variant="body2" fontWeight={500}>
            {strength}
          </Typography>
          {diversity.map((label) => (
            <Box display="flex" alignItems={'center'} key={label}>
              <Icon color={contains.includes(label) ? 'success' : 'disabled'} sx={{ mr: 1 }}>
                check_circle
              </Icon>
              <Typography variant="caption" fontWeight={600}>
                {label}
              </Typography>
            </Box>
          ))}
        </Stack>
      }
      placement={placement}
    >
      {children}
    </BootstrapTooltip>
  )
}
