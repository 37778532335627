export const fieldValidationIdentification = (value: string) => {
  const regexIdentification = /^(0[1-9]|1\d|2[0-4])\d{8}(001)?$/
  return regexIdentification.test(value)
}

export const fieldValidationEmail = (email: string) => {
  const regexEmail: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  return regexEmail.test(email)
}

export const fieldValidationPassword = (password: string) => {
  const regexPassword: RegExp = /.{8,}/
  return regexPassword.test(password)
}

export const fieldValidationOnlyLetters = (value: string) => {
  const regexOnlyNumbers = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+$/
  return regexOnlyNumbers.test(value)
}

export const fieldValidationNumberEc = (value: string) => {
  const regexPhoneEC = /^09[0-9]{8}$/
  return regexPhoneEC.test(value)
}

export const fieldValidationOnlyNumbers = (value: string) => {
  const regexOnlyNumbers = /[-+\/*πe]/
  return regexOnlyNumbers.test(value)
}

export const fieldValidationPhone = (value: string) => {
  const regexOnlyNumbers = /^\d{9,10}$/
  return regexOnlyNumbers.test(value)
}

//!!: Validations for Telephone
export const fieldValidationFormatTelephone = (value: string) => {
  const regexOnlyNumbers = /^0[2-7][a-zA-Z0-9]*$/
  return regexOnlyNumbers.test(value)
}
export const fieldValidationTelephoneEC = (value: string) => {
  const regexOnlyNumbers = /^\d{9}$/
  return regexOnlyNumbers.test(value)
}

export const fieldValidationWithoutCharacters = (value: string) => {
  const regexWithoutCharacters = /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ ]+$/
  return regexWithoutCharacters.test(value)
}

export const fieldValidationDirection = (value: string) => {
  const regexDirection = /[{}[\]!¡¿?%$=*+]/
  return regexDirection.test(value)
}

export const fieldValidationRuc = (value: string) => {
  const regexRUC = /^\d{10}001$/
  return regexRUC.test(value)
}

export const fieldValidationFingerprintCode = (value: string) => {
  const regexFingerPrintCode = /^[A-Z][0-9]{4}[A-Z][0-9]{4}$/
  return regexFingerPrintCode.test(value)
}
