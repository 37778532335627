import { Box, Button, Typography } from '@mui/material'
import { ROUTES } from 'lib'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

type Props = {}

export const ContactsBar = (props: Props) => {
  const { pathname } = useLocation()

  return (
    <React.Fragment>
      <Box width={'100%'} bgcolor={'primary.main'} py={3}>
        <Typography variant="h4" color="#FFF" fontWeight={800} align="center">
          {'Contacta a nuestro equipo'}
        </Typography>
        <Typography variant="body1" sx={{ opacity: 0.65, mt: 2 }} color="#FFF" align="center">
          {'Estaremos encantados de resolver todas tus dudas o problemas!'}
        </Typography>
      </Box>
      <Box
        id="btn-container-grid"
        py={2}
        px={4}
        // gap={2}
        display={'flex'}
        // flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box width={'100%'}>
          <Link to={ROUTES.requestRefund}>
            <Button
              fullWidth
              variant={pathname.includes(ROUTES.requestRefund) ? 'contained' : 'outlined'}
            >
              {'Solicitar una devolución'}
            </Button>
          </Link>
        </Box>

        {/* <Button
          fullWidth
          variant={pathname.includes(ROUTES.salesTeam) ? 'contained' : 'outlined'}
          disabled
          sx={{ lineHeight: { xs: 1, sm: 1.8 } }}
        >
          {'No me satisfacen los productos ofrecidos'}
        </Button> */}
      </Box>
    </React.Fragment>
  )
}
