import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import emailSent from 'assets/animations/email-sent.json'
import { AnimatedLink } from 'components'
import { useFeedback } from 'hooks'
import { resentEmailVerificationService, ROUTES } from 'lib'
import React from 'react'
import Lottie from 'react-lottie'

export const EmailResend = () => {
  const [email, setEmail] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)
  const { showMessage } = useFeedback()
  return (
    <Grid container px={8} justifyContent="center" alignItems="center" height="100%">
      <Grid>
        <Paper
          sx={{
            py: { xs: 3, sm: 5 },
            px: { xs: 2, sm: 10 },
            width: { xs: '16rem', sm: '22rem' },
          }}
          elevation={16}
        >
          <Stack spacing={3} alignItems="center">
            <Typography fontWeight={600} variant="h5">
              {'Reenvía tu correo de confirmación !'}
            </Typography>
            <Typography
              fontWeight={500}
              color="textSecondary"
              variant="body1"
              sx={{ maxWidth: 400 }}
              align="center"
            >
              {`Por cuestiones de seguridad reenviaremos a tu  correo con un link para activar tu cuenta`}
            </Typography>
            <Box>
              <Lottie
                options={{
                  animationData: emailSent,
                  loop: true,
                }}
                width={180}
                height={180}
              />
            </Box>
            <TextField
              label="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
            <Typography
              fontWeight={500}
              variant="caption"
              sx={{ maxWidth: 300 }}
              align="center"
              color="textSecondary"
            >
              {`¿No recibiste ningún correo?`}
            </Typography>
            <Stack spacing={2}>
              <Typography
                fontWeight={600}
                variant="caption"
                sx={{ maxWidth: 300 }}
                align="center"
                color="textSecondary"
              >
                {`Revisa tu bandeja de spam`}
              </Typography>

              <LoadingButton
                onClick={(e) => {
                  e.preventDefault()
                  const resend = async () => {
                    try {
                      setLoading(true)
                      await resentEmailVerificationService(email ?? '')
                      showMessage('Correo reenviado', 'success', {
                        horizontal: 'right',
                        vertical: 'top',
                      })
                    } catch (e) {
                      showMessage('Error al reenviar correo', 'error', {
                        horizontal: 'right',
                        vertical: 'top',
                      })
                    } finally {
                      setLoading(false)
                    }
                  }
                  resend()
                }}
                loading={loading}
                disabled={email === ''}
              >
                {'Enviar correo'}
              </LoadingButton>
              <AnimatedLink to={`${ROUTES.login}`} type={'circle'} style={{ width: '100%' }}>
                <Button fullWidth size="medium" color="secondary">
                  <Typography
                    display={'flex'}
                    fontWeight={500}
                    variant="subtitle2"
                    textAlign="center"
                    color="textSecondary"
                    sx={{ color: 'white' }}
                  >
                    {'Ir a login'}
                  </Typography>
                </Button>
              </AnimatedLink>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  )
}

export { EmailResend as default} from './EmailResend'