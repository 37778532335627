import { ISupportChatRef, SupportChat } from 'components'
import React from 'react'

type Props = {}

export const SupportContext = React.createContext({} as Props & ISupportChatRef)

const Provider: React.FC = ({ children }) => {
  const chatRef = React.useRef<ISupportChatRef>(null)

  const handleOpenChat = (message: string) => {
    chatRef.current?.openChat(message)
  }

  return (
    <SupportContext.Provider
      value={{
        openChat: handleOpenChat,
      }}
    >
      {children}
      <SupportChat ref={chatRef} />
    </SupportContext.Provider>
  )
}

export default Provider
