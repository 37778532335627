import { Box } from '@mui/material'
import { DialogAcceptTermsAndCondition, DialogProcess, OuletSkeleton, Steps } from 'components'
import { SolutionThemeProvider } from 'context'
import { useAuth, useCart, useFeedback, useLoading, useProcess } from 'hooks'
import { useProcessSelection } from 'hooks/processSelection/useProcessSelection'
import {
  COMPLETED_TRANSACTION_ID,
  createProcessesService,
  getProductsByIdService,
  getSpecialProductsService,
  LOGIN_STATE_BY_MODAL,
  PENDING_TRANSACTION_ID,
} from 'lib'
import { IProcess, IProduct } from 'marketplace-common'
import React from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'
import { StepReferenceName } from 'types'

export const StepsLayout = () => {
  const { showMessage } = useFeedback()
  const { isLoadingReference } = useLoading()
  const { handleCloseAllDialog, openDialog } = useProcessSelection()
  const {
    loadingInitialize,
    initializeConfigureAfterLogin,
    getStepCurrentByProcess,
    changeStepIndex,
    switchNextStep,
    addEnableSteps,
  } = useProcess()
  const {
    process: processCart,
    handleChangeProcess,
    addProductToCart,
    addProductToCartNotLoged,
    addSpecialProductToCart,
    addSpecialProductToCartNotLoged,
  } = useCart()
  const {
    isAuthenticated,
    user,
    loading: loadingAuth,
    showSelectProcess,
    loginByModalState,
  } = useAuth()
  const [searchParams] = useSearchParams()

  const reference = searchParams.get('reference')

  const loadingCurrentStep = React.useMemo(() => {
    return isAuthenticated && isLoadingReference('stepValidation')
  }, [isAuthenticated, isLoadingReference])

  const loadingByReference = React.useMemo(() => {
    return isLoadingReference('solutionsByReference')
  }, [isLoadingReference])

  const handleProductsLogged = async (processId: number, productsId: number[]) => {
    const productsNotAdded: number[] = []
    for (const productId of productsId) {
      const isProductAdded = await addProductToCart({
        productId: productId,
        processId: processId,
      })
      if (!isProductAdded) {
        productsNotAdded.push(productId)
      }
    }
    return productsNotAdded
  }

  const handlePromosLogged = async (processId: number, promosId: number[]) => {
    const promosNotAdded: number[] = []
    for (const promoId of promosId) {
      const isPromoAdded = await addSpecialProductToCart({
        specialProductId: promoId,
        processId: processId,
      })
      if (!isPromoAdded) {
        promosNotAdded.push(promoId)
      }
    }
    return promosNotAdded
  }

  const handleProductsNotLogged = async (productsId: number[]) => {
    const productsNotAdded: number[] = []
    for (const productId of productsId) {
      try {
        const product: IProduct = await getProductsByIdService(productId)
        if (product.enabled) addProductToCartNotLoged(product)
      } catch (error) {
        console.error(error)
        productsNotAdded.push(productId)
        showMessage(
          'Ups!, parece que el producto que tratas de agregar no esta disponible para la compra',
          'error'
        )
      }
    }
    return productsNotAdded
  }

  const handlePromosNotLogged = async (promosId: number[]) => {
    const promosNotAdded: number[] = []
    try {
      const specialProducts = await getSpecialProductsService()

      promosId.forEach((promoId) => {
        const specialProduct = specialProducts.find(
          ({ specialProductId }) => specialProductId === promoId
        )
        if (Boolean(specialProduct)) {
          addSpecialProductToCartNotLoged(specialProduct!)
        } else {
          promosNotAdded.push(promoId)
          showMessage(
            'Ups!, parece que el producto que tratas de agregar no esta disponible para la compra',
            'error'
          )
        }
      })
    } catch (error) {
      console.error(error)
    }
    return promosNotAdded
  }

  const validateProductsAndPromosNotAdded = (
    productsNotAdded: number[],
    productsId: number[],
    promosNotAdded: number[],
    promosId: number[]
  ) => {
    if (
      productsNotAdded.length === productsId.length &&
      promosNotAdded.length === promosId.length
    ) {
      return
    }
    addEnableSteps(StepReferenceName.PICK_PRODUCT)
    switchNextStep()
  }

  const handleReference = async (process?: IProcess) => {
    if (!Boolean(reference)) return
    changeStepIndex(0)

    const productsId =
      reference
        ?.split(',')
        .filter((item) => item.includes('P'))
        .map((item) => Number(item.slice(1))) ?? []
    const promosId =
      reference
        ?.split(',')
        .filter((item) => item.includes('O'))
        .map((item) => Number(item.slice(1))) ?? []

    if (productsId.length === 0 && promosId.length === 0) return
    const transactionState =
      process?.history[process?.history.length - 1]?.state?.transactionStateId
    if (
      Boolean(process) &&
      (transactionState === COMPLETED_TRANSACTION_ID || transactionState === PENDING_TRANSACTION_ID)
    ) {
      changeStepIndex(3)
      return
    }

    if (Boolean(process)) {
      const productsNotAdded = await handleProductsLogged(process?.processId!, productsId)
      const promosNotAdded = await handlePromosLogged(process?.processId!, promosId)
      validateProductsAndPromosNotAdded(productsNotAdded, productsId, promosNotAdded, promosId)
      return
    }
    const productsNotAdded = await handleProductsNotLogged(productsId)
    const promosNotAdded = await handlePromosNotLogged(promosId)
    validateProductsAndPromosNotAdded(productsNotAdded, productsId, promosNotAdded, promosId)
  }

  const createProcess = async () => {
    try {
      const result = await createProcessesService({
        information: '{}',
        companyId: user.identification,
        securityUserId: user.userId,
      })
      await getStepCurrentByProcess(result)
      handleChangeProcess(result)
      return result
    } catch (error) {
      console.error(error)
    }
  }

  const validateByCreatedProcess = async () => {
    try {
      const result = await createProcess()
      handleReference(result)
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    const isNoneState = loginByModalState === LOGIN_STATE_BY_MODAL.NONE
    const isLoggedByModal = loginByModalState === LOGIN_STATE_BY_MODAL.LOGGED_BY_MODAL
    const isInitAfterLogin = loginByModalState === LOGIN_STATE_BY_MODAL.INITIALIZED_AFTER_LOGIN
    const isFinishProcess = loginByModalState === LOGIN_STATE_BY_MODAL.FINISH_PROCESS

    if (isLoggedByModal || isInitAfterLogin) return
    if (!isAuthenticated && !loadingAuth) handleReference()
    if (isAuthenticated && isFinishProcess) createProcess()
    if (isAuthenticated && isNoneState) validateByCreatedProcess()
  }, [user, loginByModalState])

  return (
    <React.Fragment>
      <DialogAcceptTermsAndCondition />
      <Box sx={{ display: 'flex', minHeight: '100vh', width: '100vw' }}>
        <Steps drawerWidth={280} />
        <Box
          pb={{ xs: 12, sm: 8, md: 0 }}
          component="main"
          sx={{
            flexGrow: 1,
            minHeight: '100%',
            height: 'fit-content',
            bgcolor: (theme) => (theme.palette.mode === 'light' ? '#EFEFEF' : '#000'),
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box>
            <SolutionThemeProvider>
              <React.Fragment>
                {loadingCurrentStep || loadingByReference || loadingAuth ? (
                  <OuletSkeleton />
                ) : (
                  <Outlet />
                )}
                <DialogProcess
                  openDialog={openDialog.includes('selectProcess') && showSelectProcess}
                  loadingInitialize={loadingInitialize}
                  processId={processCart?.processId ?? 0}
                  handleCloseDialog={() => {
                    handleCloseAllDialog()
                  }}
                  initializeConfigureAfterLogin={initializeConfigureAfterLogin}
                />
              </React.Fragment>
            </SolutionThemeProvider>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export { StepsLayout as default } from './StepsLayout'
