import { Divider, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ISolution, ISolutionProduct } from 'marketplace-common'
import { SummaryCard } from './SummaryCard'
import { SummaryExpiredCard } from './SummaryExpiredCard'
import { SummaryProductToActivateCard } from './SummaryProductToActivateCard'

type Props = {
  products?: ISolutionProduct[]
  productsToActivate?: ISolutionProduct[]
  expiredProducts?: ISolutionProduct[]
  solution: ISolution
}

export const SummaryProductSection = (props: Props) => {
  const { products, solution, expiredProducts, productsToActivate } = props

  return (
    <Box className={'my-product-summary-' + solution.reference + '-tour-step'}>
      <Typography>{'MIS PRODUCTOS'}</Typography>
      <Divider />
      <Grid py={3} container spacing={2}>
        {expiredProducts?.map((product) => (
          <Grid id="gridProductosExpirados" item xs={12} sm={6} md={4} key={product.productId}>
            <SummaryExpiredCard
              expiredProduct={product}
              showRenewButton={Boolean(productsToActivate?.length === 0) && !Boolean(products)}
            />
          </Grid>
        ))}
        {products?.map((product) => (
          <Grid id="gridProductos" item xs={12} sm={6} md={4} key={product.productId}>
            <SummaryCard
              product={product}
              renewProduct={Boolean(productsToActivate?.length === 0)}
            />
          </Grid>
        ))}
        {productsToActivate?.map((product) => (
          <Grid id="gridProductosPorActivar" item xs={12} sm={6} md={4} key={product.productId}>
            <SummaryProductToActivateCard productToActivate={product} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
