import { useLoading } from 'hooks'
import { getMainSolutionsByUserIdentificationService, getMainSolutionsService } from 'lib'
import { IDetailedSolution, ISolution } from 'marketplace-common'
import React from 'react'

/**
 * The type DetailedOrSimple depends of a generic type T that could be and string or (undefined, unknown)
 * and defines the return type
 */
type DetailedOrSimple<T extends string | (undefined | unknown)> = T extends string
  ? IDetailedSolution[]
  : ISolution[]

/**
 *
 * Returns a list of main solutions depending of prop userIdentification.
 * This hook will create a new instance every call.
 *
 * @param {T extends string | (undefined | unknown)} userIdentification
 * @returns An array of ISolutions if no params, or an array of IDetailedSolution if userIdentification is passed as param
 */

type Hook<T> = {
  solutions: DetailedOrSimple<T>
  loading: boolean
  getLogo: (mainSolutionId: number) => string | undefined
}

export const useGetSolutions = <T extends string | (undefined | unknown)>(
  userIdentification?: T
): Hook<T> => {
  const [solutions, setSolutions] = React.useState<DetailedOrSimple<T>>([])
  const [loading, setLoading] = React.useState(true)
  const { addLoadingReference, removeLoadingReference } = useLoading()

  const getMainSolutions = React.useCallback(async () => {
    /** The result and the method called depends of the userIdentification type */
    try {
      addLoadingReference('solutions')
      const result =
        userIdentification && typeof userIdentification === 'string'
          ? await getMainSolutionsByUserIdentificationService(userIdentification)
          : await getMainSolutionsService()
      setSolutions(result)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      removeLoadingReference('solutions')
    }
  }, [userIdentification])

  const getLogo = (mainSolutionId: number) => {
    const solution = solutions.find((solution) => solution.mainSolutionId === mainSolutionId)
    return solution?.logo
  }

  /**
   * Initial side effect.
   */
  React.useEffect(() => {
    getMainSolutions()    
  }, [getMainSolutions])

  return {
    solutions,
    loading,
    getLogo,
  }
}
