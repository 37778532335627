import { AccountForm, Checkout, PickProduct, InvoiceForm } from 'components'
import React from 'react'
import { StepReference } from 'types'

type Props = {
  reference: StepReference
}

/**
 *
 * Factory of process forms
 *
 * @param props reference: defines the rendered component
 * @returns A process form deoendign on the reference prop
 */
export const StepForms = (props: Props) => {
  const { reference } = props
  if (reference === 'account') return <AccountForm />
  if (reference === 'pickProduct') return <PickProduct />
  if (reference === 'payment') return <Checkout />
  if (reference === 'invoice') return <InvoiceForm />
  return <React.Fragment />
}
