import {
  Avatar,
  AvatarGroup,
  Button,
  Icon,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Box } from '@mui/system'
import { ExplorerBtn, TextEditorViewer } from 'components'
import { useCart, useFunctionShop } from 'hooks'
import { formatCurrency, getLandingByParamsService } from 'lib'
import React from 'react'
import { SOLUTION_COLORS } from 'styles'
import theme from 'styles/themes/main'
import { ILanding } from 'types'
import biosferasoftLogoMain from '../../assets/img/logo-biosferasoft-main.webp'

const initialLanding = {
  urlLanding: '',
  description: '[{"type":"paragraph","children":[{"text":""}],"align":"left"}]',
} as ILanding

type TextEditorValueType = {
  children: {
    fontFamily: string
  }[]
}

export const Landing = () => {
  const { nonEmptyCart } = useCart()
  const { handleAddProductByReference } = useFunctionShop()

  const [landing, setLanding] = React.useState<ILanding>()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isPromo = landing && landing.specialProductId !== null
  const uniqueLogos = [
    ...new Set(landing && landing.specialProductId && landing.specialProductId.logos),
  ]

  const getLandingByParams = async (
    url?: string,
    productId?: number,
    specialProductId?: number
  ) => {
    try {
      const result = await getLandingByParamsService(url, productId, specialProductId)
      const formattedLanding = {
        ...result,
        urlLanding: result.urlLanding.replace(process.env.REACT_APP_BASE_LANDING_URL!, ''),
      } as ILanding
      setLanding(formattedLanding)
    } catch (error) {
      setLanding(initialLanding)
      console.error(error)
    }
  }

  const getFontsByDescription = (data: TextEditorValueType[]) => {
    const fonts = data
      .map((item) =>
        item.children.filter((child) => child.fontFamily !== '' && Boolean(child.fontFamily))
      )
      .flat()
      .map((font) => font.fontFamily)

    return fonts
  }

  const getProductIdentifier = (landing: ILanding) => {
    if (landing.productId) {
      return `P${landing.productId.productId}`
    } else if (landing.specialProductId) {
      return `O${landing.specialProductId.specialProductId}`
    }
    return ''
  }

  React.useEffect(() => {
    const url = window.location.href

    if (Boolean(url)) {
      getLandingByParams(url)
    }
  }, [])

  return (
    <Paper
      sx={{
        width: { xs: '380px', sm: '900px' },
        height: '95%',
        margin: 'auto',
      }}
      elevation={5}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={2}
        justifyContent={'space-between'}
        alignItems={'center'}
        px={5}
        py={3}
      >
        <img
          src={biosferasoftLogoMain}
          alt={'Biosferasoft'}
          width="167"
          height="70"
          loading="lazy"
        />

        <ExplorerBtn applyPadding={true} />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={1}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box
          display={'flex'}
          flexDirection={isMobile ? 'column' : 'row'}
          gap={isMobile ? 1 : 3}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {isPromo ? (
            <AvatarGroup max={4}>
              {landing &&
                uniqueLogos.map((logo, i) => (
                  <Avatar
                    key={i}
                    sx={{
                      cursor: 'pointer',
                      width: { xs: 64, sm: 84 },
                      height: { xs: 64, sm: 84 },
                      backgroundColor: SOLUTION_COLORS.promo.primary.main,
                      color: SOLUTION_COLORS.promo.primary.dark,
                    }}
                    src={logo}
                  >
                    <Icon sx={{ fontSize: { xs: 28, sm: 32, md: 40 } }}>local_offer</Icon>
                  </Avatar>
                ))}
            </AvatarGroup>
          ) : (
            <Avatar
              src={landing && landing.productId !== null ? landing && landing.productId?.logo : ''}
              sx={{
                cursor: 'pointer',
                width: { xs: 50, sm: 124 },
                height: { xs: 50, sm: 124 },
              }}
            />
          )}

          <Box display={'flex'} flexDirection={'column'} gap={1} alignItems={'center'}>
            <Box>
              <Tooltip
                title={
                  isPromo ? landing.specialProductId?.name ?? '' : landing?.productId?.name ?? ''
                }
              >
                <Box height={'30px'}>
                  <Typography
                    variant="caption"
                    align="center"
                    fontWeight={700}
                    color="secondary"
                    sx={{
                      fontSize: '1.2rem',
                      lineHeight: '18px',
                      px: '20px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      whiteSpace: 'normal',
                    }}
                  >
                    {isPromo
                      ? landing.specialProductId?.name ?? ''
                      : landing?.productId?.name ?? ''}
                  </Typography>
                </Box>
              </Tooltip>
              <Typography
                variant="h5"
                align="center"
                fontWeight={600}
                sx={{
                  fontSize: '1.7rem',
                }}
              >
                {`${formatCurrency(
                  isPromo
                    ? landing.specialProductId?.subTotalPrice ?? 0
                    : landing?.productId?.subTotalPrice ?? 0
                )} + IVA`}
              </Typography>
            </Box>
            <Button
              sx={{
                height: nonEmptyCart && isMobile ? 40 : 35,
                borderRadius: 1,
                width: 180,
                paddingY: { xs: 0.9, sm: 0.8 },
                paddingX: 0,
              }}
              size="small"
              onClick={() => {
                if (!landing) return
                const reference = getProductIdentifier(landing)
                handleAddProductByReference(reference)
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  display: 'flex',
                  gap: 0.7,
                  alignItems: 'center',
                  fontWeight: 600,
                  lineHeight: { xs: 1.1, sm: 1.75 },
                  fontSize: { xs: 12, sm: 13 },
                }}
              >
                {'Comprar'}
                <Icon sx={{ padding: 0, margin: 0, fontSize: { xs: 13, sm: 17 } }}>storefront</Icon>
              </Typography>
            </Button>
          </Box>
        </Box>

        <Box width={{ xs: 340, sm: 800 }}>
          {landing && (
            <TextEditorViewer
              usedFonts={getFontsByDescription(JSON.parse(landing.description ?? ''))}
              initialValue={landing.description}
            />
          )}
        </Box>
      </Box>
    </Paper>
  )
}

export { Landing as default } from './Landing'
