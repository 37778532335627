import { FeedbackLayout } from 'layouts/FeedbackLayout'
import { ROUTES } from 'lib'

import React, { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const Acquired = React.lazy(() => import('pages/Processes/Acquired'))

const VerificationSent = React.lazy(() => import('pages/Auth/VerificationSent'))

const AccountVerified = React.lazy(() => import('pages/Auth/AccountVerified'))

const VerificationResend = React.lazy(() => import('pages/Auth/VerificationResend'))

const EmailResend = React.lazy(() => import('pages/Auth/EmailResend'))

const Policy = React.lazy(() => import('pages/Policy/Policy'))

const Terms = React.lazy(() => import('pages/Terms/Terms'))

const FeedbackRoutes: RouteObject = {
  path: ROUTES.feedback,
  element: <FeedbackLayout />,
  children: [
    {
      path: ROUTES.acquired,
      element: (
        <Suspense fallback={<></>}>
          <Acquired />
        </Suspense>
      ),
    },
    {
      path: ROUTES.verificationSent,
      element: (
        <Suspense fallback={<></>}>
          <VerificationSent />
        </Suspense>
      ),
    },
    {
      path: ROUTES.accountVerified,
      element: (
        <Suspense fallback={<></>}>
          <AccountVerified />
        </Suspense>
      ),
    },
    {
      path: ROUTES.verificationResend,
      element: (
        <Suspense fallback={<></>}>
          <VerificationResend />
        </Suspense>
      ),
    },
    {
      path: ROUTES.resendEmailVerification,
      element: (
        <Suspense fallback={<></>}>
          <EmailResend />
        </Suspense>
      ),
    },
    {
      path: ROUTES.policy,
      element: (
        <Suspense fallback={<></>}>
          <Policy />
        </Suspense>
      ),
    },
    {
      path: ROUTES.terms,
      element: (
        <Suspense fallback={<></>}>
          <Terms />
        </Suspense>
      ),
    },
  ],
}

export default FeedbackRoutes
