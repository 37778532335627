import { changePasswordService } from 'lib'
import React from 'react'
type Hook = {
  userInformation: UserChangePasswordInformation
  loading: boolean
  switchLoading: () => void
  sendChangePasswordRequest: () => Promise<any>
  changeData: (key: keyof UserChangePasswordInformation, value: string | number) => void
}

type UserChangePasswordInformation = {
  password: string
  passwordConfirmation: string
  userId: number
}

export const useRestorePassword = (): Hook => {
  const [userChangePasswordInformation, setUserChangePasswordInformation] =
    React.useState<UserChangePasswordInformation>({} as UserChangePasswordInformation)
  const [loading, setLoading] = React.useState<boolean>(false)

  const changeData = (key: keyof UserChangePasswordInformation, value: string | number) => {
    setUserChangePasswordInformation((prev) => ({ ...prev, [key]: value }))
  }
  const sendChangePasswordRequest = React.useCallback(() => {
    try {
      setLoading(true)
      if (
        userChangePasswordInformation.password !==
        userChangePasswordInformation.passwordConfirmation
      ) {
        throw new Error('Las contraseñas no coinciden')
      }
      return changePasswordService(
        userChangePasswordInformation.userId,
        userChangePasswordInformation.password
      )
    } catch (error) {
      throw new Error(
        'Ocurrió un error al cambiar la contraseña ,  si esto persiste comunícate con soporte'
      )
    }
  }, [userChangePasswordInformation])

  const switchLoading = () => {
    setLoading((prev) => !prev)
  }
  return {
    userInformation: userChangePasswordInformation,
    loading,
    switchLoading,
    sendChangePasswordRequest,
    changeData,
  }
}
