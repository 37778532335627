import { SupportLayout } from "layouts"
import { PARAMS, ROUTES } from "lib"
import { ChangePassword, ForgotPassword } from "pages"
import { RouteObject } from "react-router-dom"

const SupportRoutes: RouteObject = {
    path: ROUTES.support,
    element: <SupportLayout />,
    children: [
        {
            path: ROUTES.forgotPassword,
            element: <ForgotPassword />
        },
        {
            path: ROUTES.changePassword,
            element: <SupportLayout />,
            children: [
                {
                    path: PARAMS.changePassword,
                    element: <ChangePassword />
                }
            ]
        }
    ]
}

export default SupportRoutes