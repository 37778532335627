import { IInvoiceItem } from 'marketplace-common'
import { IItem } from 'types'

export const generateItemsFromInvoice = (products: IInvoiceItem[]) => {
  const items: IItem[] = []
  for (const product of products) {
    const { item, productId, quantity, unitPrice } = product
    const invoiceItem: IItem = {
      item_id: productId.toString(),
      item_name: item,
      price: Number(unitPrice.slice(1)),
      quantity: quantity,
    }
    items.push(invoiceItem)
  }
  return items
}
