import { LoadingButton } from '@mui/lab'
import { Box, Icon, Paper, Stack, TextField, Typography } from '@mui/material'
import person from 'assets/animations/person.json'
import {
  useAuth,
  useCreateCompany,
  useCreateProcess,
  useFeedback,
  useNavigate,
  useShop,
} from 'hooks'
import { ROUTES } from 'lib'
import React from 'react'
import Lottie from 'react-lottie'

export const CompanyForm = () => {
  const { user } = useAuth()
  const { showMessage } = useFeedback()
  const { cart, removeItem, nonEmptyCart } = useShop()
  const { loading: loadingProcesses } = useCreateProcess()
  const [companyName, setCompanyName] = React.useState<string>(user.userName ?? '')
  const { animatedNavigation } = useNavigate()

  const createCallback = async () => {
    if (nonEmptyCart) {
      try {
        animatedNavigation(`${ROUTES.processes}/${cart[0].reference}`, 'circle')
        for (const solution of cart) {
          removeItem(solution)
        }
      } catch (error) {
        showMessage(
          'No se pudo continuar con el carrito, para mas información contactate con soporte',
          'error',
          { horizontal: 'right', vertical: 'top' }
        )
      }
    } else {
      animatedNavigation(`${ROUTES.explore}/${ROUTES.solutions}`, 'circle')
    }
  }

  const { createCompany, loading: loadingCompany } = useCreateCompany(createCallback)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      createCompany(user.identification, companyName)
      showMessage('Razon social guardada!', 'success')
    } catch (error) {
      showMessage('No se pudo completar la acción', 'error')
    }
  }

  return (
    <Paper
      elevation={0}
      sx={{
        p: 4,
      }}
      component="form"
      onSubmit={handleSubmit}
    >
      <Stack direction={'row'}>
        <Box
          width={180}
          height={180}
          borderRadius="100vw"
          bgcolor={(theme) => `${theme.palette.primary.main}`}
          position="relative"
        >
          <Lottie
            options={{
              animationData: person,
              loop: true,
            }}
            width={280}
            height={280}
            style={{
              position: 'absolute',
              top: -80,
              left: '-50%',
            }}
          />
        </Box>
        <Stack mx={5} spacing={2}>
          <Typography variant="h4" fontWeight={600}>
            {'Ingresa tu nombre'}
          </Typography>
          <Typography maxWidth={350} color="textSecondary">
            {`Para continuar coloca tu nombre de cliente o la razón social correspondiente a la identificación ${user.identification}`}
          </Typography>
          <TextField
            variant="standard"
            placeholder="Nombre de cliente o razón social"
            autoFocus
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            onFocus={(e) => e.target.select()}
            required
          />
          <LoadingButton
            endIcon={<Icon>navigate_next</Icon>}
            type="submit"
            loading={loadingProcesses || loadingCompany}
          >
            {'Continuar'}
          </LoadingButton>
        </Stack>
      </Stack>
    </Paper>
  )
}
