import { LoadingButton } from '@mui/lab'
import { Box, Button, Icon, Paper, Typography, useMediaQuery } from '@mui/material'
import success from 'assets/animations/payment-success.json'
import { AnimatedLink } from 'components/animated/Link'
import { COMPLETED_TRANSACTION_ID, ROUTES } from 'lib'
import { ITransactionState } from 'marketplace-common'
import React from 'react'
import Lottie from 'react-lottie'
import theme from 'styles/themes/main'
import { IJsonGetter } from 'types'
type Props = {
  state: ITransactionState
  onSuccess: () => void
  loadingCompleteProcess: boolean
}

const ICONS: IJsonGetter = {
  1: 'info_outlined',
  6: 'access_time',
  3: 'cancel',
}

const LABELS: IJsonGetter = {
  1: 'Felicitaciones',
  6: 'Procesando',
}

const PRIMARY_DESCRIPTION: IJsonGetter = {
  1: 'Tu pago se ha realizado con exito',
  6: 'Tu solicitud está siendo validada, este proceso no suele tardar mas de 24 horas.',
}

const SECONDARY_DESCRIPTION: IJsonGetter = {
  1: 'Estas a un solo paso de adquirir nuestros productos!',
  6: 'Nosotros te haremos saber cuando este lista.',
}

export const TransactionStatus = (props: Props) => {
  const { state, onSuccess, loadingCompleteProcess } = props
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      width={'100%'}
      height={'100vh'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      position={'relative'}
    >
      <Box
        sx={{
          bgcolor: 'primary.main',
          width: '15vw',
          height: '15vw',
          position: 'absolute',
          marginLeft: 30,
          marginTop: 10,
          zIndex: 0,
          borderRadius: '100vw',
        }}
      />
      <Box
        sx={{
          bgcolor: 'primary.main',
          width: '1vw',
          height: '1vw',
          position: 'absolute',
          marginLeft: 65,
          marginTop: -15,
          zIndex: 0,
          borderRadius: '100vw',
        }}
      />
      <Box
        sx={{
          bgcolor: 'primary.main',
          width: '2vw',
          height: '2vw',
          position: 'absolute',
          marginLeft: -50,
          marginTop: -30,
          zIndex: 0,
          borderRadius: '100vw',
        }}
      />
      {!isMobile && (
        <Box
          sx={{
            position: 'absolute',
            marginLeft: -50,
            marginTop: -30,
            zIndex: 0,
          }}
        >
          <Lottie
            options={{
              animationData: success,
              loop: true,
            }}
            width={300}
            height={300}
          />
        </Box>
      )}

      <Paper
        elevation={0}
        sx={{
          backgroundColor: 'rgba(255,255,255,0.5)',
          backdropFilter: 'blur(15px)',
          maxWidth: 450,
          pt: 2,
        }}
      >
        <Typography
          variant="h5"
          fontWeight={800}
          align="center"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Icon sx={{ mr: 1 }} fontSize="medium">
            {ICONS[state.transactionStateId]}
          </Icon>
          {LABELS[state.transactionStateId]}
        </Typography>
        <Typography
          variant="body2"
          align="center"
          fontWeight={400}
          sx={{ my: 2, maxWidth: '80%', marginLeft: '10%' }}
        >
          <span>{PRIMARY_DESCRIPTION[state.transactionStateId]}</span>
          <br />
          <span>{SECONDARY_DESCRIPTION[state.transactionStateId]}</span>
        </Typography>
        <Box
          bgcolor={(theme) => theme.palette.background.paper}
          pt={2}
          pb={2}
          width={'100%'}
          display="flex"
          flexDirection={'column'}
          alignItems="center"
        >
          {state.transactionStateId !== COMPLETED_TRANSACTION_ID ? (
            <React.Fragment>
              <AnimatedLink
                to={`${ROUTES.user}/${ROUTES.marketplace}/${ROUTES.solutions}`}
                type="circle"
              >
                <Button size="medium" sx={{ px: 8 }}>
                  {'Vamos a tu Dashboard'}
                </Button>
              </AnimatedLink>
            </React.Fragment>
          ) : (
            <LoadingButton
              disabled={loadingCompleteProcess}
              loading={loadingCompleteProcess}
              sx={{ pl: 6, pr: 5 }}
              endIcon={<Icon>chevron_right</Icon>}
              onClick={() => {
                onSuccess()
              }}
            >
              {'Finalizar compra'}
            </LoadingButton>
          )}
        </Box>
      </Paper>
    </Box>
  )
}
