import { Box, Button, Icon, Stack, Typography, useMediaQuery } from '@mui/material'
import { ShoppingCart } from 'components'
import {
  useNavigate as useAnimatedNavigate,
  useAuth,
  useCart,
  useFeedback,
  useFunctionShop,
  useGetCompany,
} from 'hooks'
import { ROUTES } from 'lib'
import theme from 'styles/themes/main'

interface Props {
  unregisteredCompany?: boolean
}

export const NavBar = (props: Props) => {
  const { unregisteredCompany } = props
  const { cartProducts, nonEmptyCart } = useCart()
  const { showMessage } = useFeedback()
  const { user } = useAuth()
  const { getCompanyById } = useGetCompany()
  const { animatedNavigation } = useAnimatedNavigate()
  const { handleContinueShop } = useFunctionShop()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleExplore = async () => {
    try {
      await getCompanyById(user.identification)
      animatedNavigation(`${ROUTES.explore}/${ROUTES.solutions}`, 'circle')
    } catch (error) {
      showMessage('Para continuar guarda la razón social de tu empresa', 'info')
    }
  }

  return (
    <Stack
      width={'100%'}
      flexDirection="column"
      bgcolor={(theme) => theme.palette.alt?.background}
      id="navBar"
    >
      <Box
        id="searchShopCart"
        display="flex"
        flexDirection={{ xs: 'column-reverse', sm: 'row' }}
        justifyContent="flex-end"
        p={3}
      >
        <Box id="nav-box" display="flex" alignItems={{ xs: 'flex-start', sm: 'center' }} gap={1}>
          <Button
            className="view-products-tour-steps"
            sx={{
              borderRadius: 1,
              width: 180,
              height: nonEmptyCart && isMobile ? 41 : 35,
              paddingY: { xs: 0.9, sm: 0.8 },
              paddingX: 2,
            }}
            size="medium"
            onClick={handleExplore}
          >
            <Typography
              variant="caption"
              sx={{
                display: 'flex',
                gap: 0.7,
                alignItems: 'center',
                fontWeight: 600,
                lineHeight: { xs: 1.1, sm: 1.75 },
                fontSize: { xs: 12, sm: 13 },
              }}
            >
              {'Ver Productos'}
              <Icon sx={{ padding: 0, margin: 0, fontSize: { xs: 13, sm: 17 } }}>apps</Icon>
            </Typography>
          </Button>
          {!nonEmptyCart && (
            <Button
              className="buy-tour-steps"
              sx={{
                height: nonEmptyCart && isMobile ? 40 : 35,
                borderRadius: 1,
                width: 180,
                paddingY: { xs: 0.9, sm: 0.8 },
                paddingX: 0,
              }}
              size="medium"
              onClick={handleContinueShop}
            >
              <Typography
                variant="caption"
                sx={{
                  display: 'flex',
                  gap: 0.7,
                  alignItems: 'center',
                  fontWeight: 600,
                  lineHeight: { xs: 1.1, sm: 1.75 },
                  fontSize: { xs: 12, sm: 13 },
                }}
              >
                {'Comprar'}
                <Icon sx={{ padding: 0, margin: 0, fontSize: { xs: 13, sm: 17 } }}>storefront</Icon>
              </Typography>
            </Button>
          )}
          <ShoppingCart
            cart={cartProducts}
            nonEmptyCart={nonEmptyCart}
            disableContinue={unregisteredCompany}
          />
        </Box>
      </Box>
    </Stack>
  )
}
