import { useLocalStorage } from 'hooks'
import { LOCAL_STORAGE_CART_ITEMS_KEY } from 'lib'
import React from 'react'
import { ISolution } from 'marketplace-common'

type Hook = {
  cart: ISolution[]
  addItem: (item: ISolution) => void
  removeItem: (item: ISolution) => void
  nonEmptyCart: boolean
}

/**
 *
 * //TODO store solutions by user
 *
 * @returns all the solutions that were added to the cart,
 * a function to add a solution to the cart
 * a function to remove a solution from the cart
 * a boolean that shows if the cart is empty
 */
export const useShop = (): Hook => {
  const { storeItem, getItem } = useLocalStorage()
  const [cart, setCart] = React.useState<ISolution[]>([])
  const addItem = (item: ISolution) => setCart((current) => [...current, item])
  const removeItem = (item: ISolution) =>
    setCart((current) => current.filter((c) => c.mainSolutionId !== item.mainSolutionId))

  /**get the stored items in the local storage */
  const initCart = React.useCallback(() => {
    const saved = getItem(LOCAL_STORAGE_CART_ITEMS_KEY)
    if (Boolean(saved)) {
      setCart(saved)
    }
  }, [getItem])

  const nonEmptyCart = React.useCallback(() => Boolean(cart.length > 0), [cart])

  React.useEffect(() => {
    initCart()
  }, [initCart])

  React.useEffect(() => {
    storeItem(LOCAL_STORAGE_CART_ITEMS_KEY, cart)
  }, [cart, storeItem])

  return {
    cart,
    nonEmptyCart: nonEmptyCart(),
    addItem,
    removeItem,
  }
}
