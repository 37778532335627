import { LoadingButton } from '@mui/lab'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  Typography,
} from '@mui/material'

type Props = {
  open: boolean
  onAccept: (e: React.FormEvent) => Promise<void>
  onCancel: () => void
  loading: boolean
}

export const DialogConfirmNewData = (props: Props) => {
  const { open, onAccept, onCancel, loading } = props
  return (
    <Dialog
      open={open}
      onClose={() => {
        onCancel()
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        <Typography variant="caption" fontSize={19} fontWeight={700}>
          {'¿Estás seguro de actualizar tu correo electrónico?'}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ textAlign: 'center', fontWeight: 700 }}
        >
          <Typography variant="caption" fontSize={15} fontWeight={500}>
            {
              '¿Si modificas tu correo electrónico debes tomar en cuenta que ahora deberás ingresar a tu cuenta con el nuevo correo?'
            }
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            gap: { xs: 2 },
          }}
        >
          <LoadingButton
            endIcon={<Icon>check</Icon>}
            disabled={loading}
            loading={loading}
            sx={{ width: '150px' }}
            onClick={(e) => {
              onAccept(e)
            }}
          >
            SI
          </LoadingButton>
          <LoadingButton
            endIcon={<Icon>close</Icon>}
            sx={{
              width: '150px',
              marginLeft: 0,
              '&:hover': {
                opacity: ' 0.7 !important',
              },
            }}
            onClick={() => {
              onCancel()
            }}
          >
            NO
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default DialogConfirmNewData
