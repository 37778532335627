export const enum Browser {
  FIREFOX = 'Firefox',
  EDGE = 'Microsoft Edge',
  CODE_EDGE = 'Edg',
  CHROME_IOS = 'Google Chrome en iOS',
  CODE_CHROME_IOS = 'CriOS',
  FIREFOX_IOS = 'Firefox en iOS',
  CODE_FIREFOX_IOS = 'FxiOS',
  OPERA = 'Opera',
  CODE_OPERA = 'OPR',
  BRAVE = 'Brave',
  CHROME = 'Google Chrome',
  UNKNOWN = 'Desconocido',
}
