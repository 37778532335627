import { useAuth, useNavigate } from 'hooks'
import { ROUTES } from 'lib'

interface Hook {
  handleContinueShop: () => void
  handleAddProductByReference: (id: string) => void
}

export const useFunctionShop = (): Hook => {
  const { isAuthenticated } = useAuth()
  const { animatedNavigation } = useNavigate()

  const handleShop = async () => {
    try {
      const route = `${ROUTES.processes}/${'nuna'}`
      animatedNavigation(route)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleShopNotLogged = async () => {
    try {
      const processNoLoggedRoute = `${ROUTES.processes}/nuna`
      animatedNavigation(processNoLoggedRoute)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleContinueShop = () => {
    isAuthenticated ? handleShop() : handleShopNotLogged()
  }

  const handleAddProductByReference = (id: string) => {
    try {
      const url = `${ROUTES.processes}?reference=${id}`
      animatedNavigation(url)
    } catch (error) {
      console.log('error', error)
    }
  }
  return {
    handleContinueShop,
    handleAddProductByReference,
  }
}
