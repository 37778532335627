import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogTitle, Typography } from '@mui/material'
import { useAuth } from 'hooks'
import { ROUTES } from 'lib'
import React from 'react'
import { Link } from 'react-router-dom'
import { MAIN_COLORS } from 'styles'

export const DialogAcceptTermsAndCondition = () => {
  const { user, handleLoading, acceptTermsAndConditions, refuseTermsAndCondition, loadingButtons } =
    useAuth()

  const [openConditionTermsDialog, setOpenConditionTermsDialog] = React.useState<boolean>(false)

  const loadingAccept = loadingButtons.includes('accept')
  const loadingRefuse = loadingButtons.includes('refuse')

  const handleOpenDialog = () => setOpenConditionTermsDialog(true)
  const handleCloseDialog = () => setOpenConditionTermsDialog(false)

  const verifyTermsAndConditions = () => {
    const termsAndConditionAccepted = Boolean(user?.allowedConditionTerms)
    if (!termsAndConditionAccepted) {
      handleLoading(true)
      handleOpenDialog()
      return
    }
    handleCloseDialog()
  }

  React.useEffect(() => {
    const existsUserId = Boolean(user.userId)
    if (existsUserId) verifyTermsAndConditions()
  }, [user])

  return (
    <Dialog
      open={openConditionTermsDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ display: 'flex' }}>
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          {'Es necesario se revise y se acepte los '}
          <Link to={`/${ROUTES.feedback}/${ROUTES.terms}`} target="_blank">
            <span style={{ color: 'blue', fontWeight: 'bold' }}>{'términos y condiciones'}</span>
          </Link>
          {' de marketplace.'}
        </Typography>
      </DialogTitle>

      <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <LoadingButton
          sx={{
            color: MAIN_COLORS.primary.main,
            backgroundColor: MAIN_COLORS.alt.background,
            border: `1px solid ${MAIN_COLORS.primary.main}`,
            '&:hover': {
              color: MAIN_COLORS.alt.background,
            },
          }}
          loading={loadingRefuse}
          disabled={loadingAccept}
          onClick={refuseTermsAndCondition}
        >
          {'Rechazar'}
        </LoadingButton>
        <LoadingButton
          loading={loadingAccept}
          disabled={loadingRefuse}
          onClick={() => acceptTermsAndConditions(user.userId)}
        >
          {'Aceptar'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
