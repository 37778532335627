import { Box } from '@mui/material'
import { LoadingProvider, ProcessProvider, ProcessSelectionProvider } from 'context'
import { Outlet } from 'react-router-dom'

export function ProcessLayout() {
  return (
    <LoadingProvider>
      <ProcessSelectionProvider>
        <ProcessProvider>
          <Box
            sx={{
              display: 'flex',
              overflow: 'hidden',
              maxHeight: '100vh',
              overflowY: 'auto',
            }}
          >
            <Outlet />
          </Box>
        </ProcessProvider>
      </ProcessSelectionProvider>
    </LoadingProvider>
  )
}
