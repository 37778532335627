import { useGetCompany } from 'hooks/company/useGetCompany'
import React from 'react'

type Hook = {
  initializing: boolean
  unregisteredCompany: boolean
}

export const useVerifyCompany = (companyId: string, onCreate?: () => void): Hook => {
  const [initializing, setInitializing] = React.useState<boolean>(true)
  const [unregisteredCompany, setUnregisteredCompany] = React.useState<boolean>(false)
  const { getCompanyById } = useGetCompany()

  const findCompany = React.useCallback(async () => {
    try {
      await getCompanyById(companyId)
    } catch (error) {
      setUnregisteredCompany(true)
    } finally {
      setInitializing(false)
    }
  }, [getCompanyById, companyId])

  React.useEffect(() => {
    findCompany()
  }, [findCompany])

  return {
    initializing,
    unregisteredCompany,
  }
}
