import { createTheme, ThemeOptions } from '@mui/material/styles'
import { MAIN_COLORS } from '../colors/colors'
import type { } from '@mui/lab/themeAugmentation'
import { DEFAULT_BREACKPOINTS } from 'styles'

declare module '@mui/material/styles' {
  interface Palette {
    alt?: { background: string }
  }
  interface PaletteOptions {
    alt?: { background: string }
  }
}

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: MAIN_COLORS.primary.main,
    },
    secondary: {
      main: MAIN_COLORS.secondary.main,
    },
    info: {
      main: MAIN_COLORS.primary.main,
    },
    alt: {
      background: MAIN_COLORS.alt.background,
    },
    success: {
      main: MAIN_COLORS.success.main,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          textTransform: 'none',
          fontWeight: 600,
          '&:hover': {
            opacity: ' 0.7 !important',
          },
        },
      },
      defaultProps: {
        size: 'small',
        color: 'primary',
        variant: 'contained',
        disableElevation: true,
        disableRipple: true,
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            opacity: ' 0.7 !important',
          },
        },
      },
      defaultProps: {
        size: 'small',
        color: 'primary',
        variant: 'contained',
        disableElevation: true,
        disableRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
      defaultProps: {
        size: 'small',
      },
    },
    MuiIcon: {
      defaultProps: {
        fontSize: 'small',
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderLeft: 'none',
          borderRight: 'none',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `solid 1px ${MAIN_COLORS.primary.main}30`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          paddingLeft: 30,
          paddingRight: 30,
          minHeight: 0,
        },
      },
      defaultProps: {
        iconPosition: 'start',
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        group: {
          marginLeft: 0,
        },
        content: {
          backgroundColor: 'transparent !important',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    fontSize: 14,
  },
  breakpoints: DEFAULT_BREACKPOINTS,
}

const theme = createTheme(themeOptions)

export default theme
