export const getItem = (key: string): any | undefined => {
  const value = localStorage.getItem(key)
  try {
    if (value) {
      const json = JSON.parse(value)
      return json
    }
  } catch (error) {
    if (value) {
      const json = JSON.parse(JSON.stringify(value))
      return json
    }
    return undefined
  }
}
