import {
  Avatar,
  Box,
  Dialog,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { useGetProductsRepeact, useGetSolutions } from 'hooks'
import { Fragment } from 'react'
import { ICartProduct } from 'marketplace-common'

interface Props {
  open: boolean
  products: ICartProduct[]
  handleClose: (dialog: string) => void
}

export const LicencesDialog = (props: Props) => {
  const { open, products, handleClose } = props
  const { grouped } = useGetProductsRepeact()
  const { solutions } = useGetSolutions()
  const dataProducts = grouped(products)

  const getDateValidate = (months: number) => {
    const years = Math.floor(months / 12)
    const monthsRemaining = months % 12
    return `${years > 0 && years >= 1 ? `${years} ${years > 1 ? 'años' : 'año'}` : ''} ${
      monthsRemaining > 0 && monthsRemaining >= 1
        ? `${years > 0 ? 'y ' : ''}${monthsRemaining}  ${monthsRemaining > 1 ? 'meses' : 'mes'}`
        : ''
    }`
  }

  const getLogo = (mainSolutionId: number | undefined) => {
    const solution = solutions.find((solution) => solution.mainSolutionId === mainSolutionId)
    return solution?.logo
  }

  return (
    <Fragment>
      {products && (
        <Dialog
          open={open}
          onClose={() => {
            handleClose('Detail')
          }}
          maxWidth="sm"
        >
          <AnimatePresence>
            <Stack py={1} px={4} position="relative">
              <Stack alignItems={'center'} justifyContent={'center'} p={3} spacing={1}>
                <Icon sx={{ color: 'green', fontSize: 33 }}>check_circle</Icon>
                <Typography variant="h6" align="center">
                  {'Detalles de los productos'}
                </Typography>
              </Stack>
              <TableContainer sx={{ paddingBottom: '20px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600, textAlign: 'left', width: '75%' }}>
                        Producto
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, textAlign: 'right', width: '10%' }}>
                        Cantidad
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, textAlign: 'right', width: '15%' }}>
                        Vigencia
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.length > 0 ? (
                      dataProducts.map((product) => (
                        <TableRow key={product.productId} sx={{ '& > *': { paddingY: '2px' } }}>
                          <TableCell sx={{ fontSize: '12px', width: '75%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Avatar
                                alt="Remy Sharp"
                                src={getLogo(product.product?.mainSolutionId)}
                                sx={{ width: 24, height: 24 }}
                              />
                              {product.product?.name}
                            </Box>
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{product.count} </TableCell>
                          <TableCell sx={{ textAlign: 'right' }}>
                            {product.product?.validityMonths
                              ? getDateValidate(Number(product?.product.validityMonths))
                              : product?.product!.validityDays}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        <TableRow sx={{ '& > *': { paddingY: '0px' } }}>
                          <TableCell colSpan={1} sx={{ textAlign: 'center' }}>
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell colSpan={1} sx={{ textAlign: 'center' }}>
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell colSpan={1} sx={{ textAlign: 'center' }}>
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '& > *': { paddingY: '0px' } }}>
                          <TableCell colSpan={1} sx={{ textAlign: 'center' }}>
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell colSpan={1} sx={{ textAlign: 'center' }}>
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell colSpan={1} sx={{ textAlign: 'center' }}>
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                display={'flex'}
                width={'100%'}
                justifyContent={'flex-end'}
                pr={5}
                position="absolute"
                right={0}
              >
                <Tooltip title="Cerrar" arrow>
                  <IconButton
                    sx={{
                      fontSize: { xs: 20, sm: 25 },
                      right: { xs: -20, sm: -25 },
                      top: { xs: 15, sm: -2 },
                    }}
                    onClick={() => handleClose('Licences')}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          </AnimatePresence>
        </Dialog>
      )}
    </Fragment>
  )
}
