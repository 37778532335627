import { Box, Grid, Icon, IconButton, Paper, Typography } from '@mui/material'
import { useAuth, useNavigate, useSupport } from 'hooks'
import { ROUTES } from 'lib'

const LOGIN_ROUTE = `${ROUTES.login}`
const SOLUTION_ROUTE = `/${ROUTES.user}/${ROUTES.marketplace}/${ROUTES.solutions}`

export const Policy = () => {
  const { openChat } = useSupport()
  const { animatedNavigation } = useNavigate()
  const { isAuthenticated } = useAuth()
  const handleRequestHelp = () => {
    const message = `Hola requiero ayuda con la política de privacidad`
    openChat(message)
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper sx={{ width: '90%', height: '90%', overflow: 'auto' }}>
        <Grid container direction="row" sx={{ margin: 2, maxWidth: '95%' }}>
          <Grid item xs={12}>
            <IconButton
              onClick={(e) =>
                animatedNavigation(isAuthenticated ? SOLUTION_ROUTE : LOGIN_ROUTE, 'circle')
              }
              size="medium"
              sx={{ mr: 2 }}
            >
              <Icon fontSize="medium">chevron_left</Icon>{' '}
              <Typography sx={{ fontSize: '0.7em ', fontWeight: 'bold' }}>Regresar</Typography>
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{ textAlign: 'center', fontSize: '1.2em', fontWeight: 'bold' }}
            >
              POLÍTICA DE PRIVACIDAD
            </Typography>
          </Grid>
          {/*  */}
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              BIOSFERASOFT de conformidad con las disposiciones de la Ley Orgánica de Protección de
              Datos Personales, publicada en el 5to Suplemento del Registro Oficial Nro. 459, de 26
              de mayo de 2021, y demás normativa conexa en materia de protección de datos vigente en
              el Ecuador, informa los términos y condiciones de privacidad de datos personales, así
              como las políticas y prácticas de protección de datos personales, uso de cookies y
              spam del sitio web de la compañía BIOSFERASOFT SAS. (en adelante “BIOSFERASOFT”), al
              que se accede por medio de la URL https://marketplace.biosferasoft.com y sus dominios
              de nivel inferior (el "sitio web" o la "página web”).
              <br />
              <br />
              Revise atentamente estos términos y condiciones, ya que le servirán para comprender
              las actividades, alcance del tratamiento de datos personales que realiza BIOSFERASOFT,
              así como sus finalidades, a fin de que comparta su información personal con nosotros.
              Estos términos y condiciones se aplican solo a la información recopilada a través de
              la página web.
              <br />
              <br />
              La recogida y tratamiento automatizado de los datos de carácter personal tiene como
              finalidad el mantenimiento de la relación comercial y el desempeño de tareas de
              información, formación, asesoramiento y otras actividades propias de BIOSFERASOFT.
              <br />
              <br />
              Estos datos únicamente serán compartidos a aquellas entidades que sean necesarias con
              el único objetivo de dar cumplimiento a la finalidad anteriormente expuesta.
              <br />
              <br />
              BIOSFERASOFT podrá actualizar la presente Política de Privacidad en cualquier momento,
              respetando las obligaciones los derechos del ordenamiento jurídico vigente, por lo
              cual le pedimos que revise con regularidad. Dicha actualización se hará pública en
              cualquier caso por parte de BIOSFERASOFT y publicada en
              https://marketplace.biosferasoft.com. Esta política tiene fecha de vigencia desde el
              mes de agosto del 2024.
            </Typography>

            <Typography
              variant="h4"
              sx={{ textAlign: 'left', fontSize: '0.9em', fontWeight: 'bold', pt: 2 }}
            >
              1. ¿Cuál es la información que recopilamos?
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              Para efectuar la comercialización de nuestros productos y servicios requerimos de sus
              datos personales, los mismos que nos ha facilitado al momento de adquirir nuestros
              servicios y/o productos; nos ha otorgado a través de los formularios del sitio web; o,
              a través del centro de servicio al cliente. Los datos personales facilitados por el
              cliente de manera directa, de manera no limitativa son: nombres, apellidos, dirección
              de correo electrónico, número de teléfono, reclamos o requerimientos, etc., así como
              cualquier otro tipo de información facilitada durante el transcurso de la relación
              comercial.
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: 'left', fontSize: '0.9em', fontWeight: 'bold', pt: 2 }}
            >
              Datos recabados de forma automatizada
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              Recabamos datos de forma automatizada cuando visita o navega única y exclusivamente
              por nuestro sitio web, a través de cookies (pequeños archivos de texto almacenados en
              su navegador), de la cual no podemos identificar la identidad del usuario: Estos datos
              son: número de visitas, tiempo de navegación, otros. Esta opción es aplicable y
              parametrizable dependiendo de la configuración de su navegador.
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: 'left', fontSize: '0.9em', fontWeight: 'bold', pt: 2 }}
            >
              Datos obtenidos de fuentes externas
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              Corresponde a datos necesarios para que, de acuerdo a la normativa tributaria
              ecuatoriana vigente, cumplamos con el proceso de facturación, así como para la
              prestación adecuada del servicio contratado, referente al tipo de servicios elegidos,
              datos de facturación, y demás relacionados a la gestión de cobranza la facturación.
            </Typography>

            <Typography
              variant="h4"
              sx={{ textAlign: 'left', fontSize: '0.9em', fontWeight: 'bold', pt: 2 }}
            >
              2. Finalidades de la información
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              Los datos personales acordado los facilitados por el cliente serán utilizados por
              BIOSFERASOFT para gestionar adecuadamente los servicios solicitados o contratados, y
              en los términos informados en la presente política de privacidad y sus
              actualizaciones, conforme a las siguientes bases legitimadoras: <br />
              <br /> &emsp;<strong>- </strong>Finalidades basadas en el cumplimiento de la relación
              comercial y la ejecución del contrato de servicios: Aquellas necesarias para llevar a
              cabo la venta de los servicios de BIOSFERASOFT, y la gestión de la relación a efectos
              de dar cumplimiento a lo dispuesto a las transacciones comerciales, como las que se
              detallan a continuación: <br />
              <br />
              Aquellas necesarias para llevar a cabo la venta de los servicios de BIOSFERASOFT, y la
              gestión de la relación a efectos de dar cumplimiento a lo dispuesto a las
              transacciones comerciales, como las que se detallan a continuación:
              <br />
              <br /> &emsp;<strong>- </strong>Gestionar, tramitar y dar respuesta a peticiones,
              solicitudes, incidencias o consultas del usuario, cuando éste facilite sus datos a
              través de los formularios habilitados en el sitio web o a través de nuestros medios
              los centros de atención al cliente. <br />
              <br />
              &emsp;<strong>- </strong>Gestionar la confirmación de la identidad y datos civiles de
              los potenciales clientes, en base a la información procedente del Registro Civil,
              Identificación y Cedulación, la Dirección Nacional de Registros Públicos (DINARDAP) y
              Servicio de Rentas Internas (SRI) Superintendencia de compañías, entre otros. <br />
              <br /> &emsp;<strong>- </strong>Analizar los requerimientos realizados por los
              Organismos de Control, entes regulatorios, fiscalía u órdenes judiciales, que permita
              mantener la seguridad de las transacciones, revisión de eventos ilegales o
              fraudulentos.
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: 'left', fontSize: '0.9em', fontWeight: 'bold', pt: 2 }}
            >
              Finalidades basadas en el consentimiento del Titular de Datos Personales:
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              Son aquellos asumidos por las partes, en base a las obligaciones mutuas que han
              adquirido, en relación a los productos y/o servicios ofrecidos por BIOSFERASOFT:
              <br />
              <br />
              &emsp;<strong>- </strong>En caso de impago, podremos comunicar sus datos personales
              asociados a una obligación pendiente, a los organismos auxiliares del sistema
              financiero y/o a las entidades responsables de dichos sistemas de información
              crediticia para su publicación en los ficheros de solvencia, ante cualquier
              institución debidamente acreditada.
              <br />
              <br />
              &emsp;<strong>- </strong>Para analizar sus preferencias utilizamos los datos derivados
              de la venta, consumo y facturación media de los Servicios, que formen parte de las
              ventas.
              <br />
              <br />
              BIOSFERASOFT podrá realizar análisis sobre los datos disponibles del cliente durante
              la vida de la relación comercial, para diseñar un perfil (ARQUETIPO) más adaptable al
              cliente que permita a la empresa servirlo de mejor forma y de acuerdo a sus
              preferencias, el mismo incluye: estimaciones sobre el canal de compra preferido,
              satisfacción del servicio, la edad y género, y datos sobre sus preferencias obtenidas
              de las plataformas mencionadas.
              <br />
              <br />
              &emsp;<strong>- </strong>Tratamientos de anonimización de datos (proceso de convertir
              los datos en una forma en que no se pueda identificar a individuos) o conjuntos de
              datos, mediante técnica, las herramientas que permitan obtener resultados agregados,
              con el fin de realizar estimaciones con fines estadísticos, de interés público o de
              investigación científica o histórica
              <br />
              <br />
              &emsp;<strong>- </strong>Para realizar tratamiento de los datos personales del cliente
              y/o potencial cliente, a fin de ejecutar las relaciones contractuales que mantenga
              vigentes o que pueda sostener en el futuro con BIOSFERASOFT, así como para fines
              estadístico los/o analítico los/o para que se evalúe la calidad del servicio brindado,
              atender los requerimientos los/o comunicaciones a través de los canales de atención de
              BIOSFERASOFT.
              <br />
              <br />
              &emsp;<strong>- </strong>Para permitir el envío de comunicaciones comerciales por
              parte de BIOSFERASOFT ajustadas a tu “arquetipo” sobre propio los/o de terceros con
              los cuales BIOSFERASOFT mantiene alianzas estratégicas para brindar beneficios.
              <br />
              <br />
              &emsp;<strong>- </strong>Para prestar y personalizar las funciones de las aplicaciones
              los sitios web de BIOSFERASOFT.
            </Typography>

            <Typography
              variant="h4"
              sx={{ textAlign: 'left', fontSize: '0.9em', fontWeight: 'bold', pt: 2 }}
            >
              Finalidades en cumplimiento de una obligación legal:
            </Typography>

            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              &emsp;<strong>- </strong>Cualquier obligación de carácter legal que resulte de
              aplicación de las leyes, como las derivadas de obligaciones judiciales, servicios de
              emergencias, y demás tipificados en las normas ecuatorianas.
            </Typography>

            <Typography
              variant="h4"
              sx={{ textAlign: 'left', fontSize: '0.9em', fontWeight: 'bold', pt: 2 }}
            >
              3. Transferencia de Datos:
            </Typography>

            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              BIOSFERASOFT podrá realizar transferencias de datos personales, tanto nacionales como
              internacionales, con el objetivo de cumplir su objeto social, actividades de
              responsabilidad social y aplicación de herramientas tecnológicas. En caso de
              transferencia internacional, BIOSFERASOFT se cerciorará que sea efectuada a
              jurisdicciones que tutelen la protección y privacidad de datos personales. Los
              destinatarios estarán sujetos a las mismas obligaciones las medidas de seguridad,
              técnica los legales descritas en la Ley Orgánica de Protección de Datos Personales y
              la demás normativa conexa.
            </Typography>

            <Typography
              variant="h4"
              sx={{ textAlign: 'left', fontSize: '0.9em', fontWeight: 'bold', pt: 2 }}
            >
              4. Conservación de la información:
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              BIOSFERASOFT tratará los datos personales del cliente dando cumplimiento al principio
              de limitación del plazo de conservación establecido en la Ley Orgánica de Datos
              Personales. BIOSFERASOFT actuará como responsable del tratamiento de los datos
              personales proporcionados por el cliente, los datos proporcionados serán almacenados
              por BIOSFERASOFT con domicilio legal Avenida 6 de Diciembre S/N y la Niña de la ciudad
              de Quito, En caso de requerir comunicarse con el delegado de Protección de Datos de
              BIOSFERASOFT realizar a las siguientes direcciones de contacto: correo electrónico:
              administracion@biosferasoft.com, sin perjuicio de los procedimientos que hubiere lugar
              ante la Autoridad Nacional de Protección de Datos Personales. Los datos se almacenarán
              en una base de datos denominada “Clientes”, misma que será conservada conforme el
              servicio a prestar como parte de la relación contractual con BIOSFERASOFT y las
              estipulaciones contempladas en la presente política, y hasta por diez (10) años
              posteriores a la terminación de la misma.
              <br />
              <br />
              En caso no tenga un vínculo comercial con BIOSFERASOFT, la autorización brindada
              perdurará hasta por dos (2) años desde que otorgue mi consentimiento. En dicho caso,
              mis Datos Personales serán almacenados en la base de datos de BIOSFERASOFT, con
              domicilio legal en la Avenida 6 de Diciembre S/N y la Niña, Oficinas Administrativas,
              de la ciudad de Quito, En caso de requerir comunicarse con el delegado de Protección
              de Datos de BIOSFERASOFT realizar a las siguientes direcciones de contacto: correo
              electrónico: administracion@biosferasoft.com, sin perjuicio de los procedimientos que
              hubiere lugar ante la Autoridad Nacional de Protección de Datos Personales. La
              información se almacenará con la denominación “Potenciales Clientes”.
              <br />
              <br />
              El titular de los datos personales proporcionará los mismos de manera personal o a
              través de su representante legal por medio del formulario web o virtual habilitado
              para el efecto.
              <br />
              <br />
              En ambos casos, el tiempo de conservación de los datos personales suministrados por el
              “Potenciales clientes” o por “clientes” podrá subsistir durante el tiempo necesario
              según los plazos de prescripción previstos en la legislación vigente para el ejercicio
              o defensa de posibles reclamaciones administrativas, tributarias, legales o
              judiciales. En estos casos, la información se mantendrá debidamente bloqueada con
              acceso restringido a determinados perfiles, con las medidas técnica las organizativas
              necesarias que garanticen la seguridad de la información, evitando su alteración,
              pérdida, tratamiento o acceso no autorizado; transcurridos dichos plazos, los datos
              serán eliminados o anonimizados, según proceda, y de conformidad con la normativa
              ecuatoriana que resulte de aplicación.
              <br />
              <br />
              En los casos en que obtenemos datos automáticamente a través de la navegación en
              nuestra página web el cliente podrá limitar su uso en el tiempo eliminándolas de los
              navegadores o dispositivos de mantener obligaciones pendientes, podremos comunicar tus
              datos personales asociados a una obligación pendiente, a los organismos auxiliares del
              sistema financiero, hasta que las obligaciones sean cubiertas en su totalidad.
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: 'left', fontSize: '0.9em', fontWeight: 'bold', pt: 2 }}
            >
              5. Seguridad de la Información
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              BIOSFERASOFT implementará las medidas técnicas y organizativas apropiadas para
              garantizar un nivel de seguridad adecuado al riesgo para la protección de los datos
              personales que le proporcionen; y, en caso de que se produzca una violación de la
              seguridad que dé lugar a la destrucción, pérdida o alteración accidental o ilícita, o
              a la divulgación o el acceso no autorizados a los datos personales proporcionados por
              los usuarios, BIOSFERASOFT lo notificará en un término máximo de tres días, contados a
              partir de que tuvo conocimiento de la misma.
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: 'left', fontSize: '0.9em', fontWeight: 'bold', pt: 2 }}
            >
              6. Consentimiento
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              Al utilizar nuestra plataforma y remitir sus datos personales a través de los medios
              dispuestos por BIOSFERASOFT para el efecto, expresa su consentimiento libre y
              voluntario a BIOSFERASOFT para que lleve a cabo el tratamiento de sus datos
              personales, de acuerdo con las finalidades, procedimiento las condiciones expresadas
              en nuestra Política de Privacidad; por lo cual, usted confirma que su consentimiento
              es específico, informado e inequívoco.{' '}
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: 'left', fontSize: '0.9em', fontWeight: 'bold', pt: 2 }}
            >
              7. Actualización de los Término los Condiciones
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              La página web de BIOSFERASOFT se actualiza periódicamente, por lo que nuestros
              términos y condiciones pueden cambiar en cualquier momento. Por lo tanto, revise los
              términos y condiciones de privacidad publicados de tiempo en tiempo.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export { Policy as default } from './Policy'
