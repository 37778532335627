import { BreakpointsOptions } from "@mui/material"

export const DEFAULT_BREACKPOINTS: BreakpointsOptions = {
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
    }
}