import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { useCart, useGetSolutions, useTransactionLicense } from 'hooks'
import React from 'react'

export const TransactionLicenseDialog = () => {
  const { getLogo } = useGetSolutions()
  const { handleRenewProduct, allProductEnabled } = useCart()
  const {
    showTransactionLicenseDialog,
    transactionLicensesWithLimitClose,
    handleHideTransactionLicenseDialog,
  } = useTransactionLicense()

  const [open, setOpen] = React.useState(showTransactionLicenseDialog)

  const handleClose = () => {
    setOpen(false)
    handleHideTransactionLicenseDialog()
  }
  const validateIfShowRenewButton = (producId: number) => {
    return allProductEnabled.some((productInfo) => producId === productInfo.productId)
  }
  React.useEffect(() => {
    setOpen(showTransactionLicenseDialog)
  }, [showTransactionLicenseDialog])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      >
        <Icon>{'close_icon'}</Icon>
      </IconButton>
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          textAlign: 'center',
          fontSize: { xs: '0.9rem', sm: '1.1rem' },
        }}
      >
        <Box pr={1}>
          <Alert severity="warning" sx={{ fontWeight: 600 }}>
            {'¡Estás cerca de alcanzar el límite de transacciones que permite tu licencia!'}
          </Alert>
        </Box>
      </DialogTitle>

      <DialogContent>
        {transactionLicensesWithLimitClose.map(
          ({ license, currentTransaction, licenseLimitTransaction }) => (
            <Box
              py={1}
              pr={1}
              key={license.licenseId}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
              }}
            >
              <Box pb={1} sx={{ display: 'flex', alignItems: 'center' }}>
                {Boolean(license?.product?.mainSolutionId) && (
                  <Avatar src={getLogo(license?.product?.mainSolutionId!)} />
                )}

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    pl={1}
                    align={'left'}
                    fontWeight={600}
                    variant={'caption'}
                    fontSize={'0.75em'}
                    color={'textSecondary'}
                  >
                    {license.product?.name}
                  </Typography>

                  <Box sx={{ display: 'flex' }}>
                    <Typography
                      pl={1}
                      color={'red'}
                      align={'left'}
                      variant={'caption'}
                      fontSize={'0.75em'}
                    >
                      {'Actual: '}
                    </Typography>
                    <Typography
                      pl={1}
                      color={'red'}
                      align={'left'}
                      fontWeight={600}
                      variant={'caption'}
                      fontSize={'0.75em'}
                    >
                      {currentTransaction}
                    </Typography>

                    <Typography
                      pl={3}
                      color={'red'}
                      align={'left'}
                      fontSize={'0.75em'}
                      variant={'caption'}
                    >
                      {'Límite: '}
                    </Typography>
                    <Typography
                      pl={1}
                      color={'red'}
                      align={'left'}
                      fontWeight={600}
                      fontSize={'0.75em'}
                      variant={'caption'}
                    >
                      {licenseLimitTransaction}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Tooltip
                  title={
                    validateIfShowRenewButton(license.product?.productId!)
                      ? 'Renovar licencia'
                      : 'Este producto no esta disponible para la compra '
                  }
                >
                  <Button
                    onClick={() => {
                      const productId =
                        license.product?.productId ??
                        license.specialProductDetailInformation?.productInformation?.productId!
                      handleRenewProduct(productId)
                      handleClose()
                    }}
                    autoFocus
                    disabled={!validateIfShowRenewButton(license.product?.productId!)}
                  >
                    {'Renovar'}
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          )
        )}
      </DialogContent>
    </Dialog>
  )
}
