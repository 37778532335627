import ReactGA4 from 'react-ga4'

const MEASUREMENT_ID = process.env.REACT_APP_MEASURE_ID!

export const initializeGoogleAnalytics = () => {
  ReactGA4.initialize(MEASUREMENT_ID, {
    gaOptions: {
      debug_mode: true,
    },
    gtagOptions: {
      debug_mode: true,
    },
  })
}

export const trackGoogleAnalyticsEventWithData = (
  category: string,
  eventName: string,
  label: string,
  data: any
) => {
  const eventParams = {
    category,
    label,
    ...data,
  }
  ReactGA4.event(eventName, eventParams)
}
