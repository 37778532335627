import {
  AnimatedNavigationHandler,
  AnimatedNavigationHandlerRef,
  AnimatedNavigationType,
} from 'components'
import React from 'react'
import { NavigateOptions, To } from 'react-router-dom'

type Props = {
  animatedNavigation: (
    to: To | number,
    type?: AnimatedNavigationType,
    options?: NavigateOptions | undefined
  ) => void
}

export const NavigationContext = React.createContext({} as Props)

const NavigationProvider: React.FC = ({ children }) => {
  /**Use a reference to avoid children re render any time an animation is triggered*/
  const navigationRef = React.useRef<AnimatedNavigationHandlerRef>(null)

  const animatedNavigation = React.useCallback(
    (to: To | number, type?: AnimatedNavigationType, options?: NavigateOptions | undefined) => {      
      navigationRef.current?.animatedNavigation(to, type, options)
    },
    []
  )

  return (
    <NavigationContext.Provider
      value={{
        animatedNavigation: animatedNavigation,
      }}
    >
      {children}
      <AnimatedNavigationHandler ref={navigationRef} />
    </NavigationContext.Provider>
  )
}

export default NavigationProvider
