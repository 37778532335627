import { AxiosRequestConfig } from "axios"
import { getItem } from "lib/utils/localStorage"
import { LOCAL_STORAGE_KEYS } from "marketplace-common"


export const requestInterceptorSuccessAdministration= (config: AxiosRequestConfig<any>) => {
          const token = getItem(LOCAL_STORAGE_KEYS.TOKEN) ?? ''
          const headers = {
                    Authorization: `Bearer ${process.env.REACT_APP_ADMINISTRATION_API_KEY}`,
                    Token: token
          }
          config.headers = headers
          return config
}

export const requestInterceptorErrorSecurityAdministration = (error: any) => {
          return Promise.reject(error)
}

