import { Avatar, Box, Button, Chip, Icon, Paper, Stack, Typography } from '@mui/material'
import { StyledText } from 'components'
import { motion } from 'framer-motion'
import { useAuth, useFeedback, useGetSolutions } from 'hooks'
import { ROUTES, updateSelectedProfile } from 'lib'
import { IProduct, ISolution, ISolutionReference } from 'marketplace-common'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SOLUTION_COLORS, SOLUTION_THEMES } from 'styles'
import { IProfileUpdateSelectedRequest } from 'types'

type Props = {}

export const Acquired = (props: Props) => {
  const { user } = useAuth()
  const { solutions} = useGetSolutions(user.identification)
  const { showMessage } = useFeedback()
  const location = useLocation()
  const solution: ISolution = (location.state as any).solution
  const product: IProduct = (location.state as any).product
  const solutionColors = Object.keys(SOLUTION_THEMES).map(
    (key) => SOLUTION_THEMES[key as ISolutionReference].palette.primary
  )

  const handleOnLoginProfile = React.useCallback(async () => {    
    const profile = solutions.find(
      (currentSolution) => currentSolution.mainSolutionId === solution.mainSolutionId
    )!.profiles[0]

    const profileSelectRequest = {
      companyId: profile.companyId,
      solutionId: profile.mainSolutionId,
      profileId: profile.profileId,
      userId: user.userId,
    }

    try {
      const response = await updateSelectedProfile(
        profileSelectRequest as IProfileUpdateSelectedRequest
      )
      if (response === '') {
        showMessage('Aun no disponible en esta versión')
      } else {
        showMessage('Se abrirá una nueva ventana tu perfil espera un momento')
        setTimeout(() => {
          window.open(response, '_blank')
        }, 1000)
      }
    } catch (error) {
      console.log(error)
    }
  }, [solutions, user])
  return (
    <Box width={'100%'} height={'100%'} display="flex" position={'relative'}>
      {solutionColors.map((color, index) => (
        <Box
          key={color.main + index}
          sx={{
            width: '100px',
            height: '100px',
            borderRadius: '100%',
            bgcolor: color.main,
            position: 'absolute',
            right: `calc(25% - 40px)`,
            top: 'calc(50% - 40px)',
            marginTop: 2.5 * index * (index % 2 === 0 ? 1 : -1) * (Math.random() + 0.65),
            marginRight:
              3 *
              (solutionColors.length - index) *
              (index % 2 === 0 ? 1 : -1) *
              (Math.random() + 0.65),
          }}
          component={motion.div}
          animate={{
            scale: 1.8 + Math.random(),
            x:
              35 *
              (solutionColors.length - index) *
              (index % 2 === 0 ? 1 : -1) *
              (Math.random() + 0.65),
          }}
          transition={{
            repeat: Infinity,
            duration: 1.75,
            repeatType: 'mirror',
            repeatDelay: 1 + Math.random(),
          }}
        />
      ))}
      <Box width={'50%'} height={'100%'} display="flex">
        <Stack py={6} spacing={6} px={5} justifyContent="space-between">
          <Avatar
            src={solution.logo}
            sx={{ bgcolor: SOLUTION_COLORS[solution.reference].primary.main }}
          />
          <Stack spacing={4}>
            <Chip sx={{ width: 'fit-content', px: 3 }} label="Marketplace" />
            <Typography variant="h3" fontWeight={700}>
              {'Agradecemos tu compra!'}
            </Typography>
          </Stack>
          <StyledText
            text={
              <Box display={'flex'} flexDirection="column" component={'p'}>
                <Typography variant="caption" fontWeight={500} color="textSecondary">
                  {`Marketplace ${new Date().getFullYear()}`}
                </Typography>
                <Typography variant="caption" fontWeight={600}>
                  {'® Todos los derechos reservados'}
                </Typography>
              </Box>
            }
          />
        </Stack>
      </Box>
      <Box width={'50%'} height={'100%'} display="flex">
        <Paper
          sx={{
            m: 3,
            width: '100%',
            bgcolor: 'rgba(255,255,255,0.3)',
            backdropFilter: 'blur(40px)',
          }}
        >
          <Stack
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
            spacing={3}
          >
            <Avatar sx={{ bgcolor: 'primary.main' }}>
              <Icon>shopping_bag</Icon>
            </Avatar>
            <Typography variant="body1" fontWeight={500}>
              {`Haz adquirido ${product.name} de ${solution.name}`}
            </Typography>
            <Typography variant="body1" fontWeight={500} color="textSecondary">
              {`Puedes:`}
            </Typography>

            <Button
              sx={{ px: 4 }}
              onClick={(e) => {
                handleOnLoginProfile()
              }}
            >
              {'Configurar solución'}
            </Button>

            <Typography variant="body1" fontWeight={500} color="textSecondary">
              {`ó`}
            </Typography>
            <Link to={`/${ROUTES.user}/${ROUTES.marketplace}/${ROUTES.solutions}`} replace={true}>
              <Button sx={{ px: 4 }} variant="outlined">
                {'Volver al panel principal'}
              </Button>
            </Link>
          </Stack>
        </Paper>
      </Box>
    </Box>
  )
}

export { Acquired as default } from './Acquired'