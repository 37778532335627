import { CircularProgress, Stack } from '@mui/material'

type Props = {}

export const ResponsiveLoading = (props: Props) => {
  return (
    <Stack width={'100%'} height={'100%'} justifyContent="center" alignItems="center">
      <CircularProgress thickness={8} size={60} color="primary" />
    </Stack>
  )
}
