import {
  AppBar,
  Box,
  Icon,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  CardHomeRedirect,
  DialogProcess,
  FullscreenLoading,
  ShoppingCart,
  SolutionCard,
  StyledText,
} from 'components'
import { CardPromo } from 'components/solution/CardPromo'
import { motion } from 'framer-motion'
import {
  useAuth,
  useCart,
  useGetProcesses,
  useGetSolutions,
  useLocalStorage,
  useNavigate,
  useProcessHelper,
  useSpecialProducts,
} from 'hooks'
import {
  QUINDEE_RECIBIDOS,
  QUINDEE_SOLUTION,
  ROUTES,
  constLocalStorage,
  getProcessesByCompanyIdService,
} from 'lib'
import { IProcess, ISolution } from 'marketplace-common'
import React from 'react'
import { SOLUTION } from 'types'
import biosferasoftLogoIcon from '../../assets/img/logo-biosferasoft-icon.webp'

const Title = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <StyledText
      text={
        <Box sx={{ display: 'flex', gap: 2 }}>
          {isDesktop && (
            <img src={biosferasoftLogoIcon} alt={'Biosferasoft'} width="32" height="52" loading="lazy"/>
          )}
          <Box>
            <Typography variant="h6" fontWeight={600} fontSize={{ xs: '1rem', sm: '1.25rem' }}>
              {'Marketplace'}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              fontWeight={500}
              lineHeight={{ xs: 1, sm: 1.8 }}
              fontSize={{ xs: '0.7rem', sm: '0.875rem' }}
            >
              {'Selecciona el producto que deseas adquirir'}
            </Typography>
          </Box>
        </Box>
      }
    />
  )
}

/**
 *
 * @returns Get Solutions page
 */
const DASH_BOARD_ROUTE = `${ROUTES.user}/${ROUTES.marketplace}/${ROUTES.solutions}`
const LOGIN_ROUTE = `${ROUTES.login}`

export const GetSolutions = () => {
  const { getItem } = useLocalStorage()
  const { animatedNavigation } = useNavigate()
  const { solutions, loading } = useGetSolutions()
  const { cartProducts, nonEmptyCart, refreshCart } = useCart()
  const { user, isAuthenticated, showSelectProcess } = useAuth()
  const { initializeConfigureAfterLogin, loadingInitialize } = useProcessHelper()
  const { haveSpecialProducts, loadingSpecialProducts, getSpecialProducts } = useSpecialProducts()
  const { processes } = useGetProcesses(user?.identification)
  const { solutions: userSolutions } = useGetSolutions(user?.identification)

  const [openDialog, setOpenDialog] = React.useState(false)
  const [selected, setSelected] = React.useState<ISolution | undefined>()
  const [orderedSolutions, setOrderedSolutions] = React.useState<ISolution[]>([])

  const existsOrderedSolutions = Boolean(orderedSolutions) && orderedSolutions.length !== 0

  const solutionsOrder: number[] = [
    SOLUTION.QUINDE_FEE,
    SOLUTION.NUNA_KEY,
    SOLUTION.MICO_DOCS,
    SOLUTION.ALPAQQA,
    SOLUTION.QREATIVEE,
  ]

  const handleOpenDialog = () => setOpenDialog(true)
  const handleCloseDialog = () => setOpenDialog(false)

  const handleSelect = (solution: ISolution | undefined) => setSelected(solution)

  const solutionHaveUser = (mainSolutionId: number) => {
    if (!isAuthenticated) {
      return false
    }
    let haveSolution = false
    if (userSolutions.length > 0 && userSolutions[0].hasOwnProperty('profiles')) {
      haveSolution = userSolutions.some((solution) => {
        const { products } = solution
        const isNotQuindeRecibidos = products?.some(
          (product) => product.name !== QUINDEE_RECIBIDOS.toUpperCase()
        )
        if (solution.name !== QUINDEE_SOLUTION) {
          return solution.mainSolutionId === mainSolutionId
        } else if (isNotQuindeRecibidos) {
          return solution.mainSolutionId === mainSolutionId && isNotQuindeRecibidos
        }
      })
    }
    return haveSolution
  }

  const solutionsHaveAdmin = (solution: ISolution) => {
    if (user.userId === undefined) {
      return (
        solution.mainSolutionId !== parseInt(process.env.REACT_APP_MODULE_SOLUTION_ADMINISTRATOR!)
      )
    } else {
      const isAdministrator = userSolutions.some((solution) =>
        solution?.profiles?.find(
          (profile) =>
            profile.profileId === parseInt(process.env.REACT_APP_PROFILE_SOLUTION_ADMINISTRATOR!)
        ) === undefined
          ? false
          : true
      )
      return isAdministrator
        ? true
        : solution.mainSolutionId !== parseInt(process.env.REACT_APP_MODULE_SOLUTION_ADMINISTRATOR!)
    }
  }

  const createProcess = async () => {
    if (Boolean(user?.userId)) {
      await initializeConfigureAfterLogin(user)
      handleCloseDialog()
    }
  }

  const handleOrderSolution = () => {
    const newOrderedSolutions = solutions
      .filter((solution) => {
        return solutionsHaveAdmin(solution)
      })
      .sort((a, b) => {
        const indexA = solutionsOrder.indexOf(a.mainSolutionId)
        const indexB = solutionsOrder.indexOf(b.mainSolutionId)

        return indexA === -1 ? 1 : indexB === -1 ? -1 : indexA - indexB
      })
    setOrderedSolutions(newOrderedSolutions)
  }

  React.useEffect(() => {
    const inizializedUserProcess = async () => {
      const cartProductsLocalStorage = getItem(constLocalStorage.CART_PRODUCTS)
      const cartSpecialProductsLocalStorage = getItem(constLocalStorage.CART_SPECIAL_PRODUCTS)
      const existProductsLocalStorage =
        Boolean(cartProductsLocalStorage) && cartProductsLocalStorage.length > 0
      const existSpecialProductsLocalStorage =
        Boolean(cartSpecialProductsLocalStorage) && cartSpecialProductsLocalStorage.length > 0

      const result: IProcess[] = await getProcessesByCompanyIdService(user.identification)
      const existProcess = result[0] !== undefined

      if (existProcess && (existProductsLocalStorage || existSpecialProductsLocalStorage)) {
        handleOpenDialog()
      }
      if (!existProcess && (existProductsLocalStorage || existSpecialProductsLocalStorage)) {
        createProcess()
      }
      if (!existProductsLocalStorage && !existSpecialProductsLocalStorage && existProcess) {
        await refreshCart(result[0].processId)
      }
    }
    inizializedUserProcess()
    getSpecialProducts()
  }, [])

  React.useEffect(() => {
    const existsSolutions = Boolean(solutions) || solutions.length !== 0
    if (existsSolutions) handleOrderSolution()
  }, [solutions])

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', overflow: 'auto' }} id="GetSolutions">
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <AppBar elevation={0} color="transparent" position="static" sx={{ width: '100%' }}>
            <Toolbar sx={{ py: 2, justifyContent: { xs: 'flex-start', sm: 'space-between' } }}>
              <Box display="flex" alignItems={'center'}>
                <IconButton
                  onClick={(e) =>
                    animatedNavigation(isAuthenticated ? DASH_BOARD_ROUTE : LOGIN_ROUTE, 'circle')
                  }
                  size="medium"
                  sx={{
                    mr: 0.7,
                    bgcolor: (theme) => `${theme.palette.primary.main}20`,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Icon fontSize="medium">{isAuthenticated ? 'home' : 'login'}</Icon>
                  <Typography
                    variant="caption"
                    align="center"
                    fontSize={10}
                    fontWeight="bold"
                    color="text.primary"
                  >
                    {isAuthenticated ? 'Dashboard' : 'Login'}
                  </Typography>
                </IconButton>
                <Title />
              </Box>
              <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems={'center'}>
                <ShoppingCart cart={cartProducts} nonEmptyCart={nonEmptyCart} />
              </Box>
            </Toolbar>
          </AppBar>
          <Box id="solutionContainer" display="flex" justifyContent={'center'}>
            <Stack
              id="solutions"
              spacing={4}
              py={3}
              flexDirection={'row'}
              alignItems="flex-end"
              alignSelf={Boolean(selected) ? 'flex-start' : 'center'}
              justifyContent={'space-evenly'}
              flexGrow={1}
              flexWrap={'wrap'}
              maxWidth={800}
              paddingBottom={{ xs: 16, sm: 2 }}
            >
              {!loadingSpecialProducts && existsOrderedSolutions && (
                <Box
                  id="solution-card-box"
                  key={orderedSolutions[0]?.mainSolutionId}
                  component={motion.div}
                  initial={{
                    x: 100 * Math.random(),
                    y: 100 * Math.random() * (0 % 2 === 0 ? -1 : 1),
                    opacity: 0,
                  }}
                  animate={{ x: 0, y: 0, opacity: 1 }}
                  transition={{
                    duration: 0.25 + (solutions.length - 0) / 5,
                    delay: (0 + 1) / 500,
                  }}
                  sx={{
                    paddingBottom:
                      !isAuthenticated && 0 === solutions.length - 2 ? { xs: 16, sm: 0 } : 0,
                  }}
                >
                  <SolutionCard
                    solution={orderedSolutions[0]}
                    userHaveSolution={solutionHaveUser(orderedSolutions[0]?.mainSolutionId)}
                    onSelect={handleSelect}
                  />
                </Box>
              )}

              {haveSpecialProducts && (
                <Box
                  component={motion.div}
                  initial={{
                    x: 100 * Math.random(),
                    y: 100 * Math.random() * (1 % 2 === 0 ? -1 : 1),
                    opacity: 0,
                  }}
                  animate={{ x: 0, y: 0, opacity: 1 }}
                  transition={{
                    duration: 0.25 + (6 - 2) / 5,
                    delay: (6 + 1) / 500,
                  }}
                >
                  {!loadingSpecialProducts && <CardPromo />}
                </Box>
              )}
              {!loadingSpecialProducts &&
                existsOrderedSolutions &&
                orderedSolutions
                  .filter((solution) => solution.mainSolutionId !== SOLUTION.QUINDE_FEE)
                  .map((solution, index) => (
                    <Box
                      id="solution-card-box"
                      key={solution.mainSolutionId}
                      component={motion.div}
                      initial={{
                        x: 100 * Math.random(),
                        y: 100 * Math.random() * (index % 2 === 0 ? -1 : 1),
                        opacity: 0,
                      }}
                      animate={{ x: 0, y: 0, opacity: 1 }}
                      transition={{
                        duration: 0.25 + (solutions.length - index) / 5,
                        delay: (index + 1) / 500,
                      }}
                      sx={{
                        paddingBottom:
                          !isAuthenticated && index === solutions.length - 2
                            ? { xs: 16, sm: 0 }
                            : 0,
                      }}
                    >
                      <SolutionCard
                        solution={solution}
                        userHaveSolution={solutionHaveUser(solution.mainSolutionId)}
                        onSelect={handleSelect}
                      />
                    </Box>
                  ))}
              {isAuthenticated && (
                <Box
                  component={motion.div}
                  initial={{
                    x: 100 * Math.random(),
                    y: 100 * Math.random() * (6 % 2 === 0 ? -1 : 1),
                    opacity: 0,
                  }}
                  animate={{ x: 0, y: 0, opacity: 1 }}
                  transition={{
                    duration: 0.25 + (6 - 2) / 5,
                    delay: (6 + 1) / 500,
                  }}
                >
                  {!loadingSpecialProducts && !loading && <CardHomeRedirect />}
                </Box>
              )}
            </Stack>
          </Box>
        </Box>
      </Box>
      <DialogProcess
        openDialog={openDialog && showSelectProcess}
        loadingInitialize={loadingInitialize}
        processId={processes[0] && processes[0].processId}
        handleCloseDialog={handleCloseDialog}
        initializeConfigureAfterLogin={initializeConfigureAfterLogin}
      />

      {loadingInitialize && <FullscreenLoading withOpacity />}
    </React.Fragment>
  )
}
export { GetSolutions as default } from './Get'
