import { Grid, Stack } from '@mui/material'
import { ContactsBar } from 'components'
import { Outlet } from 'react-router-dom'

export const ContactsLayout = () => {
  return (
    <Grid container flexGrow={1} direction="row">
      <Stack flexGrow={1}>
        <ContactsBar />
        <Outlet />
      </Stack>
    </Grid>
  )
}
export { ContactsLayout as default } from './ContactsLayout'
