import { LoadingButton } from '@mui/lab'
import {
  Alert,
  AlertTitle,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { LoginForm } from 'components/auth/LoginForm'
import { SignUpForm } from 'components/auth/SignUpForm'
import {
  useAuth,
  useCart,
  useFeedback,
  useGetSolutions,
  useProcess,
  useProcessSelection,
} from 'hooks'
import {
  copyProcessService,
  IDENTIFICATION_CARD_ID,
  OUTSIDE_ID,
  PASSPORT,
  ROUTES,
  RUC_ID,
  validateIdentificationCard,
  validateSingleRegistryOfTaxpayers,
} from 'lib'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MAIN_COLORS } from 'styles'
import { IUserRequest, Options } from 'types'

type Props = {
  loadingChangeRuc?: boolean
  onCloseDialog: () => void
  onClickCancel: () => void
  handleChangeIdentificationToRuc: (
    identification: string,
    newRuc: string,
    companyName: string
  ) => void
}

export const DialogValidateUserId = (props: Props) => {
  const { loadingChangeRuc, onCloseDialog, onClickCancel, handleChangeIdentificationToRuc } = props
  const navigate = useNavigate()
  const { user, login } = useAuth()
  const { breakpoints } = useTheme()
  const { getLogo } = useGetSolutions()
  const { showMessage } = useFeedback()
  const { handleOpenDialog, handleCloseDialog, openDialog } = useProcessSelection()
  const { process: processInfo } = useProcess()
  const { productsNotAvailable, specialProductsNotAvailable } = useCart()

  const [option, setOption] = React.useState<Options>(ROUTES.signUp as Options)
  const [dataUser, setDataUser] = React.useState<IUserRequest>({} as IUserRequest)

  const isMobile = useMediaQuery(breakpoints.down('md'))
  const idIsRuc = user.identificationType === RUC_ID
  const idIsCi = user.identificationType === IDENTIFICATION_CARD_ID
  const idIsPassport = user.identificationType === PASSPORT
  const idIsOutside = user.identificationType === OUTSIDE_ID

  const getDataUser = (user: IUserRequest) => setDataUser({ ...user })

  const handleChangeOption = (option: Options) => setOption(option)

  const isIdentificationRucOrCi = () => {
    const existsUserId = Boolean(user.userId)
    if (existsUserId) {
      const validateIdIsRucOrCi =
        validateIdentificationCard(user?.identification) ||
        validateSingleRegistryOfTaxpayers(user?.identification)
      return validateIdIsRucOrCi
    }
  }

  const onClickSignUp = async (userResponse: IUserRequest) => {
    try {
      const existsProcessId = Boolean(processInfo?.processId)
      if (existsProcessId) {
        await copyProcessService(processInfo?.processId!, userResponse.identification)
        handleChangeOption('login')
      }
    } catch (error: any) {
      showMessage(error.message as string, 'error')
    }
  }

  const onClickLogin = async (email: string, password: string) => {
    try {
      const userResponse = await login(email, password, false, '')
      const existsUser = Boolean(userResponse)

      if (existsUser) {
        handleCloseDialog('productsNotAvailable')

        const encryptRoute = `/${ROUTES.processes}/nuna`
        navigate(encryptRoute)
      }
    } catch (error: any) {
      showMessage(error.message as string, 'error')
      handleChangeOption('login')
    }
  }

  return (
    <>
      <Dialog
        open={openDialog.includes('productsNotAvailable')}
        onClose={onCloseDialog}
        maxWidth={isMobile ? 'sm' : 'md'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {idIsCi && (
          <DialogTitle id="alert-dialog-title">
            <Alert severity="info" color="warning" sx={{ fontSize: isMobile ? '0.75rem' : 'auto' }}>
              <AlertTitle sx={{ fontSize: isMobile ? '0.85rem' : 'auto' }}>
                {'Algunos productos necesitan RUC'}
              </AlertTitle>
              <Typography variant={'body2'}>
                {'Para poder utilizar estos productos debe cambiar su '}
                <strong>{'identificación'}</strong>
                {' a '}
                <strong>{'RUC.'}</strong>
              </Typography>

              <Typography variant={'body2'}>
                {'La razón social de tu empresa deberás modificarla en quindefee.'}
              </Typography>
            </Alert>
          </DialogTitle>
        )}

        {idIsRuc && (
          <DialogTitle id="alert-dialog-title">
            <Alert severity="info" color="warning" sx={{ fontSize: isMobile ? '0.75rem' : 'auto' }}>
              <AlertTitle sx={{ fontSize: isMobile ? '0.85rem' : 'auto' }}>
                {'Algunos productos necesitan cédula'}
              </AlertTitle>
              <Typography variant={'body2'}>
                {'No puede tener productos de '}
                <strong>{'persona natural'}</strong>
                {' si esta registrado con '}
                <strong>{'RUC.'}</strong>
              </Typography>

              <Typography variant={'body2'} fontWeight={'bold'}>
                {'Elimina el producto para continuar.'}
              </Typography>
            </Alert>
          </DialogTitle>
        )}

        {idIsPassport && (
          <DialogTitle id="alert-dialog-title">
            <Alert severity="info" color="warning" sx={{ fontSize: isMobile ? '0.75rem' : 'auto' }}>
              <AlertTitle sx={{ fontSize: isMobile ? '0.85rem' : 'auto' }}>
                {'¡Ocurrió un problema!'}
              </AlertTitle>
              <Typography variant={'body2'}>
                {'No puedes adquirir productos de '}
                <strong>{'facturación electrónica '}</strong>
                {'porque tu cuenta está registrada con '}
                <strong>{'pasaporte.'}</strong>
              </Typography>
            </Alert>
          </DialogTitle>
        )}

        {idIsOutside && (
          <DialogTitle id="alert-dialog-title">
            <Alert severity="info" color="warning" sx={{ fontSize: isMobile ? '0.75rem' : 'auto' }}>
              <AlertTitle sx={{ fontSize: isMobile ? '0.85rem' : 'auto' }}>
                {'¡Ocurrió un problema!'}
              </AlertTitle>
              <Typography variant={'body2'}>
                {'No puedes adquirir productos porque tu cuenta está registrada con una '}
                <strong>{'identificación del exterior.'}</strong>
              </Typography>
            </Alert>
          </DialogTitle>
        )}

        <DialogContent
          sx={{
            p: 0,
            width: isMobile ? 'auto' : '48rem',
            overflow: isMobile ? 'scroll' : 'hidden',
          }}
        >
          <Box
            sx={
              isMobile
                ? { display: 'flex', flexDirection: 'column', alignItems: 'center' }
                : { display: 'flex', gap: 1, justifyContent: 'space-around' }
            }
          >
            <Box py={2} width={isMobile ? '90%' : '45%'}>
              <Typography textAlign={'center'} fontWeight={'bold'}>
                {'Productos no válidos para tu cuenta'}
              </Typography>

              {!isIdentificationRucOrCi() && (
                <Box pt={2}>
                  <Alert
                    severity="info"
                    color="warning"
                    sx={{ fontSize: isMobile ? '0.75rem' : 'auto' }}
                  >
                    <AlertTitle sx={{ fontSize: isMobile ? '0.85rem' : 'auto' }}>
                      {'Algunos productos necesitan RUC'}
                    </AlertTitle>
                    {'Para poder utilizar estos productos debe cambiar su '}
                    <strong>{'identificación'}</strong>
                    {' a '}
                    <strong>{'RUC.'}</strong>
                  </Alert>
                </Box>
              )}

              <Box
                pt={2}
                pl={'24px'}
                height={isMobile ? 'fit-content' : '16rem'}
                sx={{ overflow: isMobile ? 'hidden' : 'scroll' }}
              >
                {productsNotAvailable.map((product) => (
                  <Box
                    py={1}
                    sx={{
                      py: isMobile ? '0' : '8px',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 1,
                      alignItems: 'center',
                    }}
                  >
                    <AvatarGroup spacing={'small'}>
                      <Avatar
                        src={getLogo(product.mainSolutionId)}
                        sx={{
                          width: { xs: 20, sm: 24, md: 28 },
                          height: { xs: 20, sm: 24, md: 28 },
                        }}
                      />
                    </AvatarGroup>
                    <Typography
                      fontWeight={600}
                      color="textSecondary"
                      variant="caption"
                      align="left"
                      fontSize={'0.75em'}
                    >
                      {product.name}
                    </Typography>
                  </Box>
                ))}
                {specialProductsNotAvailable.map((specialProduct) => (
                  <Box
                    py={1}
                    sx={{
                      py: isMobile ? '0' : '8px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <AvatarGroup max={4} spacing={'small'}>
                      {specialProduct.specialProducts.map((product) => (
                        <Avatar
                          key={product.specialProductDetailId}
                          src={getLogo(product.productInformation.mainSolutionId)}
                          sx={{
                            width: { xs: 20, sm: 24, md: 28 },
                            height: { xs: 20, sm: 24, md: 28 },
                          }}
                        />
                      ))}
                    </AvatarGroup>
                    <Typography
                      fontWeight={600}
                      color="textSecondary"
                      variant="caption"
                      align="left"
                      pl={1}
                      fontSize={'0.75em'}
                    >
                      {specialProduct.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>

            {idIsCi && (
              <>
                <Divider
                  orientation={isMobile ? 'horizontal' : 'vertical'}
                  variant="middle"
                  flexItem
                />

                <Box pt={2} width={isMobile ? '90%' : '45%'}>
                  <Typography textAlign={'center'} fontWeight={'bold'}>
                    {'Tu cuenta cambiará al siguiente ruc'}
                  </Typography>

                  {isIdentificationRucOrCi() ? (
                    <Box
                      py={1}
                      height={'18rem'}
                      sx={{
                        display: 'flex',
                        gap: 2,
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        label={'Ruc'}
                        value={user.identification + '001'}
                        sx={{
                          width: '10em',
                          '& input.Mui-disabled': {
                            WebkitTextFillColor: MAIN_COLORS.primary.hover,
                          },
                          '& label.Mui-disabled': {
                            color: MAIN_COLORS.primary.hover,
                          },
                        }}
                        onChange={(e) => {}}
                        disabled
                      />
                    </Box>
                  ) : (
                    <Box
                      pt={2}
                      pr={isMobile ? '0' : '24px'}
                      height={
                        isMobile ? 'fit-content' : isIdentificationRucOrCi() ? '16rem' : '24rem'
                      }
                      sx={{ overflow: isMobile ? 'hidden' : 'scroll' }}
                    >
                      {option === ROUTES.signUp && (
                        <SignUpForm
                          fromModal={true}
                          getDataUser={getDataUser}
                          onClickSignUp={onClickSignUp}
                          handleChangeOption={handleChangeOption}
                        />
                      )}
                      {option === ROUTES.login && (
                        <LoginForm
                          fromModal={true}
                          dataUser={dataUser}
                          onClickLogin={onClickLogin}
                          handleChangeOption={handleChangeOption}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: MAIN_COLORS.alt.background,
              color: MAIN_COLORS.primary.main,
              border: `1px solid ${MAIN_COLORS.primary.main}`,
              '&:hover': {
                color: MAIN_COLORS.alt.background,
              },
            }}
            onClick={onClickCancel}
          >
            {'Cancelar'}
          </Button>
          {idIsCi && (
            <Button onClick={() => handleOpenDialog('confirmProductsNotAvailable')} autoFocus>
              {'Si, cambiar a RUC'}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog.includes('confirmProductsNotAvailable')}
        onClose={() => handleCloseDialog('confirmProductsNotAvailable')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'¿Está seguro de cambiar su identificación a RUC?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {'Una vez hecho el cambio ya no se puede revertir.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: MAIN_COLORS.alt.background,
              color: MAIN_COLORS.primary.main,
              border: `1px solid ${MAIN_COLORS.primary.main}`,
              '&:hover': {
                color: MAIN_COLORS.alt.background,
              },
            }}
            onClick={() => handleCloseDialog('confirmProductsNotAvailable')}
          >
            {'Cancelar'}
          </Button>
          <LoadingButton
            loading={loadingChangeRuc}
            onClick={() =>
              handleChangeIdentificationToRuc(
                user.identification,
                user.identification + '001',
                user.userName
              )
            }
            autoFocus
          >
            {'Si, cambiar a RUC'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
