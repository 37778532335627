import { Icon, Skeleton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ExploreIndicator, ProcessSummary } from 'components'
import { motion } from 'framer-motion'
import { useAuth, useGetProcesses, useTransaction } from 'hooks'
import React from 'react'

export const UserProcesses = () => {
  const { user } = useAuth()
  const { processes, loading, refresh } = useGetProcesses(user.identification)
  const { transactions, getTransactionsByCompanyId } = useTransaction()

  React.useEffect(() => {
    const existsUserId: boolean = Boolean(user.identification)
    if (!existsUserId) return
    getTransactionsByCompanyId(user.identification)
  }, [])

  const lastTransactionInvoiceId = React.useMemo(() => {
    if (transactions.length > 0) {
      const id = transactions[transactions.length - 1].invoiceContractId
      return id
    }
    return undefined
  }, [transactions])

  const loadingTransaction = React.useMemo(() => {
    return transactions.length > 0
  }, [transactions])

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography variant="h5" sx={{ textAlign: 'center', marginY: '20px', fontWeight: 600 }}>
        <Icon sx={{ mr: '5px', fontSize: 'x-large' }}>auto_awesome_motion</Icon>
        Mi Carrito
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {loading && (
          <Skeleton
            sx={{
              display: 'flex',
              width: { xs: '290px', sm: '450px', md: '430px', lg: '500px' },
              height: '200px',
              boxSizing: 'border-box',
            }}
            component={motion.div}
            animate={{
              opacity: 1,
              scale: 1,
            }}
            initial={{
              opacity: 0,
              scale: 0.9,
            }}
            transition={{
              type: 'spring',
              stiffness: 200,
              damping: 25,
              delay: 0.15 * Math.random(),
            }}
          />
        )}
        {processes
          .filter((pro) => !pro.complete)
          .map((process) => (
            <Box id="process-card" key={process.processId}>
              <ProcessSummary
                disableCompleteButtom={!loadingTransaction}
                process={process}
                lastTransactionInvoiceId={lastTransactionInvoiceId}
                isRefund={
                  transactions.find((transaction) =>
                    transaction.history.some(
                      (history) =>
                        history.state.transactionStateId === 3 &&
                        history.processId === process.processId
                    )
                  ) !== undefined
                }
                onRefresh={refresh}
              />
            </Box>
          ))}
      </Box>
      {processes.filter((pro) => !pro.complete).length === 0 && !loading && (
        <Box paddingBottom={{ xs: 12, sm: 1 }}>
          <ExploreIndicator />
        </Box>
      )}
    </Box>
  )
}

export { UserProcesses as default } from './User'