import { createCompanyService } from 'lib'
import React from 'react'

type Hook = {
  loading: boolean
  createCompany: (companyId: string, companyName: string) => Promise<void>
}

export const useCreateCompany = (onCreate?: () => void): Hook => {
  const [loading, setLoading] = React.useState(false)

  const createCompany = async (companyId: string, companyName: string) => {
    try {
      setLoading(true)
      await createCompanyService({ companyId, companyName })
      if (onCreate) {
        onCreate()
      }
    } catch (error: any) {
      throw new Error(error.message)
    }
  }

  return {
    loading,
    createCompany,
  }
}
