export const MAIN_COLORS = {
  primary: {
    main: '#272727',
    hover: '#5d5d5d',
  },
  secondary: {
    main: '#0064B1',
    selected: '#90EE90',
  },
  alt: {
    background: '#EFEFEF',
    text: '#000000',
  },
  success: {
    main: '#66bb6a',
  },
}

export const SOLUTION_COLORS = {
  quinde: {
    primary: {
      main: '#3872C4',
      dark: '#163158',
    },
    secondary: {
      main: '#C7B038',
      dark: ' #E9CEA1 ',
    },
  },
  alpaqqa: {
    primary: {
      main: '#5E35B1',
      dark: '#2F1B59',
    },
    secondary: {
      main: '#A14A09',
      dark: '#D0E4A3',
    },
  },
  qreativee: {
    primary: {
      main: '#C2185B',
      dark: '#9A1B4D',
    },
    secondary: {
      main: '#1E72B4',
      dark: '#149A52',
    },
  },
  miqo: {
    primary: {
      main: '#FFC12A',
      dark: '#D89900',
    },
    secondary: {
      main: '#2A66FF',
      dark: ' #2676D8',
    },
  },
  nuna: {
    primary: {
      main: '#299AA6',
      dark: '#006873',
    },
    secondary: {
      main: '#A65229 ',
      dark: ' #873200',
    },
  },
  administration: {
    primary: {
      main: '#5E9792',
      dark: '#2762AE',
    },
    secondary: {
      main: '#3872C4',
      dark: '#163158',
    },
  },
  promo: {
    primary: {
      main: '#FBBE11',
      dark: '#FD3675',
    },
    secondary: {
      main: '#113AFB',
      dark: '#3675FD',
    },
  },
  main: {
    primary: {
      main: '#272727',
      dark: '#979797',
    },
  },
}
