import { Avatar, Box, Button, Icon, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { motion } from 'framer-motion'

type Props = {
  onSelect: (identification: string) => void
  email: string
  identification: string
  mask?: boolean
  buttonLabel?: string
  isSelect?: boolean
  hideButton?: boolean
  icon?: string
}

export const AccountInfo = (props: Props) => {
  const { email, identification, mask, onSelect, buttonLabel, isSelect, hideButton, icon } = props

  const replaceAt = (index: number, replacement: string, line: string) => {
    return line.substring(0, index) + replacement + line.substring(index + replacement.length)
  }
  const generateIdentificationMask = (identification: string) => {
    let emailMask = replaceAt(5, 'X', identification)
    emailMask = replaceAt(6, 'X', emailMask)
    emailMask = replaceAt(7, 'X', emailMask)
    return emailMask
  }

  const generateEmailMask = (email: string) => {
    let maskedEmail = email.replace(/([^@.])/g, '*').split('')
    let previous = ''
    for (let i = 0; i < maskedEmail.length; i++) {
      if (i <= 1 || previous === '.' || previous === '@') {
        maskedEmail[i] = email[i]
      }
      previous = email[i]
    }
    return maskedEmail.join('')
  }

  return (
    <Box
      component={motion.div}
      whileHover={{
        scale: 1.05,
      }}
      sx={{
        cursor: 'pointer',
        width: { xs: 270, md: 650 },
        position: 'relative',
      }}
      onClick={() => onSelect && onSelect(identification)}
    >
      {isSelect && (
        <Box
          sx={{
            position: 'absolute',
            top: -9,
            right: -9,
            width: '25px',
            height: '25px',
            backgroundColor: 'white',
            borderRadius: '100%',
          }}
        >
          <Icon
            sx={{
              position: 'absolute',
              top: -7,
              right: -7,
              borderRadius: '100%',
            }}
            fontSize="large"
            color="success"
            component={motion.span}
            initial={{
              scale: 0,
              opacity: 0,
            }}
            exit={{
              scale: 0,
              opacity: 0,
            }}
            animate={{
              scale: 1,
              opacity: 1,
            }}
          >
            check_circle
          </Icon>
        </Box>
      )}
      <Stack
        component={Paper}
        display={'flex'}
        gap={1}
        alignItems="center"
        justifyContent={hideButton ? 'flex-start' : 'space-around'}
        flexWrap={'nowrap'}
        sx={{ flexDirection: { xs: 'column', md: 'row' }, px: 1, py: 2 }}
      >
        <Avatar sx={{ bgcolor: 'primary.main', width: 45, height: 45, fontSize: '1.2em' }}>
          {email.charAt(0).toUpperCase() ?? undefined}
        </Avatar>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 1, md: 2 },
            alignItems: { xs: 'flex-start', md: 'center' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
              maxWidth: { xs: 240, md: 300 },
              overflow: 'hidden',
            }}
          >
            <Icon sx={{ color: 'action.active' }}>email</Icon>
            <Box>
              <Typography variant="caption" color="textSecondary">
                {'Correo electrónico'}
              </Typography>
              <Tooltip title={mask ? generateEmailMask(email).substring(0, 25) : email} arrow>
                <Typography
                  variant="body1"
                  sx={{ fontSize: { xs: '' }, textOverflow: 'ellipsis' }}
                  textAlign={{ xs: 'left' }}
                >
                  {`${mask ? generateEmailMask(email).substring(0, 25) : email}`}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              <Icon sx={{ color: 'action.active' }}>badge</Icon>
              <Box>
                <Typography variant="caption" color="textSecondary">
                  {'Identificación'}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: { xs: '' } }}
                  textAlign={{ xs: 'left' }}
                >
                  {`${mask ? generateIdentificationMask(identification) : identification}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {!hideButton && (
          <Button
            onClick={() => onSelect(identification)}
            endIcon={<Icon>{icon ? icon : 'done'}</Icon>}
          >
            {buttonLabel || 'Utilizar esta cuenta'}
          </Button>
        )}
      </Stack>
    </Box>
  )
}
