import { usePagination } from 'hooks/utils/usePagination'
import { Box, Button, Grid, Icon, Stack, Typography } from '@mui/material'
import { NavBar, RefundsTable, ResponsiveLoading } from 'components'
import { useAuth, useVerifyCompany } from 'hooks'
import { REFUND_REQUESTS_PATH, ROUTES } from 'lib'
import { IRefundFilters } from 'types'
import { IRefundRequest } from 'marketplace-common'
import { NavLink } from 'react-router-dom'

export const Refunds = () => {
  const { user } = useAuth()
  const { unregisteredCompany } = useVerifyCompany(user.identification)

  const { list, loading } = usePagination<IRefundRequest, IRefundFilters>(REFUND_REQUESTS_PATH, {
    companyId: user.identification,
    page: 1,
    limit: 50,
  } as IRefundFilters)

  return (
    <>
      <NavBar unregisteredCompany={unregisteredCompany} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '20px',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Box
          sx={{
            flex: 2,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              fontWeight: 600,
              marginY: '20px',
            }}
          >
            <Icon sx={{ mr: '5px', pt: '4px', fontSize: 'x-large' }}>mail_outline</Icon>
            {'Solicitudes de devolución'}
          </Typography>
        </Box>

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <NavLink
            to={`/${ROUTES.user}/${ROUTES.contacts}/${ROUTES.requestRefund}`}
            end
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <Button
              endIcon={<Icon>mail_outline</Icon>}
              sx={{
                paddingY: 1,
                mr: { xs: 0, lg: 1 },
              }}
              variant="contained"
            >
              Solicitar Reembolso
            </Button>
          </NavLink>
        </Box>
      </Box>

      <Grid container flexGrow={1}>
        {loading ? (
          <ResponsiveLoading />
        ) : (
          <Stack width={'100%'} spacing={2} py={1} px={3}>
            <RefundsTable requests={list} />
          </Stack>
        )}
      </Grid>
    </>
  )
}

export { Refunds as default } from './Refunds'