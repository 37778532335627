import { getSpecialProductsService } from 'lib'
import React from 'react'
import { ISpecialProduct } from 'marketplace-common'
import { useLoading } from 'hooks'

type Hook = {
  specialProducts: ISpecialProduct[]
  haveSpecialProducts: boolean
  loadingSpecialProducts: boolean
  handleChangeFilter: (select: boolean) => void
  filterSpecialProductsSelect: boolean
  getSpecialProducts: () => Promise<void>
}

export const useSpecialProducts = (): Hook => {
  const [specialProducts, setSpecialProducts] = React.useState<ISpecialProduct[]>([])
  const [loadingSpecialProducts, setLoadingSpecialProducts] = React.useState<boolean>(true)
  const [filterSelect, setFilterSelect] = React.useState<boolean>(false)
  const { addLoadingReference, removeLoadingReference } = useLoading()
  const getSpecialProducts = async () => {
    try {
      addLoadingReference('specialProducts')
      setLoadingSpecialProducts(true)
      const result = await getSpecialProductsService()
      setSpecialProducts(result)
    } catch (error) {
      console.log('Error useSpecialProduct', error)
    } finally {
      removeLoadingReference('specialProducts')
      setLoadingSpecialProducts(false)
    }
  }

  const handleChangeFilter = (select: boolean) => {
    setFilterSelect(select)
  }

  const haveSpecialProducts = React.useMemo(() => specialProducts.length > 0, [specialProducts])

  return {
    specialProducts,
    haveSpecialProducts,
    loadingSpecialProducts,
    handleChangeFilter,
    filterSpecialProductsSelect: filterSelect,
    getSpecialProducts,
  }
}
