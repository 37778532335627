import { getProcessStepsService } from 'lib'
import React from 'react'
import { IStep } from 'types'

type Hook = {
  steps: IStep[]
}

export const useGetSteps = (): Hook => {
  const [steps, setSteps] = React.useState<IStep[]>([])

  const getSteps = React.useCallback(async () => {
      const result = await getProcessStepsService()
      setSteps(result)
  }, [])

  React.useEffect(() => {
    getSteps()
  }, [getSteps])

  return {
    steps,
  }
}
