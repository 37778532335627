import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'

export const FeedbackLayout = () => {
  return (
    <Box
      width={'100vw'}
      height={'100vh'}
      bgcolor={(theme) => theme.palette.alt?.background}
      overflow="auto"
    >
      <Outlet />
    </Box>
  )
}
