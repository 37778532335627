import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import forgotPassword from 'assets/animations/forgot-password.json'
import { AnimatedLink, SolutionArch } from 'components'
import { useFeedback, useForgotPassword, useGetSolutions } from 'hooks'
import { ROUTES } from 'lib'
import React from 'react'
import Lottie from 'react-lottie'
import theme from 'styles/themes/main'
import biosferasoftLogoMain from '../../assets/img/logo-biosferasoft-main.webp'

type Props = {}

export const ForgotPassword = (props: Props) => {
  const { email, switchLoading, changeEmail, sendForgotPasswordRequest, loading } =
    useForgotPassword()
  const { showMessage } = useFeedback()
  const { solutions } = useGetSolutions()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const filteredSolutions = solutions.filter((solution) => solution.mainSolutionId !== 6)

  const handleSendForgotPasswordRequest = React.useCallback(async () => {
    try {
      await sendForgotPasswordRequest()
      showMessage('El correo de recuperación fue enviado ', 'success')
    } catch (error) {
      showMessage('Verifica que tu correo electrónico sea correcto', 'error')
    } finally {
      switchLoading()
    }
  }, [sendForgotPasswordRequest])

  return (
    <Grid container height={'100%'} justifyContent="center" alignItems="center">
      <Paper
        elevation={0}
        sx={{
          py: { xs: 3, sm: 5 },
          px: { xs: 3, sm: 6 },
        }}
      >
        <Stack spacing={2} sx={{ width: { xs: '16rem', sm: '22rem' } }}>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} pt={2}>
            {!isMobile && <SolutionArch solutions={filteredSolutions} />}
            <img src={biosferasoftLogoMain} alt={'Biosferasoft'} width="252" height="106" />
          </Box>

          <Lottie
            options={{
              animationData: forgotPassword,
              loop: true,
            }}
            width={180}
            height={180}
          />
          <Typography
            fontWeight={500}
            variant="body2"
            sx={{ width: '100%' }}
            align="center"
            color="textSecondary"
          >
            {`¿Olvidaste tu contraseña?`}
          </Typography>
          <Typography
            fontWeight={500}
            variant="caption"
            sx={{ width: '100%' }}
            align="center"
            color="textSecondary"
          >
            {`No te preocupes, te enviaremos un enlace a al correo asociado a tu cuenta para que lo puedas recuperar`}
          </Typography>
          <Box py={2} width="100%">
            <TextField
              placeholder="Correo electrónico"
              fullWidth
              autoFocus
              type="email"
              value={email}
              onChange={(e) => changeEmail(e.target.value)}
              required
            />
          </Box>
          <LoadingButton onClick={handleSendForgotPasswordRequest} loading={loading}>
            {'Enviar correo'}
          </LoadingButton>
          <AnimatedLink to={ROUTES.login} type="circle">
            <Button variant="text" fullWidth>
              {'Regresar a iniciar sesión'}
            </Button>
          </AnimatedLink>
        </Stack>
      </Paper>
    </Grid>
  )
}
