import { Box, Button, Paper } from '@mui/material'
import creditCards from 'assets/animations/credit-cards.json'
import { IDatawebCreateCheckoutRequest, useDataweb, useProcess } from 'hooks'
import React from 'react'
import Lottie from 'react-lottie'
import { useLocation } from 'react-router-dom'
import properties from '../../../../package.json'
import '../styles.css'
import datafastImage from '../../../assets/img/verified.png'

export const Dataweb = () => {
  const { loading, initCheckout, handleCallback } = useDataweb()
  const [showAnimation, setShowAnimation] = React.useState(true)
  const { process: processInfo, finishCallback } = useProcess()
  const location = useLocation()

  const cleanProcessId = (processId: string) => {
    if (processId.endsWith('=')) {
      return processId.slice(0, -1)
    }
    if (processId.endsWith('==')) {
      return processId.slice(0, -2)
    }
    if (processId.endsWith('===')) {
      return processId.slice(0, -3)
    }
    return processId
  }

  const redirect = () => {
    // const isDevelopServer = !properties.homepage.includes(process.env.REACT_APP_PRODUCTION_PATH!)
    // const url = isDevelopServer ? `${properties.homepage}` : `${properties.homepage}`
    const baseURL =
      process.env.NODE_ENV === 'production'
        ? `${properties.homepage}`
        : `${window.location.origin}/marketplace`
    const isValidProcess = Boolean(processInfo) && Boolean(processInfo!.processId)
    if (!isValidProcess) {
      return ''
    }
    return `${baseURL}${location.pathname}`
  }

  const redirectFakeCheckout = async () => {
    try {
      await handleCallback('0', processInfo?.processId ?? 1)
      finishCallback()
      alert('pago procesado ok recarga la pagina por que eres un desarrollador')
    } catch (e) {
      alert('Error al procesar el pago fake')
    }
  }

  React.useEffect(() => {
    if (!Boolean(processInfo)) return

    initCheckout({
      processId: processInfo!.processId,
    } as IDatawebCreateCheckoutRequest)
  }, [])

  React.useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setShowAnimation(false)
      }, 2000)
    }
  }, [loading])

  /**
   * Esto debe ser asi porque si no se hace de esta manera, datafast no permite
   * que se haga el pago con tarjeta , no se deberia ocupar jquery pero no hay
   * otra manera de hacerlo por requerimiento de  datafast
   */
  const functionValidateCardHolder = function () {
    if ($('.wpwl-control-cardHolder').val() === '') {
      $('.wpwl-control-cardHolder').addClass('wpwl-has-error')
      $('.wpwl-control-cardHolder').addClass('card-holder-error')
      $('.wpwl-control-cardHolder').after(
        "<div class='wpwl-hint wpwl-hint-cardHolderError'>Campo requerido</div>"
      )
      $('.wpwl-button-pay').addClass('wpwl-button-error').attr('disabled', 'disabled')
      return false
    }
    return true
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box sx={{ opacity: showAnimation ? 0 : 1, width: '100%' }}>
        <Paper
          sx={{
            display: 'flex',
            p: 1,
            paddingY: 3,
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1.2,
          }}
        >
          {process.env.REACT_APP_FAKE_CHECKOUT === 'true' && (
            <Box sx={{ px: 2, display: 'flex', justifyContent: 'flex-start' }}>
              <Button
                onClick={() => {
                  redirectFakeCheckout()
                }}
              >
                Pago
              </Button>
            </Box>
          )}
          <Box
            component={'form'}
            action={redirect()}
            className="paymentWidgets"
            data-brands="VISA MASTER DISCOVER DINERS AMEX"
            id="paymentWidgets"
            sx={{ opacity: 0 }}
          >
            {Boolean((window as any).wpwlOptions) &&
              ((window as any).wpwlOptions.onBeforeSubmitCard = functionValidateCardHolder)}
          </Box>
          <Box>
            <Box
              component={'img'}
              height={{ xs: '43.86px', sm: '61.40px', md: '78.95px' }}
              width={{ xs: '250px', sm: '350px', md: '450px' }}
              src={datafastImage}
              alt="datafast"
            />
          </Box>
        </Paper>
      </Box>

      {showAnimation && (
        <Box
          sx={{
            display: 'flex',
            top: -10,
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            bgcolor: (theme) => theme.palette.alt?.background,
          }}
        >
          <Lottie
            width={300}
            height={300}
            options={{
              animationData: creditCards,
            }}
          />
        </Box>
      )}
    </Box>
  )
}
