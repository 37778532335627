import { forgotPasswordService } from 'lib'
import React from 'react'

type Hook = {
  email: string
  loading: boolean
  switchLoading: () => void
  changeEmail: (email: string) => void
  sendForgotPasswordRequest: () => Promise<any>
}

export const useForgotPassword = (): Hook => {
  const [email, setEmail] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)
  const changeEmail = (email: string) => {
    setEmail(email)
  }

  const sendForgotPasswordRequest = React.useCallback(() => {
    try {
      setLoading(true)
      return forgotPasswordService(email)
    } catch (error) {
      throw error
    }
  }, [email])

  const switchLoading = () => {
    setLoading((prev) => !prev)
  }
  return {
    loading,
    email,
    switchLoading,
    changeEmail,
    sendForgotPasswordRequest,
  }
}
