import { ICartProduct, IProduct } from 'marketplace-common'

type DetailAgruped = {
  productId?: number
  specialProductId?: number
  product?: IProduct
  count: number
}

type Hook = {
  grouped: (cart: ICartProduct[]) => DetailAgruped[]
}

export const useGetProductsRepeact = (): Hook => {
  const getRepeatProductsOnlyProducts = (products: IProduct[]) => {
    const grouped = [] as DetailAgruped[]
    products.forEach((item) => {
      const existThisProduct = grouped.find(
        (itemGrouped) => itemGrouped.productId === item.productId
      )
      if (existThisProduct) {
        existThisProduct.count = existThisProduct.count + 1
        const index = grouped.findIndex((grouped) => grouped.productId === item.productId)
        grouped[index] = existThisProduct
        // existThisProduct.product = grouped[index].product
      } else {
        grouped.push({
          productId: item.productId,
          count: 1,
          product: item,
        })
      }
    })
    return grouped
  }

  const grouped = (cart: ICartProduct[]) => {
    const specialCart = cart.filter((item) => item.specialProduct !== null)
    const productsOfSpecial = [] as IProduct[]
    specialCart.forEach((item) => {
      const products = item.specialProduct?.specialProducts.map((item) => item.productInformation)
      if (products) {
        productsOfSpecial.push(...products)
      }
    })
    const products = cart.filter((item) => item.product !== null).map((item) => item.product!)
    const grouped = getRepeatProductsOnlyProducts([...products, ...productsOfSpecial])
    return grouped
  }

  return {
    grouped,
  }
}
