import { Grid, TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { ICompanyInformation } from 'marketplace-common'
import React from 'react'
import { IValidateErrors } from 'types'

type Props = {
  companyInformation: ICompanyInformation
  fieldErrors: IValidateErrors
  setFieldErrors: React.Dispatch<React.SetStateAction<IValidateErrors>>
  changeCompanyInformation: (companyInfo: ICompanyInformation) => void
}

export const PaymentForm = (props: Props) => {
  const { companyInformation, fieldErrors, setFieldErrors, changeCompanyInformation } = props

  const handleChangeField =
    (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value
      const newCompanyInformation = {
        companyId: companyInformation.companyId,
        informationType: '1',
        enable: false,
        default: false,
        identification:
          fieldName === 'identification'
            ? value.replace(/[^0-9]/g, '')
            : companyInformation.identification,
        name: fieldName === 'name' ? value : companyInformation.name,
        middleName: fieldName === 'middleName' ? value : companyInformation.middleName,
        lastName: fieldName === 'lastName' ? value : companyInformation.lastName,
        mail: fieldName === 'mail' ? value : companyInformation.mail,
        address: fieldName === 'address' ? value : companyInformation.address,
        phoneNumber:
          fieldName === 'phoneNumber'
            ? value.replace(/[^0-9]/g, '')
            : companyInformation.phoneNumber,
      } as ICompanyInformation

      changeCompanyInformation(newCompanyInformation)
      setFieldErrors((prevErrors) => ({
        identification: fieldName === 'identification' ? '' : prevErrors.identification,
        address: fieldName === 'address' ? '' : prevErrors.address,
        lastName: fieldName === 'lastName' ? '' : prevErrors.lastName,
        mail: fieldName === 'mail' ? '' : prevErrors.mail,
        middleName: fieldName === 'middleName' ? '' : prevErrors.middleName,
        name: fieldName === 'name' ? '' : prevErrors.name,
        phoneNumber: fieldName === 'phoneNumber' ? '' : prevErrors.phoneNumber,
      }))
    }

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Box display={'flex'} flexDirection={'row'} gap={1}>
          <Box display={'flex'} flexDirection={'column'} gap={1} sx={{ width: '100%' }}>
            <TextField
              variant="filled"
              label="Identificación"
              fullWidth
              inputProps={{ maxLength: 13 }}
              autoFocus
              required
              value={companyInformation?.identification ?? ''}
              onChange={handleChangeField('identification')}
            />
            {fieldErrors.identification && (
              <Typography variant="caption" color="error">
                {fieldErrors.identification}
              </Typography>
            )}
            <TextField
              variant="filled"
              label={'Primer Nombre'}
              fullWidth
              required
              value={companyInformation?.name ?? ''}
              onChange={handleChangeField('name')}
            />
            {fieldErrors.name && (
              <Typography variant="caption" color="error">
                {fieldErrors.name}
              </Typography>
            )}

            <TextField
              variant="filled"
              label="Apellido"
              fullWidth
              value={companyInformation?.lastName ?? ''}
              onChange={handleChangeField('lastName')}
            />
            {fieldErrors.lastName && (
              <Typography variant="caption" color="error">
                {fieldErrors.lastName}
              </Typography>
            )}
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={1} sx={{ width: '100%' }}>
            <TextField
              variant="filled"
              label="Correo electrónico"
              fullWidth
              required
              value={companyInformation?.mail ?? ''}
              onChange={handleChangeField('mail')}
            />
            {fieldErrors.mail && (
              <Typography variant="caption" color="error">
                {fieldErrors.mail}
              </Typography>
            )}

            <TextField
              variant="filled"
              label="Segundo Nombre"
              fullWidth
              value={companyInformation?.middleName ?? ''}
              onChange={handleChangeField('middleName')}
            />
            {fieldErrors.middleName && (
              <Typography variant="caption" color="error">
                {fieldErrors.middleName}
              </Typography>
            )}

            <TextField
              variant="filled"
              label="Teléfono"
              fullWidth
              required
              inputProps={{ maxLength: 10 }}
              value={companyInformation?.phoneNumber ?? ''}
              onChange={handleChangeField('phoneNumber')}
            />
            {fieldErrors.phoneNumber && (
              <Typography variant="caption" color="error">
                {fieldErrors.phoneNumber}
              </Typography>
            )}
          </Box>
        </Box>
        <TextField
          variant="filled"
          label="Dirección"
          fullWidth
          value={companyInformation?.address ?? ''}
          onChange={handleChangeField('address')}
        />
        {fieldErrors.address && (
          <Typography variant="caption" color="error">
            {fieldErrors.address}
          </Typography>
        )}
      </Box>
    </>
  )
}
