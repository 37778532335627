import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { IBankingInformation } from 'marketplace-common'

interface Props {
  bankInformation: IBankingInformation
}

export const BankInformation = (props: Props) => {
  const { bankInformation } = props

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 1.2,
        maxWidth: '330px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Box
          component={'img'}
          sx={{
            borderRadius: '13px',
            width: { xs: '32px' },
            height: { xs: '32px' },
          }}
          loading="lazy"
          src={bankInformation.bankLogo}
        />
        <Typography
          variant="caption"
          sx={{
            textAlign: 'center',
            lineHeight: 1.3,
            fontWeight: 700,
            fontSize: { xs: 16 },
          }}
        >
          {bankInformation.bankName}
        </Typography>
      </Box>
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              lineHeight: 1.3,
              fontWeight: 700,
              fontSize: 14,
            }}
          >
            {'Número de cuenta'}
          </Typography>

          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              lineHeight: 1.3,
              fontWeight: 500,
              fontSize: 14,
            }}
          >
            {bankInformation.accountNumber}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              lineHeight: 1.3,
              fontWeight: 700,
              fontSize: 14,
            }}
          >
            {'Nombre del Beneficiario'}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              lineHeight: 1.3,
              fontWeight: 500,
              fontSize: 14,
            }}
          >
            {bankInformation.beneficiaryName}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              lineHeight: 1.3,
              fontWeight: 700,
              fontSize: 14,
            }}
          >
            {'Tipo de Cuenta'}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              lineHeight: 1.3,
              fontWeight: 500,
              fontSize: 14,
            }}
          >
            {bankInformation.accountType}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              lineHeight: 1.3,
              fontWeight: 700,
              fontSize: 14,
            }}
          >
            {'Ruc'}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              lineHeight: 1.3,
              fontWeight: 500,
              fontSize: 14,
            }}
          >
            {bankInformation.ruc}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
