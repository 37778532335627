import { Theme } from '@mui/material'
import {
  alpaqqaTheme,
  qreativeeTheme,
  nunaTheme,
  quindeTheme,
  miqoTheme,
  administrationTheme,
  promoTheme,
  mainTheme,
} from 'styles'

export const SOLUTION_THEMES = {
  quinde: quindeTheme,
  alpaqqa: alpaqqaTheme,
  qreativee: qreativeeTheme,
  miqo: miqoTheme,
  nuna: nunaTheme,
  administration: administrationTheme,
  promo: promoTheme,
  main: mainTheme
}

export const SOLUTION_THEMES_BY_ID: { [key: number]: Theme } = {
  0: mainTheme,
  1: quindeTheme,
  2: alpaqqaTheme,
  3: nunaTheme,
  4: miqoTheme,
  5: qreativeeTheme,
  6: administrationTheme,
  7: promoTheme,
}
