import { Box, Divider, Icon, Typography } from '@mui/material'
import {
  Dataweb,
  DialogValidateUserId,
  FullscreenLoading,
  PaymentMethod,
  TransactionStatus,
  Transfer,
} from 'components'
import {
  useAuth,
  useCart,
  useDataweb,
  useFeedback,
  useProcess,
  useProcessSelection,
  useTransaction,
} from 'hooks'
import {
  changeCompanyIdToRucService,
  COMPLETED_TRANSACTION_ID,
  INIT_TRANSACTION_ID,
  PAYMENT_METHOD,
  PENDING_TRANSACTION_ID,
  REJECTED_TRANSACTION_ID,
} from 'lib'
import { PaymentMethod as PaymentMethodTypes, StepReferenceName } from 'types'

import { LoadingButton } from '@mui/lab'
import { motion } from 'framer-motion'
import { IProcess, ITransactionState } from 'marketplace-common'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { TransactionAlert } from './Alert'
import { PaymentSummary } from './Summary'

export const Checkout = () => {
  const [method, setMethod] = React.useState<PaymentMethodTypes>('card')
  const { handleCallback } = useDataweb()
  const [loading, setLoading] = React.useState<Boolean>(false)
  const { validateProductsByUserId } = useCart()
  const { user } = useAuth()
  const { showMessage } = useFeedback()
  const { handleOpenDialog, handleCloseDialog, openDialog } = useProcessSelection()
  const {
    loading: loadingCreate,
    loading: loadingTransaction,
    transactions,
    getTransactionsByProcessId,
  } = useTransaction()

  const {
    process,
    completeProcess,
    payInProcessOrComplete,
    currentStep,
    shopToAnotherAccount,
    handleChangeShopToAnotherAccount,
    changeStepIndex,
    loadingCompleteProcess,
    deleteEnableSteps,
  } = useProcess()

  const handleSuccessfulTransaction = async () => {
    try {
      setLoading(true)
      await completeProcess()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const isOpenDialog = openDialog.includes('productsNotAvailable')

  const inProcessOrCompleted = React.useMemo((): boolean => {
    const lastTransaction = [...transactions].pop()
    const isCompleted =
      transactions.length > 0 &&
      (lastTransaction?.state.transactionStateId === PENDING_TRANSACTION_ID ||
        lastTransaction?.state.transactionStateId === COMPLETED_TRANSACTION_ID)
    return isCompleted
  }, [transactions])

  const isDbPaymentMethod = React.useMemo((): boolean => {
    const lastTransaction = [...transactions].pop()
    const isTarget =
      transactions.length > 0 &&
      (lastTransaction?.method === PAYMENT_METHOD.DB || lastTransaction?.type === PAYMENT_METHOD.DB)
    return isTarget
  }, [transactions])

  React.useEffect(() => {
    payInProcessOrComplete(inProcessOrCompleted)
  }, [inProcessOrCompleted])

  const isRejected = React.useMemo((): boolean => {
    const lastTransaction = [...transactions].pop()
    return (
      transactions.length > 0 &&
      lastTransaction?.state.transactionStateId === REJECTED_TRANSACTION_ID
    )
  }, [transactions])

  const isInitialize = React.useMemo((): boolean => {
    const lastTransaction = [...transactions].pop()
    return (
      transactions.length > 0 && lastTransaction?.state.transactionStateId === INIT_TRANSACTION_ID
    )
  }, [transactions])

  const state = React.useMemo((): ITransactionState | undefined => {
    const lastTransaction = [...transactions].pop()
    return lastTransaction?.state
  }, [transactions])

  const transactionAdministratorComment = React.useMemo(() => {
    return [...transactions].pop()?.transactionAdministratorComment ?? ''
  }, [transactions])

  const handleHiddenStepAccount = (process: IProcess) => {
    const shopForDifferentCompany =
      process.assignedCompanyId !== null && process.assignedCompanyId !== process.companyId
    if (shopForDifferentCompany && !shopToAnotherAccount) {
      handleChangeShopToAnotherAccount()
    }
  }

  React.useEffect(() => {
    const existsProcessId: boolean = Boolean(process?.processId)
    if (!existsProcessId) return
    getTransactionsByProcessId(process!.processId!)
  }, [process])

  React.useEffect(() => {
    if (!process) return
    handleHiddenStepAccount(process!)
  }, [process, shopToAnotherAccount])

  const [searchParams] = useSearchParams()
  const callbackId = searchParams.get('id')
  const [stateCallback, setStateCallback] = React.useState(true)
  const handleOnDatafastRefresh = async (callbackId: string, processId: number) => {
    try {
      setLoading(true)
      await handleCallback(callbackId, processId)
      await getTransactionsByProcessId(processId)
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const handleCloseDialogValidateUserId = () => {
    changeStepIndex(1)
    handleCloseDialog('productsNotAvailable')
    deleteEnableSteps(StepReferenceName.PAYMENT)
  }

  const validateProducts = async (processId: number, userIdType: string) => {
    const existsProductsNotAvailable = await validateProductsByUserId(processId, userIdType)
    if (existsProductsNotAvailable) {
      handleOpenDialog('productsNotAvailable')
    }
  }

  const handleChangeIdentificationToRuc = async (
    identification: string,
    newRuc: string,
    companyName: string
  ) => {
    try {
      await changeCompanyIdToRucService(identification, newRuc, companyName)
      window.location.reload()
    } catch (error) {
      handleCloseDialog('confirmProductsNotAvailable')
      handleCloseDialogValidateUserId()

      showMessage(
        'Se produjo un error al intentar cambiar la identificación a RUC, por favor comuníquese con soporte para ayuda.',
        'error'
      )
      console.error(error)
    }
  }

  React.useEffect(() => {
    if (transactions.length === 0) return
    if (isRejected) {
      setStateCallback(false)
      return
    }
    if (isInitialize) {
      setStateCallback(false)
      return
    }
    if (callbackId && !inProcessOrCompleted) {
      setStateCallback(true)
    } else {
      setStateCallback(false)
    }
  }, [transactions, isRejected, isInitialize, callbackId, inProcessOrCompleted])

  React.useEffect(() => {
    if (transactions.length === 0) return
    if (!process) return
    if (!callbackId) return
    if (inProcessOrCompleted) return
    handleOnDatafastRefresh(callbackId, process!.processId!)
  }, [callbackId, process, inProcessOrCompleted, isRejected, isInitialize, transactions])

  React.useEffect(() => {
    if (process?.processId && user.identificationType) {
      validateProducts(process?.processId, user.identificationType)
    }
  }, [])

  return (
    <Box
      pb={{ xs: 12, md: 0 }}
      sx={{
        minHeight: '100vh',
        display: 'flex',
        margin: '20px auto',
        paddingX: 3,
        flexDirection: 'column',
      }}
    >
      {loadingTransaction || loadingCompleteProcess || loadingCreate || loading ? (
        <FullscreenLoading />
      ) : (
        <>
          {!isOpenDialog && (
            <>
              {inProcessOrCompleted && state ? (
                <TransactionStatus
                  state={state}
                  onSuccess={handleSuccessfulTransaction}
                  loadingCompleteProcess={loadingCompleteProcess}
                />
              ) : (
                <React.Fragment>
                  <Box
                    sx={{
                      py: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 1.3,
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Typography variant="h5" fontWeight={900} sx={{ textAlign: 'center' }}>
                        {currentStep?.description}
                      </Typography>

                      <LoadingButton
                        startIcon={<Icon>chevron_left</Icon>}
                        onClick={async () => {
                          if (currentStep !== undefined) {
                            changeStepIndex(currentStep.index - (shopToAnotherAccount ? 1 : 2))
                          }
                        }}
                        component={motion.div}
                        variant="contained"
                        initial={{
                          x: -300,
                          opacity: 0,
                        }}
                        animate={{
                          x: 0,
                          opacity: 1,
                        }}
                        sx={{ px: 2, pb: 1, mr: 1 }}
                      >
                        {'Paso anterior'}
                      </LoadingButton>
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Divider />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column-reverse', lg: 'row' },
                      gap: { xs: 3, md: 3 },
                      alignItems: { xs: 'center', lg: 'flex-start' },
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <PaymentMethod
                        paymentMethod={method}
                        onChange={(method) => {
                          setMethod(method)
                        }}
                        disabled={loadingCreate}
                      />

                      {method === 'card' && !stateCallback && (
                        <Box
                          sx={{
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Dataweb />
                        </Box>
                      )}

                      {method === 'transfer' && (
                        <Box
                          sx={{
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Transfer
                            transactions={transactions}
                            getTransactionsByProcessId={getTransactionsByProcessId}
                          />
                        </Box>
                      )}
                    </Box>

                    <Box
                      sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <PaymentSummary />
                    </Box>
                  </Box>
                </React.Fragment>
              )}
              {isRejected && (
                <TransactionAlert
                  transactionAdministratorComment={transactionAdministratorComment}
                  isDbMethod={isDbPaymentMethod}
                />
              )}
            </>
          )}
        </>
      )}

      <DialogValidateUserId
        onCloseDialog={handleCloseDialogValidateUserId}
        onClickCancel={handleCloseDialogValidateUserId}
        handleChangeIdentificationToRuc={(identification, newRuc, companyName) =>
          handleChangeIdentificationToRuc(identification, newRuc, companyName)
        }
      />
    </Box>
  )
}
