import { Grid, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { CompanyForm, NavBar, ResponsiveLoading } from 'components'
import { useAuth, useVerifyCompany } from 'hooks'
import React from 'react'
import { Outlet } from 'react-router-dom'

export const MarketplaceLayout = () => {
  const { user } = useAuth()
  const { initializing, unregisteredCompany } = useVerifyCompany(user.identification)

  return (
    <Grid display={'flex'} flexGrow={1} flexDirection={'column'}  >
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        flexGrow={1}
        position={'sticky'}
        top={0}
        zIndex={2}
      >
        <NavBar unregisteredCompany={unregisteredCompany} />
      </Stack>
      {initializing ? (
        <ResponsiveLoading />
      ) : (
        <React.Fragment>
          {unregisteredCompany ? (
            <Stack justifyContent={'center'} alignItems={'center'} flexGrow={1}>
              <CompanyForm />
            </Stack>
          ) : (
            <Box overflow="auto" flexGrow={1} height={'100%'} marginBottom={4}>
              <Outlet />
            </Box>
          )}
        </React.Fragment>
      )}
    </Grid>
  )
}

export { MarketplaceLayout as default} from './MarketplaceLayout'