import { isAfter } from 'date-fns'
import { useAuth, useFeedback, useInvoiceContract, useLocalStorage, useNavigate } from 'hooks'
import {
  addProcessProductService,
  addProcessProductSpecialProductService,
  cleanCartByProcessIdService,
  COMPLETED_TRANSACTION_ID,
  constLocalStorage,
  createProcessesService,
  getProcessesByCompanyIdService,
  getProductEnableService,
  getProductsByProcessIdService,
  getProductsValidateRucService,
  removeProcessProductService,
  ROUTES,
} from 'lib'
import {
  ICartProduct,
  IInvoiceDetails,
  IProcess,
  IProduct,
  ISpecialProduct,
} from 'marketplace-common'
import React from 'react'
import ReactPixel from 'react-facebook-pixel'

interface Props {
  cartProducts: ICartProduct[]
  addProductToCart: (request: {
    productId?: number
    processId: number
  }) => Promise<boolean | undefined>
  addProductToCartNotLoged: (product: IProduct) => void
  addSpecialProductToCartNotLoged: (specialProduct: ISpecialProduct) => void
  cleanFromCart: (processId: number) => void
  cleanFromCartNotLogged: () => void
  removeFromCart: (
    processProductId: number,
    productId: number
  ) => Promise<ICartProduct[] | undefined>
  removeListFromCart: (
    processProductIdList: number[],
    productId: number,
    hasValidity: boolean
  ) => ICartProduct[] | undefined
  removeFromCartNoLogged: (
    processProductId: number,
    productId: number
  ) => ICartProduct[] | undefined
  removeListFromCartNoLogged: (processProductIdList: number[]) => ICartProduct[] | undefined
  removeSpecialProducFromCartNoLogged: (
    processProductId: number,
    specialProductId: number
  ) => ICartProduct[] | undefined
  getProductsByProcessId: (processId: number) => Promise<ICartProduct[]>
  groupProductsByProductId: (productId: number) => ICartProduct[]
  groupSpecialProductsBySpecialProductId: (specialProductId: number) => ICartProduct[]
  nonEmptyCart: boolean
  isProductOnTheCart: (productId: number) => boolean
  cleanCart: () => void
  fillCart: (cartProducts: ICartProduct[]) => void
  refreshCart: (processId: number) => Promise<void>
  addSpecialProductToCart: (request: {
    specialProductId?: number
    processId: number
  }) => Promise<boolean | undefined>
  productsIds: number[] | undefined
  specialProductsId: number[] | undefined
  productsIdsFromLocalStorage: number[] | undefined
  specialProductsIdFromLocalStorage: number[] | undefined
  loadingShoppingCart: LoadingCart
  handleGetProcessProductId: (productId: number) => number | undefined
  handleGetProcessProductSpecialProductId: (specialProductId: number) => number | undefined
  removeSpecialProductFromCart: (
    processProductId: number,
    specialProductId: number
  ) => Promise<ICartProduct[] | undefined>
  specialProductExpired: ISpecialProduct | null
  process: IProcess | undefined
  handleChangeProcess: (process: IProcess | undefined) => void
  handleChangeApplyCodeFlag: (state: boolean) => void
  applyCodeFlag: boolean
  handleRenewProduct: (productId: number) => Promise<void>
  getProcessByCompanyIdentification: () => Promise<void>
  allProductEnabled: IProduct[]
  productsNotAvailable: IProduct[]
  specialProductsNotAvailable: ISpecialProduct[]
  validateProductsByUserId: (processId: number, userIdType: string) => Promise<boolean>
  invoiceDetails: IInvoiceDetails
  handleInvoiceDetails: (
    productsId?: number[],
    specialProductsId?: number[],
    promotionalCodeId?: number
  ) => Promise<void>
  loadingInvoice: boolean
}

type LoadingCart = {
  productId: number[]
  specialProductId: number[]
}

export const CartContext = React.createContext({} as Props)

const CartProvider: React.FC = ({ children }) => {
  const { isAuthenticated, user } = useAuth()
  const { showMessage } = useFeedback()
  const { animatedNavigation } = useNavigate()
  const { storeItem, getItem, deleteItem } = useLocalStorage()
  const { invoiceDetails, handleInvoiceDetails, loadingInvoice } = useInvoiceContract()

  const [process, setProcess] = React.useState<IProcess>()
  const [applyCodeFlag, setApplyCodeFlag] = React.useState<boolean>(false)
  const [cartProducts, setCartProducts] = React.useState<ICartProduct[]>([] as ICartProduct[])
  const [loadingShoppingCart, setLoadingShoppingCart] = React.useState<LoadingCart>({
    productId: [],
    specialProductId: [],
  })

  const [allProductEnabled, setAllProductEnabled] = React.useState<IProduct[]>([])
  const [productsNotAvailable, setProductsNotAvailable] = React.useState<IProduct[]>([])
  const [specialProductsNotAvailable, setSpecialProductsNotAvailable] = React.useState<
    ISpecialProduct[]
  >([])

  const loadProductEnabled = async () => {
    const productInformation = await getProductEnableService()
    setAllProductEnabled(productInformation)
  }

  React.useEffect(() => {
    loadProductEnabled()
  }, [])

  /***
   * @description Get the Process enabled for the user and obtain the first processId to use it in the cart   *
   * @return {Promise<void>} when resolve the promise the processId is set in the state
   */
  const getProcessByCompanyIdentification = async () => {
    try {
      const response = (await getProcessesByCompanyIdService(user.identification)) as IProcess[]
      const responseFilter = response.filter((process) => process.complete !== true)
      if (responseFilter.length > 0) {
        setProcess(responseFilter[0])
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  /***
   * @description get the Process_products this entity represent the products assigned to the process
   * if the response have a error the function return an empty array
   * @param {number} processId the processId to get the products
   * @return {Promise<ICartProduct[]>} when resolve the promise return the products assigned to the process was sent in the param
   */
  const getProductsByProcessId = async (processId: number) => {
    try {
      const response = await getProductsByProcessIdService(processId)
      return response as ICartProduct[]
    } catch (error) {
      console.log('error', error)
      return [] as ICartProduct[]
    }
  }

  /***
   * @description add a product in ProcessProduct table the product is assigned to the process
   * @param {{productId:number,processId:number}} request the productId to add in the process
   * @return {Promise<void>} when resolve the promise the product is added to the CartProduct
   */
  const addProductToCart = async (request: { productId?: number; processId: number }) => {
    setLoadingShoppingCart((prev) => ({
      ...prev,
      productId: [...prev.productId, request.productId!],
    }))
    let isProductAdded = false
    try {
      const processProduct: ICartProduct = await addProcessProductService(request)
      const existsProcessProduct: boolean = Boolean(processProduct)
      if (!existsProcessProduct) return

      ReactPixel.track('addProductToCart', {
        name: processProduct.product?.name,
        productId: processProduct.product?.productId,
      })
      let productsId: number[] = []
      let specialProductsId: number[] = []
      setCartProducts((prevCart) => {
        const newValue = [...(prevCart ?? []), processProduct]
        productsId = getProductsIds(newValue)
        specialProductsId = getSpecialProductsId(newValue)
        return newValue
      })
      handleInvoiceDetails(productsId, specialProductsId)
      isProductAdded = true
    } catch (error) {
      console.log('error', error)
      showMessage(
        'Ups!, parece que el producto que tratas de agregar no esta disponible para la compra',
        'error'
      )
    } finally {
      setLoadingShoppingCart((prev) => ({
        ...prev,
        productId: prev.productId.filter((productId) => productId !== request.productId),
      }))
    }
    return isProductAdded
  }

  const addSpecialProductToCart = async (request: {
    specialProductId?: number
    processId: number
  }) => {
    setLoadingShoppingCart((prev) => ({
      ...prev,
      specialProductId: [...prev.specialProductId, request.specialProductId!],
    }))
    let isPromoAdded = false
    try {
      const processProduct: ICartProduct = await addProcessProductSpecialProductService(request)
      const existsProcessProduct: boolean = Boolean(processProduct)
      if (!existsProcessProduct) return
      ReactPixel.track('addPromoToCart', {
        name: processProduct.specialProduct?.name,
        productId: processProduct.specialProduct?.specialProductId,
      })
      let productsId: number[] = []
      let specialProductsId: number[] = []
      setCartProducts((prevCart) => {
        const newValue = [...(prevCart ?? []), processProduct]
        productsId = getProductsIds(newValue)
        specialProductsId = getSpecialProductsId(newValue)
        return newValue
      })
      handleInvoiceDetails(productsId, specialProductsId)
      isPromoAdded = true
    } catch (error) {
      console.log('error', error)
      showMessage(
        'Ups!, parece que el producto que tratas de agregar no esta disponible para la compra',
        'error'
      )
    } finally {
      setLoadingShoppingCart((prev) => ({
        ...prev,
        specialProductId: prev.specialProductId.filter(
          (specialProductId) => specialProductId !== request.specialProductId
        ),
      }))
    }
    return isPromoAdded
  }

  /***
   * @description generate a unique id for the processProductId
   */
  const usedIDs = new Set()
  const generateUniqueID = () => {
    let newID
    do {
      newID = Math.floor(Math.random() * 150) + 1
    } while (usedIDs.has(newID))
    usedIDs.add(newID)
    return newID
  }

  /***
   * @description add a product to cart where user is no logged
   * @param {{ processId: number,processProductId:number, product: IProduct}}
   * @return {<void>} added to the CartProduct
   */
  const addProductToCartNotLoged = (product: IProduct) => {
    try {
      const structure = { processId: 0, processProductId: generateUniqueID() }
      const register: ICartProduct = { ...structure, product }
      ReactPixel.track('addProductToCartNotLoged', {
        name: product.name,
      })
      const productsId = getProductsIds([...(cartProducts ?? []), register])
      const specialProductsId = getSpecialProductsId([...(cartProducts ?? []), register])

      handleInvoiceDetails(productsId, specialProductsId)

      setCartProducts((prevCart) => {
        const newCartProducts = [...(prevCart ?? []), register]
        const cardOnlyProducts = newCartProducts.filter((product) =>
          product.hasOwnProperty('product')
        )
        storeItem(constLocalStorage.CART_PRODUCTS, cardOnlyProducts)
        return newCartProducts
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  /***
   * @description add a specialProduct to cart where user is no logged
   * @param {{ specialProduct: IspecialProduct: ISpecialProduct}}
   * @return {<void>} added to the CartProduct
   */

  const addSpecialProductToCartNotLoged = (specialProduct: ISpecialProduct) => {
    try {
      const structure = { processId: 0, processProductId: generateUniqueID() }
      const register: ICartProduct = { ...structure, specialProduct }
      ReactPixel.track('addPromoToCartNotLoged', {
        name: specialProduct.name,
      })
      const productsId = getProductsIds([...(cartProducts ?? []), register])
      const specialProductsId = getSpecialProductsId([...(cartProducts ?? []), register])

      handleInvoiceDetails(productsId, specialProductsId)

      setCartProducts((prevCart) => {
        const newCartSpecialProducts = [...(prevCart ?? []), register]
        const cardOnlySpecialProducts = newCartSpecialProducts.filter((product) =>
          product.hasOwnProperty('specialProduct')
        )
        storeItem(constLocalStorage.CART_SPECIAL_PRODUCTS, cardOnlySpecialProducts)
        return newCartSpecialProducts
      })
    } catch (error) {
      console.log('error', error)
    }
  }
  /***
   *@description remove a product in ProcessProduct table , the product is removed from the process
   * @param {number} processProductId the cartProductId to remove in the process is a ProcessProductId is the id of the register,
   * @param {number} productId the productId
   * @return {Promise<void>} when resolve the promise the product is removed from the CartProduct
   */
  const removeFromCart = async (processProductId: number, productId: number) => {
    setLoadingShoppingCart((prev) => ({
      ...prev,
      productId: [...prev.productId, productId!],
    }))

    try {
      await removeProcessProductService(processProductId)
      const handleSetCart = (prevCart: ICartProduct[]) => {
        const newCartProducts = prevCart.filter(
          (productCart: ICartProduct) => productCart.processProductId !== processProductId
        )
        return newCartProducts
      }
      const cartProductsUpdate = handleSetCart(cartProducts)

      const productsId = getProductsIds(cartProductsUpdate)
      const specialProductsId = getSpecialProductsId(cartProductsUpdate)

      handleInvoiceDetails(productsId, specialProductsId)

      setCartProducts(cartProductsUpdate)
      showMessage('Item eliminado del carrito', 'success')
      return cartProductsUpdate
    } catch (error) {
      console.log('error', error)
      showMessage('Ocurrió un error comunícate con soporte', 'error')
    } finally {
      setLoadingShoppingCart((prev) => ({
        ...prev,
        productId: prev.productId.filter((currentProductId) => currentProductId !== productId),
      }))
    }
  }

  /***
   *@description remove a product in P  rocessProduct table , the product is removed from the process
   * @param {number} processProductId the cartProductId to remove in the process is a ProcessProductId is the id of the register,
   * @param {number} productId the productId
   * @return {Promise<void>} when resolve the promise the product is removed from the CartProduct
   */
  const removeListFromCart = (
    processProductIdList: number[],
    productId: number,
    hasValidity: boolean
  ) => {
    setLoadingShoppingCart((prev) => ({
      ...prev,
      productId: [...prev.productId, productId!],
    }))

    try {
      processProductIdList.map(async (processProductId) => {
        await removeProcessProductService(processProductId)
      })
      const cartProductsUpdate = cartProducts.filter(
        (productCart: ICartProduct) => !processProductIdList.includes(productCart.processProductId)
      )
      setCartProducts(cartProductsUpdate)
      if (!hasValidity) {
        showMessage('Item eliminado del carrito', 'success')
      }

      const productsId = getProductsIds(cartProductsUpdate)
      const specialProductsId = getSpecialProductsId(cartProductsUpdate)

      handleInvoiceDetails(productsId, specialProductsId)

      return cartProductsUpdate
    } catch (error) {
      console.log('error', error)
      showMessage('Ocurrió un error comunícate con soporte', 'error')
    } finally {
      setLoadingShoppingCart((prev) => ({
        ...prev,
        productId: prev.productId.filter((product) => product !== productId),
      }))
    }
  }

  const removeSpecialProductFromCart = async (
    processProductId: number,
    specialProductId: number
  ) => {
    setLoadingShoppingCart((prev) => ({
      ...prev,
      specialProductId: [...prev.specialProductId, specialProductId],
    }))
    try {
      await removeProcessProductService(processProductId)
      const handleSetCart = (prevCart: ICartProduct[]) => {
        const newCartProducts = prevCart?.filter(
          (productCart) => productCart.processProductId !== processProductId
        )
        return newCartProducts
      }
      const cartProductsUpdate = handleSetCart(cartProducts)
      setCartProducts(cartProductsUpdate)
      showMessage('Item eliminado del carrito', 'success')
      return cartProductsUpdate
    } catch (error) {
      console.log('error', error)
      showMessage('Ocurrió un error comunícate con soporte', 'error')
    } finally {
      setLoadingShoppingCart((prev) => ({
        ...prev,
        specialProductId: prev.specialProductId.filter((id) => id !== specialProductId),
      }))
    }
  }

  /***
   *@description remove a product from cart where user is no logged
   * @param {number} processProductId the processProductId to remove the product from the process and the cart
   * @param {number} productId the productId
   */
  const removeFromCartNoLogged = (processProductId: number, productId: number) => {
    try {
      const handleSetCart = (prevCart: ICartProduct[]) => {
        const newCartProducts = prevCart?.filter(
          (productCart) => productCart.processProductId !== processProductId
        )
        const cardOnlyProducts = newCartProducts.filter((product) =>
          product.hasOwnProperty('product')
        )
        storeItem(constLocalStorage.CART_PRODUCTS, cardOnlyProducts)
        return newCartProducts
      }
      const cartProductsUpdate = handleSetCart(cartProducts)
      setCartProducts(cartProductsUpdate)

      const productsId: number[] = cartProductsUpdate
        .filter((item) => Boolean(item.product?.productId))
        .map(({ product }) => product?.productId) as number[]
      const specialProductsId: number[] = cartProductsUpdate
        .filter((item) => Boolean(item.specialProduct?.specialProductId))
        .map(({ specialProduct }) => specialProduct?.specialProductId) as number[]

      handleInvoiceDetails(productsId, specialProductsId)
      return cartProductsUpdate
    } catch (error) {
      console.log('error', error)
    }
  }

  /***
   *@description remove a product from cart where user is no logged
   * @param {number} processProductId the processProductId to remove the product from the process and the cart
   * @param {number} productId the productId
   */
  const removeListFromCartNoLogged = (processProductIdList: number[]) => {
    try {
      const handleSetCart = (prevCart: ICartProduct[]) => {
        const newCartProducts = prevCart?.filter(
          (productCart) => !processProductIdList.includes(productCart.processProductId)
        )
        const cardOnlyProducts = newCartProducts.filter((product) =>
          product.hasOwnProperty('product')
        )
        storeItem(constLocalStorage.CART_PRODUCTS, cardOnlyProducts)
        return newCartProducts
      }
      const cartProductsUpdate = handleSetCart(cartProducts)
      setCartProducts(cartProductsUpdate)
      return cartProductsUpdate
    } catch (error) {
      console.log('error', error)
    }
  }

  /***
   *@description remove a specialProduct from cart where user is no logged
   * @param {number} processProductId the processProductId to remove the product from the process and the cart
   * @param {number} specialProductId the specialProductId
   */
  const removeSpecialProducFromCartNoLogged = (
    processProductId: number,
    specialProductId: number
  ) => {
    try {
      const handleSetCart = (prevCart: ICartProduct[]) => {
        const newCartSpecialProducts = prevCart?.filter(
          (productCart) => productCart.processProductId !== processProductId
        )
        const cardOnlySpecialProducts = newCartSpecialProducts.filter((product) =>
          product.hasOwnProperty('specialProduct')
        )
        storeItem(constLocalStorage.CART_SPECIAL_PRODUCTS, cardOnlySpecialProducts)
        return newCartSpecialProducts
      }

      const cartProductsUpdate = handleSetCart(cartProducts)
      setCartProducts(cartProductsUpdate)

      const productsId: number[] = cartProductsUpdate
        .filter((item) => Boolean(item.product?.productId))
        .map(({ product }) => product?.productId) as number[]
      const specialProductsId: number[] = cartProductsUpdate
        .filter((item) => Boolean(item.specialProduct?.specialProductId))
        .map(({ specialProduct }) => specialProduct?.specialProductId) as number[]

      handleInvoiceDetails(productsId, specialProductsId)

      return cartProductsUpdate
    } catch (error) {
      console.log('error', error)
    }
  }

  /***
   * @description verify if the cartProducts is not empty
   * @return {boolean} return true if the cartProducts is not empty
   */
  const nonEmptyCart = React.useMemo(
    () => Boolean(cartProducts && cartProducts.length > 0),
    [cartProducts]
  )

  /***
   *@description verify if the product is on the cart
   * @param {number} productId the productId to verify if is on the cart
   * @return {boolean} return true if the product is on the cart
   */
  const isProductOnTheCart = React.useCallback(
    (productId: number) => {
      const existsProduct =
        cartProducts
          ?.filter((product) => product.product !== null && product.product !== undefined)
          .some(({ product }) => product!.productId === productId) ?? false
      return existsProduct
    },
    [cartProducts]
  )

  /***
   * @description group the products by productId from cartProducts and return the products with the same productId
   * @param{number} productId the productId to group the products
   * @return {ICartProduct[]} return the products with the same productId
   */
  const groupProductsByProductId = React.useCallback(
    (productId: number) => {
      const productsFound = cartProducts?.filter(
        ({ product }) => product != null && product.productId === productId
      )
      return productsFound as ICartProduct[]
    },
    [cartProducts]
  )

  /***
   * @description group the products by productId from cartProducts and return the products with the same productId
   * @param{number} productId the productId to group the products
   * @return {ICartProduct[]} return the products with the same productId
   */
  const groupSpecialProductsBySpecialProductId = React.useCallback(
    (specialProductId: number) => {
      const specialProductsFound = cartProducts?.filter(
        ({ specialProduct }) =>
          specialProduct != null && specialProduct.specialProductId === specialProductId
      )
      return specialProductsFound as ICartProduct[]
    },
    [cartProducts]
  )
  const cleanFromCartLogged = async (processId: number) => {
    try {
      await cleanCartByProcessIdService({ processId })
      cleanCart()
      handleInvoiceDetails()
      showMessage('Carrito Vacío', 'success')
    } catch (error) {
      console.log('error', error)
      showMessage('Ocurrió un error comunícate con soporte', 'error')
    }
  }

  const cleanFromCartNotLogged = () => {
    try {
      const keysToDelete = [
        constLocalStorage.CART_PRODUCTS,
        constLocalStorage.CART_SPECIAL_PRODUCTS,
        constLocalStorage.PROMOTIONAL_CODE,
      ]
      keysToDelete.forEach((item) => deleteItem(item))
      cleanCart()
      handleInvoiceDetails()
      showMessage('Carrito Vacío', 'success')
    } catch (error) {
      console.log('error', error)
      showMessage('Ocurrió un error, comunícate con soporte', 'error')
    }
  }

  /***
   * @description clean the cartProducts state
   */
  const cleanCart = () => {
    setCartProducts([] as ICartProduct[])
  }

  const fillCart = (cartProducts: ICartProduct[]) => {
    setCartProducts(cartProducts)
  }

  const refreshCart = async (processId: number) => {
    const response = await getProductsByProcessId(processId)
    setCartProducts(response)
  }

  const handleChangeProcess = (process: IProcess | undefined) => {
    setProcess(process)
  }

  const getProductsIds = (cartProducts: ICartProduct[]) => {
    const productsId = cartProducts
      .filter((cartProduct) => Boolean(cartProduct.product))
      .map((cartProduct) => cartProduct.product!.productId)
    return productsId
  }

  const getSpecialProductsId = (cartProducts: ICartProduct[]) => {
    const specialProductsId = cartProducts
      .filter((cartProduct) => Boolean(cartProduct.specialProduct))
      .map((cartProduct) => cartProduct.specialProduct!.specialProductId)
    return specialProductsId
  }

  const productsIds = React.useMemo(() => {
    if (!Boolean(cartProducts)) {
      return undefined
    }
    const productsId = cartProducts!
      .filter((cartProduct) => Boolean(cartProduct.product))
      .map((cartProduct) => cartProduct.product!.productId)
    return productsId
  }, [cartProducts])

  const specialProductsId = React.useMemo(() => {
    if (!Boolean(cartProducts)) {
      return undefined
    }
    const specialProductsId = cartProducts!
      .filter((cartProduct) => Boolean(cartProduct.specialProduct))
      .map((cartProduct) => cartProduct.specialProduct!.specialProductId)
    return specialProductsId
  }, [cartProducts])

  const productsIdsFromLocalStorage = () => {
    const products = getItem(constLocalStorage.CART_PRODUCTS)
    if (!Boolean(products)) {
      return undefined
    }
    const productsIds: number[] = products
      .filter(({ product }: { product: IProduct }) => product != null)
      .map(({ product }: { product: IProduct }) => product.productId)
    return productsIds
  }

  const handleGetSpecialProductExpired = () => {
    const currentDate = new Date()
    for (const product of cartProducts) {
      if (product.specialProduct) {
        const expirationDate = new Date(product.specialProduct.endValidityDate)
        const isExpired = isAfter(new Date(expirationDate), currentDate)
        if (!isExpired) {
          return product.specialProduct
        }
      }
    }
    return null
  }

  const specialProductsIdFromLocalStorage = () => {
    const specialProducts = getItem(constLocalStorage.CART_SPECIAL_PRODUCTS)
    if (!Boolean(specialProducts)) {
      return undefined
    }
    const specialProductsIds: number[] = specialProducts.map(
      ({ specialProduct }: { specialProduct: ISpecialProduct }) => specialProduct.specialProductId
    )
    return specialProductsIds
  }

  const handleGetProcessProductId = (productId: number) => {
    const cartProduct = cartProducts?.find(
      (cartProduct) => cartProduct.product !== null && cartProduct.product?.productId === productId
    )
    return cartProduct?.processProductId
  }

  const handleGetProcessProductSpecialProductId = (specialProductId: number) => {
    const cartProduct = cartProducts?.find(
      (cartProduct) =>
        cartProduct.specialProduct !== null &&
        cartProduct.specialProduct?.specialProductId === specialProductId
    )
    return cartProduct?.processProductId
  }

  const handleChangeApplyCodeFlag = (state: boolean) => {
    setApplyCodeFlag(state)
  }

  const handleRenewProduct = async (productId: number) => {
    const processes: IProcess[] = await getProcessesByCompanyIdService(user.identification)
    let process = processes.find((process) => process.enabled && !process.complete)

    if (!Boolean(process)) {
      const processRequest = {
        information: '{}',
        companyId: user.identification,
        securityUserId: user.userId,
      }
      process = await createProcessesService(processRequest)
    }

    if (!Boolean(process)) {
      return
    }

    const isCompleteProcess =
      process!.history[process!.history.length - 1]?.state.transactionStateId ===
      COMPLETED_TRANSACTION_ID
    if (isCompleteProcess) {
      showMessage(
        'Tienes un proceso por finalizar, antes de renovar el producto finaliza tu proceso actual',
        'warning'
      )
      return
    }

    const request = {
      productId,
      processId: process!.processId,
    }
    addProductToCart(request)

    const route = `${ROUTES.processes}/${'nuna'}`
    animatedNavigation(route)
  }

  React.useEffect(() => {
    if (!isAuthenticated) return
    getProcessByCompanyIdentification()
  }, [isAuthenticated])

  React.useEffect(() => {
    const processId = process?.processId
    const existsProcessId = Boolean(processId)
    if (!existsProcessId) return
    getProductsByProcessId(processId!).then((response) => {
      setCartProducts(response)
    })
  }, [process])

  React.useEffect(() => {
    if (!isAuthenticated) {
      const cartProducts = getItem(constLocalStorage.CART_PRODUCTS)
      const cartSpecialProducts = getItem(constLocalStorage.CART_SPECIAL_PRODUCTS)
      const combinedCartProducts: ICartProduct[] = []
      if (cartProducts) {
        combinedCartProducts.push(...cartProducts)
      }
      if (cartSpecialProducts) {
        combinedCartProducts.push(...cartSpecialProducts)
      }

      setCartProducts(combinedCartProducts)
    }
  }, [getItem, isAuthenticated])

  const validateProductsByUserId = async (processId: number, userIdType: string) => {
    const productsNotAvailable = await getProductsValidateRucService(processId, userIdType)

    const existsProductsNotAvailable =
      Boolean(productsNotAvailable) && productsNotAvailable.length !== 0
    if (existsProductsNotAvailable) {
      const products = productsNotAvailable
        .map((item) => {
          const existsProduct = Boolean(item.product)
          if (existsProduct) {
            return item.product
          }
        })
        .filter((product) => Boolean(product)) as IProduct[]
      setProductsNotAvailable(products)

      const specialProducts = productsNotAvailable
        .map((item) => {
          const existsSpecialProduct = Boolean(item.specialProduct)
          if (existsSpecialProduct) {
            return item.specialProduct
          }
        })
        .filter((product) => Boolean(product)) as ISpecialProduct[]
      setSpecialProductsNotAvailable(specialProducts)
    }
    return existsProductsNotAvailable
  }

  return (
    <CartContext.Provider
      value={{
        cartProducts,
        addProductToCart,
        addProductToCartNotLoged,
        cleanFromCart: cleanFromCartLogged,
        cleanFromCartNotLogged,
        removeFromCart,
        removeFromCartNoLogged,
        removeListFromCartNoLogged,
        removeListFromCart,
        isProductOnTheCart,
        groupProductsByProductId,
        getProductsByProcessId,
        nonEmptyCart,
        cleanCart,
        fillCart,
        refreshCart,
        addSpecialProductToCart,
        productsIds,
        specialProductsId,
        loadingShoppingCart,
        handleGetProcessProductId,
        handleGetProcessProductSpecialProductId,
        removeSpecialProductFromCart,
        addSpecialProductToCartNotLoged,
        removeSpecialProducFromCartNoLogged,
        groupSpecialProductsBySpecialProductId,
        productsIdsFromLocalStorage: productsIdsFromLocalStorage(),
        specialProductsIdFromLocalStorage: specialProductsIdFromLocalStorage(),
        specialProductExpired: handleGetSpecialProductExpired(),
        process,
        handleChangeProcess,
        handleChangeApplyCodeFlag,
        applyCodeFlag,
        handleRenewProduct,
        getProcessByCompanyIdentification,
        allProductEnabled,
        productsNotAvailable,
        specialProductsNotAvailable,
        validateProductsByUserId,
        invoiceDetails,
        handleInvoiceDetails,
        loadingInvoice,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartProvider
