import { Grid } from '@mui/material'
import { StepForms } from 'components'
import { useProcess, useSolutionTheme } from 'hooks'
import { ISolutionReference } from 'marketplace-common'
import React from 'react'

/**
 *
 * @returns Steps page
 */

export const Steps = () => {
  const { currentStep} = useProcess()
  const { changeTheme } = useSolutionTheme()

  /**
   * @see /styles/themes/ for more info
   *
   * Change the main theme for a solution theme, check the styles/themes folder for more information
   * */

  // React.useEffect(() => {
  //   if (isPromoReference) {
  //     changeTheme(REFERENCE_SPECIAL_PRODUCTS as SolutionReference)
  //   } else if (solution?.reference) {
  //     changeTheme(solution.reference)
  //   }
  // }, [solution, changeTheme, isPromoReference])

  React.useEffect(() => {
    changeTheme('main' as ISolutionReference)
  }, [changeTheme])

  return (
    <React.Fragment>
      {currentStep && (
        <Grid xs={12} flexGrow={1} height={'100%'}>
          <StepForms reference={currentStep.reference} />
        </Grid>
      )}
    </React.Fragment>
  )
}

export {Steps as default} from './Steps'