import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import emailSent from 'assets/animations/email-sent.json'
import { AnimatedLink } from 'components'
import { useFeedback } from 'hooks'
import { ROUTES, resentEmailVerificationService } from 'lib'
import Lottie from 'react-lottie'
import { useLocation } from 'react-router-dom'
import { Options } from 'types'
import { decryptParam } from 'utils'

type Props = {
  emailParam?: string
  handleChangeOption?: (option: Options) => void
  fromModal?: boolean
}
export const VerificationSent = (props: Props) => {
  const { emailParam, handleChangeOption, fromModal } = props
  const location = useLocation()

  const emailEncrypt = new URLSearchParams(location.search).get('email')
  const email = emailEncrypt !== null ? decryptParam(emailEncrypt) : ''

  const { showMessage } = useFeedback()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <Paper
        sx={{
          py: { xs: 3, sm: 5 },
          px: { xs: 2, sm: 10 },
          width: { xs: '16rem', sm: '22rem' },
        }}
        elevation={fromModal ? 0 : 16}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography fontWeight={600} variant="h5">
            {'Listo!'}
          </Typography>
          <Typography
            fontWeight={500}
            color="textSecondary"
            variant="body1"
            sx={{ maxWidth: 370 }}
            align="center"
          >
            {`Por cuestiones de seguridad hemos enviado un correo a ${
              emailParam !== undefined ? emailParam : email
            } con un link para activar tu cuenta`}
          </Typography>
          <Box>
            <Lottie
              options={{
                animationData: emailSent,
                loop: true,
              }}
              width={150}
              height={150}
            />
          </Box>
          <Typography
            fontWeight={500}
            fontSize={12}
            variant="caption"
            sx={{ maxWidth: 500 }}
            align="center"
            color="textSecondary"
          >
            {`¿No recibiste ningún correo?`}
          </Typography>
          <Stack spacing={1}>
            <Typography
              fontWeight={600}
              variant="caption"
              sx={{ maxWidth: 300 }}
              align="center"
              color="textSecondary"
            >
              {`Revisa tu bandeja de spam`}
            </Typography>
            <Typography
              fontWeight={500}
              variant="caption"
              sx={{ maxWidth: 300 }}
              align="center"
              color="textSecondary"
            >
              {`ó`}
            </Typography>
            <Button
              sx={{ width: 200 }}
              onClick={(e) => {
                e.preventDefault()
                const resend = async () => {
                  try {
                    await resentEmailVerificationService(email ?? '')
                    showMessage('Correo reenviado', 'success', {
                      horizontal: 'left',
                      vertical: 'top',
                    })
                  } catch (e) {
                    showMessage('Error al reenviar correo', 'error', {
                      horizontal: 'left',
                      vertical: 'top',
                    })
                  }
                }
                resend()
              }}
            >
              {'Volver a enviar correo'}
            </Button>

            {fromModal !== undefined && fromModal ? (
              <Button
                sx={{ width: 200 }}
                onClick={() => {
                  if (fromModal && handleChangeOption !== undefined) {
                    handleChangeOption(ROUTES.login as Options)
                  }
                }}
              >
                {'Iniciar Sesión'}
              </Button>
            ) : (
              <AnimatedLink
                to={`${ROUTES.login}`}
                type="circle"
                style={{ width: '100%' }}
                fullWidth
              >
                <Button sx={{ width: 200 }}>{'Iniciar Sesión'}</Button>
              </AnimatedLink>
            )}

            <AnimatedLink
              to={`${ROUTES.user}/${ROUTES.marketplace}/${ROUTES.solutions}`}
              type="circle"
              style={{ width: '100%' }}
            >
              <Button sx={{ width: '100%' }}>{'Ver Productos'}</Button>
            </AnimatedLink>
          </Stack>
        </Box>
      </Paper>
    </Box>
  )
}

export { VerificationSent as default} from './VerificationSent'