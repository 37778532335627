import { Avatar, Box, Icon, Tooltip, Typography } from '@mui/material'
import { motion } from 'framer-motion'

type Props = {
  icon?: string
  src?: string
  isSelected: boolean
  backgroundColor?: string
  color?: string
  name: string
  handleOnClick: () => void
  disableHover?: boolean
  disabledRotationAnimation?: boolean
  disableSolutionNameTitle?: boolean
}
export const SolutionAvatar = (props: Props) => {
  const {
    icon,
    src,
    isSelected,
    backgroundColor,
    color,
    name,
    handleOnClick,
    disableHover,
    disabledRotationAnimation,
    disableSolutionNameTitle,
  } = props
  return (
    <Tooltip title={name} arrow placement="right">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          onClick={() => {
            handleOnClick()
          }}
          component={motion.div}
          whileHover={{
            scale: disableHover ? 1 : 1.05,
            filter: 'grayscale(0%)',
            cursor: 'pointer',
          }}
          animate={{
            filter: isSelected ? 'grayscale(0%)' : 'grayscale(100%)',
            rotate: disabledRotationAnimation ? 0 : 360,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            duration: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
            scale: {
              type: 'spring',
              damping: 5,
              stiffness: 100,
              restDelta: 0.001,
            },
          }}
          sx={{
            cursor: 'pointer',
            width: { xs: 35, sm: 40, md: 50 },
            height: { xs: 35, sm: 40, md: 50 },
            backgroundColor,
            color,
          }}
          src={src}
        >
          {icon && (
            <Icon sx={{ fontSize: { xs: 25, sm: 30, md: 35 }, color: 'white' }}>{icon}</Icon>
          )}
        </Avatar>
        <Typography
          component={motion.div}
          exit={{
            opacity: 0,
          }}
          transition={{
            duration: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
            scale: {
              type: 'spring',
              damping: 5,
              stiffness: 100,
              restDelta: 0.001,
            },
          }}
          variant="caption"
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
          textAlign="center"
          fontWeight={isSelected ? 700 : 500}
        >
          {!disableSolutionNameTitle && name}
        </Typography>
      </Box>
    </Tooltip>
  )
}
