import React from 'react'

type Hook = {
  toggle: () => void
  value: boolean
  setTrue: () => void
  setFalse: () => void
}

/**
 *
 * @param initialState the intial value of the hook
 * @returns utility functions to play with a boolean variable state and the current value
 */
export const useToggle = (initialState?: boolean): Hook => {
  const [value, setValue] = React.useState(Boolean(initialState))

  const toggle = () => setValue((current) => !current)
  const setTrue = () => setValue(true)
  const setFalse = () => setValue(false)

  return {
    value,
    setFalse,
    setTrue,
    toggle,
  }
}
