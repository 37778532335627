import { Box, Button, Card, Icon, IconButton, Typography } from '@mui/material'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import {
  PAYMENT_METHOD,
  REVERTED_TRANSACTION_ID,
  TRANSACTION_ICONS,
  TRANSACTION_STATES,
  formatCurrency,
  getInvoiceContractByIdService,
} from 'lib'
import { filterType } from 'pages'
import React from 'react'
import { TransactionStateId } from 'types'
import { ReasonRejectDialog } from './ReasonRejectDialog'
import { ITransaction } from 'marketplace-common'

type Props = {
  transaction: ITransaction
  onDetails: (transaction: ITransaction) => void
  handleOpenDialog: (dialog: string) => void
  processIdSelected: (processId: number) => void
  filter: filterType
}

export const TransactionSummary = (props: Props) => {
  const { transaction, onDetails, handleOpenDialog, processIdSelected, filter } = props
  const [openModal, setOpenModal] = React.useState<boolean>(false)
  const [hasAccessKey, setHasAccessKey] = React.useState<boolean>()
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const transferReject = React.useMemo(() => {
    return (
      transaction.state.transactionStateId === TransactionStateId.RECHAZADO &&
      transaction.type === PAYMENT_METHOD.TRANSFER
    )
  }, [transaction.state.transactionStateId, transaction.type])

  React.useEffect(() => {
    const validAccessKey = async () => {
      try {
        const invoice = await getInvoiceContractByIdService(transaction.invoiceContractId)
        setHasAccessKey(Boolean(invoice.accessKey))
      } catch (error) {
        console.error(error)
      }
    }
    validAccessKey()
  }, [transaction])

  return (
    <React.Fragment>
      <Card sx={{ width: '100%', maxWidth: { xs: '250px', sm: '330px' } }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            p: 1.6,
            flexDirection: 'column',
            gap: 1.2,
            alignItems: 'center',
          }}
        >
          <Box
            id="testes"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '24px',
              width: '100%',
            }}
          >
            <Box sx={{ width: '100%', display: 'flex' }}>
              <Box
                pl={1}
                pr={3}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1,
                }}
              >
                <IconButton
                  sx={{
                    bgcolor: (theme) =>
                      transaction.state.transactionStateId === REVERTED_TRANSACTION_ID
                        ? theme.palette.error.main
                        : theme.palette.primary.main,
                    color: '#FFF',
                    '&:hover': {
                      bgcolor: (theme) =>
                        transaction.state.transactionStateId === REVERTED_TRANSACTION_ID
                          ? theme.palette.error.dark
                          : theme.palette.primary.dark,
                      opacity: 0.8,
                    },
                  }}
                >
                  <Icon>{TRANSACTION_ICONS[transaction.state.transactionStateId]}</Icon>
                </IconButton>
              </Box>
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography fontWeight={700} variant="body2">
                  {'Forma de pago: '}
                  <Typography fontWeight={600} variant="caption">
                    {transaction.type === PAYMENT_METHOD.DB
                      ? PAYMENT_METHOD.DB_DESCRIPTION
                      : PAYMENT_METHOD.TRANSFER_DESCRIPTION}
                  </Typography>
                </Typography>

                <Typography fontWeight={700} variant="body2">
                  {'Estado: '}
                  <Typography fontWeight={600} variant="caption">
                    {TRANSACTION_STATES[transaction.state.transactionStateId]}
                  </Typography>
                </Typography>

                <Typography fontWeight={700} variant="body2">
                  {'Valor: '}
                  <Typography fontWeight={600} variant="caption">
                    {transaction.amount !== null && formatCurrency(transaction.amount)}
                  </Typography>
                </Typography>

                <Typography fontWeight={700} variant="body2">
                  {'Fecha: '}
                  <Typography fontWeight={600} variant="caption">
                    {format(
                      new Date(transaction.history[transaction.history.length - 1].paymentDate),
                      "dd 'de' MMMM yyyy",
                      { locale: es }
                    )}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1.2,
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              pl: 1.8,
              pb: 1,
            }}
          >
            <Button
              sx={{ background: 'black', color: 'white', width: '190px' }}
              endIcon={<Icon>description</Icon>}
              onClick={() => {
                onDetails(transaction)
                handleOpenDialog('Detail')
              }}
            >
              {'Ver detalles del pago'}
            </Button>
            <Button
              sx={{ background: 'black', color: 'white', width: '190px' }}
              endIcon={<Icon>category</Icon>}
              onClick={() => {
                processIdSelected(transaction.history[0].processId)
                handleOpenDialog('Licences')
              }}
            >
              {'Productos Adquiridos'}
            </Button>

            {filter !== 'others' && transaction.downloadInvoiceUrl && hasAccessKey && (
              <Button
                sx={{ background: 'black', color: 'white', width: '190px' }}
                endIcon={<Icon>download</Icon>}
                onClick={() => {
                  window.open(transaction.downloadInvoiceUrl, '_blank')
                }}
              >
                {'Descargar Factura'}
              </Button>
            )}
            {filter !== 'others' && transaction.rideUrl && hasAccessKey && (
              <Button
                sx={{ background: 'black', color: 'white', width: '190px' }}
                endIcon={<Icon>receipt</Icon>}
                onClick={() => {
                  window.open(transaction.rideUrl, '_blank')
                }}
              >
                {'Ver Factura'}
              </Button>
            )}
            {transferReject && (
              <Button
                sx={{ background: 'black', color: 'white', width: '190px' }}
                endIcon={<Icon>attach_file</Icon>}
                onClick={handleOpenModal}
              >
                {'Motivo de Rechazo'}
              </Button>
            )}
          </Box>
        </Box>
      </Card>
      <ReasonRejectDialog
        open={openModal}
        adminComment={transaction.transactionAdministratorComment}
        handleClose={handleCloseModal}
      />
    </React.Fragment>
  )
}
