import { buildSearchString, listEntityWithSearchParamsService } from 'lib'
import React from 'react'
import { IPaginationResponse } from 'marketplace-common'

type Hook<Entity, Filters> = {
  pagination: IPaginationResponse<Entity>
  list: Entity[]
  filters: Filters
  loading: boolean
  filterList: (newFilters: Filters) => Promise<void>
  refreshList: () => Promise<void>
}

export const usePagination = <Entity, Filters>(
  path: string,
  initialFilters?: Partial<Filters>,
  isSecurity?: boolean
): Hook<Entity, Filters> => {
  const [list, setList] = React.useState<Entity[]>([])
  const [pagination, setPagination] = React.useState<IPaginationResponse<Entity>>({
    result: [] as Entity[],
    count: 0,
    page: 1,
  })
  const [filters, setFilters] = React.useState<Filters>({ ...initialFilters } as Filters)
  const [loading, setLoading] = React.useState(true)

  const initialSearchParams = buildSearchString(initialFilters)

  const getList = React.useCallback(async () => {
    try {
      setLoading(true)
      const list = (await listEntityWithSearchParamsService(
        path,
        initialSearchParams,
        isSecurity
      )) as IPaginationResponse<Entity>
      setList(list.result)
      setPagination(list)
    } catch (error) {
      console.log(JSON.stringify(error))
    } finally {
      setLoading(false)
    }
  }, [path, initialSearchParams])

  const filterList = async (newFilters: Filters) => {
    try {
      setLoading(true)
      const list = await listEntityWithSearchParamsService(
        path,
        buildSearchString(newFilters),
        isSecurity
      )
      setFilters(newFilters)
      setList(list.result)
      setPagination(list)
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const refreshList = async () => {
    try {
      setLoading(true)
      const list = await listEntityWithSearchParamsService(
        path,
        buildSearchString(filters),
        isSecurity
      )
      setList(list.result)
      setPagination(list)
    } catch (error) {
      console.log(JSON.stringify(error))
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    getList()
  }, [getList])

  return {
    list,
    pagination,
    filters,
    loading,
    filterList,
    refreshList,
  }
}
