import { Avatar, Box, Icon, Paper, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useAuth, useNavigate } from 'hooks'
import { ROUTES } from 'lib'
import React from 'react'

const DASH_BOARD_ROUTE = `${ROUTES.user}/${ROUTES.marketplace}/${ROUTES.solutions}`
const LOGIN_ROUTE = `${ROUTES.login}`
export const CardHomeRedirect = () => {

  const { animatedNavigation } = useNavigate()
  const { isAuthenticated } = useAuth() 
  return (
    <Paper
      sx={{ width: 220, height: 220, cursor: 'pointer',  position: 'relative' }}
      elevation={3}
      onClick={() =>  animatedNavigation(isAuthenticated ? DASH_BOARD_ROUTE : LOGIN_ROUTE, 'circle')}
      component={motion.div}
      whileHover={{ scale: 1.1}}
    >
      <Box sx={{ position: 'absolute', right: 4, top: 4 }}>
        <Icon fontSize="medium" style={{ color: 'white' }}>home_outlined</Icon>
      </Box>
      <Box
        height={'58%'}
        bgcolor={'#000000'}
        sx={{
          borderTopLeftRadius: (theme) => theme.shape.borderRadius,
          borderTopRightRadius: (theme) => theme.shape.borderRadius,
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Avatar
         sx={{
           width: 'calc(202px * 0.375)',
           height: 'calc(202px * 0.375)',
         }}
        >
  <Icon style={{ fontSize: 60 }}>person2_outlined</Icon>
        </Avatar>
         
      </Box>
      <Box
        height={'35%'}
        px={4}
        display="flex"
        flexDirection={'column'}
        justifyContent="center"
        alignItems={'center'}
      >
        {
          <React.Fragment>
            <Typography align="center" sx={{ color: '#000000' }} fontWeight={700}>
              {'Vamos a tu dashboard '}
            </Typography>
            
          </React.Fragment>
        }
      </Box>
    </Paper>
  )
}
