import { AxiosRequestConfig } from 'axios'
import { getItem } from 'lib/utils/localStorage'
import { LOCAL_STORAGE_KEYS } from 'marketplace-common'

export const requestInterceptorSuccessSecurity = (config: AxiosRequestConfig<any>) => {
  const token = getItem(LOCAL_STORAGE_KEYS.TOKEN) ?? ''
  const headers = {
    Authorization: `Bearer ${process.env.REACT_APP_SECURITY_API_KEY}`,
    Token: token,
  }
  config.headers = headers
  return config
}

export const requestInterceptorErrorSecurity = (error: any) => {
  return Promise.reject(error)
}
