import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useGetSolutions } from 'hooks'
import { IRefundRequest } from 'marketplace-common'

type Props = {
  list: IRefundRequest
}

export const RefundHistory = (props: Props) => {
  const { list } = props
  const { specialProductList, productList } = list
  const { getLogo } = useGetSolutions()

  return (
    <Box sx={{ margin: 1 }}>
      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        <Typography variant="h6">Producto Comprados</Typography>
      </Box>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Producto</TableCell>
            <TableCell>Cantidad</TableCell>
            <TableCell align="right">Sub Total ($)</TableCell>
            <TableCell align="right">IVA ($)</TableCell>
            <TableCell align="right">Total($)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.productList &&
            productList.map((product) => (
              <TableRow key={product.product.productId}>
                <TableCell>
                  {getLogo(product.product.mainSolutionId) ? (
                    <img
                      src={getLogo(product.product.mainSolutionId)}
                      alt={`Logo de solución ${product.product.mainSolutionId}`}
                      width="32"
                      height="32"
                      loading="lazy"
                    />
                  ) : (
                    <div>
                      <CircularProgress sx={{ width: '32', height: '32' }} />
                    </div>
                  )}
                </TableCell>
                <TableCell>{product.product.name}</TableCell>
                <TableCell align="justify">{product.quantity}</TableCell>
                <TableCell align="right">
                  {(product.product.transactionPrice * product.quantity).toFixed(2)}
                </TableCell>
                <TableCell align="right">
                  {(product.product.transactionPrice * (product.product.iva / 100)).toFixed(2)}
                </TableCell>
                <TableCell align="right">
                  {(product.product.price * product.quantity).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          {list.specialProductList &&
            specialProductList.map((detail) => (
              <TableRow key={detail.specialProductId}>
                <TableCell>
                  {getLogo(detail.mainSolutionId) ? (
                    <img
                      src={getLogo(detail.mainSolutionId)}
                      alt={`Logo de solución ${detail.mainSolutionId}`}
                      width="32"
                      height="32"
                      loading="lazy"
                    />
                  ) : (
                    <div>
                      <CircularProgress sx={{ width: '32', height: '32' }} />
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  <Box display={'flex'} flexDirection={'column'}>
                    {detail.name}
                    <Typography
                      fontWeight={900}
                      color="textSecondary"
                      variant="caption"
                      align="left"
                      fontSize={'0.75em'}
                    >
                      {detail.specialName}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="justify">{detail.quantity}</TableCell>
                <TableCell align="right">{(detail.price * detail.quantity).toFixed(2)}</TableCell>
                <TableCell align="right">
                  {(detail.price * (detail.iva / 100)).toFixed(2)}
                </TableCell>
                <TableCell align="right">{detail.totalPrice.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          <TableCell align="right" colSpan={5}></TableCell>
          <TableCell align="right">
            Total Reembolso: $ {list.invoiceContract.totalPayment.toFixed(2)}
          </TableCell>
        </TableBody>
      </Table>
    </Box>
  )
}
