import { Backdrop, CircularProgress } from '@mui/material'

/**
 *
 * @returns A backdrop component a top of all other html components
 */

type Props = {
  withOpacity?: boolean
}

export const FullscreenLoading = (props: Props) => {
  const { withOpacity } = props

  return (
    <Backdrop
      sx={{ zIndex: 9999, backgroundColor: !withOpacity ? 'transparent' : 'rgba(0,0,0,0.3)' }}
      open={true}
    >
      <CircularProgress thickness={8} size={60} color="primary" />
    </Backdrop>
  )
}
