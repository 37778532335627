import { IProcessStatusScreenRef } from 'components'

import {
  useAuth,
  useCart,
  useCompanyInformation,
  useFeedback,
  useInvoiceContract,
  useLoading,
  useLocalStorage,
  useNavigate,
  useProcessStep,
  useSpecialProducts,
  useTransaction,
  useValidateCompanyInformation,
} from 'hooks'

import {
  GA_EVENT,
  LOGIN_STATE_BY_MODAL,
  REFERENCE_SPECIAL_PRODUCTS,
  ROUTES,
  completeProcessService,
  constLocalStorage,
  createProcessesService,
  emmitInvoiceService,
  formTypeValues,
  getMainSolutionsByReferenceService,
  getProcessByIdService,
  removeCodeService,
  updateProcessesService,
  validateCodeLoggedService,
  validateCodeNotLoggedService,
} from 'lib'
import {
  ICartProduct,
  ICompanyInformation,
  IProcess,
  IPromocionalCode,
  ISolution,
  ISpecialProduct,
  IUser,
  IValidateCode,
} from 'marketplace-common'
import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useParams, useSearchParams } from 'react-router-dom'
import { IFormControl, IStep, StepReference, StepReferenceName } from 'types'
import { generateItemsFromInvoice, trackGoogleAnalyticsEventWithData } from 'utils'

type Props = {
  steps: IStep[]
  solution?: ISolution
  currentStep: IStep | undefined
  currentIndex: number
  process: IProcess | undefined
  getEnableSteps: StepReference[]
  getStepCurrentByProcess: (process: IProcess) => Promise<void>
  addEnableSteps: (reference: StepReferenceName) => void
  deleteEnableSteps: (reference: StepReferenceName) => void
  onCallback: boolean
  switchNextStep: (shopToAnotherAccount?: boolean | undefined) => void
  finishProcess: () => void
  changeStepIndex: (index: number) => void
  finishCallback: () => void
  completeProcess: () => Promise<void>
  emmitInvoice: () => Promise<void>
  getProcessByProcessId: (processId: number) => Promise<void>
  handleAssignedCompanyToProcess: (identification: string, processParam?: IProcess) => Promise<void>
  handleAssignedCompanyInformationIdToProcess: (
    companyInformationId?: number,
    process?: IProcess,
    invoiceInformationId?: number
  ) => Promise<IProcess>
  initializeConfigureAfterLogin: (user: IUser) => Promise<void>
  loadingInitialize: boolean
  payInProcessOrComplete: (inProcessOrCompleted: boolean) => void
  inProcessOrCompleted: boolean
  isPromoReference: boolean
  specialProducts: ISpecialProduct[]
  haveSpecialProducts: boolean
  loadingSpecialProducts: boolean
  filterSpecialProductsSelect: boolean
  handleChangeFilter: (select: boolean) => void
  getMainSolution: (mainSolutionReference: string) => Promise<void>
  handleChangePromoReference: (promoChange: boolean) => void
  generalLoading: boolean
  hasExpiredSpecialProduct: boolean
  handleValidateCodeLogged: (
    existPromotionalCodeAndProcess: boolean,
    code: string,
    productsIdsParam?: number[],
    specialProductsIdParam?: number[]
  ) => Promise<IValidateCode | undefined>
  handleValidateCodeNotLogged: (
    existPromotionalCode: boolean,
    code: string,
    productsIdsParam?: number[],
    specialProductsIdParam?: number[]
  ) => Promise<IValidateCode | undefined>
  handleRemoveCode: () => Promise<void>
  loadingValidate: boolean
  handleChangeCode: (promotionalCode: string) => void
  code: string
  shopToAnotherAccount: boolean
  handleChangeShopToAnotherAccount: () => void
  handleCleanAssignedCompanyToProcess: (processParam?: IProcess) => Promise<void>
  loadingCompleteProcess: boolean
}

export const ProcessContext = React.createContext({} as Props)

const ProcessProvider: React.FC = ({ children }) => {
  const [loadingInitialize, setLoadingInitialize] = React.useState<boolean>(false)
  const [isPromoReference, setIsPromoReference] = React.useState<boolean>(false)
  const [loadingValidate, setLoadingValidate] = React.useState<boolean>(false)
  const [loadingCompleteProcess, setLoadingCompleteProcess] = React.useState<boolean>(false)
  const [code, setCode] = React.useState<string>('')

  /**if this state is true , the step select account show */
  const [shopToAnotherAccount, setShopToAnotherAccount] = React.useState<boolean>(false)

  const {
    steps,
    currentStep,
    currentIndex,
    switchNextStep,
    handleResetSteps,
    changeStepIndex,
    getEnableSteps,
    addEnableSteps,
    deleteEnableSteps,
    getStepCurrentByProcess,
    loading,
    payInProcessOrComplete,
    inProcessOrCompleted,
    hasExpiredSpecialProduct,
  } = useProcessStep()
  const {
    cleanCart,
    addProductToCart,
    handleChangeProcess,
    addSpecialProductToCart,
    productsIds,
    specialProductsId,
    process,
    invoiceDetails,
    handleInvoiceDetails,
    loadingInvoice,
  } = useCart()
  const { getItem, storeItem, deleteItem } = useLocalStorage()
  const { handleSubmitInvoiceContract } = useInvoiceContract()
  const { isAuthenticated, user, changeLoginStateByModal } = useAuth()
  const { addLoadingReference, removeLoadingReference } = useLoading()
  const {
    getTransactionsByProcessId,
    handleSaveTransaction,
    loading: loadingTransaction,
  } = useTransaction()
  const { noAllFields } = useValidateCompanyInformation()
  const [searchParams] = useSearchParams()
  const { handleSaveCompanyInformationDisabledFromLocalStorage } = useCompanyInformation()
  const { animatedNavigation } = useNavigate()

  const {
    specialProducts,
    haveSpecialProducts,
    loadingSpecialProducts,
    handleChangeFilter,
    filterSpecialProductsSelect,
    getSpecialProducts,
  } = useSpecialProducts()

  /***
   * @description get the process id from the url params this id is used to get the process from the ws
   * @returns  the process id from the url params
   */
  // const encryptedParam = searchParams.get(PROCESS_ID_PARAM.replace('=', ''))
  // const processId = encryptedParam !== null ? decryptParam(encryptedParam) : null

  /***
   * @description assigned to column assigned_company_id in table process and update the process with the company identification
   * @param identification company identification will be assigned to the process and this company will assign the license buy
   * @returns  a promise that resolve when the process is updated
   */
  const handleAssignedCompanyToProcess = React.useCallback(
    async (identification: string, processParam?: IProcess) => {
      if (!Boolean(process)) return
      try {
        const processToCopy = Boolean(processParam) ? processParam : process
        const processToUpdate = { ...processToCopy, assignedCompanyId: identification }
        const result = await updateProcessesService(processToUpdate, process!.processId)
        handleChangeProcess(result)
      } catch (error) {
        console.log(error)
      }
    },
    [process]
  )

  /***
   * @description assigned to column assigned_company_id in table process and update the process with the company identification
   * @param identification company identification will be assigned to the process and this company will assign the license buy
   * @returns  a promise that resolve when the process is updated
   */
  const handleCleanAssignedCompanyToProcess = React.useCallback(
    async (processParam?: IProcess) => {
      const existsProcessAndProcessParam = Boolean(process) || Boolean(processParam)
      if (!existsProcessAndProcessParam) return
      try {
        const processToCopy = Boolean(processParam) ? processParam : process
        const processToUpdate = { ...processToCopy, assignedCompanyId: null }
        const result = await updateProcessesService(processToUpdate, processToUpdate!.processId!)
        handleChangeProcess(result)
      } catch (error) {
        console.log(error)
      }
    },
    [process]
  )

  /***
   * @description assigned to column company_information_id in table process and update the process with the company information id
   * @param companyInformationId company information id will be assigned to the process
   * @returns  a promise that resolve when the process is updated
   */
  const handleAssignedCompanyInformationIdToProcess = React.useCallback(
    async (
      companyInformationId?: number,
      processToAssigned?: IProcess,
      invoiceInformationId?: number
    ) => {
      try {
        const processAssigned = processToAssigned ? processToAssigned : process

        if (processAssigned) {
          const { companyId, assignedCompanyId, securityUserId, processId } = processAssigned

          const request = {
            companyId,
            assignedCompanyId,
            companyInformationId,
            invoiceInformationId,
            securityUserId,
          }
          const result = await updateProcessesService(request, processId)
          handleChangeProcess(result)
          return result
        }
      } catch (error) {
        console.log(error)
      }
    },
    [process]
  )

  /***
   * @description create a process when the user is logged in de Modal Login
   * @param process the process created when the user is logged in de Modal Login
   * @returns  return process created
   */
  const createProcessAfterLogin = async (process: any) => {
    try {
      const response = await createProcessesService(process)
      return response
    } catch (error) {
      console.log('error', error)
    }
  }

  /***
   * @description add product to cart when the user is logged in de Modal Login and save
   * processProducts that was saved in the local storage
   * @param process the process created when the user is logged in de Modal Login
   */
  const addProductProcessFromLocalStorage = (process: IProcess) => {
    try {
      const processProducts = getItem(constLocalStorage.CART_PRODUCTS)
      if (!Boolean(process) || !Boolean(processProducts)) return
      processProducts.forEach(async (cartProduct: ICartProduct) => {
        const request = {
          productId:
            cartProduct.product !== null && cartProduct.product !== undefined
              ? cartProduct.product.productId
              : undefined,
          processId: process!.processId,
        }
        await addProductToCart(request)
      })
      deleteItem(constLocalStorage.CART_PRODUCTS)
    } catch (error) {
      console.log('error', error)
    }
  }

  /***
   * @description function to show a notification
   * @param message message to show on screen
   * @param valid type of message
   */
  const handleThrowMessage = (message: string, valid: boolean) => {
    showMessage(`${message}`, valid ? 'success' : 'error', {
      horizontal: 'right',
      vertical: 'top',
    })
  }

  /***
   * @description service for verify a promotional Code
   * @param existPromotionalCodeAndProcess the process created when the user is logged in de Modal Login
   * @param code code to entered
   * @return the response and update the process
   */
  const handleValidateCodeLogged = async (
    existPromotionalCodeAndProcess: boolean,
    code: string,
    productsIdsParam?: number[],
    specialProductsIdParam?: number[],
    processIdParam?: number
  ) => {
    try {
      if (existPromotionalCodeAndProcess) {
        setLoadingValidate(true)
        const processId = processIdParam !== undefined ? processIdParam : process!.processId
        const response = await validateCodeLoggedService(
          code,
          processId,
          productsIdsParam !== undefined ? productsIdsParam : productsIds,
          specialProductsIdParam !== undefined ? specialProductsIdParam : specialProductsId
        )
        if (response.process) {
          handleChangeProcess(response.process)
        }
        handleThrowMessage(response.message, response.valid)
        return response
      } else {
        handleThrowMessage('Ingresa un código promocional', false)
      }
    } catch (error) {
      console.log('Error :', error)
    } finally {
      setLoadingValidate(false)
    }
  }

  /***
   * @description service for verify a promotional Code when the user is not logged
   * @param existPromotionalCode verify if exist user entered code
   * @param code code to entered by user
   * @return the response and update the process
   */
  const handleValidateCodeNotLogged = async (
    existPromotionalCode: boolean,
    code: string,
    productsIdsParam?: number[],
    specialProductsIdParam?: number[]
  ) => {
    try {
      if (existPromotionalCode) {
        setLoadingValidate(true)
        const response = await validateCodeNotLoggedService(
          code,
          productsIdsParam !== undefined ? productsIdsParam : productsIds,
          specialProductsIdParam !== undefined ? specialProductsIdParam : specialProductsId
        )
        handleThrowMessage(response.message, response.valid)
        return response
      } else {
        handleThrowMessage('Ingresa un código promocional', false)
      }
    } catch (error) {
      console.log('Error :', error)
    } finally {
      setLoadingValidate(false)
    }
  }

  /***
   * @description function for update a code
   *
   */
  const handleChangeCode = (promotionalCode: string) => {
    setCode(promotionalCode)
  }
  /***
   * @description service for remove a promotional Code
   * @return the response and update the process without promotionalCode
   */
  const handleRemoveCode = async () => {
    try {
      setLoadingValidate(true)
      if (process) {
        const response = await removeCodeService(process!.processId)
        if (response.process !== undefined) {
          handleChangeProcess(response.process)
        }
        if (!response.valid) {
          handleThrowMessage(response.message, true)
        }
      }
    } catch (error) {
      console.log('Error :', error)
    } finally {
      setLoadingValidate(false)
    }
  }

  /***
   * @description add specialProduct to cart when the user is logged in de Modal Login and save
   * processProducts that was saved in the local storage
   * @param process the process created when the user is logged in de Modal Login
   */
  const addSpecialProductProcessFromLocalStorage = (process: IProcess) => {
    try {
      const processSpecialProducts = getItem(constLocalStorage.CART_SPECIAL_PRODUCTS)
      if (!Boolean(process) || !Boolean(processSpecialProducts)) return
      processSpecialProducts.forEach(async (cartProduct: ICartProduct) => {
        const request = {
          specialProductId:
            cartProduct.specialProduct !== null && cartProduct.specialProduct !== undefined
              ? cartProduct.specialProduct.specialProductId
              : undefined,
          processId: process!.processId,
        }
        await addSpecialProductToCart(request)
      })
      deleteItem(constLocalStorage.CART_SPECIAL_PRODUCTS)
    } catch (error) {
      console.log('error', error)
    }
  }

  /***
   * @description initialize the process when the user is logged in de Modal Login
   * @param user the user logged in de Modal Login
   */
  const createCompanyInformationFromLocalStorage = async (
    process: IProcess,
    companyInformation: ICompanyInformation
  ) => {
    try {
      const companyInformation = getItem(constLocalStorage.COMPANY_INFO)
      if (companyInformation) {
        const result = await handleSaveCompanyInformationDisabledFromLocalStorage({
          ...companyInformation,
          companyId: process.companyId,
        })
        const processUpdate = await handleAssignedCompanyInformationIdToProcess(result, process)
        deleteItem(constLocalStorage.COMPANY_INFO)
        return processUpdate
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const processWithCompanyInformation = async (
    process: IProcess,
    companyInformation: ICompanyInformation
  ) => {
    const processUpdate = await createCompanyInformationFromLocalStorage(
      process,
      companyInformation
    )
    const transactions = await getTransactionsByProcessId(processUpdate.processId)
    const haveTransactions = transactions.length > 0
    const invoiceContractId = haveTransactions ? transactions[0].invoiceContractId : null
    const invoiceContract = await handleSubmitInvoiceContract(
      processUpdate.processId,
      invoiceContractId,
      process.promotionalCode !== null ? true : false
    )
    await handleSaveTransaction(invoiceContract.invoiceContractId, processUpdate, transactions[0])
  }

  const initializeConfigureAfterLogin = async (user: IUser) => {
    changeLoginStateByModal(LOGIN_STATE_BY_MODAL.INITIALIZED_AFTER_LOGIN)
    const infoProcess = { companyId: user.identification, securityUserId: user.userId }
    try {
      setLoadingInitialize(true)
      const companyInformation = getItem(constLocalStorage.COMPANY_INFO)
      const existCompanyInformation = Boolean(companyInformation)
      const allFields = existCompanyInformation ? noAllFields(companyInformation) : false
      let newProcess = await createProcessAfterLogin(infoProcess)
      addProductProcessFromLocalStorage(newProcess)
      addSpecialProductProcessFromLocalStorage(newProcess)
      handleChangeProcess(newProcess)
      const promotionalCode = getItem(constLocalStorage.PROMOTIONAL_CODE) as IPromocionalCode
      if (promotionalCode !== undefined) {
        const existPromotionalCodeAndProcess =
          Boolean(promotionalCode.code.length > 0) && Boolean(newProcess !== undefined)
        const response = await handleValidateCodeLogged(
          existPromotionalCodeAndProcess,
          promotionalCode.code,
          undefined,
          undefined,
          newProcess.processId
        )
        if (response?.process?.promotionalCode !== null && response?.valid) {
          deleteItem(constLocalStorage.PROMOTIONAL_CODE)
        }
        if (response?.process !== null) {
          newProcess = response?.process
        }
      }
      const shopAnotherAccountLocal =
        Boolean(getItem(constLocalStorage.SHOP_ANOTHER_ACCOUNT)) &&
        getItem(constLocalStorage.SHOP_ANOTHER_ACCOUNT) === true

      if (allFields) {
        await processWithCompanyInformation(newProcess, companyInformation)
      }
      deleteEnableSteps(StepReferenceName.ACCOUNT)
      if (shopAnotherAccountLocal) {
        deleteEnableSteps(StepReferenceName.PAYMENT)
        await handleCleanAssignedCompanyToProcess(newProcess)
        deleteItem(constLocalStorage.SHOP_ANOTHER_ACCOUNT)
      }
      deleteItem(constLocalStorage.PROCESS_CREATE)

      const encryptRoute = `${ROUTES.processes}/nuna`
      animatedNavigation(encryptRoute)
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoadingInitialize(false)
      changeLoginStateByModal(LOGIN_STATE_BY_MODAL.FINISH_PROCESS)
    }
  }

  /***
   * @description get the process from the ws by the process id
   */
  const getProcessByProcessId = async (processId: number) => {
    try {
      const result: IProcess = await getProcessByIdService(processId)
      handleChangeProcess(result)
      const shopToAnotherAccount = result.assignedCompanyId === null
      setShopToAnotherAccount(shopToAnotherAccount)
      getStepCurrentByProcess(result)
    } catch (error) {
      console.log(JSON.stringify(error))
    }
  }

  const getInvoiceDetails = React.useCallback(
    async (promotionalCodeId?: number) => {
      if (productsIds?.length === 0 && specialProductsId?.length === 0) return
      await handleInvoiceDetails(productsIds, specialProductsId, promotionalCodeId)
    },
    [productsIds, specialProductsId]
  )

  React.useEffect(() => {
    if (productsIds?.length === 0 && specialProductsId?.length === 0) return
    handleInvoiceDetails(
      productsIds,
      specialProductsId,
      process?.promotionalCode?.promotionalCodeId
    )
  }, [])

  const trackCompleteProcess = (process: IProcess) => {
    try {
      const { products, total } = invoiceDetails
      const { processId, companyId, promotionalCode } = process
      const itemList = products ? generateItemsFromInvoice(products) : null
      const gaData = {
        transaction_id: process.processId.toString(),
        process_id: processId,
        company_id: companyId,
        value: Number(total.slice(1)),
        currency: 'USD',
        coupon:
          promotionalCode !== undefined && promotionalCode !== null
            ? promotionalCode.code
            : 'NO PROMO',
        items: itemList,
      }
      trackGoogleAnalyticsEventWithData(
        'Button',
        GA_EVENT.COMPLETE_PROCESS_EVENT,
        'Compra Finalizada',
        gaData
      )
    } catch (error) {
      console.log(error)
    }
  }
  /***
   * @description Complete process when the payment is successful
   */
  const completeProcess = async () => {
    try {
      setLoadingCompleteProcess(true)

      if (process) {
        await completeProcessService({ processId: process!.processId })
        await emmitInvoice()
        trackCompleteProcess(process)

        const transactions = await getTransactionsByProcessId(process.processId)
        const haveTransactions = transactions.length > 0

        ReactPixel.track('Purchase', {
          paymentMethod: haveTransactions ? transactions[0].method : '',
          currency: 'USD',
          value: process.amount,
          content_id: transactions[0]?.licenseInformation[0]?.product?.productId
            ? 'P' + transactions[0].licenseInformation[0].product?.productId
            : 'O' + transactions[0].licenseInformation[0].specialProductDetailId,
        })

        cleanCart()
        navigate.animatedNavigation(
          `${ROUTES.user}/${ROUTES.marketplace}/${ROUTES.solutions}`,
          'circle'
        )
      }
    } catch (error) {
      console.log(error)
      showMessage('No se pudo completar tu carrito, comunícate con soporte', 'error')
    } finally {
      setLoadingCompleteProcess(false)
    }
  }

  const processStatusScreenRef = React.useRef<IProcessStatusScreenRef>(null)
  const navigate = useNavigate()
  const { showMessage } = useFeedback()

  /** main solution states */
  const [solution, setSolution] = React.useState<ISolution | undefined>(undefined)
  const { mainSolutionReference } = useParams()

  const getMainSolution = React.useCallback(async (mainSolutionReference: string) => {
    try {
      addLoadingReference('solutionsByReference')
      const result: ISolution = await getMainSolutionsByReferenceService(mainSolutionReference)
      setSolution(result)
    } catch (error) {
      console.log(JSON.stringify(error))
    } finally {
      removeLoadingReference('solutionsByReference')
    }
  }, [])

  const handleChangePromoReference = (promoChange: boolean) => {
    setIsPromoReference(promoChange)
    handleChangeFilter(promoChange)
  }

  React.useEffect(() => {
    if (Boolean(mainSolutionReference) && mainSolutionReference !== REFERENCE_SPECIAL_PRODUCTS) {
      getMainSolution(mainSolutionReference!)
      getSpecialProducts()
    } else {
      getSpecialProducts()
      setIsPromoReference(true)
      handleChangeFilter(true)
    }
  }, [mainSolutionReference, getMainSolution])

  /**callback dataweb */
  const callbackId = searchParams.get('id')

  const [onCallback, setOnCallback] = React.useState(false)

  const finishCallback = React.useCallback(() => {
    setOnCallback(false)
  }, [])

  React.useEffect(() => {
    if (callbackId) {
      setOnCallback(true)
    }
  }, [callbackId])

  /** verifies that the user has a process solution but have not logged in */

  const generalLoading = React.useMemo(() => {
    if (Boolean(mainSolutionReference) && mainSolutionReference !== REFERENCE_SPECIAL_PRODUCTS) {
      const notCompleteDataForUser = !Boolean(solution) && !Boolean(steps) && steps.length < 0
      return (
        notCompleteDataForUser ||
        loadingInitialize ||
        loading ||
        loadingInvoice ||
        loadingTransaction
      )
    } else {
      return (
        loadingSpecialProducts ||
        loading ||
        !Boolean(steps) ||
        steps.length === 0 ||
        loadingInvoice ||
        loadingTransaction
      )
    }
  }, [
    mainSolutionReference,
    solution,
    steps,
    loading,
    loadingSpecialProducts,
    loadingInitialize,
    loadingInvoice,
    loadingTransaction,
  ])

  /** Initialize animations sequence */
  // React.useEffect(() => {
  //   processStatusScreenRef.current?.initProcessScreen()
  //   if (callbackId) {
  //     processStatusScreenRef.current?.initializePaymentSequence()
  //     return
  //   } else {
  //     processStatusScreenRef.current?.initializeMainSequence()
  //   }
  //   processStatusScreenRef.current?.initializeMainSequence()
  // }, [callbackId])

  const finishProcess = () => {
    setSolution(undefined)
    handleResetSteps()
    handleChangeProcess(undefined)
    processStatusScreenRef.current?.hideAll()
  }

  const handleChangeShopToAnotherAccount = () => {
    setShopToAnotherAccount((prev) => {
      if (!isAuthenticated) {
        storeItem(constLocalStorage.SHOP_ANOTHER_ACCOUNT, !prev)
      }
      return !prev
    })
  }

  React.useEffect(() => {
    if (Boolean(process) && currentStep?.reference === 'invoice') {
      const shopToAnotherAccount = process!.assignedCompanyId !== user?.identification
      setShopToAnotherAccount(shopToAnotherAccount)
    }
  }, [])

  const emmitInvoice = async () => {
    try {
      if (process) {
        const transactions = await getTransactionsByProcessId(process.processId)
        const haveTransactions = transactions.length > 0
        const invoiceContractId = haveTransactions ? transactions[0].invoiceContractId : null
        if (invoiceContractId === null) {
          showMessage('No se encontró el ID de la factura', 'error')
          return
        }
        await emmitInvoiceService({
          invoiceContractId: invoiceContractId!,
          processId: process!.processId,
        })
      }
    } catch (error) {
      showMessage('No se pudo completar la emisión de la factura, comunícate con soporte', 'error')
    }
  }

  return (
    <ProcessContext.Provider
      value={{
        steps,
        loadingInitialize,
        currentStep,
        currentIndex,
        solution,
        process,
        getEnableSteps,
        getStepCurrentByProcess,
        addEnableSteps,
        deleteEnableSteps,
        onCallback,
        finishProcess,
        switchNextStep,
        changeStepIndex,
        finishCallback,
        completeProcess,
        emmitInvoice,
        getProcessByProcessId,
        handleAssignedCompanyInformationIdToProcess,
        handleAssignedCompanyToProcess,
        initializeConfigureAfterLogin,
        payInProcessOrComplete,
        inProcessOrCompleted,
        isPromoReference,
        specialProducts,
        haveSpecialProducts,
        loadingSpecialProducts,
        filterSpecialProductsSelect,
        handleChangeFilter,
        handleChangePromoReference,
        getMainSolution,
        generalLoading,
        hasExpiredSpecialProduct,
        handleValidateCodeLogged: handleValidateCodeLogged,
        handleValidateCodeNotLogged: handleValidateCodeNotLogged,
        handleRemoveCode,
        loadingValidate,
        code,
        handleChangeCode,
        shopToAnotherAccount,
        handleChangeShopToAnotherAccount,
        handleCleanAssignedCompanyToProcess,
        loadingCompleteProcess,
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </ProcessContext.Provider>
  )
}

export default ProcessProvider
