import { LoadingButton } from '@mui/lab'
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Grid,
  Icon,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useGetSolutions } from 'hooks'

type Props = {}

export const SalesForm = (props: Props) => {
  const { loading: loadingSolutions, solutions } = useGetSolutions()

  return (
    <Grid container flexGrow={1} px={4} sx={{ height: '100%' }}>
      <Grid xs={12} item sx={{ mb: 2, pb: 3 }}>
        <Alert severity="success" icon={<Icon>info</Icon>}>
          ¿No encontraste el producto adecuado para ti o quieres adquirir algún otro tipo de
          producto?
          <AlertTitle sx={{ fontSize: '0.9em', fontWeight: 600, mt: 1 }}>
            {'Contáctese con nuestro equipo de ventas'}
          </AlertTitle>
        </Alert>
      </Grid>
      <Grid xs={12}>
        <Paper
          sx={{
            pb: 4,
            px: 3,
          }}
          elevation={0}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack spacing={2}>
                <Typography variant="caption" fontWeight={600}>
                  {'¿Qué producto quisieras mejorar?'}
                </Typography>
                <Autocomplete
                  disablePortal
                  freeSolo
                  options={[
                    ...solutions.map((s) => `${s.name} - ${s.description}`),
                    'Otro tipo de producto',
                  ]}
                  loading={loadingSolutions}
                  loadingText="Cargando productos"
                  noOptionsText="No tiene productos registrados"
                  getOptionLabel={(option) => option}
                  onChange={(_, value) => {
                  }}
                  fullWidth
                  openOnFocus
                  renderInput={(params) => (
                    <TextField {...params} required placeholder="Selecciona" />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={2}>
                <Typography variant="caption" fontWeight={600}>
                  {'Descripción'}
                </Typography>
                <TextField
                  rows={4}
                  multiline
                  placeholder="Escribe una clara descripción de lo que estas buscando"
                  required
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container justifyContent={'flex-end'} sx={{ pt: 4 }}>
            <Grid item xs={6} alignItems={'flex-end'}>
              <LoadingButton
                color="secondary"
                type="submit"
                fullWidth
                disabled={loadingSolutions}
              >
                {'Enviar'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
