import {
  Box,
  Button,
  Dialog,
  Divider,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { AnimatePresence } from 'framer-motion'
import {
  REVERTED_TRANSACTION_ID,
  TRANSACTION_ICONS,
  formatCurrency,
  TRANSACTION_STATES,
  PAYMENT_METHOD,
} from 'lib'
import React from 'react'
import { Fragment } from 'react'
import { ReasonRejectDialog } from './ReasonRejectDialog'
import { ITransaction } from 'marketplace-common'

interface Props {
  open: boolean
  transaction: ITransaction | undefined
  handleClose: (dialog: string) => void
}

export const DetailDialog = (props: Props) => {
  const { open, transaction, handleClose } = props
  const [openModal, setOpenModal] = React.useState<boolean>(false)
  const [adminComment, setAdminComment] = React.useState<string>()

  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  return (
    <Fragment>
      {transaction && (
        <Dialog
          open={open}
          onClose={() => {
            handleClose('Detail')
          }}
          fullWidth
          maxWidth="xs"
        >
          <AnimatePresence>
            <Stack py={1} px={2} position="relative">
              <Stack alignItems={'center'} justifyContent={'center'} p={4} spacing={1}>
                <IconButton
                  sx={{
                    bgcolor: (theme) =>
                      transaction.state.transactionStateId === REVERTED_TRANSACTION_ID
                        ? theme.palette.error.main
                        : theme.palette.primary.main,
                    color: '#FFF',
                  }}
                >
                  <Icon>{TRANSACTION_ICONS[transaction.state.transactionStateId]}</Icon>
                </IconButton>
                <Typography variant="h6" align="center">
                  {'Detalles de transacción'}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  fontWeight={800}
                  sx={{ opacity: 0.5 }}
                >
                  {transaction.type === PAYMENT_METHOD.DB ? PAYMENT_METHOD.DB_DESCRIPTION : 'Otro'}
                </Typography>
              </Stack>
              <Stack direction={'row'} justifyContent={'space-between'} py={3}>
                <Typography variant="caption" fontWeight={600}>
                  {'Método de pago'}
                </Typography>
                <Typography variant="caption" fontWeight={600} color="textSecondary">
                  {transaction.method === PAYMENT_METHOD.DB
                    ? PAYMENT_METHOD.DB_DESCRIPTION
                    : 'Otro'}
                </Typography>
              </Stack>
              <Typography variant="caption" fontWeight={600}>
                {'Historial'}
              </Typography>
              {transaction.history
                .filter((history) => history.state.transactionStateId !== 8)
                .sort(
                  (first, second) =>
                    (new Date(first.paymentDate).valueOf() -
                      new Date(second.paymentDate).valueOf()) as number
                )
                .map((transactionHistory) => (
                  <React.Fragment
                    key={
                      'transaction-history' +
                      transactionHistory.paymentId +
                      transaction.transactionId
                    }
                  >
                    <Stack direction={'row'} py={3} alignItems="center" spacing={2}>
                      <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
                        <Typography variant="body2" letterSpacing={0.5}>
                          {`Realizada el ${format(
                            new Date(transactionHistory.paymentDate),
                            "dd 'de' MMMM yyyy",
                            { locale: es }
                          )}`}
                        </Typography>
                        <Typography fontWeight={700} variant="body2">
                          {transactionHistory.amount
                            ? `Valor: ${formatCurrency(transactionHistory.amount)}`
                            : '-'}
                        </Typography>
                      </Box>
                      <Typography variant="caption" fontWeight={600} sx={{ opacity: 0.5 }}>
                        {TRANSACTION_STATES[transactionHistory.state.transactionStateId]}
                      </Typography>

                      <IconButton
                        sx={{
                          bgcolor: (theme) =>
                            transactionHistory.state.transactionStateId === REVERTED_TRANSACTION_ID
                              ? theme.palette.error.main
                              : theme.palette.primary.main,
                          color: '#FFF',
                        }}
                      >
                        <Icon>
                          {TRANSACTION_ICONS[transactionHistory.state.transactionStateId]}
                        </Icon>
                      </IconButton>
                      {transactionHistory.transactionAdministratorComment !== null &&
                        transactionHistory.transactionAdministratorComment.length > 0 && (
                          <Button
                            sx={{ width: 15, fontSize: 10 }}
                            onClick={() => {
                              setAdminComment(transactionHistory.transactionAdministratorComment)
                              handleOpenModal()
                            }}
                          >
                            Ver Motivo
                          </Button>
                        )}
                    </Stack>
                    <Divider />
                  </React.Fragment>
                ))}
              <Stack direction={'row'} justifyContent={'space-between'} py={3}>
                <Typography variant="body1" fontWeight={600}>
                  {'Total'}
                </Typography>
                <Typography variant="body1" fontWeight={600} color="textSecondary">
                  {transaction.amount && formatCurrency(transaction.amount)}
                </Typography>
              </Stack>
              <Box
                display={'flex'}
                width={'100%'}
                justifyContent={'flex-end'}
                pr={5}
                position="absolute"
                right={0}
              >
                <Tooltip title="Cerrar" arrow>
                  <IconButton
                    sx={{
                      fontSize: { xs: 20, sm: 25 },
                      right: { xs: -20, sm: -25 },
                      top: { xs: 15, sm: -2 },
                    }}
                    onClick={() => handleClose('Detail')}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          </AnimatePresence>
        </Dialog>
      )}
      {adminComment && (
        <ReasonRejectDialog
          open={openModal}
          adminComment={adminComment}
          handleClose={handleCloseModal}
        />
      )}
    </Fragment>
  )
}
