import { AnimatedNavigationType } from 'components'
import { useNavigate } from 'hooks'
import React from 'react'
import { Link, LinkProps, NavigateOptions } from 'react-router-dom'

type Props = {
  type: AnimatedNavigationType | undefined
  options?: NavigateOptions | undefined
  fullWidth?: boolean
}

/**
 *
 * @param props - accept the same properties for a a react-router-dom <Link /> component
 * +2 props, 1st type an AnimatedNavigationType, to create an animated navigation effect
 * and second extra options (state, search, params, etc) for the useNatigate hook.
 * Check the Navigation context to see more
 * @see https://reactrouter.com/docs/en/v6/getting-started/overview#relative-links
 * @returns a react-router-dom <Link /> component with a animated location transition
 */

export const AnimatedLink = (props: LinkProps & React.RefAttributes<HTMLAnchorElement> & Props) => {
  const { animatedNavigation } = useNavigate()
  const { to, type, children, options, fullWidth } = props
  const width = fullWidth ? '100%' : 'unset'
  return (
    <Link
      {...props}
      onClick={(e) => {
        /** Prevent the default behaivor of the link component and wait for the animation to complete to resume the navigation.*/
        e.preventDefault()
        animatedNavigation(to, type, options)
      }}
      className="animated-link"
      style={{
        color: 'initial !important',
        textDecoration: 'none',
        width,
      }}
    >
      {children}
    </Link>
  )
}
