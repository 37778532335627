import React from 'react'

type Props = {
  loadingType: LoadingReference[]
  addLoadingReference: (reference: LoadingReference) => void
  removeLoadingReference: (reference: LoadingReference) => void
  isLoadingReference: (reference: LoadingReference) => boolean
}
type LoadingReference =
  | 'step'
  | 'solutions'
  | 'stepValidation'
  | 'products'
  | 'specialProducts'
  | 'solutionsByReference'
  | 'checkout'
  | 'productsToActivate'
  | 'expiredProducts'

export const LoadingContext = React.createContext({} as Props)

const LoadingProvider: React.FC = ({ children }) => {
  const [loadingType, setLoadingType] = React.useState<LoadingReference[]>([
    'step',
    'stepValidation',
  ])

  const addLoadingReference = (reference: LoadingReference) => {
    setLoadingType((prev) => [...prev, reference])
  }

  const removeLoadingReference = (reference: LoadingReference) => {
    setLoadingType((prev) => prev.filter((loadingReference) => loadingReference !== reference))
  }

  const isLoadingReference = (reference: LoadingReference) => {
    return loadingType.find((loading) => loading === reference) !== undefined
  }

  return (
    <LoadingContext.Provider
      value={{
        loadingType,
        removeLoadingReference,
        addLoadingReference,
        isLoadingReference,
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </LoadingContext.Provider>
  )
}

export default LoadingProvider
