import { Snackbar, Alert, AlertColor, SnackbarOrigin, SnackbarCloseReason } from '@mui/material'
import React from 'react'

/**
 * Export the props template for the useRef hook
 */
export interface SnackbarHandlerRef {
  showMessage: (
    message: string,
    severity?: AlertColor | undefined,
    anchorOrigin?: SnackbarOrigin
  ) => void
}

interface MessageConfig {
  open: boolean
  message: string
  severity?: AlertColor
  autoHideDuration?: number
  anchorOrigin?: SnackbarOrigin
}

const DEFAULT_ANCHOR_ORIGIN: SnackbarOrigin = { horizontal: 'right', vertical: 'top' }

/**
 * @returns Component that show snackbars
 * This component only can be used with a ref attribute due to all the logic and functionality is contained by the useImperativeHandle hook
 */
export const SnackbarHandler = React.forwardRef<SnackbarHandlerRef>(
  (_, ref: React.ForwardedRef<SnackbarHandlerRef>) => {
    const [config, setConfig] = React.useState<MessageConfig>({
      message: '',
      open: false,
      autoHideDuration: 4000,
      anchorOrigin: DEFAULT_ANCHOR_ORIGIN,
    })
    const { message, open, severity, autoHideDuration, anchorOrigin } = config
    const handleClose = (
      event: Event | React.SyntheticEvent<any, Event>,
      reason?: SnackbarCloseReason
    ) => {
      if (reason !== 'clickaway') {
        setConfig((current) => ({ ...current, open: false }))
      }
    }
    React.useImperativeHandle(ref, () => ({
      showMessage: (message, severity, anchorOrigin) => {
        setConfig((current) => ({ ...current, open: false }))
        setTimeout(() => {
          setConfig((current) => ({
            ...current,
            anchorOrigin: anchorOrigin ?? DEFAULT_ANCHOR_ORIGIN,
            message,
            open: true,
            severity: severity,
          }))
        }, 200)
      },
    }))

    return (
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    )
  }
)
