import { Box, Grid } from '@mui/material'
import { Outlet } from 'react-router-dom'

export const ConfigurationLayout = () => {


  return (
    <Grid container flexGrow={1} direction="column" id="configurationContainer">
      <Box display="flex" flexGrow={1} maxWidth={'100%'} >
        <Outlet />
      </Box>
    </Grid>
  )
}
