import React from 'react'
import { DiversityType, passwordStrength } from 'check-password-strength'

type Hook = {
  strength: string
  contains: string[]
  diversity: string[]
}

export const LEVELS = [
  'Contraseña muy débil',
  'Contraseña débil',
  'Contraseña medianamente fuerte',
  'Contraseña fuerte!',
]

const CONTAINS = {
  lowercase: 'Minúscula',
  uppercase: 'Mayúscula',
  number: 'Número',
  symbol: 'Carácter especial',
  length: 'Mínimo 9 caracteres',
}

export const usePasswordStrength = (password?: string): Hook => {
  const [strength, setStrength] = React.useState('')
  const [contains, setContains] = React.useState<string[]>([])

  React.useEffect(() => {
    const result = passwordStrength(password ?? '')
    const { id, contains, length } = result
    const containsPassword = contains.map((c) => CONTAINS[c])
    const validPasswordRange = length >= 9
    if (validPasswordRange) {
      containsPassword.push(CONTAINS.length)
    }
    setContains(containsPassword)
    setStrength(LEVELS[id] ?? '')
  }, [password])

  return {
    strength,
    contains,
    diversity: Object.keys(CONTAINS).map((k) => CONTAINS[k as DiversityType]),
  }
}
