import { StepType } from '@reactour/tour'

export const TOUR_STEPS = [
  {
    selector: '.user-layout',
    padding: 0,
    position: 'center',
    content: '¡Bienvenido a Biosferasoft! Ahora todos tus productos en un solo lugar.',
  },
  {
    selector: '.view-products-tour-steps',
    padding: 6,
    position: 'bottom',
    content: 'Explora los productos que tenemos para ofrecerte.',
  },
  {
    selector: '.buy-tour-steps',
    padding: 6,
    position: 'bottom',
    content: 'Adquiere tus productos.',
  },
  {
    selector: '.continueBuying-tour-step',
    padding: 0,
    position: 'right',
    content: 'Aquí puedes continuar con tu compra.',
  },
  {
    selector: '.shopping-car-icon-tour-steps',
    padding: 8,
    position: 'bottom',
    content: 'Aquí encontrarás los detalles de compra.',
  },
  {
    selector: '.my-products-tour-step',
    padding: 0,
    position: 'right',
    content: 'Aquí encontrarás tus productos.',
  },
  {
    selector: '.my-product-summary-quinde-tour-step',
    padding: 8,
    position: 'bottom',
    content: 'Aquí puedes encontrar tus licencias adquiridas.',
  },
  {
    selector: '.my-product-profile-quinde-tour-step',
    padding: 8,
    position: 'bottom',
    content: 'Aquí encontrarás tus perfiles e ingresar con un solo click.',
  },
  {
    selector: '.my-shopping-car-tour-step',
    padding: 0,
    position: 'right',
    content: 'Aquí encontrarás tu carrito.',
  },
  {
    selector: '.my-payments-tour-step',
    content: 'Explora tu historial de pago, revisa y descarga tus facturas.',
    position: 'right',
    padding: 0,
  },
  {
    selector: '.my-settings-tour-step',
    padding: 0,
    position: 'right',
    content: 'Actualiza tu información de cuenta y gestiona tu contraseña.',
  },
] as StepType[]
