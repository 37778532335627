import { Box, CSSObject, CssBaseline, Theme, styled, useMediaQuery, useTheme } from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import { TourProvider } from '@reactour/tour'
import { DialogAcceptTermsAndCondition, DialogProcess, SidePanel } from 'components'
import { LoadingProvider } from 'context'
import { useAuth, useCart, useGetProcesses, useLocalStorage, useProcessHelper } from 'hooks'
import { TOUR_STEPS, constLocalStorage, getProcessesByCompanyIdService } from 'lib'
import { IProcess } from 'marketplace-common'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { MAIN_COLORS } from 'styles'

const drawerWidth = 260

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  minHeight: '100vh',
  height: '100%',
  backgroundColor: theme.palette.alt?.background,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: '5px',
    height: '5px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#fff',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#000',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#000',
  },
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 25px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 25px)`,
  },
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
)

export const UserLayout = () => {
  const { refreshCart } = useCart()
  const { getItem } = useLocalStorage()
  const { user, showSelectProcess, closeTutorial } = useAuth()
  const { initializeConfigureAfterLogin, loadingInitialize } = useProcessHelper()
  const { processes } = useGetProcesses(user?.identification)

  const theme = useTheme()
  const size = useMediaQuery(theme.breakpoints.up('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const termsAndConditionAccepted = Boolean(user?.allowedConditionTerms)

  const [open, setOpen] = React.useState<boolean>(size)
  const [openDialog, setOpenDialog] = React.useState(false)
    

  const handleOpenDialog = () => setOpenDialog(true)
  const handleCloseDialog = () => setOpenDialog(false)

  const handleDrawer = () => {
    setOpen((prev) => !prev)
  }

  const handleCloseDrawer = () => {
    setOpen(false)
  }

  const createProcess = async () => {
    await initializeConfigureAfterLogin(user)
    handleCloseDialog()
  }

  const initializedUserProcess = async () => {
    const cartProductsLocalStorage = getItem(constLocalStorage.CART_PRODUCTS)
    const cartSpecialProductsLocalStorage = getItem(constLocalStorage.CART_SPECIAL_PRODUCTS)
    const existProductsLocalStorage =
      Boolean(cartProductsLocalStorage) && cartProductsLocalStorage.length > 0
    const existSpecialProductsLocalStorage =
      Boolean(cartSpecialProductsLocalStorage) && cartSpecialProductsLocalStorage.length > 0

    const result: IProcess[] = await getProcessesByCompanyIdService(user.identification)
    const existProcess = result[0] !== undefined

    if (existProcess && (existProductsLocalStorage || existSpecialProductsLocalStorage)) {
      handleOpenDialog()
    }
    if (!existProcess && (existProductsLocalStorage || existSpecialProductsLocalStorage)) {
      createProcess()
    }
    if (!existProductsLocalStorage && !existSpecialProductsLocalStorage && existProcess) {
      await refreshCart(result[0].processId)
    }
  }

  React.useEffect(() => {
    initializedUserProcess()
  }, [])

  React.useEffect(() => {
    const handleResize = () => {
      setOpen(size)
    }
    handleResize()
  }, [size])

  return (
    <LoadingProvider>
      <TourProvider
        scrollSmooth
        disableInteraction
        steps={TOUR_STEPS}
        beforeClose={() => {
          closeTutorial()
        }}
        styles={{
          popover: (base) => ({
            ...base,
            '--reactour-accent': MAIN_COLORS.secondary.main,
            borderRadius: 0,
          }),
          badge: (base) => ({ ...base, left: 'auto', right: '-0.8125em' }),
          controls: (base) => ({ ...base, marginTop: 18 }),
          close: (base) => ({
            ...base,                        
            right: 'auto', left: 8, top: 8
          }),
        }}
        onClickMask={({ setCurrentStep, currentStep, steps, setIsOpen }) => {
          if (steps) {
            if (currentStep === steps.length - 1) {
              setIsOpen(false)
            }
            setCurrentStep((currentStep) => {
              const nextStep = currentStep === steps.length - 1 ? 0 : currentStep + 1              
              return nextStep
            })
          }
        }}
      >
        {termsAndConditionAccepted ? (
          <Box
            className="user-layout"
            width={'100vw'}
            height={'100vh'}
            display={'flex'}
            bgcolor={(theme) => theme.palette.alt?.background}
          >
            <CssBaseline />

            <Drawer variant="permanent" anchor="left" open={open}>
              <SidePanel open={open} handleDrawer={handleDrawer} />
            </Drawer>
            <Main
              open={true}
              onClick={isMobile ? handleCloseDrawer : () => {}}
              sx={{
                pt: '0px',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                  width: '5px',
                  height: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#fff',
                  borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#000',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#000',
                },
              }}
            >
              <Outlet />
            </Main>
          </Box>
        ) : (
          <DialogAcceptTermsAndCondition />
        )}
        <DialogProcess
          openDialog={openDialog && showSelectProcess}
          loadingInitialize={loadingInitialize}
          processId={processes[0] && processes[0].processId}
          handleCloseDialog={handleCloseDialog}
          initializeConfigureAfterLogin={initializeConfigureAfterLogin}
        />
      </TourProvider>
    </LoadingProvider>
  )
}

export {UserLayout as default} from './UserLayout'