export interface ITransactionRequest {
  invoiceContractId?: number
}

export type PaymentMethod = 'card' | 'transfer'

export enum TransacciotionFinallyState {
  COMPLETADO = 1,
  RECHAZADO = 2,
  REVERTIDO = 3,
}

export enum TransactionStateId {
  COMPLETADO = 1,
  RECHAZADO = 2,
  REVERTIDO = 3,
  PENDIENTE = 6,
  POR_REVERTIR = 7,
  INICIALIZADO = 8,
}
