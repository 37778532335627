import {
  getAllProductsToActivateByCompanyIdService,
  getExpiredProductsByCompanyIdService,
  getProductsByMainSolutionIdService,
} from 'lib'
import React from 'react'
import { IProduct, ISolutionProduct } from 'marketplace-common'
import { useLoading } from 'hooks'

type Hook = {
  products: IProduct[]
  allProductsToActivate: ISolutionProduct[]
  expiredProducts: ISolutionProduct[]
  getProductsByMainSolution: (mainSolutionId: number) => Promise<IProduct[]>
  loadingProducts: boolean
  handleOrderProducts: (products: IProduct[]) => IProduct[]
}

export const useGetProducts = (mainSolutionId?: number, companyId?: string): Hook => {
  const [products, setProducts] = React.useState<IProduct[]>([])
  const [allProductsToActivate, setAllProductsToActivate] = React.useState<ISolutionProduct[]>([])
  const [expiredProducts, setExpiredProducts] = React.useState<ISolutionProduct[]>([])
  const [loadingProducts, setLoadingProducts] = React.useState(true)
  const { addLoadingReference, removeLoadingReference } = useLoading()

  const handleOrderProducts = (products: IProduct[]): IProduct[] => {
    const orderedProducts = products.sort(function (a, b) {
      return a.productOrder - b.productOrder
    })
    return orderedProducts
  }

  const getProducts = React.useCallback(async () => {
    try {
      if (mainSolutionId) {
        addLoadingReference('products')
        const result = await getProductsByMainSolutionIdService(mainSolutionId)
        setLoadingProducts(true)
        setProducts(handleOrderProducts(result))
        return result
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoadingProducts(false)
      removeLoadingReference('products')
    }
  }, [mainSolutionId])

  const getAllProductsToActivate = React.useCallback(async () => {
    try {
      if (companyId) {
        addLoadingReference('productsToActivate')
        const result = await getAllProductsToActivateByCompanyIdService(companyId)
        setLoadingProducts(true)
        setAllProductsToActivate(result)
        return result
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoadingProducts(false)
      removeLoadingReference('productsToActivate')
    }
  }, [companyId])

  const getExpiredProducts = React.useCallback(async () => {
    try {
      if (companyId) {
        addLoadingReference('expiredProducts')
        const result = await getExpiredProductsByCompanyIdService(companyId)
        setLoadingProducts(true)
        setExpiredProducts(result)
        return result
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoadingProducts(false)
      removeLoadingReference('expiredProducts')
    }
  }, [companyId])

  const getProductsByMainSolution = async (mainSolutionId: number) => {
    try {
      addLoadingReference('products')
      const result = await getProductsByMainSolutionIdService(mainSolutionId)
      setLoadingProducts(true)
      setProducts(handleOrderProducts(result))
      return result
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoadingProducts(false)
      removeLoadingReference('products')
    }
  }

  React.useEffect(() => {
    getProducts()
  }, [getProducts])

  React.useEffect(() => {
    getAllProductsToActivate()
  }, [getAllProductsToActivate])

  React.useEffect(() => {
    getExpiredProducts()
  }, [getExpiredProducts])
  return {
    products,
    allProductsToActivate,
    expiredProducts,
    getProductsByMainSolution,
    loadingProducts,
    handleOrderProducts,
  }
}
