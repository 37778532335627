export const buildSearchString = (filters?: any) => {
  if (!filters) return ''
  let searchString = filters && Object.keys(filters).length === 0 ? '' : '?'
  
  let i = 0

  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      if (i === 0) {
        searchString = searchString + key + '=' + filters[key]
      } else if (i > 0) {
        searchString = searchString + '&' + key + '=' + filters[key]
      }
    }
    i++
  }
  return searchString
}
