import { Box, Typography, TypographyProps } from '@mui/material'
import React from 'react'

type Props = {
  text: string | React.ReactNode
  adormentColor?: string
}

export const StyledText = (props: Props & TypographyProps) => {
  const { text, adormentColor } = props
  return (
    <Box display={'flex'}>
      <Box
        sx={{
          bgcolor: adormentColor ?? 'primary.main',
          height: 'inherit',
          width: 6,
          display: 'flex',
        }}
      />
      <Typography {...props} sx={{ ml: 3 }}>
        {text}
      </Typography>
    </Box>
  )
}
