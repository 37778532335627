import { getIdentificationTypesService } from 'lib'
import { IIdentificationType } from 'marketplace-common'
import React from 'react'

type Hook = {
  identificationTypes: IIdentificationType[]
}

export const useGetIdentificationTypes = (): Hook => {
  const [identificationTypes, setIdentificationTypes] = React.useState<IIdentificationType[]>([])

  const getSteps = React.useCallback(async () => {
    const result = await getIdentificationTypesService()
    setIdentificationTypes(result)
  }, [])

  React.useEffect(() => {
    getSteps()
  }, [getSteps])

  return {
    identificationTypes,
  }
}
