import { Box, Grid, Paper, Stack, Typography } from '@mui/material'
import Lottie from 'react-lottie'
import emailSent from 'assets/animations/email-sent.json'

export const VerificationResend = () => {
  return (
    <Grid container p={8} justifyContent="center" alignItems="center" height="100%">
      <Grid>
        <Paper
          sx={{
            py: 4,
            px: 8,
          }}
          elevation={16}
        >
          <Stack spacing={3} alignItems="center">
            <Typography fontWeight={600} variant="h5">
              {'Enlace caducado por tiempo!'}
            </Typography>
            <Typography
              fontWeight={500}
              color="textSecondary"
              variant="body1"
              sx={{ maxWidth: 300 }}
              align="center"
            >
              {`Por cuestiones de seguridad hemos vuelto a enviar un enlace a tu correo`}
            </Typography>
            <Box>
              <Lottie
                options={{
                  animationData: emailSent,
                  loop: true,
                }}
                width={180}
                height={180}
              />
            </Box>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  )
}

export { VerificationResend as default} from './VerificationResend'