import { Paper } from '@mui/material'
import { Box } from '@mui/system'
import { Outlet } from 'react-router-dom'

export const ProductLandingLayout = () => {
  return (
    <Box
      bgcolor={(theme) => `${theme.palette.alt?.background}`}
      height="100vh"
      position={'relative'}
      sx={{ overflow: 'auto' }}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Outlet />
    </Box>
  )
}
