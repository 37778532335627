import { LoadingButton } from '@mui/lab'
import { Box, Icon, Toolbar, Typography } from '@mui/material'
import { ShoppingCart } from 'components'
import { motion } from 'framer-motion'
import { useCart, useProcess } from 'hooks'
import React from 'react'
import { StepReferenceName } from 'types'

type Props = {
  handleBtnContinue: () => Promise<void> | void
  hideButton?: boolean
  loading?: boolean
  title?: string
  titleIcon?: string
}
export const ProcessToolbar = (props: Props) => {
  const { handleBtnContinue, hideButton, loading, title, titleIcon } = props
  const { cartProducts, nonEmptyCart } = useCart()
  const { currentStep, changeStepIndex } = useProcess()

  const existsTitleIcon = Boolean(titleIcon)

  /***
   * @description Validate if delete in pick product step
   */
  const noDeleteInPickProduct = currentStep?.reference !== StepReferenceName.PICK_PRODUCT

  const allowBack = React.useMemo(() => {
    return currentStep?.index !== 0
  }, [currentStep?.index])

  return (
    <Toolbar
      sx={{
        pt: 2,
        position: 'sticky',
        top: 0,
        justifyContent: 'space-between',
        background: '#EFEFEF',
        zIndex: 999,
      }}
    >
      {!allowBack && (
        <Box
          sx={{
            pl: { xs: 0, sm: '32px', md: '5%' },
            width: { sm: '50%', md: '80%' },
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {existsTitleIcon && (
            <Icon sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>{titleIcon}</Icon>
          )}
          <Typography variant="h5" fontWeight={700} sx={{ fontSize: { xs: '1rem', sm: '1.5rem' } }}>
            {title}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          width: allowBack ? '100%' : { sm: '50%', md: '20%' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 1,
          flexDirection: { xs: currentStep?.index !== 0 ? 'column-reverse' : '', sm: 'row' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            width: { xs: allowBack ? '100%' : 'fit-content', sm: 'fit-content' },
            justifyContent: { xs: 'space-between', sm: 'end' },
          }}
        >
          {allowBack && (
            <LoadingButton
              startIcon={<Icon>chevron_left</Icon>}
              onClick={async () => {
                if (currentStep !== undefined) {
                  changeStepIndex(currentStep.index - 1)
                }
              }}
              component={motion.div}
              variant="contained"
              initial={{
                x: -300,
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
              }}
              sx={{
                px: 2,
                pb: 1,
                mr: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {'Paso anterior'}
            </LoadingButton>
          )}
          {!hideButton && (
            <LoadingButton
              disabled={loading}
              loading={loading}
              endIcon={<Icon>chevron_right</Icon>}
              onClick={async () => {
                await handleBtnContinue()
              }}
              component={motion.div}
              variant="contained"
              initial={{
                x: -300,
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
              }}
              sx={{
                px: 2,
                pb: 1,
                mr: { xs: 0, sm: 1 },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {'Continuar'}
            </LoadingButton>
          )}
        </Box>
        <Box
          sx={{
            alignSelf: 'flex-end',
          }}
        >
          <ShoppingCart
            hideButton={true}
            cart={cartProducts}
            nonEmptyCart={nonEmptyCart}
            noDeleteInPickProduct={noDeleteInPickProduct}
            handleBtnContinue={handleBtnContinue}
          />
        </Box>
      </Box>
    </Toolbar>
  )
}
