import { LoadingButton } from '@mui/lab'
import { Card, Divider, Icon, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { motion } from 'framer-motion'
import { useCart, useFeedback } from 'hooks'
import { getProductsByIdService, LEGACY_TYPE, QUINDEE_RECIBIDOS, QUINDEE_SOLUTION } from 'lib'
import { ISolutionProduct } from 'marketplace-common'
import React from 'react'
import { SOLUTION } from 'types'
type Props = {
  expiredProduct: ISolutionProduct
  showRenewButton: boolean
}
export const SummaryExpiredCard = (props: Props) => {
  const { expiredProduct, showRenewButton: showRenewButtonProps } = props
  const { showMessage } = useFeedback()
  const { handleRenewProduct, allProductEnabled } = useCart()
  const [isLegacy, setIsLegacy] = React.useState(false)

  const handleLegacyProductType = async (productId: number) => {
    try {
      const productInformation = await getProductsByIdService(productId)
      const { productTypeName, mainSolutionName } = productInformation
      const isLegacy =
        (productTypeName === LEGACY_TYPE && mainSolutionName === QUINDEE_SOLUTION) ||
        productTypeName === QUINDEE_RECIBIDOS
      setIsLegacy(isLegacy)
    } catch (error) {
      showMessage('No se encontró el producto', 'error')
    }
  }

  const showRenewButton = React.useMemo(() => {
    if (!allProductEnabled || allProductEnabled.length === 0) {
      return false
    }
    const existProductActive = allProductEnabled.some(
      (productInfo) => expiredProduct.productId === productInfo.productId
    )
    if (!existProductActive) {
      return false
    }

    return showRenewButtonProps
  }, [allProductEnabled, expiredProduct, showRenewButtonProps])

  React.useEffect(() => {
    if (expiredProduct) {
      handleLegacyProductType(expiredProduct.productId)
    }
  }, [expiredProduct])

  return (
    <Card
      sx={{
        px: 1,
        py: 2,
        minWidth: 200,
        flex: 1,
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgba(255,255,255,0.5)',
        justifyContent: 'space-between',
        height: '100%',
        minHeight: '100%',
      }}
      elevation={0}
      component={motion.div}
      animate={{
        opacity: 1,
        transition: { delay: 0.15 * Math.random() },
        scale: 1,
      }}
      initial={{
        opacity: 0,
        scale: 1.1 * Math.random(),
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant="caption"
          fontWeight={600}
          fontSize={{ xs: '0.8rem', sm: '1.1rem' }}
          pl={1.4}
          lineHeight={1}
        >
          {expiredProduct.name}
        </Typography>
        {showRenewButton && (
          <LoadingButton
            sx={{ height: 'fit-content', minWidth: 'auto' }}
            onClick={() => handleRenewProduct(expiredProduct.productId)}
          >
            {'Renovar'}
            <Icon sx={{ pl: '2px' }}>{'restore_icon'}</Icon>
          </LoadingButton>
        )}
      </Box>
      <Divider />

      {expiredProduct.mainSolutionId === SOLUTION.NUNA_KEY && (
        <Typography
          variant="subtitle2"
          lineHeight={1.5}
          fontWeight={450}
          fontSize={{ xs: '0.7rem', sm: 12 }}
          textAlign={'center'}
        >
          <strong>
            "Un asesor se comunicara contigo en breves, o puedes comunicarte con soporte si tienes
            alguna duda"
          </strong>
        </Typography>
      )}
      {isLegacy && (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <strong>{'Licencia gratuita'}</strong>
        </Box>
      )}
      {!isLegacy && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'nowrap',
            fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.82rem' },
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              paddingLeft: 1,
            }}
            height={'39px'}
            alignItems={'center'}
          >
            <Typography
              variant="caption"
              fontWeight={400}
              fontSize={{ xs: '0.8rem', sm: '0.9rem' }}
              color={'red'}
            >
              <strong>Expiro el</strong>
            </Typography>
            <Typography
              variant="caption"
              fontWeight={400}
              fontSize={{ xs: '0.8rem', sm: '0.9rem' }}
              color={'red'}
            >
              <strong>{expiredProduct.expirationDate.substring(0, 10)}</strong>
            </Typography>
          </Box>
        </Box>
      )}
    </Card>
  )
}
