import { Box, Icon, IconButton, Paper, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import UploadAnimation from 'assets/animations/upload_animation.json'
import { AnimatePresence } from 'framer-motion'
import { useFeedback } from 'hooks'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import Lottie from 'react-lottie'
type Props = {
  typeDescription: string
  onFileAdded: (file?: File) => void
  file?: File
  title: string
  havePdf?: boolean
}

export const validateExtencionSignature = (value: string) => {
  const regexExtencionSignature = /\.(png|jpg|jpeg|JPG|JPEG|PNG)$/
  return regexExtencionSignature.test(value)
}

export const UploadImage = (props: Props) => {
  const { typeDescription, onFileAdded, file, title } = props
  const [image, setImage] = React.useState<string | null>(null)
  const { palette } = useTheme()
  const { showMessage } = useFeedback()

  const handleImagenChange = (file: File) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        if (e.target) {
          setImage(e.target.result as string)
        }
      }
      reader.readAsDataURL(file)
    }
  }

  const onDrop = (acceptedFiles: any) => {
    if (Array.isArray(acceptedFiles)) {
      const file = acceptedFiles as File[]
      if (validateExtencionSignature(file[0].name)) {
        onFileAdded(file[0])
        handleImagenChange(file[0])
        return
      } else {
        showMessage(
          `Recuerde que el tipo de archivo que debe subir es de tipo: ${typeDescription}`,
          'error'
        )
      }
    }
  }

  const { getRootProps, getInputProps} = useDropzone({ onDrop })

  return (
    <Paper
      elevation={3}
      sx={{
        height: '100%',
        width: '100%',
        minWidth: '170px',
        borderRadius: '10px',
        background: palette.primary.main,
        color: '#ffffff',
        cursor: 'pointer',
      }}
    >
      <Box sx={{ p: 1.3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography color="white" fontWeight="600" fontSize={{ xs: '0.8em', sm: '0.9em' }}>
            {title}
          </Typography>
          {file && (
            <Tooltip title={'Eliminar Comprobante'}>
              <IconButton onClick={() => onFileAdded(undefined)}>
                <Icon sx={{ color: '#D93921' }}>delete</Icon>
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Box>
          {!file && (
            <React.Fragment>
              <input multiple={false} {...getInputProps()} />
              <Stack
                {...getRootProps()}
                alignItems="center"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                borderRadius={4}
              >
                <Tooltip title={'Haga click para subir el comprobante'} arrow>
                  <Box>
                    <Lottie
                      options={{
                        animationData: UploadAnimation,
                        loop: true,
                      }}
                      width={150}
                      height={150}
                    />
                  </Box>
                </Tooltip>
              </Stack>
            </React.Fragment>
          )}

          <AnimatePresence>
            {file && (
              <Box
                component={'img'}
                sx={{
                  width: '100%',
                  height: '152px',
                  borderRadius: '7px',
                  objectFit: 'cover',
                }}
                loading="lazy"
                src={image as string}
              />
            )}
          </AnimatePresence>
        </Box>
      </Box>
    </Paper>
  )
}
