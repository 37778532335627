import { Box, Skeleton } from '@mui/material'
import React from 'react'

export const OuletSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 1.6,
        gap: 2,
      }}
    >
      {Array.from({ length: 1 }).map((_, index) => (
        <React.Fragment
          key={Math.random() + index + Math.random() + Math.random() + Math.random() * Math.random()}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 1.5,
              alignItems: 'center',
              width: '100%',
              mt: 10,
            }}
          >
            <Skeleton              
              variant="rectangular"
              sx={{ maxWidth: 900, width: '80%', height: 20, borderRadius: 3 }}
            />

            <Skeleton              
              variant="rectangular"
              sx={{ maxWidth: 900, width: '80%', height: 350, borderRadius: 3 }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              sx={{
                width: '80%',
                display: 'flex',
                justifyContent: 'flex-end',
                borderRadius: 3,
              }}
            >
              <Skeleton                
                variant="rectangular"
                sx={{
                  maxWidth: 400,
                  width: '100%',
                  height: 40,
                  borderRadius: 3,
                }}
              />
            </Box>
          </Box>
        </React.Fragment>
      ))}
    </Box>
  )
}
