import { Snackbar, Alert, AlertTitle, Typography, Stack, Button } from '@mui/material'
import { useSupport } from 'hooks'
import React from 'react'

type Props = {
  transactionAdministratorComment: string
  isDbMethod: boolean
}

export const TransactionAlert = (props: Props) => {
  const { transactionAdministratorComment, isDbMethod } = props
  const { openChat } = useSupport()
  const [open, setOpen] = React.useState(true)

  const handleRequestHelp = () => {
    const message = 'Hola necesito ayuda, con mi pago por tarjeta.'
    openChat(message)
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={null}
      onClose={(_, reason) => {
        if (reason !== 'clickaway') {
          setOpen(false)
        }
      }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Alert
        severity="error"
        sx={{ width: '100%' }}
        onClose={() => {
          setOpen(false)
        }}
      >
        <AlertTitle>
          <Typography variant="caption" fontWeight={700} fontSize="0.8em" sx={{ opacity: 0.75 }}>
            {'Lo sentimos...'}
          </Typography>
        </AlertTitle>
        <Stack spacing={1}>
          <Typography variant="caption" fontWeight={600} sx={{ opacity: 0.85 }}>
            {'Tu transacción a sido rechazada'}
          </Typography>
          {!isDbMethod && (
            <Typography variant="caption" color={'error'} fontWeight={600} fontSize={15}>
              {`Motivo: `}{' '}
              <strong style={{ color: '#000' }}>{transactionAdministratorComment}</strong>
            </Typography>
          )}

          <Typography variant="caption" sx={{ opacity: 0.85, maxWidth: 300 }}>
            {
              'Puedes volver a intentarlo de nuevo en cualquier momento con cualquiera de nuestros metodos de pago'
            }
          </Typography>
          <Stack direction={'row'} spacing={2} py={2} alignItems="center">
            <Typography variant="caption" fontWeight={500}>
              {'Si esto es un error'}
            </Typography>
            <Button variant="outlined" color="error" onClick={handleRequestHelp}>
              {'Ponte en contacto con nosotros'}
            </Button>
          </Stack>
        </Stack>
      </Alert>
    </Snackbar>
  )
}
