import { fieldValidationRuc } from 'utils'

export const PROVINCES_NUMBER = 24
export const IDENTIFICATION_CARD_LENGTH = 10
export const RUC_LENGTH = 13
export const PASSPORT_LENGTH_MIN = 2
export const PASSPORT_LENGTH_MAX = 20
export const OUTSIDE_ID_LENGTH_MIN = 2
export const OUTSIDE_ID_LENGTH_MAX = 15

export const validateDocument = (document: string): boolean => {
  let validDocument: boolean = true
  try {
    let addition: number = 0
    let residue: number = 0
    let isPrivate: boolean = false
    let isPublic: boolean = false
    let isNatural: boolean = false
    let verifiyingDigit = 0
    let module: number = 11
    let d1: number = 0
    let d2: number = 0
    let d3: number = 0
    let d4: number = 0
    let d5: number = 0
    let d6: number = 0
    let d7: number = 0
    let d8: number = 0
    let d9: number = 0
    let d10: number = 0
    let p1: number = 0
    let p2: number = 0
    let p3: number = 0
    let p4: number = 0
    let p5: number = 0
    let p6: number = 0
    let p7: number = 0
    let p8: number = 0
    let p9: number = 0

    if (document.length < 10) {
      validDocument = false
    }

    // Los primeros dos digitos corresponden al codigo de la province
    let province = parseInt(document.substring(0, 2))

    if (province <= 0 || province > PROVINCES_NUMBER) {
      validDocument = false
    }

    // Aqui almacenamos los digitos de la cedula en variables.
    d1 = parseInt(document.substring(0, 1))
    d2 = parseInt(document.substring(1, 2))
    d3 = parseInt(document.substring(2, 3))
    d4 = parseInt(document.substring(3, 4))
    d5 = parseInt(document.substring(4, 5))
    d6 = parseInt(document.substring(5, 6))
    d7 = parseInt(document.substring(6, 7))
    d8 = parseInt(document.substring(7, 8))
    d9 = parseInt(document.substring(8, 9))
    d10 = parseInt(document.substring(9, 10))

    // El tercer digito es:
    // 9 para sociedades privadas y extranjeros
    // 6 para sociedades publicas
    // menor que 6 (0,1,2,3,4,5) para personas naturales
    if (d3 === 7 || d3 === 8) {
      validDocument = false
    }

    // Solo para personas naturales (modulo 10)
    if (d3 < 6) {
      isNatural = true
      module = 10
      p1 = d1 * 2
      if (p1 >= 10) {
        p1 -= 9
      }
      p2 = d2 * 1
      if (p2 >= 10) {
        p2 -= 9
      }
      p3 = d3 * 2
      if (p3 >= 10) {
        p3 -= 9
      }
      p4 = d4 * 1
      if (p4 >= 10) {
        p4 -= 9
      }
      p5 = d5 * 2
      if (p5 >= 10) {
        p5 -= 9
      }
      p6 = d6 * 1
      if (p6 >= 10) {
        p6 -= 9
      }
      p7 = d7 * 2
      if (p7 >= 10) {
        p7 -= 9
      }
      p8 = d8 * 1
      if (p8 >= 10) {
        p8 -= 9
      }
      p9 = d9 * 2
      if (p9 >= 10) {
        p9 -= 9
      }
    }

    // Solo para sociedades publicas (module 11)
    // Aqui el digito verficador esta en la posicion 9, en las otras 2
    // en la pos. 10
    if (d3 === 6) {
      isPublic = true
      p1 = d1 * 3
      p2 = d2 * 2
      p3 = d3 * 7
      p4 = d4 * 6
      p5 = d5 * 5
      p6 = d6 * 4
      p7 = d7 * 3
      p8 = d8 * 2
      p9 = 0
    }

    /* Solo para entidades privadas (module 11) */
    if (d3 === 9) {
      isPrivate = true
      p1 = d1 * 4
      p2 = d2 * 3
      p3 = d3 * 2
      p4 = d4 * 7
      p5 = d5 * 6
      p6 = d6 * 5
      p7 = d7 * 4
      p8 = d8 * 3
      p9 = d9 * 2
    }

    addition = p1 + p2 + p3 + p4 + p5 + p6 + p7 + p8 + p9
    residue = addition % module

    // Si residue=0, dig.ver.=0, caso contrario 10 - residue
    verifiyingDigit = residue === 0 ? 0 : module - residue
    let length = document.length // Longitud del string

    // ahora comparamos el elemento de la posicion 10 con el dig. ver.
    if (isPublic === true) {
      if (verifiyingDigit !== d9) {
        validDocument = false
      }
      /* El ruc de las empresas del sector publico terminan con 0001 */
      if (document.substring(9, length) !== '0001') {
        validDocument = false
      }
    }

    if (isPrivate === true) {
      if (verifiyingDigit !== d10) {
        //document.setText("")
        validDocument = false
      }
      if (document.substring(10, length) !== '001') {
        //document.setText("")
        validDocument = false
      }
    }

    if (isNatural === true) {
      if (verifiyingDigit !== d10) {
        validDocument = false
      }
      if (document.length > 10 && document.substring(10, length) !== '001') {
        validDocument = false
      }
    }
  } catch (error) {
    validDocument = false
  }
  return validDocument
}

export const validateIdentificationCard = (idCard: string): boolean => {
  return /* validateDocument(idCard) && */ idCard.length === IDENTIFICATION_CARD_LENGTH
}

export const validateSingleRegistryOfTaxpayers = (ruc: string): boolean => {
  return ruc.length === RUC_LENGTH && fieldValidationRuc(ruc)
}

export const validatePassport = (id: string): boolean => {
  return id.length >= PASSPORT_LENGTH_MIN && id.length <= PASSPORT_LENGTH_MAX
}

export const validateOutSideId = (id: string): boolean => {
  return id.length >= OUTSIDE_ID_LENGTH_MIN && id.length <= OUTSIDE_ID_LENGTH_MAX
}
