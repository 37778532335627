import { LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterDateFns'
import { ThemeProvider } from '@mui/material'
import {
  AuthProvider,
  CartProvider,
  FeedbackProvider,
  LoadingProvider,
  NavigationProvider,
  SupportProvider,
  TransactionLicenseProvider,
} from 'context'
import { es } from 'date-fns/locale'
import React from 'react'
import Routes from 'routes'
import { mainTheme } from 'styles'
import { initializeGoogleAnalytics } from 'utils'

const App = () => {
  React.useEffect(() => {
    initializeGoogleAnalytics()
  }, [])

  console.log(`${process.env.REACT_APP_VERSION} version`)
  return (
    <ThemeProvider theme={mainTheme}>
      <LocalizationProvider dateAdapter={DateAdapter} locale={es}>
        <NavigationProvider>
          <AuthProvider>
            <FeedbackProvider>
              <SupportProvider>
                <LoadingProvider>
                  <CartProvider>
                    <TransactionLicenseProvider>
                      <Routes />
                    </TransactionLicenseProvider>
                  </CartProvider>
                </LoadingProvider>
              </SupportProvider>
            </FeedbackProvider>
          </AuthProvider>
        </NavigationProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
