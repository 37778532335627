import { LoadingButton } from '@mui/lab'
import { Box, Icon, Skeleton, Tooltip, Typography } from '@mui/material'
import { DetailDialog, ExploreIndicator, LicencesDialog, TransactionSummary } from 'components'
import { useAuth, useTransaction } from 'hooks'
import { getProductsByProcessIdService } from 'lib'
import { ITransaction } from 'marketplace-common'
import React from 'react'
import { ICartProduct } from 'marketplace-common'
import { TransactionStateId } from 'types'

export type filterType = 'complete' | 'others'

export const UserTransactions = () => {
  const { user } = useAuth()
  const { transactions, getTransactionsByCompanyId, loading } = useTransaction()
  const [openDetail, setOpenDetail] = React.useState<boolean>(false)
  const [openLicences, setOpenLicences] = React.useState<boolean>(false)
  const [selectedTransaccion, setSelectedTransaccion] = React.useState<ITransaction>()
  const [processId, setProcessId] = React.useState<number | undefined>()
  const [filter, setFilter] = React.useState<filterType>('complete')
  const [products, setProducts] = React.useState<ICartProduct[]>()

  const handleOpenDialog = (dialog: string) => {
    if (dialog === 'Detail') {
      setOpenDetail(true)
    } else if (dialog === 'Licences') {
      setOpenLicences(true)
    }
  }

  const handleCloseEditDialog = (dialog: string) => {
    if (dialog === 'Detail') {
      setOpenDetail(false)
    } else if (dialog === 'Licences') {
      setOpenLicences(false)
    }
  }

  const getProducts = async (processId: number) => {
    try {
      const allProducts = await getProductsByProcessIdService(processId)
      setProducts(allProducts)
    } catch (error) {
      console.log('error', error)
    }
  }

  React.useEffect(() => {
    if (processId) {
      getProducts(processId)
    }
  }, [processId])

  React.useEffect(() => {
    const existsUserId: boolean = Boolean(user.identification)
    if (!existsUserId) return
    getTransactionsByCompanyId(user.identification)
  }, [])

  const transactionsFilterAndSort = React.useMemo(() => {
    if (filter === 'complete') {
      return transactions
        .filter(
          (transaction) => transaction.state.transactionStateId === TransactionStateId.COMPLETADO
        )
        .sort((a, b) => b.transactionId - a.transactionId)
    } else {
      return transactions
        .filter(
          (transaction) =>
            transaction.state.transactionStateId === TransactionStateId.PENDIENTE ||
            transaction.state.transactionStateId === TransactionStateId.RECHAZADO ||
            transaction.state.transactionStateId === TransactionStateId.POR_REVERTIR ||
            transaction.state.transactionStateId === TransactionStateId.REVERTIDO
        )
        .sort((a, b) => b.transactionId - a.transactionId)
    }
  }, [filter, transactions])

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography variant="h5" sx={{ textAlign: 'center', marginY: '20px', fontWeight: 600 }}>
        <Icon sx={{ mr: '5px', pt: '4px', fontSize: 'x-large' }}>payments</Icon>
        {'Mis Pagos'}
      </Typography>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 2, lg: 1.4 },
          p: 2,
          pr: { xs: 2, lg: 3 },
          alignItems: 'center',
          justifyContent: { xs: 'center', lg: 'flex-end' },
        }}
      >
        <Tooltip title="Pagos completos" arrow>
          <LoadingButton
            endIcon={<Icon>check</Icon>}
            loading={loading}
            variant={filter === 'complete' ? 'contained' : 'outlined'}
            loadingIndicator="..."
            sx={{
              width: { xs: '100%', sm: 160 },
              paddingY: 1,
              fontSize: { xs: 14, sm: 12 },
            }}
            onClick={() => setFilter('complete')}
          >
            Pagos Completos
          </LoadingButton>
        </Tooltip>
        <Tooltip title="Pagos pendientes, rechazados, por revertir, revertidos" arrow>
          <LoadingButton
            endIcon={<Icon>access_time</Icon>}
            loading={loading}
            variant={filter === 'others' ? 'contained' : 'outlined'}
            loadingIndicator="..."
            sx={{
              width: { xs: '100%', sm: 160 },
              paddingY: 1,
            }}
            onClick={() => setFilter('others')}
          >
            Otros
          </LoadingButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          height: '100%',
          diplay: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          py: 4,
          px: 2,
        }}
      >
        <Box
          sx={{
            display: 'grid',
            width: '100%',
            justifyContent: 'center',
            justifyItems: 'center',
            alignItems: 'center',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: 2,
            pt: 1,
            '&:only-child': {
              justifySelf: 'center',
            },
          }}
        >
          {loading &&
            Array.from(Array(3).keys()).map((index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                sx={{ width: '100%', maxWidth: { sm: '330px' }, height: 228 }}
              />
            ))}
          {!loading &&
            transactionsFilterAndSort.map((transaction) => (
              <TransactionSummary
                key={transaction.transactionId}
                transaction={transaction}
                onDetails={(selected) => setSelectedTransaccion(selected)}
                handleOpenDialog={handleOpenDialog}
                processIdSelected={(selected) => setProcessId(selected)}
                filter={filter}
              />
            ))}
          <Box
            sx={{
              mt: '-20px',
              width: '100%',
              minWidth: '100%',
            }}
          >
            {transactionsFilterAndSort.length === 0 && !loading && (
              <Box paddingBottom={{ xs: 12, sm: 1 }}>
                <ExploreIndicator />
              </Box>
            )}
          </Box>
        </Box>
        <DetailDialog
          open={openDetail}
          transaction={selectedTransaccion}
          handleClose={handleCloseEditDialog}
        />

        {products !== undefined && (
          <LicencesDialog
            open={openLicences}
            products={products}
            handleClose={handleCloseEditDialog}
          />
        )}
      </Box>
    </Box>
  )
}

export { UserTransactions as default} from './User'