import { Stack } from '@mui/material'
import { SignUpForm } from 'components'

export const SignUp = () => {
  return (
    <Stack
      px={{ xs: 3, sm: 5, md: 5, lg: 5, xl: 5 }}
      pt={{ xs: 1.5, sm: 2, md: 2, lg: 2.5, xl: 1 }}
      height="100%"
      id="singUp"
    >
      <SignUpForm />
    </Stack>
  )
}
export { SignUp as default } from './SignUp'
