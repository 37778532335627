import { RequireAuth, SalesForm } from 'components'
import { ConfigurationLayout, RequestLayout } from 'layouts'
import { ROUTES } from 'lib'

import React, { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const UserLayout = React.lazy(() => import('layouts/UserLayout'))
const MarketplaceLayout = React.lazy(() => import('layouts/MarketplaceLayout'))
const ContactsLayout = React.lazy(() => import('layouts/ContactsLayout'))

const UserSolutions = React.lazy(() => import('pages/Solutions/User'))
const UserProcesses = React.lazy(() => import('pages/Processes/User'))
const UserTransactions = React.lazy(() => import('pages/Transactions/User'))
const Refunds = React.lazy(() => import('pages/Refunds/Refunds'))
const RefundForm = React.lazy(() => import('components/refund/Form'))
const Account = React.lazy(() => import('pages/Configuration/Account'))

const UserRoutes: RouteObject = {
  path: ROUTES.none,
  element: <RequireAuth />,
  children: [
    {
      path: ROUTES.user,
      element: (
        <Suspense fallback={<></>}>
          <UserLayout />
        </Suspense>
      ),
      children: [
        {
          path: ROUTES.marketplace,
          element: (
            <Suspense fallback={<></>}>
              <MarketplaceLayout />
            </Suspense>
          ),
          children: [
            {
              path: ROUTES.solutions,
              element: (
                <Suspense fallback={<></>}>
                  <UserSolutions />
                </Suspense>
              ),
            },
            {
              path: ROUTES.processes,
              element: (
                <Suspense fallback={<></>}>
                  <UserProcesses />
                </Suspense>
              ),
            },
            {
              path: ROUTES.payments,
              element: (
                <Suspense fallback={<></>}>
                  <UserTransactions />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: ROUTES.requests,
          element: <RequestLayout />,
          children: [
            {
              path: ROUTES.refunds,
              element: (
                <Suspense fallback={<></>}>
                  <Refunds />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: ROUTES.contacts,
          element: (
            <Suspense fallback={<></>}>
              <ContactsLayout />
            </Suspense>
          ),
          children: [
            {
              path: ROUTES.requestRefund,
              element: (
                <Suspense fallback={<></>}>
                  <RefundForm />
                </Suspense>
              ),
            },
            {
              path: ROUTES.salesTeam,
              element: <SalesForm />,
            },
          ],
        },
        {
          path: ROUTES.configuration,
          element: <ConfigurationLayout />,
          children: [
            {
              path: ROUTES.account,
              element: (
                <Suspense fallback={<></>}>
                  <Account />
                </Suspense>
              ),
            },
          ],
        },
      ],
    },
  ],
}

export default UserRoutes
