import { ThemeProvider } from '@mui/material'
import React from 'react'
import { mainTheme, SOLUTION_THEMES } from 'styles'
import { ISolutionReference } from 'marketplace-common'

type Props = {
  changeTheme: (ref: ISolutionReference) => void
}

export const SolutionThemeContext = React.createContext({} as Props)

const SolutionThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = React.useState(mainTheme)

  /**Changes the theme based on a solution reference */
  const changeTheme = React.useCallback((ref: ISolutionReference) => {
    setTheme(SOLUTION_THEMES[ref])
  }, [])

  return (
    <SolutionThemeContext.Provider
      value={{
        changeTheme,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </SolutionThemeContext.Provider>
  )
}

export default SolutionThemeProvider
