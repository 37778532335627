import axios from 'axios'
import { useFeedback } from 'hooks/feedback/useFeedback'
import { CHECKOUT_PATH, DATAWEB_PATH, checkoutDatawebTransactionService, getIpClient } from 'lib'
import { requestInterceptorErrorSecurityAdministration, requestInterceptorSuccessAdministration } from 'lib/api/administration.interceptor'
import React from 'react'
export interface IDatawebCreateCheckoutRequest {
  processId: number
}

type Hook = {
  loading: boolean
  checkout: IDatawebCheckout | undefined
  error: string | undefined
  initCheckout: (checkoutData: IDatawebCreateCheckoutRequest) => Promise<void>
  handleCallback: (id: string, processId: number) => Promise<void>
}

export interface IDatawebCheckout {
  result: IDatawebResult
  buildNumber: string
  timestamp: string
  ndc: string
  id: string
}

export interface IDatawebResult {
  code: string
  description: string
}


const instance = axios.create({
  baseURL: process.env.REACT_APP_ADMINISTRATION_BASE_API_URL,
})

instance.interceptors.request.use(
  requestInterceptorSuccessAdministration,
  requestInterceptorErrorSecurityAdministration
)
export const useDataweb = (): Hook => {
  const [checkout, setCheckout] = React.useState<IDatawebCheckout | undefined>()
  const [lastCheckoutData, setLastCheckoutData] = React.useState<
    IDatawebCreateCheckoutRequest | undefined
  >()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState<string | undefined>()
  const { showMessage } = useFeedback()

  /**
   * @description create a script to build the form to pay with checkout id provided by dataweb
   * @param id @type{string} id of checkout
   */
  const buildFormToPay = (checkoutId: string) => {
    const script = document.createElement('script')
    script.src = `${process.env.REACT_APP_DATAWEB_CHECKOUT_WIDGETS_API_URL}${checkoutId}`
    document.body.appendChild(script)
  }

  /***
   * @description Call ws to create checkout id , this id only provide dataweb, and with this id we can build the form to pay
   * @param checkoutData @type{IDatawebCreateCheckoutRequest} date to create checkout
   *
   */
  const initCheckout = async (checkoutData: IDatawebCreateCheckoutRequest) => {
    setLoading(true)
    setLastCheckoutData(checkoutData)
    try {
      const ip = (await getIpClient()) as string

      const result = await instance.post(`${DATAWEB_PATH}/${CHECKOUT_PATH}`, {...checkoutData,ip})
      const checkoutResult: IDatawebCheckout = result.data
      if (result.status === 100) {
        return 
      }
      setCheckout(checkoutResult)

      buildFormToPay(checkoutResult.id)
    } catch (error) {
      console.log(JSON.stringify(error))
      showMessage(
        'Error al conectar con la pasarela de pagos comunícate con soporte o inténtalo luego',
        'error'
      )
      setError('Error')
    } finally {
      setLoading(false)
    }
  }

  const handleCallback = React.useCallback(
    async (id, processId) => {
      try {
        setLoading(true)
        await checkoutDatawebTransactionService({ id, processId })
      } catch (error: any) {
        if (lastCheckoutData) {
          //           await initCheckout(lastCheckoutData)
        }
        throw new Error(error.message ?? '')
      } finally {
        setLoading(false)
      }
    },
    [lastCheckoutData]
  )

  return {
    checkout,
    error,
    loading,
    initCheckout,
    handleCallback,
  }
}
