import { getProcessesByCompanyIdService } from 'lib'
import React from 'react'
import { IProcess } from 'marketplace-common'

type Hook = {
  processes: IProcess[]
  loading: boolean
  refresh: () => void
}

export const useGetProcesses = (companyId?: string): Hook => {
  const [processes, setProcesses] = React.useState<IProcess[]>([])
  const [loading, setLoading] = React.useState(true)

  const getProcessesByCompanyId = React.useCallback(async () => {
    if (companyId) {
      try {
        const result: IProcess[] = await getProcessesByCompanyIdService(companyId)
        /**Parse the information prop cause the service send it as a string, but actualy is a json */
        setProcesses(result)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
  }, [companyId])

  const refresh = React.useCallback(() => {
    getProcessesByCompanyId()
  }, [])

  React.useEffect(() => {
    getProcessesByCompanyId()
  }, [getProcessesByCompanyId])

  return {
    processes,
    loading,
    refresh,
  }
}
