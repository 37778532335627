import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material'
import Lottie from 'react-lottie'
import verified from 'assets/animations/verified.json'
import { Link } from 'react-router-dom'
import { ROUTES } from 'lib'

export const AccountVerified = () => {
  return (
    <Grid container p={{ xs: 4, sm: 8 }} justifyContent="center" alignItems="center" height="100%">
      <Grid>
        <Paper
          sx={{
            py: 4,
            px: 8,
          }}
          elevation={16}
        >
          <Stack spacing={3} alignItems="center">
            <Typography fontWeight={600} variant="h5">
              {'Listo!'}
            </Typography>
            <Typography
              fontWeight={500}
              color="textSecondary"
              variant="body1"
              sx={{ maxWidth: 300 }}
              align="center"
            >
              {`Tu cuenta a sido validada`}
            </Typography>
            <Box>
              <Lottie
                options={{
                  animationData: verified,
                  loop: true,
                }}
                width={180}
                height={180}
              />
            </Box>
            <Typography
              fontWeight={500}
              variant="caption"
              sx={{ maxWidth: 300 }}
              align="center"
              color="textSecondary"
            >
              {`Ya puedes utilizar marketplace`}
            </Typography>
            <Stack pt={2} spacing={2}>
              <Link to={`/${ROUTES.login}`} replace>
                <Button>{'Iniciar sesión'}</Button>
              </Link>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  )
}

export { AccountVerified as default} from './AccountVerified'