import React, { useCallback, useMemo } from 'react'
import { Descendant, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, Slate, withReact } from 'slate-react'
import { ElementSlate } from './components/ElementSlate'
import { Leaf } from './components/Leaf'
import { Box } from '@mui/material'

type Props = {
  initialValue?: string
  /**
   * Array de fuentes que se usaran en el editor se debe enviar el nombre de la fuente como se encuentra en google fonts
   */
  usedFonts: string[] | string
}
export const TextEditorViewer = (props: Props) => {
  const { initialValue, usedFonts } = props
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  React.useEffect(() => {
    if (!initialValue) return
    editor.children = JSON.parse(initialValue!)
  }, [initialValue])

  const renderElement = useCallback(
    (props: { attributes: any; children: React.ReactNode; element: any }) => (
      <ElementSlate {...props} />
    ),
    []
  )
  const renderLeaf = useCallback(
    (props: { attributes: any; children: React.ReactNode; leaf: any }) => <Leaf {...props} />,
    []
  )
  return (
    <Slate
      editor={editor}
      value={initialValue ? (JSON.parse(initialValue) as Descendant[]) : ([] as Descendant[])}
      onChange={() => {}}
    >
      {Array.isArray(usedFonts) &&
        (usedFonts as string[]).map((font: string) => (
          <Box
            key={font + Math.random()}
            component={'link'}
            rel="stylesheet"
            href={`https://fonts.googleapis.com/css2?family=${font}&display=swap`}
          />
        ))}
      {usedFonts &&
        !Array.isArray(usedFonts) &&
        JSON.parse(usedFonts).map((font: string) => (
          <Box
            key={font + Math.random()}
            component={'link'}
            rel="stylesheet"
            href={`https://fonts.googleapis.com/css2?family=${font}&display=swap`}
          />
        ))}
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        id="editable-slate"
        style={{
          width: 'inherit',
        }}
        readOnly={true}
      />
    </Slate>
  )
}
