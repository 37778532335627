import { createTheme, ThemeOptions } from "@mui/material/styles"
import { MAIN_COLORS, SOLUTION_COLORS } from "../colors/colors"
import type { } from '@mui/lab/themeAugmentation'
import { DEFAULT_BREACKPOINTS } from "styles"

declare module "@mui/material/styles" {
    interface Palette {
        alt?: { background: string }
    }
    interface PaletteOptions {
        alt?: { background: string }
    }

}

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: SOLUTION_COLORS.administration.primary.main,
        },
        info: {
            main: SOLUTION_COLORS.administration.primary.main,
        },
        alt: {
            background: SOLUTION_COLORS.administration.primary.main,
        },
        success: {
            main: MAIN_COLORS.success.main
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "50vw",
                    textTransform: "none",
                    fontWeight: 600
                }
            },
            defaultProps: {
                size: 'small',
                color: 'primary',
                variant: 'contained',
                disableElevation: true,
                disableRipple: true,
            }
        },
        MuiLoadingButton: {
            defaultProps: {
                size: 'small',
                color: 'primary',
                variant: 'contained',
                disableElevation: true,
                disableRipple: true,
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: "outlined",
                size: "small",
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8
                }
            },
            defaultProps: {
                size: "small"
            }
        },
        MuiIcon: {
            defaultProps: {
                fontSize: "small"
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    borderLeft: 'none',
                    borderRight: 'none'
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    borderBottom: `solid 1px ${SOLUTION_COLORS.administration.primary.main}30`,
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    paddingLeft: 30,
                    paddingRight: 30
                }
            }
        }
    },
    typography: {
        fontFamily: 'Quicksand',
        fontSize: 14,
    },
    breakpoints: DEFAULT_BREACKPOINTS
}

const theme = createTheme(themeOptions)

export default theme