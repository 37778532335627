export const encryptParam = (param: string) => {
  return window.btoa(param)
}

export const decryptParam = (param: string) => {
  if (param.includes('%3d') || param.includes('%3D')) {
    const newParam = param.replace('%3d', '').replace('%3D', '')

    return window.atob(newParam)
  }
  return window.atob(
    param
      .replace('%3d', '')
      .replace('%3D', '')
      .replace('==', '')
      .replace('=', '')
      .replace('===', '')
  )
}
