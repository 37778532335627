import { SolutionLayout } from 'layouts'
import { ROUTES } from 'lib'
import React, { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const GetSolutionsPage = React.lazy(() => import('pages/Solutions/Get'))

const ShopRoutes: RouteObject = {
  path: ROUTES.explore,
  element: <SolutionLayout />,
  children: [
    {
      path: ROUTES.solutions,
      element: (
        // <Suspense fallback={<FullscreenLoading />}>
        <Suspense fallback={<></>}>
          <GetSolutionsPage />
        </Suspense>
      ),
    },
  ],
}

export default ShopRoutes
