import { Button, Icon } from '@mui/material'
import { useNavigate as useAnimatedNavigate, useFeedback } from 'hooks'
import { ROUTES } from 'lib'
import { useLocation } from 'react-router-dom'

type Props = {
  applyPadding?: boolean
}
export const ExplorerBtn = (props: Props) => {
  const { applyPadding } = props
  const { showMessage } = useFeedback()
  const { animatedNavigation } = useAnimatedNavigate()
  const location = useLocation()

  const signUpLocation = location.pathname.includes(ROUTES.signUp)
  const loginLocation = location.pathname.includes(ROUTES.login)
  const render = signUpLocation || loginLocation || ROUTES.root

  const handleExplore = async () => {
    try {
      animatedNavigation(`${ROUTES.explore}/${ROUTES.solutions}`, 'circle')
    } catch (error) {
      showMessage('Para continuar guarda la razón social de tu empresa', 'info')
    }
  }

  return (
    <>
      {render && (
        <Button
          endIcon={<Icon>shopping_bag_outlined</Icon>}
          sx={applyPadding ? { py: '', px: '', lineHeight: 1 } : { py: '10px', px: '80px' }}
          size="medium"
          onClick={handleExplore}
        >
          {'Ver Productos'}
        </Button>
      )}
    </>
  )
}
