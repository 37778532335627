import { styled } from '@mui/material'

export const PersistentContainer = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{
  open?: boolean
  drawerWidth: number
}>(({ theme, open, drawerWidth }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}))
