import { Box, Button, Icon, Stack, Typography } from '@mui/material'
import explore from 'assets/animations/explore.json'
import { useFunctionShop } from 'hooks'
import Lottie from 'react-lottie'

export const ExploreIndicator = () => {
  const { handleContinueShop } = useFunctionShop()

  return (
    <Stack
      width={'100%'}
      height={'100%'}
      position="relative"
      bgcolor={(theme) => `${theme.palette.alt?.background}80`}
    >
      <Stack
        width={'100%'}
        height={'100%'}
        justifyContent="center"
        alignItems="center"
        bgcolor={(theme) => `${theme.palette.alt?.background}80`}
        position={'relative'}
        sx={{ backdropFilter: 'blur(55px)' }}
        spacing={4}
      >
        <Typography variant="h5" fontWeight={600} maxWidth="60%" align="center" pt={{ xs: 3 }}>
          {'¿Todavía no adquieres nuestros productos?'}
        </Typography>
        <Typography
          variant="body1"
          fontWeight={500}
          maxWidth="60%"
          align="center"
          color={'textSecondary'}
        >
          {'Adéntrate en nuestro marketplace y mira lo que tenemos para ofrecerte'}
        </Typography>
        <Lottie
          options={{
            animationData: explore,
            loop: true,
          }}
          width={150}
          height={150}
          style={{ borderRadius: '100%', marginTop: 50 }}
        />
        <Box>
          <Button onClick={handleContinueShop} endIcon={<Icon>navigate_next</Icon>} sx={{ px: 6 }}>
            {'Ver Productos'}
          </Button>
        </Box>
      </Stack>
    </Stack>
  )
}
