import { FullscreenLoading } from 'components'
import { useAuth } from 'hooks'
import { ROUTES } from 'lib'
import React from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

/**
 *
 * @see /context/Auth.tsx For more information check the Auth Context
 *
 * @returns A fullscreen loading if the app is looking an user on the local storage or validating the sesion of the current user.
 * If the user is authenticated returns the component that matches some route (Outlet) else redirects to the login screen.
 */
const LOGIN_ROUTE = `/${ROUTES.login}`
export const RequireAuth = () => {
  const { validatingSession, isAuthenticated } = useAuth()
  const navigate = useNavigate()

  const location = useLocation()

  /**
   * The from property is used to nativate to the desired screen once the user complte the login process
   */

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate(LOGIN_ROUTE, { replace: true })
    }
  }, [isAuthenticated])

  return validatingSession ? (
    <FullscreenLoading />
  ) : isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate state={{ from: location.pathname }} replace to={`/${ROUTES.login}`} />
  )
}
