import { Icon, Skeleton, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTour } from '@reactour/tour'
import { ExploreIndicator, SolutionSummary, TransactionLicenseDialog } from 'components'
import { useAuth, useGetProducts, useGetSolutions, useTransactionLicense } from 'hooks'
import React from 'react'
import { SOLUTION } from 'types'

/**
 *
 * @returns User solutions page
 */

export const UserSolutions = () => {
  const { user, showTutorial } = useAuth()
  const { setIsOpen } = useTour()
  const { loadTransactionLicense, showTransactionLicenseDialog } = useTransactionLicense()
  const { solutions, loading } = useGetSolutions(user.identification)
  const { allProductsToActivate, expiredProducts } = useGetProducts(undefined, user.identification)

  const solutionsOrder: number[] = [
    SOLUTION.QUINDE_FEE,
    SOLUTION.NUNA_KEY,
    SOLUTION.MICO_DOCS,
    SOLUTION.ALPAQQA,
    SOLUTION.QREATIVEE,
  ]

  const handleTransactionLicense = () => {
    const licenseIds = solutions
      .filter(({ products }) => products)
      .map(({ products }) => products.map(({ licenseId }) => licenseId))
      .flat()
      .toString()
    if (licenseIds === '') return
    loadTransactionLicense(licenseIds)
  }

  React.useEffect(() => {
    if (showTutorial && !loading) setIsOpen(showTutorial)
  }, [showTutorial, loading])

  React.useEffect(() => {
    const existsSolution = Boolean(solutions) && solutions.length !== 0
    if (!existsSolution) return

    handleTransactionLicense()
  }, [solutions])

  return (
    <>
      <Typography variant="h5" sx={{ textAlign: 'center', marginY: '20px', fontWeight: 600 }}>
        <Icon sx={{ mr: '5px', pt: '4px', fontSize: 'x-large' }}>apps</Icon>
        {'Mis Productos'}
      </Typography>
      <Stack spacing={3} width={'100%'} pb={4}>
        {solutions.length === 0 && !loading && <ExploreIndicator />}

        {solutions.length === 0 && loading
          ? Array.from({ length: 6 }).map((_, index) => (
              <Box sx={{ pl: 6, display: 'flex', alignItems: 'center', gap: 1.6, pt: 2 }}>
                <Skeleton
                  key={'skeleton' + index}
                  variant="circular"
                  sx={{ width: { xs: 50 }, height: { xs: 50 } }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Skeleton
                    key={'skeleton' + index}
                    variant="text"
                    sx={{ width: { xs: 50 }, height: { xs: 15 } }}
                  />
                  <Skeleton
                    key={'skeleton' + index}
                    variant="text"
                    sx={{ width: { xs: 60 }, height: { xs: 15 } }}
                  />
                </Box>
              </Box>
            ))
          : solutions
              .sort((a, b) => {
                const indexA = solutionsOrder.indexOf(a.mainSolutionId)
                const indexB = solutionsOrder.indexOf(b.mainSolutionId)
                return indexA === -1 ? 1 : indexB === -1 ? -1 : indexA - indexB
              })
              .map((solution) => (
                <SolutionSummary
                  key={solution.mainSolutionId}
                  solution={solution}
                  products={solution.products}
                  profiles={solution.profiles}
                  userId={user.userId}
                  productsToActivate={allProductsToActivate.filter(
                    (product) => product.mainSolutionId === solution.mainSolutionId
                  )}
                  expiredProducts={expiredProducts.filter(
                    (product) => product.mainSolutionId === solution.mainSolutionId
                  )}
                />
              ))}
      </Stack>
      {showTransactionLicenseDialog && <TransactionLicenseDialog />}
    </>
  )
}

export { UserSolutions as default } from './User'
