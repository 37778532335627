import React, { useCallback, useMemo } from 'react'
import { Editable, withReact, Slate, ReactEditor, useSelected } from 'slate-react'
import {
  Editor,
  Transforms,
  createEditor,
  Descendant,
  Element as SlateElement,
  Range,
  BaseEditor,
} from 'slate'
import { withHistory } from 'slate-history'
import isUrl from 'is-url'

import './styles.css'

// const HOTKEYS = {
//     'mod+b': 'bold',
//     'mod+i': 'italic',
//     'mod+u': 'underline',
//     'mod+`': 'code',
// }

// const LIST_TYPES = ['numbered-list', 'bulleted-list']

interface Props {
  placeholder?: string
  value?: Descendant[]
  onChange: (newValue: Descendant[]) => void
  readonly?: boolean
  textColor?: string
}

export type LinkElement = { type: 'link'; url: string; children: Descendant[] }

export const EDITOR_DEFAULT_VALUE = {
  type: 'paragraph',
  children: [{ text: '' }],
}

export const TextEditor = (props: Props) => {
  const { placeholder, value, onChange, readonly, textColor } = props
  const renderElement = useCallback((props) => <Element {...props} />, [])
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])
  const editor = useMemo(
    () => withInlines(withHistory(withReact(createEditor() as ReactEditor))),
    []
  )

  editor.children = value ?? []

  return (
    <Slate
      editor={editor}
      value={value ?? [EDITOR_DEFAULT_VALUE]}
      onChange={(value) => onChange(value)}
    >
      <div style={{ width: '100%' }}>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder ?? 'Enter some rich text…'}
          spellCheck
          className="editor"
          readOnly={readonly}
          style={{ color: textColor }}
          // onKeyDown={event => {
          //     for (const hotkey in HOTKEYS) {
          //         if (isHotkey(hotkey, event as any)) {
          //             event.preventDefault()
          //             const mark = (HOTKEYS as any)[hotkey]
          //             toggleMark(editor, mark)
          //         }
          //     }
          // }}
        />
      </div>
    </Slate>
  )
}

// const toggleBlock = (editor: BaseEditor, format: string) => {
//     const isActive = isBlockActive(editor, format)
//     const isList = LIST_TYPES.includes(format)

//     Transforms.unwrapNodes(editor, {
//         match: (n: any) =>
//             !Editor.isEditor(n) &&
//             SlateElement.isElement(n) &&
//             //@ts-ignore
//             LIST_TYPES.includes(n.type),
//         split: true,
//     })
//     const newProperties: Partial<SlateElement> = {
//         //@ts-ignore
//         type: isActive ? 'paragraph' : isList ? 'list-item' : format,
//     }
//     Transforms.setNodes(editor, newProperties)

//     if (!isActive && isList) {
//         const block = { type: format, children: [] }
//         Transforms.wrapNodes(editor, block)
//     }
// }

// const toggleMark = (editor: BaseEditor, format: string) => {
//     const isActive = isMarkActive(editor, format)

//     if (isActive) {
//         Editor.removeMark(editor, format)
//     } else {
//         Editor.addMark(editor, format, true)
//     }
// }

// const isBlockActive = (editor: BaseEditor, format: string) => {
//     const { selection } = editor
//     if (!selection) return false

//     const [match]: any = Editor.nodes(editor, {
//         at: Editor.unhangRange(editor, selection),
//         match: n =>
//             //@ts-ignore
//             !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
//     })

//     return !!match
// }

// const isMarkActive = (editor: BaseEditor, format: string) => {
//     const marks = Editor.marks(editor)
//     return marks ? (marks as any)[format] === true : false
// }

const Element = ({
  attributes,
  children,
  element,
}: {
  attributes: any
  children: React.ReactNode
  element: any
}) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'link':
      return <LinkComponent {...{ attributes, children, element }} />
    default:
      return (
        <p {...attributes} style={{ margin: 0 }}>
          {children}
        </p>
      )
  }
}

const Leaf = ({
  attributes,
  children,
  leaf,
}: {
  attributes: any
  children: React.ReactNode
  leaf: any
}) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

// const BlockButtons = () => {
//     const editor = useSlate()
//     return (
//         <ToggleButtonGroup>
//             <ToggleButton
//                 value={"numbered-list"}
//                 size="small"
//                 selected={isBlockActive(editor, "numbered-list")}
//                 onMouseDown={event => {
//                     event.preventDefault()
//                     toggleBlock(editor, "numbered-list")
//                 }}
//             >
//                 <Icon fontSize="small">{"format_list_numbered"}</Icon>
//             </ToggleButton>
//             <ToggleButton
//                 value={"bulleted-list"}
//                 size="small"
//                 selected={isBlockActive(editor, "bulleted-list")}
//                 onMouseDown={event => {
//                     event.preventDefault()
//                     toggleBlock(editor, "bulleted-list")
//                 }}
//             >
//                 <Icon fontSize="small">{"format_list_bulleted"}</Icon>
//             </ToggleButton>
//         </ToggleButtonGroup>
//     )
// }

// const MarkButtons = () => {
//     const editor = useSlate()
//     return (
//         <ToggleButtonGroup sx={{ mr: 1 }}>
//             <ToggleButton
//                 size="small"
//                 value={"bold"}
//                 selected={isMarkActive(editor, "bold")}
//                 onMouseDown={event => {
//                     event.preventDefault()
//                     toggleMark(editor, "bold")
//                 }}
//             >
//                 <Icon fontSize="small">{"format_bold"}</Icon>
//             </ToggleButton >
//             <ToggleButton
//                 size="small"
//                 value={"italic"}
//                 selected={isMarkActive(editor, "italic")}
//                 onMouseDown={event => {
//                     event.preventDefault()
//                     toggleMark(editor, "italic")
//                 }}
//             >
//                 <Icon fontSize="small">{"format_italic"}</Icon>
//             </ToggleButton >
//             <ToggleButton
//                 size="small"
//                 value={"italic"}
//                 selected={isMarkActive(editor, "underline")}
//                 onMouseDown={event => {
//                     event.preventDefault()
//                     toggleMark(editor, "underline")
//                 }}
//             >
//                 <Icon fontSize="small">{"format_underlined"}</Icon>
//             </ToggleButton >
//         </ToggleButtonGroup>
//     )
// }

// const LinkButton = () => {
//     const editor = useSlate()
//     return (
//         <ToggleButton
//             sx={{ ml: 1 }}
//             selected={isLinkActive(editor)}
//             value={"link"}
//             size="small"
//             onMouseDown={event => {
//                 event.preventDefault()
//                 if (isLinkActive(editor)) {
//                     unwrapLink(editor)
//                     return
//                 }
//                 const url = window.prompt('Enter the URL of the link:')
//                 if (!url) return
//                 insertLink(editor, url)
//             }}
//         >
//             <Icon fontSize="small">{isLinkActive(editor) ? "link_off" : "link"}</Icon>
//         </ToggleButton>
//     )
// }

// const insertLink = (editor: BaseEditor, url: string) => {
//     if (editor.selection) {
//         wrapLink(editor, url)
//     }
// }

const isLinkActive = (editor: BaseEditor) => {
  const [link]: any = Editor.nodes(editor, {
    match: (n) =>
      //@ts-ignore
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
  return !!link
}

const unwrapLink = (editor: BaseEditor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      //@ts-ignore
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}

const wrapLink = (editor: BaseEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const withInlines = (editor: ReactEditor) => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = (element: any) => ['link'].includes(element.type) || isInline(element)

  editor.insertText = (text: any) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data: any) => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: 0 }}>
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)

const LinkComponent = ({
  attributes,
  children,
  element,
}: {
  attributes: any
  children: React.ReactNode
  element: any
}) => {
  const selected = useSelected()

  return (
    <a
      {...attributes}
      href={element.url}
      target="_blank"
      rel="noreferrer"
      style={{
        boxShadow: selected ? 'box-shadow: 0 0 0 3px #ddd' : undefined,
        color: 'inherit',
        textDecoration: 'underline !important',
      }}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  )
}
