import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
}
ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID as string, undefined, options)

ReactDOM.render(
  <BrowserRouter basename={process.env.REACT_APP_ROOT_PATH}>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)
