import { Avatar, Divider, IconButton, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { CompanyInformationCard } from 'components/cardCompanyInformation/CompanyInformationCard'
import { motion } from 'framer-motion'
import { formTypeValues } from 'lib'
import { ICompanyInformation } from 'marketplace-common'
import { MAIN_COLORS } from 'styles'

type Props = {
  formType: string
  companyInformationList: ICompanyInformation[]
  selectedCompanyInformationId: number | undefined
  loadCompanyInformation: (companyInformationI: number) => void
  resetCompanyInformation: () => void
  setEdit: (edit: boolean) => void
  handleSelectCompanyInformation: (companyInformationId: number) => void
  handleCompanyInformationList: (companyId: string) => void
  handleOpenForm: (val: boolean, isEdit: boolean) => void
}

export const CompanyInfoCardContainer = (prop: Props) => {
  const {
    companyInformationList,
    selectedCompanyInformationId,
    handleSelectCompanyInformation,
    loadCompanyInformation,
    handleCompanyInformationList,
    formType,
    handleOpenForm,
  } = prop

  const isPaymentForm = formType === formTypeValues.PAYMENT

  return (
    <>
      <Box px={4} py={2}>
        <Typography
          variant="caption"
          sx={{ fontWeight: 700, fontSize: { xs: 17, sm: 20 } }}
          color="textSecondary"
        >
          {isPaymentForm ? 'Información de Pago' : 'Información de Facturación'}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row-reverse',
          gap: 2,
          pt: 2,
          width: '100%',
          px: 4,
          justifyContent: { xs: 'center', md: 'flex-start' },
        }}
      >
        <Paper
          component={motion.div}
          whileHover={{ scale: 1.1 }}
          sx={{ background: 'transparent', borderRadius: 3, cursor: 'pointer' }}
          onClick={() => {
            handleOpenForm(isPaymentForm, false)
          }}
        >
          <Box
            sx={{
              background: MAIN_COLORS.primary.main,
              minWidth: '135px',
              width: '135px',
              padding: 1,
              height: '150px',
              borderRadius: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            <IconButton
              sx={{
                borderRadius: '50%',
                width: '60px',
                height: '60px',
                color: 'white',
              }}
            >
              <Avatar sx={{ fontSize: 70, width: '100%', height: '100%' }}>+</Avatar>
            </IconButton>

            <Typography
              variant="caption"
              textAlign={'center'}
              sx={{ lineHeight: 1.3, fontWeight: 600, fontSize: 12, color: 'white' }}
            >
              {isPaymentForm ? 'Agregar información de pago' : 'Agregar información de facturación'}
            </Typography>
          </Box>
        </Paper>

        {companyInformationList &&
          companyInformationList.map((companyInformation) => {
            return (
              <CompanyInformationCard
                key={companyInformation.companyInformationId}
                companyInformation={companyInformation}
                handleCompanyInformationList={handleCompanyInformationList}
                loadCompanyInformation={loadCompanyInformation}
                isSelected={
                  companyInformation.companyInformationId === selectedCompanyInformationId
                }
                companyInformationType={companyInformation.informationType}
                handleSelectCompanyInformation={handleSelectCompanyInformation}
                handleOpenForm={handleOpenForm}
              />
            )
          })}
      </Box>
    </>
  )
}
