import { createProcessesService } from 'lib'
import React from 'react'
import { ProcessInfo } from 'types'
import { IProcess, ISolution } from 'marketplace-common'

type Hook = {
  createProcesses: (
    solutions: ISolution[],
    userIdentification: string,
    securityUserId: number,
    processInformation?: Partial<ProcessInfo>
  ) => Promise<IProcess>
  loading: boolean
}

export const useCreateProcess = (): Hook => {
  const [loading, setLoading] = React.useState(false)

  const createProcesses = React.useCallback(
    async (
      solutions: ISolution[],
      userIdentification: string,
      securityUserId: number,
      processInformation?: Partial<ProcessInfo>
    ): Promise<IProcess> => {
      const processes: IProcess[] = []
      try {
        setLoading(true)
        for (const solution of solutions) {
          const process = await createProcessesService({
            information: processInformation ? JSON.stringify(processInformation) : '{}',
            companyId: userIdentification,
            mainSolutionId: solution.mainSolutionId,
            securityUserId,
          })
          processes.push(process)
        }
      } catch (error) {
        throw new Error()
      } finally {
        setLoading(false)
        return processes[0]
      }
    },
    []
  )

  return {
    createProcesses,
    loading,
  }
}
