import { Box, Button, Drawer, Icon, Tab, Tabs, Typography } from '@mui/material'
import { useAuth, useProcess } from 'hooks'
import { ROUTES } from 'lib'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IStep, StepReference } from 'types'

type Props = {
  loadingStep: boolean
  renderStep: IStep[]
  completed: (reference: StepReference) => boolean
}

export const StepsMobile = (props: Props) => {
  const { loadingStep, renderStep, completed } = props
  const { isAuthenticated } = useAuth()
  const {
    currentIndex,
    changeStepIndex,
    getEnableSteps,
    inProcessOrCompleted,
    loadingCompleteProcess,
  } = useProcess()

  const navigate = useNavigate()
  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null)

  const handleScrollButtonClick = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
  }
  return (
    <Drawer id={'drawerMobileSteps'} variant="permanent" ref={scrollContainerRef}>
      <Box
        py={2}
        sx={{
          width: '100%',
          position: 'fixed',
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          bgcolor: (theme) => (theme.palette.mode === 'light' ? '#EFEFEF' : '#000'),
        }}
      >
        <Box px={0.5}>
          <Button
            disabled={loadingCompleteProcess}
            onClick={() => {
              handleScrollButtonClick()
              navigate(`/${ROUTES.user}/${ROUTES.marketplace}/${ROUTES.processes}`)
            }}
          >
            <Box py={1} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Icon>{isAuthenticated ? 'dashboard' : 'login'}</Icon>
              <Typography pt={1} variant="caption" fontWeight={400} fontSize={9.5}>
                {isAuthenticated ? 'DashBoard' : 'Login'}
              </Typography>
            </Box>
          </Button>
        </Box>

        <Box px={0.5}>
          <Tabs value={1} onChange={() => {}} sx={{ bgcolor: '#272727', borderRadius: '5px' }}>
            {!loadingStep &&
              renderStep.map((step) => (
                <Tab
                  key={step.label}
                  id={`stepInfo${step.index}`}
                  sx={{ p: '7px' }}
                  onClick={() => {
                    changeStepIndex(step.index)
                  }}
                  disabled={
                    inProcessOrCompleted
                      ? getEnableSteps.includes(step.reference)
                      : !getEnableSteps.includes(step.reference)
                  }
                  iconPosition="top"
                  icon={
                    <Box
                      borderRadius={'100vw'}
                      bgcolor={
                        completed(step.reference)
                          ? step.index === currentIndex
                            ? (theme) => `${theme.palette.secondary.main}`
                            : 'success.main'
                          : step.index === currentIndex
                          ? (theme) => `${theme.palette.secondary.main}`
                          : (theme) => `${theme.palette.primary.main}20`
                      }
                      sx={{
                        width: '32px',
                        height: '32px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Icon
                        sx={{
                          color: '#fff',
                        }}
                      >
                        {completed(step.reference) ? 'done' : step.icon}
                      </Icon>
                    </Box>
                  }
                  label={
                    <Typography
                      variant="caption"
                      fontWeight={step.index === currentIndex ? 800 : 400}
                      fontSize={9.5}
                      sx={{
                        color: '#fff',
                      }}
                    >
                      {step.label}
                    </Typography>
                  }
                />
              ))}
          </Tabs>
        </Box>
      </Box>
    </Drawer>
  )
}
