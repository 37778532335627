import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'

export const SupportLayout = () => {
  return (
    <Box width={'100vw'} height={'100vh'} bgcolor={(theme) => theme.palette.alt?.background}>
      <Outlet />
    </Box>
  )
}
