import React from 'react'

type Hook = {
  open: boolean
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleClose: () => void
  anchorEl: null | HTMLElement
}

export const useOpenMenu = (): Hook => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return {
    anchorEl,
    handleClick,
    handleClose,
    open,
  }
}
