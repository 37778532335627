import { Box, Divider, Paper, Typography } from '@mui/material'
import warning from 'assets/animations/warning.json'
import { ProductsDetails } from 'components'
import { useCart, useCompanyInformation, useProcess } from 'hooks'
import { getCompanyInformationByIdService } from 'lib'
import React from 'react'
import Lottie from 'react-lottie'
import { SOLUTION } from 'types'

export const PaymentSummary = () => {
  const { process } = useProcess()
  const { invoiceDetails } = useCart()
  const { changeInvoiceInformation, invoiceInformation } = useCompanyInformation()

  const existsCompanyInformation = Boolean(invoiceInformation)

  const loadCompanyInformation = async () => {
    const existsCompanyInformationInProcess = Boolean(process?.invoiceInformationId)
    if (!existsCompanyInformationInProcess) return

    try {
      const result = await getCompanyInformationByIdService(process?.invoiceInformationId!)
      changeInvoiceInformation(result)
    } catch (error) {
      console.error(error)
    }
  }

  const handleMainSolutions = () => {
    const productsMainSolutions = invoiceDetails.products?.filter(
      (product) => product.mainSolutionId === SOLUTION.QUINDE_FEE
    )

    const specialProductsInformation = invoiceDetails.specialProducts?.flatMap((product) =>
      product.specialProduct.specialProducts.filter(
        (solution) => solution.productInformation.mainSolutionId === SOLUTION.QUINDE_FEE
      )
    )

    const totalQuindeSolution =
      (productsMainSolutions?.length || 0) + specialProductsInformation?.length

    return totalQuindeSolution >= 2
  }

  React.useEffect(() => {
    loadCompanyInformation()
  }, [])

  const existLastName = invoiceInformation.lastName !== null

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: { xs: 250, sm: 516, md: 616 },

        '&::-webkit-scrollbar': {
          width: '5px',
          height: '5px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#fff',
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#3c3c3c',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#3c3c3c',
        },
      }}
    >
      <Paper
        sx={{
          paddingX: 2,
          width: '100%',
        }}
      >
        <Box sx={{ paddingY: 2 }}>
          <Typography fontWeight={700}>{'Resumen de Compra'}</Typography>
        </Box>

        {handleMainSolutions() && (
          <Box
            sx={{
              border: '1px solid grey',
              padding: '5px',
              borderRadius: '10px',
              display: 'flex',
              gap: '15px',
              alignItems: 'center',
            }}
          >
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: warning,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={35}
              width={50}
            />
            <Typography sx={{ fontSize: '.8rem' }}>
              Recuerda, si compras dos productos de QuindeFee el segundo producto se activará cuando
              el primero caduque
            </Typography>
          </Box>
        )}

        <ProductsDetails invoiceDetail={invoiceDetails} disableDelete={true} />

        <Divider />

        {existsCompanyInformation && (
          <Box py={2}>
            <Typography fontWeight={700} color="textSecondary" variant="body2">
              {'Datos de facturación'}
            </Typography>
            <Box
              py={2}
              sx={{
                px: { xs: 1, sm: 2, md: 4 },
                display: 'flex',
                justifyContent: 'space-between',
                overflow: 'scroll',
              }}
            >
              <Box pr={1} sx={{ minWidth: '8rem' }}>
                <Typography
                  sx={{
                    fontSize: '0.85em',
                    fontWeight: 700,
                  }}
                >
                  {'Identificación: '}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.85em',
                    fontWeight: 700,
                  }}
                >
                  {'Correo: '}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.85em',
                    fontWeight: 700,
                  }}
                >
                  {'Cliente: '}
                </Typography>

                <Typography
                  sx={{
                    fontSize: '0.85em',
                    fontWeight: 700,
                  }}
                >
                  {'Teléfono: '}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.85em',
                    fontWeight: 700,
                  }}
                >
                  {'Dirección: '}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: '0.85em' }}>
                  {invoiceInformation?.identification}
                </Typography>
                <Typography sx={{ fontSize: '0.85em' }}>{invoiceInformation?.mail}</Typography>
                <Typography sx={{ fontSize: '0.85em' }}>
                  {existLastName
                    ? invoiceInformation?.name + ' ' + invoiceInformation?.lastName
                    : invoiceInformation?.name}
                </Typography>
                <Typography sx={{ fontSize: '0.85em' }}>
                  {invoiceInformation?.phoneNumber}
                </Typography>
                <Typography sx={{ fontSize: '0.85em' }}>{invoiceInformation?.address}</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Paper>
    </Box>
  )
}
