import { Card, Typography, Icon, Divider, Tooltip, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import { motion } from 'framer-motion'
import { getProductsByIdService, LEGACY_TYPE, QUINDEE_SOLUTION, QUINDEE_RECIBIDOS } from 'lib'
import { ISolutionProduct } from 'marketplace-common'
import React from 'react'
import { useFeedback } from 'hooks'
import { SOLUTION } from 'types'
type Props = {
  productToActivate: ISolutionProduct
}
export const SummaryProductToActivateCard = (props: Props) => {
  const { productToActivate } = props
  const { showMessage } = useFeedback()
  const [isLegacy, setIsLegacy] = React.useState(false)

  const handleLegacyProductType = async (productId: number) => {
    try {
      const productInformation = await getProductsByIdService(productId)
      const { productTypeName, mainSolutionName } = productInformation
      const isLegacy =
        (productTypeName === LEGACY_TYPE && mainSolutionName === QUINDEE_SOLUTION) ||
        productTypeName === QUINDEE_RECIBIDOS
      setIsLegacy(isLegacy)
    } catch (error) {
      showMessage('No se encontró el producto', 'error')
    }
  }

  React.useEffect(() => {
    if (productToActivate) {
      handleLegacyProductType(productToActivate.productId)
    }
  }, [productToActivate])
  return (
    <Card
      sx={{
        px: 1,
        py: 2,
        minWidth: 200,
        flex: 1,
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgba(255,255,255,0.5)',
        justifyContent: 'space-between',
        height: '100%',
        minHeight: '100%',
      }}
      elevation={0}
      component={motion.div}
      animate={{
        opacity: 1,
        transition: { delay: 0.15 * Math.random() },
        scale: 1,
      }}
      initial={{
        opacity: 0,
        scale: 1.1 * Math.random(),
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant="caption"
          fontWeight={600}
          fontSize={{ xs: '0.8rem', sm: '1.1rem' }}
          pl={1.4}
          lineHeight={1}
        >
          {productToActivate.name}
        </Typography>
      </Box>
      <Divider />
      {productToActivate.mainSolutionId === SOLUTION.NUNA_KEY && (
        <Typography
          variant="subtitle2"
          lineHeight={1.5}
          fontWeight={450}
          fontSize={{ xs: '0.7rem', sm: 12 }}
          textAlign={'center'}
        >
          <strong>
            "Un asesor se comunicara contigo en breves, o puedes comunicarte con soporte si tienes
            alguna duda"
          </strong>
        </Typography>
      )}
      {isLegacy && (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <strong>{'Licencia gratuita'}</strong>
        </Box>
      )}
      {!isLegacy && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'nowrap',
            fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.82rem' },
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="caption"
              fontWeight={400}
              fontSize={{ xs: '0.8rem', sm: '0.9rem' }}
            >
              <strong>{'Proximo producto a activarse'}</strong>
            </Typography>
            <Box pl={1}>
              <Tooltip
                title="Solo puedes mantener un producto activo a la vez una vez caduque, se activará este producto."
                arrow
              >
                <IconButton sx={{ backgroundColor: '#000', borderRadius: '50%', scale: '75%' }}>
                  <Icon sx={{ color: 'white' }}>priority_high</Icon>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      )}
    </Card>
  )
}
