import { Box, Dialog, Icon, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { Fragment } from 'react'

interface Props {
  open: boolean
  adminComment: string
  handleClose: (dialog: string) => void
}

export const ReasonRejectDialog = (props: Props) => {
  const { open, adminComment, handleClose } = props

  return (
    <Fragment>
      {adminComment && (
        <Dialog
          open={open}
          onClose={() => {
            handleClose('Detail')
          }}
          maxWidth="sm"
        >
          <AnimatePresence>
            <Stack py={1} px={4} position="relative">
              <Stack alignItems={'center'} justifyContent={'center'} p={3} spacing={1}>
                <Typography variant="h6" align="center">
                  {'Información sobre la Transacción'}
                </Typography>
              </Stack>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="caption" color={'error'} fontWeight={600} fontSize={15}>
                  {`Motivo de Rechazo `}
                </Typography>
                <Typography variant="caption" fontWeight={600} fontSize={15}>
                  <strong>{adminComment}</strong>
                </Typography>
              </Box>
              <Box
                display={'flex'}
                width={'100%'}
                justifyContent={'flex-end'}
                pr={5}
                position="absolute"
                right={0}
              >
                <Tooltip title="Cerrar" arrow>
                  <IconButton
                    sx={{
                      fontSize: { xs: 20, sm: 25 },
                      right: { xs: -20, sm: -25 },
                      top: { xs: 15, sm: -2 },
                    }}
                    onClick={() => handleClose('Licences')}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          </AnimatePresence>
        </Dialog>
      )}
    </Fragment>
  )
}
