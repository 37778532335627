import { AlertColor, SnackbarOrigin } from '@mui/material'
import { SnackbarHandler, SnackbarHandlerRef } from 'components'
import React from 'react'

interface Props {}

export const FeedbackContext = React.createContext({} as Props & SnackbarHandlerRef)

const Provider: React.FC = ({ children }) => {
  /**Use a reference to avoid children re render any time that a snackbar is shown*/
  const snackbarRef = React.useRef<SnackbarHandlerRef>(null)

  const showMessage = (
    message: string,
    severity?: AlertColor | undefined,
    anchorOrigin?: SnackbarOrigin
  ) => {
    snackbarRef.current?.showMessage(message, severity, anchorOrigin)
  }

  return (
    <FeedbackContext.Provider
      value={{
        showMessage,
      }}
    >
      {children}
      <SnackbarHandler ref={snackbarRef} />
    </FeedbackContext.Provider>
  )
}

export default Provider
