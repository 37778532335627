import { Box } from '@mui/material'
import { MAIN_SOLUTIONS_IMG } from 'lib'
import { MAIN_COLORS } from 'styles'

export const SolutionLoading = () => {
  return (
    <Box
      height={'100vh'}
      width={'100vw'}
      sx={{
        position: 'relative',
        background: MAIN_COLORS.alt.background,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
    >
      {MAIN_SOLUTIONS_IMG.map((solution, index) => (
        <Box
          key={index + Math.random()}
          sx={{
            margin: { xs: '-12px', sm: '-8px', md: 0 },
            backgroundImage: `url(${solution})`,
            width: { xs: 42, sm: 36, md: 52 },
            height: { xs: 42, sm: 36, md: 52 },
            heigh: '100vh',
            minWidth: { xs: 42, sm: 36, md: 52 },
            minHeight: { xs: 42, sm: 36, md: 52 },
            backgroundSize: 'contain',
            animation: 'jump 1s infinite',
            animationDelay: `${index * 0.2}s`,
            '@keyframes jump': {
              '0%': { transform: 'translateY(0)' },
              '50%': { transform: 'translateY(-20px)' },
              '100%': { transform: 'translateY(0)' },
            },
          }}
        />
      ))}
    </Box>
  )
}
