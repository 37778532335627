import axios, { AxiosError } from 'axios'
import {
  AND_PARAM,
  EMAIL_PARAM,
  IDENTIFICATION_PARAM,
  LOGIN_PATH,
  PROFILES_PATH,
  PROFILE_ASSOCIATIONS_PATH,
  SEARCH_PARAM,
  USERS_PATH,
  USER_IDENTIFICATION_PARAM,
  VALIDATE_CREDENTIALS_PATH,
  VALIDATE_EXISTING_ACCOUNT_PATH,
} from 'lib'
import {
  CHANGE_PASSWORD_PATH,
  FORGOT_PASSWORD_PATH,
  IDENTIFICATION_TYPES_PATH,
  SIGN_UP_PATH,
  TERMS_CONDITIONS_PATH,
  USERNAME_PARAM,
  VALIDATE_EXISTING_EMAIL_PATH,
  VALIDATE_EXISTING_IDENTIFICATION_PATH,
  VALIDATE_EXISTING_USERNAME_PATH,
} from 'lib/constants/path/url'
import { IProfileUpdateSelectedRequest } from 'types'
import { LOGOUT_PATH, RESEND_VERIFICATION_EMAIL, SESSION_PATH } from './../constants/path/url'
import {
  requestInterceptorErrorSecurity,
  requestInterceptorSuccessSecurity,
} from './security.interceptor'

const instance = axios.create({
  baseURL: process.env.REACT_APP_SECURITY_BASE_API_URL,
})

instance.interceptors.request.use(
  requestInterceptorSuccessSecurity,
  requestInterceptorErrorSecurity
)

/**Auth */
export const loginService = async (
  email: string,
  password: string,
  agent: string,
  coordinates: string,
  ipDevice: string
) => {
  try {
    const result = await instance.post(USERS_PATH + LOGIN_PATH, {
      email,
      password,
      agent,
      coordinates,
      ipDevice,
    })
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data?.message)
  }
}

export const validateExistingAccountService = async (identification?: string, email?: string) => {
  try {
    await instance.get(
      `${USERS_PATH}${VALIDATE_EXISTING_ACCOUNT_PATH}${SEARCH_PARAM}${
        identification ? IDENTIFICATION_PARAM + identification : ''
      }${AND_PARAM}${email ? EMAIL_PARAM + email : ''}`
    )
    return true
  } catch (error) {
    throw new Error('Cuenta ya utilizada')
  }
}

export const validateCredentialsService = async (email: string, password: string) => {
  try {
    const result = await instance.post(USERS_PATH + VALIDATE_CREDENTIALS_PATH, { email, password })
    return result.data
  } catch (error: any) {
    throw new Error('Credenciales incorrectos')
  }
}

export const createUserService = async (user: any) => {
  try {
    await instance.post(`${USERS_PATH}${SIGN_UP_PATH}`, user)
    return true
  } catch (error: any) {
    console.log(error)
  }
}

export const updateUserService = async (id: number, user: any) => {
  try {
    await instance.put(`${USERS_PATH}/${id}`, user)
    return true
  } catch (error: any) {
    console.log(error)
  }
}

export const validateExistingUsernameService = async (username: string) => {
  try {
    await instance.get(
      `${USERS_PATH}${VALIDATE_EXISTING_USERNAME_PATH}${SEARCH_PARAM}${USERNAME_PARAM}${username}`
    )
    return true
  } catch (error: any) {
    console.log(error)
  }
}

export const validateExistingEmailService = async (email: string) => {
  try {
    await instance.get(
      `${USERS_PATH}${VALIDATE_EXISTING_EMAIL_PATH}${SEARCH_PARAM}${EMAIL_PARAM}${email}`
    )
  } catch (error: any) {
    console.log(error)
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const validateExistingIdentificationService = async (identification: string) => {
  try {
    await instance.get(
      `${USERS_PATH}${VALIDATE_EXISTING_IDENTIFICATION_PATH}${SEARCH_PARAM}${IDENTIFICATION_PARAM}${identification}`
    )
  } catch (error: any) {
    console.log(error)
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const getIdentificationTypesService = async () => {
  try {
    const result = await instance.get(IDENTIFICATION_TYPES_PATH)
    return result.data
  } catch (error: any) {
    console.log(error)
  }
}

export const logoutService = async (userId: number) => {
  try {
    const result = await instance.get(USERS_PATH + LOGOUT_PATH + '/' + userId)
    return result.data
  } catch (error: any) {
    console.log(error)
  }
}

export const getUserByTokenService = async (token: string) => {
  try {
    const result = await instance.get(USERS_PATH + '/' + token)
    return result.data
  } catch (error: any) {
    console.log(error)
    throw new Error((error as AxiosError)?.response?.data?.message)
  }
}

/**Profiles */

export const getProfilesByUserIdentificationService = async (identification: any) => {
  try {
    const result = await instance.get(
      `${PROFILES_PATH}${SEARCH_PARAM}${USER_IDENTIFICATION_PARAM}${identification}`
    )
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const createProfileAssociationService = async (userId: number, solutionId: number) => {
  try {
    const result = await instance.post(`${PROFILE_ASSOCIATIONS_PATH}`, {
      userId,
      moduleId: solutionId,
    })
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const updateSelectedProfile = async (request: IProfileUpdateSelectedRequest) => {
  try {
    const result = await instance.put(`${SESSION_PATH}${PROFILES_PATH}`, request)
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

/*FORGOT PASSWORD PROCESS */

export const forgotPasswordService = async (email: string) => {
  try {
    const response = await instance.get(
      `${USERS_PATH}${FORGOT_PASSWORD_PATH}${SEARCH_PARAM}${EMAIL_PARAM}${email}`
    )
    return response.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const changePasswordService = async (userId: number, password: string) => {
  try {
    const response = await instance.post(`${USERS_PATH}${CHANGE_PASSWORD_PATH}`, {
      password,
      userId,
    })
    return response.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

/*EMAIL VERIFICATION PROCESS */

export const resentEmailVerificationService = async (email: string) => {
  try {
    const response = await instance.post(`${USERS_PATH}${RESEND_VERIFICATION_EMAIL}`, { email })
    return response.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const updateAllowedTermsAndConditionService = async (userId: number) => {
  try {
    const response = (await instance.put(USERS_PATH + TERMS_CONDITIONS_PATH, { userId })).data
    return response
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}
