import { LoadingButton } from '@mui/lab'
import { Box, Card, Icon, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useAuth, useFeedback } from 'hooks'
import { RECEPTOR_PROFILE, updateSelectedProfile } from 'lib'
import { IProfile } from 'marketplace-common'
import React from 'react'
import { IProfileUpdateSelectedRequest, ProfileType, SOLUTION } from 'types'

type Props = {
  profile: IProfile
  userId: number
}

export const ProfileSummary = (props: Props) => {
  const { showMessage } = useFeedback()
  const { profile, userId } = props
  const { user } = useAuth()
  const [loading, setLoading] = React.useState(false)

  const handleOnLoginProfile = React.useCallback(async () => {
    setLoading(true)
    const profileSelectRequest = {
      companyId: profile.companyId,
      solutionId: profile.mainSolutionId,
      profileId: profile.profileId,
      userId: userId,
    }

    try {
      const response = await updateSelectedProfile(
        profileSelectRequest as IProfileUpdateSelectedRequest
      )
      if (response === '') {
        showMessage('Aun no disponible en esta versión')
      } else {
        showMessage('Se abrirá una nueva ventana tu perfil espera un momento')
        setTimeout(() => {
          window.open(`${response}?id=${user.token}`, '_blank')
        }, 500)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [profile, user])

  return (
    <Card
      sx={{
        px: 3,
        py: 2,
        flex: 1,
        height: 'auto',
        minHeight: profile.mainSolutionId !== SOLUTION.NUNA_KEY ? 160 : 100,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgba(255,255,255,0.5)',
      }}
      elevation={0}
      component={motion.div}
      animate={{
        opacity: 1,
        transition: { delay: 0.15 * Math.random() },
        scale: 1,
      }}
      initial={{
        opacity: 0,
        scale: 1.1 * Math.random(),
      }}
    >
      <Stack spacing={1} flexGrow={1}>
        <Typography variant="caption" fontWeight={500}>
          {profile.name.toUpperCase()}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
          }}
        >
          {profile.companyName !== null && profile.name !== RECEPTOR_PROFILE && (
            <Typography variant="subtitle2" fontWeight={450} fontSize={12}>
              Nombre compañía : {profile.companyName}
            </Typography>
          )}
          {profile.companyId !== null && profile.name !== RECEPTOR_PROFILE && (
            <Typography variant="subtitle2" fontWeight={450} fontSize={12}>
              Identificación: {profile.companyId}
            </Typography>
          )}
          <Typography variant="body2" color="textSecondary">
            {profile.description}
          </Typography>
        </Box>
      </Stack>
      <Box pb={1} pt={2}>
        <LoadingButton
          sx={{ px: 6, width: '90%' }}
          endIcon={<Icon>chevron_right</Icon>}
          onClick={handleOnLoginProfile}
          loading={loading}
          disabled={profile.mainSolutionId === 3}
        >
          <Box ml={1.5}>{'Ingresar'}</Box>
        </LoadingButton>
      </Box>
    </Card>
  )
}
