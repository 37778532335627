import { TreeItem, TreeView } from '@mui/lab'
import { Avatar, Box, Divider, Grid, Icon, Typography } from '@mui/material'
import { ProfileSummary, SummaryProductSection } from 'components'
import { useAuth } from 'hooks'
import { getProductEnableService } from 'lib'
import { IProduct, IProfile, ISolution, ISolutionProduct } from 'marketplace-common'
import React from 'react'
import { SOLUTION_COLORS } from 'styles'

type Props = {
  solution: ISolution
  profiles: IProfile[]
  products: ISolutionProduct[]
  productsToActivate?: ISolutionProduct[]
  expiredProducts?: ISolutionProduct[]
  userId: number
}

/**
 *
 * @see /types/entities/solution.ts for more info
 * @see /types/entities/profile.ts for more info
 * @see /types/entities/product.ts for more info
 *
 * @param props solution: solution object info
 * profiles: array of profiles corresponding a main solution and a user
 * products: array of products corresponding a main solution and a user
 * @returns Component with all the solution info, including the profiles and the products that the user owns
 */
export function SolutionSummary(props: Props) {
  const { solution, products, profiles, userId, productsToActivate, expiredProducts } = props
  const { showTutorial } = useAuth()
  const [expanded, setExpanded] = React.useState<string[]>(showTutorial ? ['quinde'] : [])

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  React.useEffect(() => {
    if (!showTutorial || expanded.includes('quinde')) {
      return
    }
    setExpanded(['quinde'])
  }, [showTutorial])

  const renderProducts = () => {
    const existProducts =
      (products && products.length >= 1) ||
      (productsToActivate && productsToActivate.length >= 1) ||
      (expiredProducts && expiredProducts.length >= 1)

    return existProducts || showTutorial
  }

  return (
    <Grid
      container
      px={{
        xs: 0,
        sm: 3,
        md: 4,
        lg: 5,
        xl: 6,
      }}
      pt={2}
    >
      <TreeView
        defaultCollapseIcon={<Icon>expand_more</Icon>}
        defaultExpandIcon={<Icon>chevron_right</Icon>}
        onNodeToggle={handleToggle}
        expanded={expanded}
        sx={{ flexGrow: 1 }}
      >
        <TreeItem
          nodeId={solution.reference}
          sx={{ ml: 0 }}
          label={
            <Box display={'flex'} alignItems="center">
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  ml: 2,
                  borderRadius: '100%',
                  bgcolor: SOLUTION_COLORS[solution.reference].primary.main,
                }}
                src={solution.logo}
              />
              <Box py={1} ml={2}>
                <Typography variant="subtitle2">{solution.name}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {solution.description}
                </Typography>
              </Box>
            </Box>
          }
        >
          <Box id="solucionBox">
            <Box
              sx={{
                px: { xs: 2, sm: 6 },
                py: 2,
                width: '100%',
                bgcolor: 'rgba(255,255,255,0)',
                background: `linear-gradient(transparent, 65%, ${
                  SOLUTION_COLORS[solution.reference].primary.main
                })`,
                backdropFilter: 'saturate(100%) blur(150px)',
              }}
            >
              {Boolean(renderProducts()) && (
                <SummaryProductSection
                  products={products}
                  productsToActivate={productsToActivate}
                  expiredProducts={expiredProducts}
                  solution={solution}
                />
              )}
              <Box pt={1} className={'my-product-profile-' + solution.reference + '-tour-step'}>
                <Typography>{'MIS PERFILES'}</Typography>
                <Divider />
                <Grid
                  py={3}
                  container
                  spacing={2}
                  sx={{
                    color: 'blue',
                  }}
                >
                  {profiles?.map((profile, i) => (
                    <Grid id="gridPerfiles" item xs={12} sm={6} md={4} key={profile.profileId}>
                      <ProfileSummary profile={profile} userId={userId} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Box>
        </TreeItem>
      </TreeView>
    </Grid>
  )
}
