import { LoadingButton } from '@mui/lab'
import { Box, Card, Divider, Icon, Typography } from '@mui/material'
import { formatDistance } from 'date-fns'
import { es } from 'date-fns/locale'
import { motion } from 'framer-motion'
import { useCart, useFeedback, useTransactionLicense } from 'hooks'
import {
  LEGACY_TYPE,
  NUNA_SOLUTION_ID,
  QUINDEE_RECIBIDOS,
  QUINDEE_SOLUTION,
  QUINDEE_SOLUTION_ID,
  getProductsByIdService,
} from 'lib'
import { ISolutionProduct } from 'marketplace-common'
import React from 'react'
import { MAIN_COLORS } from 'styles'
import { SOLUTION } from 'types'
import { formatDateUtil } from 'utils/dateFormat'

type Props = {
  product: ISolutionProduct
  renewProduct?: boolean
}

export const SummaryCard = (props: Props) => {
  const { product, renewProduct = true } = props
  const { showMessage } = useFeedback()
  const { handleRenewProduct, allProductEnabled } = useCart()
  const { transactionLicenses, transactionLicensesWithLimitClose } = useTransactionLicense()

  const [isLegacy, setIsLegacy] = React.useState(false)
  const [colorCount, setColorCount] = React.useState<string>(MAIN_COLORS.primary.main)

  const transactionLicense = transactionLicenses.find(
    ({ license }) => license.licenseId === product.licenseId
  )
  const isProductWithLimitClose = transactionLicensesWithLimitClose.find(
    ({ license }) => license.licenseId === product.licenseId
  )

  const daysRemaining = formatDistance(new Date(product.expirationDate), new Date(), {
    locale: es,
  })

  const daysRemainingValidate = (expirationDate: string) => {
    const date1 = new Date(expirationDate)
    const date2 = new Date()
    const diffTime = date1.valueOf() - date2.valueOf()
    const diffDays = diffTime / (1000 * 60 * 60 * 24)
    return Math.ceil(diffDays)
  }

  const handleLegacyProductType = async (productId: number) => {
    try {
      const productInformation = await getProductsByIdService(productId)
      const { productTypeName, mainSolutionName } = productInformation
      const isLegacy =
        (productTypeName === LEGACY_TYPE && mainSolutionName === QUINDEE_SOLUTION) ||
        productTypeName === QUINDEE_RECIBIDOS
      setIsLegacy(isLegacy)
    } catch (error) {
      showMessage('No se encontró el producto', 'error')
    }
  }

  const showRenewButton = React.useMemo(() => {
    if (!allProductEnabled || allProductEnabled.length === 0) {
      return false
    }
    const existProductActive = allProductEnabled.some(
      (productInfo) => product.productId === productInfo.productId
    )
    if (!existProductActive) {
      return false
    }

    const isRemainingValid =
      (daysRemainingValidate(product.expirationDate) <= 10 && renewProduct) ||
      Boolean(isProductWithLimitClose)
    return isRemainingValid
  }, [allProductEnabled, product, renewProduct, isProductWithLimitClose])

  const handleTransactionCount = (currentTransaction: number, licenseLimitTransaction: number) => {
    const isCloseTheLimit = currentTransaction >= licenseLimitTransaction * 0.9
    if (isCloseTheLimit) {
      setColorCount('red')
      return
    }

    const isAlmostCloseTheLimit =
      currentTransaction >= licenseLimitTransaction * 0.75 &&
      currentTransaction <= licenseLimitTransaction * 0.99
    if (isAlmostCloseTheLimit) {
      setColorCount('orangered')
      return
    }
    setColorCount(MAIN_COLORS.primary.main)
  }

  React.useEffect(() => {
    if (product) {
      handleLegacyProductType(product.productId)
    }
  }, [product])

  React.useEffect(() => {
    const existsTransactionCount =
      Boolean(transactionLicense?.currentTransaction) &&
      Boolean(transactionLicense?.licenseLimitTransaction)
    if (existsTransactionCount) {
      handleTransactionCount(
        transactionLicense?.currentTransaction!,
        transactionLicense?.licenseLimitTransaction!
      )
    }
  }, [transactionLicense])

  const getLimitText = (solutionId: number | undefined) => {
    switch (solutionId) {
      case QUINDEE_SOLUTION_ID:
        return 'Límite de Emisión'
      case NUNA_SOLUTION_ID:
        return 'Límite de Firmas'
      default:
        return 'Límite'
    }
  }
  const getActualText = (solutionId: number | undefined) => {
    switch (solutionId) {
      case QUINDEE_SOLUTION_ID:
        return 'Actual Emitidos'
      case NUNA_SOLUTION_ID:
        return 'Actual Firmados'
      default:
        return 'Actual'
    }
  }

  return (
    <Card
      sx={{
        px: 1,
        py: 2,
        minWidth: 200,
        flex: 1,
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgba(255,255,255,0.5)',
        justifyContent: 'space-between',
        height: '100%',
        minHeight: '100%',
      }}
      elevation={0}
      component={motion.div}
      animate={{
        opacity: 1,
        transition: { delay: 0.15 * Math.random() },
        scale: 1,
      }}
      initial={{
        opacity: 0,
        scale: 1.1 * Math.random(),
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant="caption"
          fontWeight={600}
          fontSize={{ xs: '0.8rem', sm: '1.1rem' }}
          pl={1.4}
          lineHeight={1}
        >
          {product.name}
        </Typography>
        {showRenewButton && (
          <LoadingButton
            sx={{ height: 'fit-content', minWidth: 'auto' }}
            onClick={() => handleRenewProduct(product.productId)}
          >
            {'Renovar'}
            <Icon sx={{ pl: '2px' }}>{'restore_icon'}</Icon>
          </LoadingButton>
        )}
      </Box>
      <Divider />
      {product.mainSolutionId === SOLUTION.NUNA_KEY && (
        <Typography
          variant="subtitle2"
          lineHeight={1.5}
          fontWeight={450}
          fontSize={{ xs: '0.7rem', sm: 12 }}
          textAlign={'center'}
        >
          <strong>
            Un asesor se comunicara contigo en breves, o puedes comunicarte con soporte si tienes
            alguna duda
          </strong>
        </Typography>
      )}
      {isLegacy && (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <strong>{'Licencia gratuita'}</strong>
        </Box>
      )}
      {!isLegacy && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {/* Descomentar esta informacion una vez se implemente el contador de emitidos en quindefee */}
          {/* <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'nowrap',
              fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.82rem' },
              textAlign: 'center',
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="caption" fontWeight={400} color={colorCount} marginX={'0.9px'}>
                {getActualText(product.mainSolutionId)}
              </Typography>
              <Typography
                variant="caption"
                fontWeight={600}
                color={colorCount}
                fontSize={{ xs: '0.8rem', sm: '0.9rem' }}
              >
                {transactionLicense?.currentTransaction}
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="caption" fontWeight={400} color={colorCount} marginX={'0.9px'}>
                {getLimitText(product.mainSolutionId)}
              </Typography>
              <Typography
                variant="caption"
                fontWeight={600}
                color={colorCount}
                fontSize={{ xs: '0.8rem', sm: '0.9rem' }}
              >
                {transactionLicense?.licenseLimitTransaction}
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem />

            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="caption" fontWeight={400} marginX={'0.9px'}>
                {'Expira en'}
              </Typography>
              <strong
                style={{
                  color: daysRemainingValidate(product.expirationDate) <= 8 ? 'red' : 'black',
                }}
              >
                {daysRemaining}
              </strong>
            </Box>
          </Box> */}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'nowrap',
              fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.82rem' },
              textAlign: 'center',
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="caption" fontWeight={400} color={colorCount} marginX={'0.9px'}>
                Válido
              </Typography>
              <Typography
                variant="caption"
                fontWeight={600}
                color={colorCount}
                fontSize={{ xs: '0.8rem', sm: '0.9rem' }}
              >
                {formatDateUtil(product?.initialDate)}
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="caption" fontWeight={400} color={colorCount} marginX={'0.9px'}>
                Hasta
              </Typography>
              <Typography
                variant="caption"
                fontWeight={600}
                color={colorCount}
                fontSize={{ xs: '0.8rem', sm: '0.9rem' }}
              >
                {formatDateUtil(product.expirationDate)}
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem />

            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="caption" fontWeight={400} marginX={'0.9px'}>
                {'Expira en'}
              </Typography>
              <strong
                style={{
                  color: daysRemainingValidate(product.expirationDate) <= 8 ? 'red' : 'black',
                }}
              >
                {daysRemainingValidate(product.expirationDate) === 1
                  ? `${daysRemainingValidate(product.expirationDate)} dia`
                  : `${daysRemainingValidate(product.expirationDate)} días`}
              </strong>
            </Box>
          </Box>
        </Box>
      )}
    </Card>
  )
}
