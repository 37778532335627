import { Box } from '@mui/material'
import { ISolution } from 'marketplace-common'

type Props = {
  solutions: ISolution[]
}

export const SolutionArch = (props: Props) => {
  const { solutions } = props

  return (
    <Box
      sx={{
        py: { xs: 6, sm: 4, md: 0 },
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        top: { xs: '62px', sm: '42px' },
      }}
    >
      {solutions.map((solution, index) => {
        const radius = -72
        const angle = ((index + 1) * Math.PI) / (solutions.length + 1)
        const positionX = radius * Math.cos(angle)
        const positionY = radius * Math.sin(angle)

        return (
          <Box
            key={solution.mainSolutionId}
            sx={{
              margin: { xs: '-12px', sm: '-8px', md: 0 },
              backgroundImage: `url(${solution.logo})`,
              width: { xs: 42, sm: 36, md: 52 },
              height: { xs: 42, sm: 36, md: 52 },
              minWidth: { xs: 42, sm: 36, md: 52 },
              minHeight: { xs: 42, sm: 36, md: 52 },
              backgroundSize: 'contain',
              transform: `translate(${positionX}px, ${positionY}px)`,
              transformOrigin: 'bottom center',
            }}
          />
        )
      })}
    </Box>
  )
}
