import { Button, Icon } from '@mui/material'
import { motion } from 'framer-motion'
import { useCart } from 'hooks'

type Props = {
  isAuth?: boolean
  processId?: number | undefined
  noAllowToCleanItems?: boolean
}

export const CleanItemsBtn = (props: Props) => {
  const { isAuth, processId, noAllowToCleanItems } = props
  const { cleanFromCart, cleanFromCartNotLogged } = useCart()

  const handleClick = () => {
    if (processId !== undefined && isAuth) {
      cleanFromCart(processId)
    }
    if (!isAuth) {
      cleanFromCartNotLogged()
    }
  }

  return (
    <Button
      endIcon={<Icon>delete</Icon>}
      size="medium"
      onClick={handleClick}
      component={motion.div}
      variant="contained"
      disabled={noAllowToCleanItems && isAuth}
      initial={{
        x: -300,
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
    >
      {'Limpiar Carrito'}
    </Button>
  )
}
