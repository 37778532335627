import {
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  Box,
  Button,
  InputAdornment,
} from '@mui/material'
import React from 'react'
import Lottie from 'react-lottie'
import forgotPassword from 'assets/animations/forgot-password.json'
import { LoadingButton } from '@mui/lab'
import { AnimatedLink, PasswordStrengthIndicator } from 'components'
import { ROUTES } from 'lib'
import { useLocation } from 'react-router-dom'
import { decodeBase64 } from 'types'
import { LEVELS, useFeedback, usePasswordStrength, useRestorePassword, useToggle } from 'hooks'

type Props = {}

export const ChangePassword = (props: Props) => {
  const { changeData, loading, sendChangePasswordRequest, switchLoading, userInformation } =
    useRestorePassword()
  const { pathname } = useLocation()
  const { showMessage } = useFeedback()
  const { toggle, value: show } = useToggle()
  const { contains, strength, diversity } = usePasswordStrength(userInformation.password)
  const { strength: strengthPasswordConfirmation } = usePasswordStrength(
    userInformation.passwordConfirmation
  )

  const handleAction = React.useCallback(() => {
    const urlSplit = pathname.split('/')
    const actionCode = urlSplit[urlSplit.length - 1]
    const decodeAction = decodeBase64(actionCode)
    const action = new URLSearchParams(decodeAction).get('action')
    if (action === 'changePassword') {
      const userId = new URLSearchParams(decodeAction).get('userId')
      if (!Number.isNaN(Number(userId))) {
        changeData('userId', Number(userId))
      }
    }
  }, [pathname])

  React.useEffect(() => {
    handleAction()
  }, [handleAction])

  const changePassword = React.useCallback(async () => {
    try {
      await sendChangePasswordRequest()
      showMessage('La contraseña fue cambiada con éxito', 'success')
    } catch (error) {
      showMessage(
        'Ocurrió un error al cambiar la contraseña ,  si esto persiste comunícate con soporte ',
        'error'
      )
    } finally {
      switchLoading()
    }
  }, [sendChangePasswordRequest])

  const isSamePassword = React.useMemo(() => {
    return (
      userInformation.password === userInformation.passwordConfirmation &&
      userInformation.password &&
      userInformation.passwordConfirmation &&
      userInformation.password.length > 0 &&
      userInformation.passwordConfirmation.length > 0 &&
      strengthPasswordConfirmation === LEVELS[3] &&
      strength === LEVELS[3]
    )
  }, [userInformation, strengthPasswordConfirmation, strength])

  return (
    <Grid container height={'100vh'} justifyContent="center" alignItems="center">
      <Paper
        elevation={0}
        sx={{
          p: 5,
        }}
      >
        <Stack spacing={2}>
          <Lottie
            options={{
              animationData: forgotPassword,
              loop: true,
            }}
            width={180}
            height={180}
          />
          <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <Typography
              fontWeight={600}
              fontSize={'1.2em'}
              variant="body2"
              sx={{ maxWidth: 300 }}
              align="center"
              color="textSecondary"
            >
              {`Ingresa tu nueva contraseña`}
            </Typography>
          </Box>

          <Box py={2} width="100%">
            <PasswordStrengthIndicator
              open={Boolean(userInformation.password)}
              strength={strength}
              contains={contains}
              placement="right"
              diversity={diversity}
            >
              <TextField
                placeholder="Nueva contraseña"
                fullWidth
                autoFocus
                required
                type={show ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={toggle} variant="text">
                        {show ? 'Ocultar' : 'Mostrar'}
                      </Button>
                    </InputAdornment>
                  ),
                }}
                value={userInformation.password}
                onChange={(e) => changeData('password', e.target.value)}
              />
            </PasswordStrengthIndicator>

            <TextField
              placeholder="Confirmar contraseña"
              fullWidth
              autoFocus
              required
              sx={{ marginTop: 1 }}
              type={show ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={toggle} variant="text">
                      {show ? 'Ocultar' : 'Mostrar'}
                    </Button>
                  </InputAdornment>
                ),
              }}
              value={userInformation.passwordConfirmation}
              onChange={(e) => changeData('passwordConfirmation', e.target.value)}
            />
          </Box>
          <LoadingButton loading={loading} onClick={changePassword} disabled={!isSamePassword}>
            {'Cambiar Contraseña'}
          </LoadingButton>
          <AnimatedLink to={ROUTES.login} type="circle">
            <Button variant="text" fullWidth>
              {'Regresar a iniciar sesión'}
            </Button>
          </AnimatedLink>
        </Stack>
      </Paper>
    </Grid>
  )
}
