export const decodeBase64 = (base64: string): string => {
  if (base64.includes('%3d') || base64.includes('%3D')) {
    const newParam = base64.replace('%3d', '').replace('%3D', '')
    return window.atob(newParam)
  }
  return window.atob(base64.replace('%3d', '').replace('%3D', ''))
}

export const encodeBase64 = (text: string): string => {
  return btoa(text)
}
