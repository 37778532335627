import { getCompanyByIdService } from 'lib'
import React from 'react'
import { ICompany } from 'marketplace-common'

type Hook = {
  getCompanyById: (companyId: string) => Promise<ICompany>
  loading: boolean
}

export const useGetCompany = (): Hook => {
  const [loading, setLoading] = React.useState(false)

  const getCompanyById = React.useCallback(async (companyId: string) => {
    try {
      setLoading(true)
      const result = await getCompanyByIdService(companyId)
      return result
    } catch (error: any) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    getCompanyById,
  }
}
