import { useTheme } from '@mui/material'
import { motion, useAnimation } from 'framer-motion'
import React from 'react'
import { NavigateOptions, To, useNavigate } from 'react-router-dom'
/**
 * Defines the animation of the screen
 */
export type AnimatedNavigationType = 'page' | 'circle'

/**
 * Export the props template for the useRef hook
 */
export interface AnimatedNavigationHandlerRef {
  animatedNavigation: (
    to: To | number,
    type?: AnimatedNavigationType,
    options?: NavigateOptions | undefined
  ) => void
}

const DURATION = 1200

/**
 * @returns A component that creates an animation depending of the passed type
 */
export const AnimatedNavigationHandler = React.forwardRef<AnimatedNavigationHandlerRef>(
  (_, ref?) => {
    const circleControls = useAnimation()
    const pageControls = useAnimation()
    const navigate = useNavigate()
    const theme = useTheme()

    React.useImperativeHandle(ref, () => ({
      animatedNavigation: animatedNavigation,
    }))

    const animatedNavigation = (
      to: To | number,
      type?: AnimatedNavigationType,
      options?: NavigateOptions | undefined
    ) => {
      if (type === 'circle') {
        circleControls.start((i) => ({
          opacity: [0, 1, 1, 0],
          scale: [0, 200, 0],
          transition: { delay: i * Math.random() * 10, duration: DURATION / 1800 },
        }))
      }
      if (type === 'page') {
        pageControls.start((i) => ({
          opacity: [0, 1, 1, 0, 0],
          width: ['0vw', '120vw', '0vw', '0vw'],
          transition: { delay: i * Math.random() * 10, duration: DURATION / 1000 },
        }))
      }
      setTimeout(() => {
        if (typeof to === 'number') {
          navigate(to)
        } else {
          navigate(`/${to}`, options)
        }
      }, DURATION / 2)
    }

    return (
      <React.Fragment>
        <motion.div
          style={{
            position: 'absolute',
            width: 100,
            height: 100,
            backgroundColor: theme.palette.primary.main,
            top: 20,
            left: 20,
            borderRadius: '100%',
            scale: 0,
            zIndex: 9999,
          }}
          animate={circleControls}
        />
        <motion.div
          style={{
            position: 'absolute',
            width: '0vw',
            height: '100vh',
            backgroundColor: theme.palette.primary.main,
            top: 0,
            left: 0,
            zIndex: 9999,
          }}
          animate={pageControls}
        />
      </React.Fragment>
    )
  }
)
