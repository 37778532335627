import { useAuth, useLocalStorage } from 'hooks'
import { constLocalStorage, getTransactionLicenseService } from 'lib'
import { ITransactionLicense } from 'marketplace-common'
import React from 'react'

type Props = {
  showTransactionLicenseDialog: boolean
  transactionLicenses: ITransactionLicense[]
  transactionLicensesWithLimitClose: ITransactionLicense[]
  loadTransactionLicense: (licenseIds: string) => Promise<void>
  handleHideTransactionLicenseDialog: () => void
}

export const TransactionLicense = React.createContext({} as Props)

const TransactionLicenseProvider: React.FC = ({ children }) => {
  const { user } = useAuth()
  const { storeItem, getItem } = useLocalStorage()

  const [showTransactionLicenseDialog, setShowTransactionLicenseDialog] =
    React.useState<boolean>(false)
  const [transactionLicenses, setTransactionLicenses] = React.useState<ITransactionLicense[]>([])
  const [transactionLicensesWithLimitClose, setTransactionLicensesWithLimitClose] = React.useState<
    ITransactionLicense[]
  >([])

  const handleHideTransactionLicenseDialog = () => setShowTransactionLicenseDialog(false)

  const loadTransactionLicense = async (licenseIds: string) => {
    try {
      const result = await getTransactionLicenseService(user.identification, licenseIds)
      setTransactionLicenses(result)

      if (Boolean(result) && result.length !== 0) validateLicenseLimitTransaction(result)
    } catch (error) {
      console.error(error)
    }
  }

  const validateLicenseLimitTransaction = (transactions: ITransactionLicense[]) => {
    const transactionCloseToTheLimit = transactions.filter(
      ({ licenseLimitTransaction, currentTransaction }) =>
        currentTransaction >= licenseLimitTransaction * 0.9
    )
    setTransactionLicensesWithLimitClose(transactionCloseToTheLimit)

    const licenseCloseToExpired: number[] = getItem(constLocalStorage.LICENSE_CLOSE_TO_EXPIRED)

    const missingLicenseInLocalStorage = transactionCloseToTheLimit.some(
      ({ licenseId }) => !licenseCloseToExpired?.includes(licenseId)
    )

    if (!missingLicenseInLocalStorage) return

    storeItem(
      constLocalStorage.LICENSE_CLOSE_TO_EXPIRED,
      transactionCloseToTheLimit.map(({ licenseId }) => licenseId)
    )
    setShowTransactionLicenseDialog(true)
  }

  return (
    <TransactionLicense.Provider
      value={{
        showTransactionLicenseDialog,
        transactionLicenses,
        transactionLicensesWithLimitClose,
        loadTransactionLicense,
        handleHideTransactionLicenseDialog,
      }}
    >
      {children}
    </TransactionLicense.Provider>
  )
}

export default TransactionLicenseProvider
