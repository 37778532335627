import {
  useCart,
  useCompanyInformation,
  useFeedback,
  useInvoiceContract,
  useLocalStorage,
  useNavigate,
  useTransaction,
  useValidateCompanyInformation,
} from 'hooks'
import {
  ROUTES,
  constLocalStorage,
  createProcessesService,
  updateProcessesService,
  validateCodeLoggedService,
} from 'lib'
import {
  ICartProduct,
  ICompanyInformation,
  IProcess,
  IPromocionalCode,
  IUser,
} from 'marketplace-common'
import React from 'react'

type Hook = {
  initializeConfigureAfterLogin: (user: IUser) => Promise<void>
  loadingInitialize: boolean
}

export const useProcessHelper = (): Hook => {
  const [loadingInitialize, setLoadingInitialize] = React.useState<boolean>(false)
  const { getItem, deleteItem } = useLocalStorage()
  const {
    addProductToCart,
    handleChangeProcess,
    addSpecialProductToCart,
    productsIds,
    specialProductsId,
  } = useCart()
  const { handleSaveCompanyInformationDisabledFromLocalStorage } = useCompanyInformation()
  const { getTransactionsByProcessId, handleSaveTransaction } = useTransaction()
  const { handleSubmitInvoiceContract } = useInvoiceContract()
  const { animatedNavigation } = useNavigate()
  const { noAllFields } = useValidateCompanyInformation()
  const { showMessage } = useFeedback()

  /***
   * @description create a process when the user is logged in de Modal Login
   * @param process the process created when the user is logged in de Modal Login
   * @returns  return process created
   */
  const createProcessAfterLogin = async (process: any) => {
    try {
      const response = await createProcessesService(process)
      return response
    } catch (error) {
      console.log('error', error)
    }
  }

  /***
   * @description add product to cart when the user is logged in de Modal Login and save
   * processProducts that was saved in the local storage
   * @param process the process created when the user is logged in de Modal Login
   */
  const addProductProcessFromLocalStorage = (process: IProcess) => {
    try {
      if (!Boolean(process)) return
      const processProducts = getItem(constLocalStorage.CART_PRODUCTS)
      processProducts.forEach(async (product: ICartProduct) => {
        const request = {
          productId: Boolean(product) ? product.product!.productId : undefined,
          processId: process!.processId,
        }
        await addProductToCart(request)
      })
      deleteItem(constLocalStorage.CART_PRODUCTS)
    } catch (error) {
      console.log('error', error)
    }
  }

  /***
   * @description add specialProduct to cart when the user is logged in de Modal Login and save
   * processProducts that was saved in the local storage
   * @param process the process created when the user is logged in de Modal Login
   */
  const addSpecialProductProcessFromLocalStorage = (process: IProcess) => {
    try {
      if (!Boolean(process)) return
      const processSpecialProducts = getItem(constLocalStorage.CART_SPECIAL_PRODUCTS)

      processSpecialProducts.forEach(async (cartProduct: ICartProduct) => {
        const request = {
          specialProductId:
            cartProduct.specialProduct !== null && cartProduct.specialProduct !== undefined
              ? cartProduct.specialProduct.specialProductId
              : undefined,
          processId: process!.processId,
        }
        await addSpecialProductToCart(request)
      })
      deleteItem(constLocalStorage.CART_SPECIAL_PRODUCTS)
    } catch (error) {
      console.log('error', error)
    }
  }

  /***
   * @description assigned to column company_information_id in table process and update the process with the company information id
   * @param companyInformationId company information id will be assigned to the process
   * @returns  a promise that resolve when the process is updated
   */
  const handleAssignedCompanyInformationIdToProcess = async (
    companyInformationId: number,
    processToAssigned: IProcess
  ) => {
    try {
      const processToUpdate = { ...processToAssigned, companyInformationId } as IProcess
      const result = await updateProcessesService(processToUpdate, processToAssigned!.processId!)

      return result
    } catch (error) {
      console.log(error)
    }
  }

  /***
   * @description initialize the process when the user is logged in de Modal Login
   * @param user the user logged in de Modal Login
   */
  const createCompanyInformationFromLocalStorage = async (
    process: IProcess,
    companyInformation: ICompanyInformation
  ) => {
    try {
      const result = await handleSaveCompanyInformationDisabledFromLocalStorage({
        ...companyInformation,
        companyId: process.companyId.toString(),
      })
      const processUpdate = await handleAssignedCompanyInformationIdToProcess(result, process)
      deleteItem(constLocalStorage.COMPANY_INFO)
      return processUpdate
    } catch (error) {
      console.log('error', error)
    }
  }

  const processWithCompanyInformation = async (
    process: IProcess,
    companyInformation: ICompanyInformation
  ) => {
    const processUpdate = await createCompanyInformationFromLocalStorage(
      process,
      companyInformation
    )
    const transactions = await getTransactionsByProcessId(processUpdate.processId)
    const haveTransactions = transactions.length > 0
    const invoiceContractId = haveTransactions ? transactions[0].invoiceContractId : null
    const invoiceContract = await handleSubmitInvoiceContract(
      processUpdate.processId,
      invoiceContractId,
      process.promotionalCode !== null ? true : false
    )
    await handleSaveTransaction(invoiceContract.invoiceContractId, processUpdate, transactions[0])
  }

  /***
   * @description function to show a notification
   * @param message message to show on screen
   * @param valid type of message
   */
  const handleThrowMessage = (message: string, valid: boolean) => {
    showMessage(`${message}`, valid ? 'success' : 'error', {
      horizontal: 'right',
      vertical: 'top',
    })
  }

  /***
   * @description service for verify a promotional Code
   * @param existPromotionalCodeAndProcess the process created when the user is logged in de Modal Login
   * @param code code to entered
   * @return the response and update the process
   */
  const handleValidateCodeLogged = async (
    existPromotionalCodeAndProcess: boolean,
    code: string,
    productsIdsParam?: number[],
    specialProductsIdParam?: number[],
    processIdParam?: number
  ) => {
    try {
      if (existPromotionalCodeAndProcess) {
        const processId = processIdParam
        if (processId !== undefined) {
          const response = await validateCodeLoggedService(
            code,
            processId,
            productsIdsParam !== undefined ? productsIdsParam : productsIds,
            specialProductsIdParam !== undefined ? specialProductsIdParam : specialProductsId
          )
          if (response.process) {
            handleChangeProcess(response.process)
          }
          handleThrowMessage(response.message, response.valid)
          return response
        }
      } else {
        handleThrowMessage('Ingresa un código promocional', false)
      }
    } catch (error) {
      console.log('Error :', error)
    }
  }

  const initializeConfigureAfterLogin = async (user: IUser) => {
    const infoProcess = { companyId: user.identification, securityUserId: user.userId }
    try {
      setLoadingInitialize(true)
      const companyInformation = getItem(constLocalStorage.COMPANY_INFO)
      const existCompanyInformation = Boolean(companyInformation)
      const allFields = existCompanyInformation ? noAllFields(companyInformation) : false
      let newProcess = await createProcessAfterLogin(infoProcess)
      addProductProcessFromLocalStorage(newProcess)
      addSpecialProductProcessFromLocalStorage(newProcess)
      handleChangeProcess(newProcess)
      const promotionalCode = getItem(constLocalStorage.PROMOTIONAL_CODE) as IPromocionalCode
      if (promotionalCode !== undefined) {
        const existPromotionalCodeAndProcess =
          Boolean(promotionalCode.code.length > 0) && Boolean(newProcess !== undefined)
        const response = await handleValidateCodeLogged(
          existPromotionalCodeAndProcess,
          promotionalCode.code,
          undefined,
          undefined,
          newProcess.processId
        )
        if (response?.process?.promotionalCode !== null && response?.valid) {
          deleteItem(constLocalStorage.PROMOTIONAL_CODE)
        }
        if (response?.process !== null) {
          newProcess = response?.process
        }
      }

      const shopAnotherAccountLocal =
        Boolean(getItem(constLocalStorage.SHOP_ANOTHER_ACCOUNT)) &&
        getItem(constLocalStorage.SHOP_ANOTHER_ACCOUNT) === true
      if (allFields) {
        await processWithCompanyInformation(newProcess, companyInformation)
      }

      if (shopAnotherAccountLocal) {
        const processToUpdate = { ...newProcess, assignedCompanyId: null }
        const result = await updateProcessesService(processToUpdate, processToUpdate!.processId)
        handleChangeProcess(result)
      }
      deleteItem(constLocalStorage.PROCESS_CREATE)

      const encryptRoute = `${ROUTES.processes}/nuna`
      animatedNavigation(encryptRoute)
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoadingInitialize(false)
    }
  }

  return {
    initializeConfigureAfterLogin,
    loadingInitialize,
  }
}
