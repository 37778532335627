export const RUC_ID = '04'
export const IDENTIFICATION_CARD_ID = '05'
export const FINAL_COSTUMER_ID = '07'
export const PASSPORT = '06'
export const OUTSIDE_ID = '08'

export const REQUIRE_VALIDATION_TYPES = [
  RUC_ID,
  IDENTIFICATION_CARD_ID,
  FINAL_COSTUMER_ID,
  PASSPORT,
  OUTSIDE_ID,
]
