import {
  Avatar,
  Box,
  Grid,
  Icon,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { CardPromo, ProcessToolbar, ProductCard } from 'components'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import {
  useAuth,
  useCart,
  useGetProducts,
  useGetSolutions,
  useLocalStorage,
  useProcess,
} from 'hooks'
import { GA_EVENT, REFERENCE_SPECIAL_PRODUCTS, constLocalStorage } from 'lib'
import { IProduct, IPromocionalCode } from 'marketplace-common'
import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useParams } from 'react-router-dom'
import { MAIN_COLORS, SOLUTION_COLORS } from 'styles'
import { SOLUTION, StepReferenceName } from 'types'
import { trackGoogleAnalyticsEventWithData } from 'utils'
import { SolutionAvatar } from './SolutionAvatar'

export const PickProduct = () => {
  const {
    solution,
    process: processCart,
    switchNextStep,
    addEnableSteps,
    isPromoReference,
    specialProducts,
    filterSpecialProductsSelect,
    haveSpecialProducts,
    handleChangePromoReference,
    loadingValidate,
    handleValidateCodeLogged: handleValidateCodeLoged,
    handleValidateCodeNotLogged: handleValidateCodeNotLoged,
    code,
    handleChangeCode,
  } = useProcess()
  const { solutions } = useGetSolutions()
  const { products, getProductsByMainSolution, loadingProducts, handleOrderProducts } =
    useGetProducts(solution?.mainSolutionId)
  const {
    addProductToCart,
    addProductToCartNotLoged,
    isProductOnTheCart,
    nonEmptyCart,
    loadingShoppingCart,
    cartProducts,
  } = useCart()
  const { isAuthenticated, user } = useAuth()
  const { getItem, deleteItem, storeItem } = useLocalStorage()
  const [showMoreSolutions, setShowMoreSolutions] = React.useState<boolean>(true)
  const [solutionFilterType, setSolutionFilterType] = React.useState<number | 'promo'>(0)
  const { mainSolutionReference } = useParams()
  const controls = useAnimation()
  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null)
  const handleOnChangeSelectedProduct = async (mainSolutionId: number) => {
    await getProductsByMainSolution(mainSolutionId)
    handleChangePromoReference(false)
  }

  const { breakpoints } = useTheme()
  const isMobile = useMediaQuery(breakpoints.down('md'))

  const handleOnChangeSelectedSpecialProduct = () => {
    handleChangePromoReference(true)
  }
  const moreProductsForScroll: boolean = React.useMemo(() => {
    return products.length > 4
  }, [products])

  const existPromotionalCodeAndProcess: boolean = React.useMemo(() => {
    return processCart !== undefined && processCart?.promotionalCode?.code !== undefined
  }, [processCart])

  const promotionalCode = getItem(constLocalStorage.PROMOTIONAL_CODE) as IPromocionalCode
  const exitsPromotionalCodeLocal = React.useMemo(() => {
    if (!isAuthenticated && promotionalCode !== undefined) {
      return promotionalCode
    } else {
      return undefined
    }
  }, [isAuthenticated, promotionalCode])

  const deletePromotionalCode = () => {
    deleteItem(constLocalStorage.PROMOTIONAL_CODE)
  }

  const savePromotionalCode = (promotionalCode: IPromocionalCode) => {
    storeItem(constLocalStorage.PROMOTIONAL_CODE, promotionalCode)
  }

  const handleBtnContinue = async () => {
    if (isAuthenticated && existPromotionalCodeAndProcess) {
      await handleValidateCodeLoged(
        existPromotionalCodeAndProcess,
        processCart!.promotionalCode!.code
      )
    }

    if (!isAuthenticated && Boolean(exitsPromotionalCodeLocal)) {
      const response = await handleValidateCodeNotLoged(Boolean(promotionalCode), code)

      if (response?.valid) {
        savePromotionalCode(response.promotionalCode)
      } else {
        deletePromotionalCode()
        handleChangeCode('')
      }
    }

    addEnableSteps(StepReferenceName.PICK_PRODUCT)
    nonEmptyCart && switchNextStep()
  }

  const addProductUserLoged = async (product: IProduct) => {
    if (!Boolean(processCart)) return

    const request = {
      productId: product.productId,
      processId: processCart!.processId,
    }
    const { identification, userId } = user
    const gaData = {
      user_id: userId.toString(),
      company_id: identification,
      currency: 'USD',
      value: product.price,
      items: [
        {
          item_id: product.productId.toString(),
          item_name: product.name,
          price: product.subTotalPrice,
          quantity: 1,
        },
      ],
    }
    trackGoogleAnalyticsEventWithData(
      'Card',
      GA_EVENT.ADD_TO_CART_EVENT,
      'Producto agregado',
      gaData
    )
    await addProductToCart(request)

    ReactPixel.track('AddToCart', {
      content_id: 'P' + product?.productId,
      value: product.price,
      currency: 'USD',
      content_name: product.name,
      detail: product.description,
    })
  }

  const saveProcessCreateDate = () => {
    const currentDate = new Date()
    const formattedDate = currentDate.toISOString().slice(0, 10)
    storeItem(constLocalStorage.PROCESS_CREATE, formattedDate)
  }

  const addProductNotLogged = (product: IProduct) => {
    saveProcessCreateDate()
    addProductToCartNotLoged(product)

    ReactPixel.track('AddToCart', {
      content_id: 'P' + product?.productId,
      value: product.price,
      currency: 'USD',
      content_name: product.name,
      detail: product.description,
    })
  }

  const loadingAdd = React.useMemo(
    () =>
      loadingShoppingCart.specialProductId.length > 0 || loadingShoppingCart.productId.length > 0,
    [loadingShoppingCart]
  )

  const renderProducts = React.useMemo(() => {
    return !loadingProducts && (!isPromoReference || !filterSpecialProductsSelect)
  }, [filterSpecialProductsSelect, isPromoReference, loadingProducts])

  const renderSpecialProducts = React.useMemo(() => {
    return isPromoReference || filterSpecialProductsSelect
  }, [filterSpecialProductsSelect, isPromoReference])

  const getSelectedSolution = () => {
    if (isPromoReference || filterSpecialProductsSelect) return undefined
    if (solutionFilterType === 'promo') return undefined
    if (solutions === undefined) return undefined
    return solutions!.find((solution) => solution.mainSolutionId === solutionFilterType)
  }

  const getIconOfSolutionIcon = () => {
    if (isPromoReference || filterSpecialProductsSelect) return undefined
    if (solutionFilterType === 'promo') return undefined
    if (solutions === undefined) return undefined

    const solutionId = getSelectedSolution()?.mainSolutionId
    switch (solutionId) {
      case SOLUTION.QUINDE_FEE:
        return 'description_icon'
      case SOLUTION.NUNA_KEY:
        return 'key_icon'
      case SOLUTION.ALPAQQA:
        return 'inventory_icon'
      case SOLUTION.MICO_DOCS:
        return 'receipt_icon'
      case SOLUTION.QREATIVEE:
        return 'emoji_objects_icon'
    }
  }

  const getProductsValidateBySubType = (products: IProduct[]): IProduct[] => {
    let groupedBySubProductType: { [key: string]: IProduct[] } = products.reduce(
      (productsGrouped: { [key: string]: IProduct[] }, product: IProduct) => {
        const key = product.subProductTypeId ?? '_' + product.productId
        if (!productsGrouped[key]) {
          productsGrouped[key] = []
        }
        productsGrouped[key].push(product)
        return productsGrouped
      },
      {}
    )
    const newProducts = Object.values(groupedBySubProductType).map((products) => {
      if (products.length === 1) return products[0]
      return { ...products[0], validProducts: products } as IProduct
    })
    return handleOrderProducts(newProducts) as IProduct[]
  }

  React.useEffect(() => {
    if (
      isPromoReference ||
      filterSpecialProductsSelect ||
      mainSolutionReference === REFERENCE_SPECIAL_PRODUCTS
    ) {
      setSolutionFilterType('promo')
      handleChangePromoReference(true)
      return
    }
    if (Boolean(solution)) {
      setSolutionFilterType(solution!.mainSolutionId)
    }
  }, [])

  const handleScrollButtonClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (scrollContainerRef.current) {
      const lastChildElement = scrollContainerRef.current.lastElementChild
      lastChildElement?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  const validateOnSelect = (product: IProduct): boolean => {
    const existProduct = Boolean(product)
    if (!existProduct) {
      return false
    }
    const existsValidProducts = Boolean(product?.validProducts)
    if (!existsValidProducts) {
      return isProductOnTheCart(product.productId)
    }
    const anySelected = product?.validProducts
      ?.map((product) => isProductOnTheCart(product.productId))
      .some((item) => item)

    return Boolean(anySelected)
  }

  const getValidProduct = (product: IProduct) => {
    const existsValidProduct = Boolean(product.validProducts)
    if (!existsValidProduct) return null

    const cartProductsMap: Record<string, IProduct> = {}
    cartProducts.forEach((cartProduct) => {
      if (cartProduct?.product?.productId)
        cartProductsMap[cartProduct.product.productId] = cartProduct.product
    })
    const correctProduct = product.validProducts?.find(({ productId }) =>
      Boolean(cartProductsMap[productId])
    )

    return correctProduct ?? null
  }

  React.useEffect(() => {
    const bounce = async () => {
      while (true) {
        await controls.start({ y: -10, transition: { duration: 0.5 } })
        await controls.start({ y: 0, transition: { duration: 0.5 } })
        await new Promise((resolve) => setTimeout(resolve, 2000))
      }
    }
    bounce()
    return () => controls.stop()
  }, [controls])

  return (
    <React.Fragment>
      <ProcessToolbar
        handleBtnContinue={handleBtnContinue}
        hideButton={!nonEmptyCart}
        loading={loadingAdd || loadingValidate}
        title={getSelectedSolution()?.description}
        titleIcon={getIconOfSolutionIcon()}
      />
      <Grid
        pb={{ xs: 14, md: 3 }}
        container
        alignItems={'center'}
        display="flex"
        flexDirection="column"
      >
        <Box
          height={'85%'}
          display="flex"
          flexDirection={'row'}
          width={'90%'}
          justifyContent={'center'}
          alignItems={'center'}
          textAlign={'center'}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
              py: 2,
            }}
          >
            {!isPromoReference && !Boolean(getSelectedSolution()) && ''}
            <Typography
              variant="h5"
              fontWeight={700}
              sx={{
                py: { xs: '20px', sm: '10px' },
                fontSize: { xs: '1rem', sm: '1.5rem' },
                color: MAIN_COLORS.primary.hover,
                opacity: 0.7,
              }}
            >
              {Boolean(getSelectedSolution()) &&
                !isPromoReference &&
                `Selecciona el producto de ${getSelectedSolution()!.name}`}

              {(isPromoReference || filterSpecialProductsSelect) &&
                `Selecciona la oferta que más te guste`}
            </Typography>
            {Boolean(getSelectedSolution()) && (
              <SolutionAvatar
                isSelected
                disableHover
                name={getSelectedSolution()!.name ?? ''}
                src={getSelectedSolution()!.logo ?? ''}
                handleOnClick={() => {}}
                disabledRotationAnimation
                disableSolutionNameTitle
              />
            )}
            {(isPromoReference || filterSpecialProductsSelect) && (
              <SolutionAvatar
                isSelected
                name={'Ofertas'}
                icon={'local_offer'}
                backgroundColor={SOLUTION_COLORS.promo.primary.main}
                color={SOLUTION_COLORS.promo.primary.dark}
                handleOnClick={() => {}}
                disabledRotationAnimation
                disableHover
                disableSolutionNameTitle
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
          minWidth={'90%'}
          maxWidth={'90%'}
        >
          {/* Filtro solución*/}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 1,
              px: { xs: 1, sm: 1 },
              width: { xs: 45, sm: 50, md: 60 },
              minWidth: { xs: 45, sm: 50, md: 60 },
            }}
            id="solution-filter"
            component={motion.div}
          >
            <Tooltip
              title={showMoreSolutions ? 'Ocultar productos' : 'Ver más productos'}
              arrow
              placement="right"
            >
              <Box>
                <Avatar
                  component={motion.div}
                  whileHover={{
                    scale: 1,
                    cursor: 'pointer',
                  }}
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: 0.3,
                    ease: [0, 0.71, 0.2, 1.01],
                    scale: {
                      type: 'spring',
                      damping: 5,
                      stiffness: 100,
                      restDelta: 0.001,
                    },
                  }}
                  sx={{
                    cursor: 'pointer',
                    width: { xs: 35, sm: 40, md: 50 },
                    height: { xs: 35, sm: 40, md: 50 },
                    backgroundColor: 'secondary.main',
                    color: 'white',
                  }}
                  onClick={() => {
                    setShowMoreSolutions((prev) => !prev)
                  }}
                >
                  <Icon sx={{ fontSize: { xs: 28, sm: 32, md: 40 } }}>
                    {showMoreSolutions ? 'close' : 'more_vert'}
                  </Icon>
                </Avatar>
              </Box>
            </Tooltip>
            <AnimatePresence>
              {haveSpecialProducts && showMoreSolutions && (
                <Box>
                  <SolutionAvatar
                    isSelected={solutionFilterType === 'promo'}
                    name={'Ofertas'}
                    icon={'local_offer'}
                    backgroundColor={SOLUTION_COLORS.promo.primary.main}
                    color={SOLUTION_COLORS.promo.primary.dark}
                    handleOnClick={() => {
                      if (solutionFilterType === 'promo') {
                        return
                      }
                      handleOnChangeSelectedSpecialProduct()
                      setSolutionFilterType('promo')
                    }}
                  />
                </Box>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {showMoreSolutions &&
                solutions
                  .filter((solution) => solution.mainSolutionId !== 6 && solution.enabled)
                  .map((solution) => (
                    <Box key={solution.mainSolutionId}>
                      <SolutionAvatar
                        isSelected={solutionFilterType === solution.mainSolutionId}
                        name={solution.name}
                        src={solution.logo}
                        handleOnClick={() => {
                          const handleSelectMainSolution = async () => {
                            await handleOnChangeSelectedProduct(solution.mainSolutionId)
                          }
                          if (solutionFilterType === solution.mainSolutionId) return
                          setSolutionFilterType(solution.mainSolutionId)
                          handleSelectMainSolution()
                        }}
                      />
                    </Box>
                  ))}
            </AnimatePresence>
            {moreProductsForScroll && (
              <Box sx={{ paddingTop: 1 }}>
                <Tooltip title="Ir al final">
                  <motion.div animate={controls}>
                    <Avatar
                      onClick={(e) => handleScrollButtonClick(e)}
                      sx={{
                        cursor: 'pointer',
                        width: { xs: 35, sm: 40, md: 50 },
                        height: { xs: 35, sm: 40, md: 50 },
                        backgroundColor: 'secondary.main',
                        color: 'white',
                      }}
                    >
                      <Icon sx={{ fontSize: '32px' }}>arrow_downward</Icon>
                    </Avatar>
                  </motion.div>
                </Tooltip>
              </Box>
            )}
          </Box>
          {/*Filtro solución */}
          {/* Productos */}
          <Box
            display="flex"
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            id="products"
            width={'100%'}
            maxWidth={'100%'}
            overflow={'hidden'}
            height={'100%'}
          >
            <Box
              paddingY="30px"
              ref={scrollContainerRef}
              width={'94%'}
              minWidth={'94%'}
              maxWidth={'94%'}
              maxHeight={Boolean(isMobile) ? '100%' : '30%'}
              sx={{
                display: 'flex',
                flexWrap: { xs: 'wrap', sm: 'nowwrap', md: 'wrap', lg: 'wrap' },
                gap: { xs: '18px', sm: '28px', md: '42px' },
                justifyContent: 'center',
              }}
            >
              {specialProducts.map((specialProduct, index) => {
                if (
                  specialProduct.specialProducts.at(0)?.productInformation.mainSolutionId ===
                    solutionFilterType &&
                  specialProduct.specialProducts.length === 1
                ) {
                  const mainSolutionLogos: string[] = specialProduct.specialProducts.map(
                    (product) =>
                      solutions?.find(
                        (solution) =>
                          solution.mainSolutionId === product.productInformation.mainSolutionId
                      )?.logo ?? ''
                  )
                  return (
                    <Box key={specialProduct.specialProductId}>
                      <CardPromo
                        specialProduct={specialProduct}
                        mainSolutionLogos={mainSolutionLogos}
                        handleBtnContinue={handleBtnContinue}
                        identifierOffer={true}
                      />
                    </Box>
                  )
                }
              })}
              {renderProducts &&
                getProductsValidateBySubType(products).map((product) => (
                  <Box key={product.productId}>
                    <ProductCard
                      product={product}
                      validProduct={getValidProduct(product)}
                      onSelect={async (product) => {
                        isAuthenticated
                          ? await addProductUserLoged(product)
                          : addProductNotLogged(product)
                      }}
                      selected={validateOnSelect(product)}
                      mainSolutionLogo={
                        solutions?.find(
                          (solution) => solution.mainSolutionId === product.mainSolutionId
                        )?.logo ?? ''
                      }
                      handleBtnContinue={handleBtnContinue}
                    />
                  </Box>
                ))}

              {renderSpecialProducts &&
                specialProducts.map((specialProduct) => {
                  const mainSolutionLogos: string[] = specialProduct.specialProducts.map(
                    (product) =>
                      solutions?.find(
                        (solution) =>
                          solution.mainSolutionId === product.productInformation.mainSolutionId
                      )?.logo ?? ''
                  )
                  return (
                    <Box key={specialProduct.specialProductId}>
                      <CardPromo
                        specialProduct={specialProduct}
                        mainSolutionLogos={mainSolutionLogos}
                        handleBtnContinue={handleBtnContinue}
                      />
                    </Box>
                  )
                })}
            </Box>
          </Box>
          {/* Productos */}
        </Box>
      </Grid>
    </React.Fragment>
  )
}
