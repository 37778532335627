import { IJsonGetter } from 'types'

export const COMPLETED_TRANSACTION_ID = 1
export const REJECTED_TRANSACTION_ID = 2
export const REVERTED_TRANSACTION_ID = 3
export const PENDING_TRANSACTION_ID = 6
export const INIT_TRANSACTION_ID = 8

export const TRANSACTION_ICONS: IJsonGetter = {
  1: 'check',
  2: 'cancel',
  3: 'settings_backup_restore',
  6: 'access_time',
  7: 'history',
}

export const TRANSACTION_STATES: IJsonGetter = {
  1: 'Realizado',
  2: 'Rechazado',
  3: 'Revertido',
  6: 'Pendiente',
  7: 'Por revertir',
}

export const TRANSACTION_METHODS = {
  transfer: 'Transferencia',
  card: 'Tarjeta',
}
