import { Browser } from 'lib'
import { IBrands, INavigatorUAData } from 'marketplace-common'

export const getBrowser = (userAgent: string, userAgentData?: INavigatorUAData) => {
  const agentBrowser = userAgent
  let navegador = Browser.UNKNOWN

  const isFirefoxNavigator = agentBrowser.indexOf(Browser.FIREFOX) !== -1
  const isEdgeNavigator = agentBrowser.indexOf(Browser.CODE_EDGE) !== -1
  const isChromeIOSNavigator = agentBrowser.indexOf(Browser.CODE_CHROME_IOS) !== -1
  const isFirefoxIOSNavigator = agentBrowser.indexOf(Browser.CODE_FIREFOX_IOS) !== -1
  const isOperaNavigator =
    agentBrowser.indexOf(Browser.CODE_OPERA) !== -1 || agentBrowser.indexOf(Browser.OPERA) !== -1
  const isBraveNavigator =
    userAgentData &&
    userAgentData.brands.some((brand: IBrands) => {
      return brand.brand === Browser.BRAVE
    })

  if (isFirefoxNavigator) {
    navegador = Browser.FIREFOX
  } else if (isEdgeNavigator) {
    navegador = Browser.EDGE
  } else if (isChromeIOSNavigator) {
    navegador = Browser.CHROME_IOS
  } else if (isFirefoxIOSNavigator) {
    navegador = Browser.FIREFOX_IOS
  } else if (isOperaNavigator) {
    navegador = Browser.OPERA
  } else if (isBraveNavigator) {
    navegador = Browser.BRAVE
  } else {
    navegador = Browser.CHROME
  }
  return navegador
}
