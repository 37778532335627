import { LoadingButton } from '@mui/lab'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Icon,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { AnimatedLink, PasswordStrengthIndicator } from 'components'
import { motion } from 'framer-motion'
import {
  LEVELS,
  useCreateUser,
  useFeedback,
  useGetIdentificationTypes,
  useOpenMenu,
  usePasswordStrength,
  useToggle,
} from 'hooks'
import {
  FINAL_COSTUMER_ID,
  IDENTIFICATION_CARD_ID,
  OUTSIDE_ID,
  PASSPORT,
  ROUTES,
  RUC_ID,
} from 'lib'
import React from 'react'
import { Link } from 'react-router-dom'
import { IUserRequest, Options } from 'types'

type Props = {
  handleChangeOption?: (option: Options) => void
  fromModal?: boolean
  handleOpenVerification?: (value: boolean) => void
  getDataUser?: (user: IUserRequest) => void
  onClickSignUp?: (user: IUserRequest) => void
}

export const SignUpForm = (props: Props) => {
  const { handleChangeOption, fromModal, handleOpenVerification, getDataUser, onClickSignUp } =
    props
  const identificationRef = React.useRef<any>(null)
  const { identificationTypes } = useGetIdentificationTypes()
  const { toggle, value: show } = useToggle()
  const { showMessage } = useFeedback()

  const [acceptTerms, setAcceptTerms] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
  const [selectedIdentificationType, setSelectedIdentificationType] = React.useState('04')

  const {
    createUser,
    handleChange: handleChangeUser,
    validateField,
    user,
    loading,
    errors,
    reasons,
    validated,
    creating,
  } = useCreateUser()
  const {
    value: passwordFocused,
    setTrue: showIndicator,
    setFalse: hideIndicator,
  } = useToggle(false)
  const { contains, strength, diversity } = usePasswordStrength(user.password)
  const { strength: strengthPasswordConfirmation } = usePasswordStrength(user.passwordConfirmation)

  const { anchorEl, handleClick, handleClose, open } = useOpenMenu()

  const handleChangeVerification = () => {
    if (handleChangeOption !== undefined && handleOpenVerification !== undefined) {
      handleChangeOption('verification')
      handleOpenVerification(true)
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!acceptTerms) {
      showMessage('Primero debe aceptar los términos y condiciones', 'error')
      return
    }
    const userResponse = await createUser(fromModal, handleChangeVerification)
    if (userResponse !== undefined && getDataUser !== undefined) {
      getDataUser(userResponse)

      if (onClickSignUp) {
        onClickSignUp(userResponse!)
      }
    }
    setAcceptTerms(false)
  }

  const handleAcceptTerms = () => setAcceptTerms(!acceptTerms)

  const disableIdentification = React.useMemo(() => loading.includes('identification'), [loading])
  const identificationError = React.useMemo(() => errors.includes('identification'), [errors])

  const disableEmail = React.useMemo(() => loading.includes('email'), [loading])
  const emailError = React.useMemo(() => errors.includes('email'), [errors])

  const passwordError = React.useMemo(() => errors.includes('password'), [errors])

  const isSamePassword = React.useMemo(() => {
    const existPasswords = user.password && user.passwordConfirmation
    const passwordsInRage =
      existPasswords && user.password.length >= 9 && user.passwordConfirmation!.length >= 9
    const passwordsAreSame = user.password === user.passwordConfirmation
    const maximumStrength = strengthPasswordConfirmation === LEVELS[3] && strength === LEVELS[3]
    const minimStrength = strengthPasswordConfirmation === LEVELS[2] && strength === LEVELS[2]
    const validStrength = maximumStrength || minimStrength

    return passwordsInRage && passwordsAreSame && validStrength
  }, [user, strengthPasswordConfirmation, strength, LEVELS])

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const placement = isMobile ? 'top' : 'right'

  return (
    <Stack
      spacing={{ xs: 0.5, sm: 1, md: 1.5 }}
      onSubmit={handleSubmit}
      component="form"
      justifyContent="center"
      id="sign-up-form"
    >
      <Stack spacing={{ xs: 0.5, sm: 1, md: 1.5 }}>
        <Box
          component={motion.div}
          animate={{ y: 0, opacity: 1 }}
          initial={{ y: 10, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Alert severity="info" color="warning">
            <AlertTitle>Recuerda</AlertTitle>
            Esta identificación será utilizada para vincular los productos que adquieras.
          </Alert>
        </Box>
        <Box display={'flex'} gap={1} flexDirection={{ xs: 'column', sm: 'row' }}>
          <Box sx={{ width: '100%' }}>
            <Typography fontWeight={600} variant="caption">
              {'Tipo de Identificación'}
            </Typography>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {identificationTypes
                .filter((i) => i.identificationTypeId !== FINAL_COSTUMER_ID)
                .map((identificationType) => (
                  <MenuItem
                    key={identificationType.identificationTypeId}
                    onClick={() => {
                      setTimeout(() => {
                        identificationRef.current?.focus()
                      }, 200)
                      handleClose()
                      handleChangeUser(
                        'identificationTypeId',
                        identificationType.identificationTypeId
                      )
                      setSelectedIdentificationType(identificationType.identificationTypeId)
                    }}
                    dense
                    selected={user.identificationTypeId === identificationType.identificationTypeId}
                  >
                    {identificationType.description}
                  </MenuItem>
                ))}
            </Menu>
            <Button
              fullWidth
              variant="outlined"
              sx={{ py: 1.25, pl: 2, justifyContent: 'space-between', display: 'flex' }}
              disableRipple
              onClick={handleClick}
              tabIndex={-1}
            >
              <Typography variant="caption" fontWeight={600}>
                {user.identificationTypeId
                  ? identificationTypes.find(
                      (i) => i.identificationTypeId === user.identificationTypeId
                    )?.description
                  : identificationTypes[0]?.description ?? 'Tipo de identificación'}
              </Typography>
              <Icon sx={{ ml: 0.5 }}>keyboard_arrow_down</Icon>
            </Button>
          </Box>

          <Box sx={{ width: '100%' }}>
            <Typography fontWeight={600} variant="caption">
              {'Identificación'}
            </Typography>
            <TextField
              autoFocus
              fullWidth
              inputRef={identificationRef}
              value={user.identification}
              onChange={(e) => {
                const value = e.target.value
                let finalValue = value
                if ([RUC_ID, IDENTIFICATION_CARD_ID].includes(selectedIdentificationType)) {
                  finalValue = value.replace(/[^0-9]/g, '')
                } else if ([PASSPORT, OUTSIDE_ID].includes(selectedIdentificationType)) {
                  finalValue = value.replace(/[^a-zA-Z0-9]/g, '')
                }
                handleChangeUser('identification', finalValue)
              }}
              required
              disabled={disableIdentification}
              onBlur={() => {
                validateField('identification')
              }}
              error={identificationError}
              helperText={reasons.identification}
              FormHelperTextProps={{ sx: { m: 0, pl: 0.5, pt: 0.5 } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {disableIdentification && <CircularProgress size={12} thickness={7} />}
                    {validated.identification && !disableIdentification && (
                      <Icon color="success">check_circle_outline</Icon>
                    )}
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: [RUC_ID].includes(selectedIdentificationType)
                  ? 13
                  : [IDENTIFICATION_CARD_ID].includes(selectedIdentificationType)
                  ? 10
                  : [PASSPORT].includes(selectedIdentificationType)
                  ? 12
                  : 15,
              }}
            />
          </Box>
        </Box>
        <Typography fontWeight={600} variant="caption">
          {'Correo electrónico'}
        </Typography>
        <TextField
          placeholder="quindefee@biosferasoft.com"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {disableEmail && <CircularProgress size={12} thickness={7} />}
                {validated.email && !disableEmail && (
                  <Icon color="success">check_circle_outline</Icon>
                )}
              </InputAdornment>
            ),
          }}
          value={user.email}
          onBlur={() => validateField('email')}
          disabled={disableEmail}
          error={emailError}
          helperText={reasons.email}
          FormHelperTextProps={{ sx: { m: 0, pl: 0.5, pt: 0.5 } }}
          onChange={(e) => handleChangeUser('email', e.target.value)}
          required
        />
        <Typography fontWeight={600} variant="caption">
          {'Contraseña'}
        </Typography>
        <Stack display={'flex'} flexDirection={{ xs: 'column', sm: 'row' }} gap={1}>
          <PasswordStrengthIndicator
            open={passwordFocused && Boolean(user.password)}
            strength={strength}
            contains={contains}
            placement={placement}
            diversity={diversity}
          >
            <TextField
              fullWidth
              type={show ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={toggle} variant="text">
                      {show ? 'Ocultar' : 'Mostrar'}
                    </Button>
                    {validated.password && <Icon color="success">check_circle_outline</Icon>}
                  </InputAdornment>
                ),
              }}
              value={user.password}
              onChange={(e) => handleChangeUser('password', e.target.value)}
              onFocus={showIndicator}
              error={passwordError}
              onBlur={() => {
                hideIndicator()
                validateField('password')
              }}
              required
            />
          </PasswordStrengthIndicator>

          <TextField
            fullWidth
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button onClick={toggleConfirmPasswordVisibility} variant="text">
                    {showConfirmPassword ? 'Ocultar' : 'Mostrar'}
                  </Button>
                  {validated.passwordConfirmation && (
                    <Icon color="success">check_circle_outline</Icon>
                  )}
                </InputAdornment>
              ),
            }}
            value={user.passwordConfirmation}
            onChange={(e) => {
              handleChangeUser('passwordConfirmation', e.target.value)
            }}
            error={passwordError}
            onBlur={() => {
              validateField('passwordConfirmation')
            }}
            required
          />
        </Stack>

        <FormControlLabel
          control={<Checkbox checked={acceptTerms} onChange={handleAcceptTerms} />}
          label={
            <Box sx={{ display: 'flex' }}>
              <Typography pr={'2px'} variant="body2">
                {'Aceptar'}
              </Typography>
              <Link to={`/${ROUTES.feedback}/${ROUTES.terms}`} target="_blank">
                <Typography variant="body2" sx={{ textDecoration: 'underline' }}>
                  {'términos y condiciones'}
                </Typography>
              </Link>
            </Box>
          }
        />
      </Stack>
      <Stack alignItems={'center'} spacing={2} py={{ xs: 1, sm: 1, md: 1, lg: 1.5, xl: 1.5 }}>
        <LoadingButton
          fullWidth
          size="medium"
          type="submit"
          loading={creating}
          disabled={!isSamePassword}
        >
          {'Registrarme'}
        </LoadingButton>
      </Stack>
      <Stack alignItems="center" pb={{ xs: 3, sm: 4, md: 4 }}>
        {fromModal ? (
          <Button
            fullWidth
            onClick={() => {
              if (fromModal && handleChangeOption) {
                handleChangeOption(ROUTES.login as Options)
              }
            }}
          >
            <Typography
              display={'flex'}
              fontWeight={500}
              variant="subtitle2"
              sx={{ textDecoration: 'underline', display: 'flex', justifyContent: 'center' }}
            >
              {' Inicia sesión aquí'}
              <br />
              {'¿Ya tienes una cuenta? '}
            </Typography>
          </Button>
        ) : (
          <AnimatedLink to={`${ROUTES.login}`} type={'circle'} style={{ width: '100%' }} fullWidth>
            <Button fullWidth>
              <Typography
                display={'flex'}
                fontWeight={500}
                variant="subtitle2"
                sx={{ textDecoration: 'underline', display: 'flex', justifyContent: 'center' }}
              >
                {' Iniciar Sesión'}
              </Typography>
            </Button>
          </AnimatedLink>
        )}
      </Stack>
    </Stack>
  )
}
