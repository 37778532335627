import {
  createTransactionByProcessAndInvoiceContractService,
  createTransactionService,
  getTransactionsByCompanyIdService,
  getTransactionsByProcessIdService,
  updateTransactionService,
} from 'lib'
import { IProcess, ITransaction } from 'marketplace-common'
import React from 'react'
import { ITransactionRequest } from 'types'

type Hook = {
  loading: boolean
  transactions: ITransaction[]
  getTransactionsByCompanyId: (companyId: string) => Promise<ITransaction[]>
  getTransactionsByProcessId: (processId: number) => Promise<ITransaction[]>
  createTransaction: (request: ITransactionRequest) => Promise<ITransaction | undefined>
  handleUpdateTransaction: (transactionId: number, processId: number) => void
  handleCreateTransactionByProcessAndInvoiceContract: (
    invoiceContractId: number,
    processId: number
  ) => void
  handleSaveTransaction: (
    invoiceContractId: number,
    process: IProcess,
    transaction?: ITransaction
  ) => Promise<ITransaction | undefined>
}

export const useTransaction = (): Hook => {
  const [loading, setLoading] = React.useState(false)
  const [transactions, setTransactions] = React.useState<ITransaction[]>([])

  const createTransaction = async (request: ITransactionRequest) => {
    try {
      setLoading(true)
      const result = await createTransactionService(request)
      setLoading(false)
      return result
    } catch (error: any) {
      setLoading(false)
      throw new Error(error.message ?? 'No se pudo crear la transacción')
    }
  }

  const handleCreateTransactionByProcessAndInvoiceContract = async (
    invoiceContractId: number,
    processId: number
  ) => {
    const existsProcessId: boolean = Boolean(processId)
    if (!existsProcessId) {
      return
    }
    setLoading(true)

    try {
      const transaction = await createTransactionByProcessAndInvoiceContractService(
        processId,
        invoiceContractId
      )
      return transaction
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const getTransactionsByCompanyId = async (companyId: string) => {
    try {
      setLoading(true)
      const result = await getTransactionsByCompanyIdService(companyId)
      setTransactions(result)
      return result
    } catch (error) {
      console.log(error)
      throw new Error('No se pudo obtener las transacciones')
    } finally {
      setLoading(false)
    }
  }

  const getTransactionsByProcessId = async (processId: number) => {
    try {
      setLoading(true)
      const result = await getTransactionsByProcessIdService(processId)
      setTransactions(result)
      return result
    } catch (error) {
      console.log(error)
      throw new Error('No se pudo obtener las transacciones')
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateTransaction = async (transactionId: number, processId: number) => {
    const transactionRequest = {
      processId,
    }
    try {
      setLoading(true)
      const transaction = await updateTransactionService(transactionId, transactionRequest)
      return transaction
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  /***
   * @description validate if transaction exists, if exists update transaction, else create transaction
   */
  const handleSaveTransaction = async (
    invoiceContractId: number,
    process: IProcess,
    transaction?: ITransaction
  ) => {
    const alreadyExistsTransaction = Boolean(transaction)
    try {
      if (alreadyExistsTransaction) {
        const transactionCreated = await handleUpdateTransaction(
          transaction!.transactionId,
          process.processId
        )
        return transactionCreated
      }
      const transactionCreated = await handleCreateTransactionByProcessAndInvoiceContract(
        invoiceContractId,
        process.processId
      )
      return transactionCreated
    } catch (error) {
      console.log('error', error)
    }
  }

  return {
    loading,
    transactions,
    getTransactionsByCompanyId,
    getTransactionsByProcessId,
    createTransaction,
    handleUpdateTransaction,
    handleCreateTransactionByProcessAndInvoiceContract,
    handleSaveTransaction,
  }
}
