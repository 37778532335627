import { Box, Skeleton } from '@mui/material'

type Props = {
  mobileView: boolean
}

export const StepsSekeleton = (props: Props) => {
  const { mobileView } = props
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2.8,
        alignItems: 'flex-start',
        mt: 1.6,
      }}
    >
      {Array.from({ length: 4 }).map((_, index) => (
        <Box
          key={Math.random()+index+Math.random()+Math.random()+Math.random()*Math.random()}
          sx={{
            display: 'flex',
            flexDirection: mobileView ? 'column' : 'row',
            justifyContent: 'center',
            gap: mobileView ? 0.1 : 1.5,
            alignItems: 'center',
          }}
        >
          <Skeleton
            variant="circular"
            sx={{ width: { xs: 40 }, height: { xs: 40 } }}
          />
          <Skeleton
            variant="text"
            sx={{ width: mobileView ? 70 : 90, height: { xs: 25 } }}
          />
        </Box>
      ))}
    </Box>
  )
}
