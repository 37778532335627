import { useRoutes } from 'react-router-dom'
import FeedbackRoutes from './FeedbackRoutes'
import ProcessRoutes from './ProcessRoutes'
import ProductRoutes from './ProductRoutes'
import PublicRoutes from './PublicRoutes'
import ShopRoutes from './ShopRoutes'
import SupportRoutes from './SupportRoutes'
import UserRoutes from './UserRoutes'

export default function Routes() {
  return useRoutes([
    PublicRoutes,
    ShopRoutes,
    UserRoutes,
    ProcessRoutes,
    FeedbackRoutes,
    SupportRoutes,
    ProductRoutes,
  ])
}
