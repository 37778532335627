import React from 'react'

interface Hook {
  storeItem: (key: string, value: any) => void
  getItem: (key: string) => any | undefined
  deleteItem: (key: string) => void
}

/**
 *
 * @returns utility functions to use localstore (get item, save item, remove item)
 */
export const useLocalStorage = (): Hook => {
  const getItem = React.useCallback((key: string): any | undefined => {
    const value = localStorage.getItem(key)
    try {
      if (value) {
        const json = JSON.parse(value)
        return json
      }
    } catch (error) {
      return undefined
    }
  }, [])

  const storeItem = React.useCallback((key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [])

  const deleteItem = React.useCallback((key: string) => {
    localStorage.removeItem(key)
  }, [])

  return {
    getItem,
    storeItem,
    deleteItem,
  }
}
