import {
  Avatar,
  AvatarGroup,
  Box,
  CircularProgress,
  Icon,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'

import { useAuth, useCart, useGetProducts, useLocalStorage, useProcess } from 'hooks'
import { constLocalStorage } from 'lib'
import { ICartProduct, IInvoiceItem, IPromocionalCode } from 'marketplace-common'

import React from 'react'

export const ProductDetail = ({
  product,
  disableDelete,
  getLogo,
  showButtons,
}: {
  product: IInvoiceItem
  disableDelete?: boolean
  getLogo: (mainSolutionId: number) => string | undefined
  showButtons?: boolean
}) => {
  const { isAuthenticated } = useAuth()
  const {
    cartProducts,
    loadingShoppingCart,
    handleGetProcessProductId,
    removeFromCart,
    removeFromCartNoLogged,
    addProductToCartNotLoged,
    addProductToCart,
  } = useCart()

  const { getProductsByMainSolution } = useGetProducts()
  const { getItem, deleteItem } = useLocalStorage()
  const { process, handleValidateCodeLogged, handleValidateCodeNotLogged, handleChangeCode } =
    useProcess()

  const handleOnChangeSelectedProduct = async (mainSolutionId: number) => {
    const result = await getProductsByMainSolution(mainSolutionId)
    return result
  }

  const addProductNotLogged = async (product: IInvoiceItem) => {
    const products = await handleOnChangeSelectedProduct(product.mainSolutionId)
    const productFind = products.find((p) => p.productId === product.productId)
    if (productFind) {
      addProductToCartNotLoged(productFind)
    }
  }

  const addProductUserLoged = (productId: number) => {
    if (!Boolean(process)) return

    const request = {
      productId,
      processId: process!.processId,
    }
    addProductToCart(request)
  }
  const handleAddProducts = () => {
    isAuthenticated ? addProductUserLoged(product.productId) : addProductNotLogged(product)
  }
  const handleFromCardRemove = async () => {
    const productId = product.productId
    const processProductId = handleGetProcessProductId(productId)
    const existProcessProductId = Boolean(processProductId)
    if (existProcessProductId) {
      const cartProductsUpdate = isAuthenticated
        ? await removeFromCart(processProductId!, productId)
        : removeFromCartNoLogged(processProductId!, productId)
      return cartProductsUpdate
    }
  }

  const getProductsIds = (cartProducts: ICartProduct[]) => {
    if (!Boolean(cartProducts)) {
      return undefined
    }
    const productsId = cartProducts!
      .filter((cartProduct) => Boolean(cartProduct.product))
      .map((cartProduct) => cartProduct.product!.productId)
    return productsId
  }

  const existPromotionalCodeAndProcess: boolean = React.useMemo(() => {
    return process !== undefined && process?.promotionalCode?.code !== undefined
  }, [process])

  const deletePromotionalCode = () => {
    deleteItem(constLocalStorage.PROMOTIONAL_CODE)
  }

  const productApplyDiscount = React.useMemo(() => {
    return product.applyDiscount
  }, [product.applyDiscount])

  const removeProductAndValidateCode = async () => {
    const cartProducts = await handleFromCardRemove()
    if (isAuthenticated) {
      if (cartProducts && productApplyDiscount) {
        const productsIds = getProductsIds(cartProducts)
        await handleValidateCodeLogged(
          existPromotionalCodeAndProcess,
          process!.promotionalCode!.code,
          productsIds,
          undefined
        )
      }
    } else {
      const promotionalCodeLocal = getItem(constLocalStorage.PROMOTIONAL_CODE) as IPromocionalCode
      if (cartProducts && promotionalCodeLocal !== undefined && productApplyDiscount) {
        const productsIds = getProductsIds(cartProducts)
        const response = await handleValidateCodeNotLogged(
          productApplyDiscount,
          promotionalCodeLocal.code,
          productsIds,
          undefined
        )
        if (response?.promotionalCode === null) {
          deletePromotionalCode()
          handleChangeCode('')
        }
      }
    }
  }

  const getDescriptionProduct = () => {
    const cartProduct = cartProducts?.find((item) => item.product?.productId === product?.productId)
    if (Boolean(cartProduct)) return cartProduct?.product?.description

    const cartProductsLocal = getItem(constLocalStorage.CART_PRODUCTS)
    const cartProductLocal: ICartProduct = cartProductsLocal?.find(
      (item: ICartProduct) => item.product?.productId === product?.productId
    )
    if (Boolean(cartProductLocal)) return cartProductLocal?.product?.description

    return ''
  }

  return (
    <TableRow>
      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
          <AvatarGroup spacing={'small'}>
            <Avatar
              src={getLogo(product.mainSolutionId)}
              sx={{ width: { xs: 20, sm: 24, md: 28 }, height: { xs: 20, sm: 24, md: 28 } }}
            />
          </AvatarGroup>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              fontWeight={600}
              color="textSecondary"
              variant="caption"
              align="left"
              fontSize={'0.75em'}
              lineHeight={1}
            >
              {product.item}
            </Typography>
            <Typography
              pt={'2px'}
              color="textSecondary"
              variant="caption"
              align="left"
              fontSize={'0.65em'}
              lineHeight={1}
            >
              {getDescriptionProduct()}
            </Typography>
          </Box>
          {product.applyDiscount && (
            <Tooltip title="Este producto tiene descuento!" placement="top-start" arrow>
              <Icon sx={{ color: '#8ee62a' }}>local_offer_outlined</Icon>
            </Tooltip>
          )}
        </Box>
      </TableCell>

      <TableCell
        sx={{
          textAlign: 'right',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: showButtons ? 'space-evenly' : 'flex-end',
            alignItems: 'center',
          }}
        >
          {showButtons && (
            <IconButton
              disabled={loadingShoppingCart.productId.includes(product.productId)}
              onClick={async (e) => {
                e.stopPropagation()
                await removeProductAndValidateCode()
              }}
            >
              <Icon sx={{ fontSize: 22 }}>remove_circle_outline_icon</Icon>
            </IconButton>
          )}
          {loadingShoppingCart.productId.includes(product.productId) ? (
            <CircularProgress size={15} color="inherit" />
          ) : (
            <Typography color="textSecondary" fontSize={'0.8em'} fontWeight={600}>
              {product.quantity}
            </Typography>
          )}
          {showButtons && (
            <IconButton
              disabled={loadingShoppingCart.productId.includes(product.productId)}
              onClick={(e) => {
                e.stopPropagation()
                handleAddProducts()
              }}
            >
              <Icon sx={{ fontSize: 22 }}>add_circle_outline_icon</Icon>
            </IconButton>
          )}
        </Box>
      </TableCell>

      <TableCell sx={{ textAlign: 'right' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            gap: 0.5,
          }}
        >
          {product.applyDiscount && (
            <Typography
              sx={{
                textDecoration: 'line-through',
                textDecorationColor: 'red',
                color: 'red',
              }}
              fontSize={'0.8em'}
              fontWeight={600}
            >
              ${product.pricesBeforeDiscount}
            </Typography>
          )}
          <Typography fontSize={'0.8em'} fontWeight={600}>
            {product.total}
          </Typography>
        </Box>
      </TableCell>

      <TableCell sx={{ textAlign: 'right' }}>
        <Typography fontSize={'0.8em'} fontWeight={600}>
          {product.percentageDiscount ?? '-'}
        </Typography>
      </TableCell>
      {!disableDelete && (
        <TableCell sx={{ textAlign: 'right' }}>
          <IconButton
            // disabled={isAuthenticated ? loadingCart : false}
            edge="end"
            sx={{
              '&:hover': {
                color: 'red',
              },
            }}
            onClick={async () => {
              await removeProductAndValidateCode()
            }}
          >
            <Icon>delete</Icon>
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  )
}
