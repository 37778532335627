import { ILandingMainSolution } from 'types'

export const LANDINGS_MAIN_SOLUTION_PAGES: ILandingMainSolution = {
  1: process.env.REACT_APP_LADING_PAGE_QUINDE ?? '',
  2: '',
  3: '',
  4: '',
  5: '',
  6: '',
}
