import { Box, Grid, Icon, IconButton, Paper, Typography } from '@mui/material'
import { useAuth, useNavigate } from 'hooks'
import { ROUTES } from 'lib'

const LOGIN_ROUTE = `${ROUTES.login}`
const SOLUTION_ROUTE = `/${ROUTES.user}/${ROUTES.marketplace}/${ROUTES.solutions}`

export const Terms = () => {
  const { animatedNavigation } = useNavigate()
  const { isAuthenticated } = useAuth()

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper sx={{ width: '90%', height: '90%', overflow: 'auto' }}>
        <Grid container direction="row" sx={{ margin: 2, maxWidth: '95%' }}>
          <Grid item xs={12}>
            <IconButton
              onClick={(e) =>
                animatedNavigation(isAuthenticated ? SOLUTION_ROUTE : LOGIN_ROUTE, 'circle')
              }
              size="medium"
              sx={{ mr: 2 }}
            >
              <Icon fontSize="medium">chevron_left</Icon>{' '}
              <Typography sx={{ fontSize: '0.7em ', fontWeight: 'bold' }}>Regresar</Typography>
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{ textAlign: 'center', fontSize: '1.2em', fontWeight: 'bold' }}
            >
              TÉRMINOS Y CONDICIONES DE USO
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              Con la utilización de los servicios del portal de Internet Marketplace Biosfera
              Software (en adelante "El Portal") de propiedad de BIOSFERASOFT SAS (en adelante
              "BIOSFERA") por parte de cada usuario (en adelante “EL USUARIO”), se entiende aceptado
              por EL USUARIO este reglamento de Términos y Condiciones de Uso que BIOSFERASOFT ha
              puesto a su disposición. Por lo tanto, el uso de los servicios ofrecidos por El Portal
              o a través de él y el acceso a los contenidos presentados en el mismo es una
              manifestación voluntaria de EL USUARIO de ceñirse a las siguientes condiciones
              generales (en adelante, las "Condiciones de Uso"), que regulan estos servicios, bien
              sea directamente ante BIOSFERASOFT o bien ante las compañías de BIOSFERASOFT
              vinculadas localmente o internacionalmente, que están autorizadas para explotar
              económicamente u de otras maneras El Portal.
              <br />
              <br />
              El uso o ingreso a cualquiera de las páginas incluidas en El Portal le atribuye la
              calidad de “usuario” a la respectiva persona natural o jurídica. En consecuencia, EL
              USUARIO debe leer atentamente las Condiciones de Uso cada vez que se proponga utilizar
              El Portal. La utilización de El Portal se encuentra sometida a todos los avisos,
              reglamentos de uso e instrucciones publicados en la web por BIOSFERASOFT para
              conocimiento de EL USUARIO, que completan lo previsto en estas Condiciones de Uso en
              cuanto no se opongan a ellas.
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: 'left', fontSize: '0.9em', fontWeight: 'bold', pt: 2 }}
            >
              Términos y Condiciones De Uso:
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              &emsp;<strong>1. </strong> A través de El Portal se facilita a EL USUARIO el acceso y
              la utilización de diversos servicios y contenidos puestos a disposición de EL USUARIO
              por BIOSFERASOFT o por terceros a través de El Portal y/o terceros proveedores de
              servicios y contenidos (en adelante, los "Servicios"). BIOSFERASOFT se reserva el
              derecho a modificar unilateralmente, en cualquier momento y sin previo aviso, la
              presentación y configuración de El Portal, así como los Servicios y las condiciones
              requeridas para utilizar El Portal y los Servicios. Sin embargo, BIOSFERASOFT se
              propone informar los cambios posteriormente a EL USUARIO con el fin de hacerle
              amigable el uso de los Servicios.
              <br />
              <br />
              &emsp;<strong>2. </strong>La prestación del servicio de El Portal por parte de
              BIOSFERASOFT tiene carácter gratuito exceptuando la emisión del certificado de firma
              electrónica para lo cual el usuario deberá cancelar el valor indicado en la lista de
              precios de EL Portal; para EL USUARIO y no exige la previa suscripción o registro de
              EL USUARIO. Sin embargo, la utilización de algunos Servicios sólo puede hacerse
              mediante suscripción o registro de EL USUARIO y/o el pago de un precio.
              <br />
              <br />
              &emsp;<strong>3. </strong>EL USUARIO se compromete a utilizar racionalmente El Portal
              y los Servicios, observando la ley, estas Condiciones de Uso, el orden público, la
              moral y las buenas costumbres generalmente aceptadas. Asimismo, se obliga a abstenerse
              de utilizar El Portal y los Servicios con fines o efectos ilícitos, contrarios a lo
              establecido en estas Condiciones de Uso, lesivos de los derechos e intereses de
              terceros, o que de cualquier forma puedan deteriorar, inutilizar o poner fuera de
              servicio el lenguaje de programación, códigos fuente, la operación, la funcionalidad o
              la presentación de El Portal y/o los Servicios o impedir la normal utilización o
              disfrute de El Portal y de los Servicios por parte de EL USUARIO.
              <br />
              <br />
              &emsp;<strong>4. </strong>El USUARIO deberá abstenerse de obtener e incluso de
              intentar obtener informaciones, mensajes, gráficos, dibujos, archivos de sonido y/o
              imagen, fotografías, grabaciones, software y, en general, cualquier clase de material
              accesibles a través de El Portal o de los Servicios (en adelante, el "Contenido de El
              Portal") empleando para ello medios o procedimientos distintos de los que, según los
              casos, se hayan puesto a su disposición a este efecto o se hayan indicado a este
              efecto en las páginas web donde se encuentren los Contenidos o, en general, de los que
              se empleen habitualmente en Internet a este efecto siempre que no entrañen un riesgo
              de daño o inutilización de El Portal, de los Servicios y/o del Contenido de El Portal.
              <br />
              <br />
              &emsp;<strong>5. </strong>EL USUARIO se obliga y deberá usar todo contenido de El
              Portal de forma correcta y lícita. El USUARIO se compromete a abstenerse de: (a)
              utilizar el Contenido de El Portal , con fines o efectos contrarios a la ley, y a las
              buenas costumbres generalmente aceptadas o al orden público; (b) reproducir o copiar,
              distribuir, permitir el acceso del público a través de cualquier modalidad de
              comunicación pública o privada, transformar, modificar o eliminar el Contenido de El
              Portal, a menos que se cuente con la autorización del titular de los correspondientes
              derechos o ello resulte legalmente permitido; (c) suprimir, eludir o manipular el
              Copyright o los derechos de autor y demás datos identificativos de los derechos de
              Propiedad Intelectual de BIOSFERASOFT o de los terceros citados como propietarios de
              los mismos y las respectivas firmas electrónicas, si fuere el caso, con excepción de
              los licenciamientos que así lo permitan; (d) emplear cualquier contenido de El Portal
              y, en particular, la información de cualquier clase obtenida a través del sitio web o
              usar los Servicios para remitir publicidad, comunicaciones con fines de venta directa
              o con cualquier otra clase de finalidad comercial, mensajes no solicitados dirigidos a
              una pluralidad de personas con independencia de su finalidad, así como comercializar o
              divulgar de cualquier modo dicha información.
              <br />
              <br />
              &emsp;<strong>6. </strong>EL USUARIO y, en general, aquellas personas que se propongan
              establecer un hipervínculo o enlace entre su página web, redes sociales o similares y
              El Portal (en adelante, el "Hipervínculo" o “Enlace”) deberán cumplir las siguientes
              condiciones: (a) el Hipervínculo o enlace no implicara la reproducción de ninguna
              página o formato de El Portal; (b) no se establecerán Hipervínculos con las páginas
              web de El Portal distintas de la home-page o página principal de El Portal o de los
              Servicios; (c) no se creará un navegador, buscador, frame ni un border enviroment
              sobre las páginas web de El Portal; (d) no se realizarán manifestaciones o
              indicaciones falsas, inexactas o incorrectas sobre las páginas web de El Portal y los
              Servicios y, en particular, no se declarará ni dará a entender que BIOSFERASOFT ha
              autorizado el Hipervínculo o que ha supervisado o asumido de cualquier forma el
              Contenido de El Portal o los Servicios ofrecidos o puestos a disposición de la página
              web en la que se establece el Hipervínculo; (e) excepción hecha de aquellos signos que
              formen parte del mismo Hipervínculo, link o de la página web tomada de BIOSFERASOFT,
              la página web en la que se establezca el Hipervínculo no contendrá ninguna marca,
              nombre comercial, rótulo de establecimiento, denominación, logotipo, eslogan u otros
              signos distintivos pertenecientes a BIOSFERASOFT; (f) la página web en la que se
              establezca el Hipervínculo o enlace no contendrá informaciones o contenidos ilícitos,
              virus, malware o similares; o contenidos contrarios a la moral y buenas costumbres
              generalmente aceptadas y al orden público, así como tampoco contendrá contenidos
              contrarios a derechos de terceros. El establecimiento del Hipervínculo no implica en
              ningún caso la existencia de relaciones entre BIOSFERASOFT y el propietario de la
              página web en la que se establezca, ni la aceptación y aprobación por parte de
              BIOSFERASOFT de sus Contenidos o Servicios, bien se trate de un hipervínculo del
              tercero en El Portal o bien de un hipervínculo de BIOSFERASOFT en el sitio web del
              tercero; en caso de darse cualquiera de los casos mencionados en este literal
              BIOSFERASOFT se deslinda de responsabilidades frente al Usuario quien acepta la
              responsabilidad ante la visita de cualquier enlace, link o hipervínculo.
              <br />
              <br />
              &emsp;<strong>7. </strong>EL USUARIO responderá de los daños y perjuicios de toda
              naturaleza que BIOSFERASOFT pueda sufrir, directa o indirectamente, como consecuencia
              del incumplimiento de cualquiera de las obligaciones derivadas de las Condiciones de
              Uso o de la ley en relación con la utilización de El Portal.
              <br />
              <br />
              &emsp;<strong>8. </strong>En principio, EL USUARIO no puede tomar ningún software
              utilizado en El Portal. Sin embargo, en ciertos servicios, a EL USUARIO se le ofrecerá
              la posibilidad de sublicenciarlo, es decir, descargarlo directamente de El Portal con
              la autorización expresa de BIOSFERASOFT y/o sus proveedores. No obstante, EL USUARIO
              reconoce desde ahora que en estos casos el Software se halla protegido por derechos de
              autor pertenecientes a BIOSFERASOFT y/o a sus proveedores, o simplemente BIOSFERASOFT
              provee de un enlace a su descarga, siendo el Usuario responsable totalmente por el uso
              que haga del mismo debiendo documentarse sobre el uso, licenciamiento y prohibiciones
              de uso. El uso que EL USUARIO realice del Software se regirá por los términos
              contenidos en el contrato de licencia para EL USUARIO final, si lo hubiera, que
              acompaña o se incluye en el Software (en adelante, el "Contrato de Licencia"). Para
              poder instalar o utilizar cualquier Software que esté acompañado o incluya un Contrato
              de Licencia, EL USUARIO debe aceptar previamente los términos de dicho Contrato de
              Licencia, para cuyos efectos se considerarán Condiciones Particulares. Con respecto al
              Software que no venga acompañado de un contrato de licencia, pero que se ofrezca
              expresamente descargarlo en El Portal se entiende que se le concede a EL USUARIO una
              licencia personal y no transferible a usar el Software exclusivamente para acceder y
              utilizar El Portal de acuerdo con las Condiciones de Uso, siempre que mantenga
              intactas las indicaciones de derechos de autor y otros símbolos de propiedad,
              conservando la integridad del respectivo código fuente, absteniéndose de alterar el
              software y de practicarle cualquier tipo de ingeniería reversa, parche o similares. EL
              USUARIO por lo tanto, reconoce desde ahora que todo el Software contenido en este
              portal (incluyendo, entre otros, todos los códigos HTML, XML, los controles Active X y
              cualquier otro código usado en El Portal) es propiedad de BIOSFERASOFT y/o de sus
              proveedores, o simplemente BIOSFERASOFT provee de un enlace a los mismos y por ende
              están protegidos por leyes nacionales y tratados internacionales sobre propiedad
              intelectual. Cualquier reproducción o redistribución del Software está expresamente
              prohibida por la ley y puede conllevar la imposición de sanciones civiles y penales
              severas; a menos que su licenciamiento así lo permita. Los infractores serán
              perseguidos con la máxima severidad posible, considerando que la copia o reproducción
              del software a otros servidores o lugares con el objeto de su posterior reproducción o
              redistribución está expresamente prohibida. El software sólo está garantizado (si lo
              estuviera) de acuerdo con los términos del contrato de licencia. EL USUARIO reconoce
              que el Software, y cualquier documentación y/o información técnica anexa, están
              sujetos a la legislación y reglamentaciones estadounidenses sobre control de la
              exportación. EL USUARIO se compromete a no exportar ni reexportar el Software, ni
              directa ni indirectamente, a ningún país sujeto a restricciones de exportación. La
              información, el software, los productos y los servicios contenidos en El Portal pueden
              contener errores de los cuales no se hacen responsables ni BIOSFERASOFT ni sus
              proveedores.
              <br />
              <br />
              &emsp;<strong>9. </strong>Para utilizar algunos de los Servicios, incluida la emisión
              del certificado de firma electrónica EL USUARIO proporcionará previamente a
              BIOSFERASOFT ciertos datos de carácter personal (en adelante, los "Datos Personales").
              BIOSFERASOFT tratará de manera automática los Datos Personales con las finalidades y
              bajo las condiciones definidas en sus Políticas. El Suscriptor autoriza a BIOSFERASOFT
              SAS a verificar toda la información entregada a través de los medios que estime
              pertinentes, incluyendo instituciones públicas, registro civil, superintendencias,
              registros públicos, DINARDAP, entre otros.
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: 'left', fontSize: '0.9em', fontWeight: 'bold', pt: 2 }}
            >
              &emsp;10. EXCLUSIÓN DE GARANTIAS Y EXONERACIÓN DE RESPONSABILIDAD.
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              &emsp;&emsp;<strong>a. </strong>Disponibilidad y continuidad, utilidad y falibilidad
              -BIOSFERASOFT no garantiza la disponibilidad y continuidad del funcionamiento de El
              Portal y de los Servicios. Cuando ello sea razonablemente posible, BIOSFERASODT
              advertirá previamente las interrupciones en el funcionamiento de El Portal y de los
              Servicios sólo en caso de que estos puedan ser previstos. BIOSFERASOFT tampoco
              garantiza la utilidad de El Portal y de los Servicios para la realización de alguna
              actividad en particular, ni su infalibilidad y, en particular, aunque no de modo
              exclusivo, que EL USUARIO pueda efectivamente utilizar El Portal y los Servicios,
              acceder a las distintas páginas web o subdominios que forman El Portal o a aquellas
              desde las que se prestan los Servicios. BIOSFERASOFT no asume responsabilidad alguna
              por los daños y perjuicios de toda naturaleza que puedan derivarse de la falta de
              disponibilidad o de continuidad del funcionamiento de El Portal y de los servicios, de
              la falta de utilidad que EL USUARIO hubiere podido atribuir al Portal y a los
              servicios, a la falibilidad de El Portal y de los servicios y, en particular, aunque
              no de modo exclusivo, a las fallas en el acceso a las distintas páginas web de El
              Portal o a aquellas desde las que se prestan los servicios.
              <br />
              <br />
              Lo anterior, debido a que todo servicio prestado a través de INTERNET utiliza la
              infraestructura pública de comunicaciones, cuyo control y responsabilidad no están
              radicados en ningún momento en BIOSFERASOFT o asociados.
              <br />
              <br />
              &emsp;&emsp;<strong>b. </strong>Privacidad y seguridad en la utilización de El Portal
              y de los Servicios- BIOSFERASOFT no garantiza la privacidad y seguridad de la
              utilización de El Portal y de los Servicios y, en particular, no garantiza que
              terceros no autorizados no puedan tener conocimiento de la clase, condiciones,
              características y circunstancias del uso que EL USUARIO hace de El Portal y de los
              Servicios. Por lo tanto, BIOSFERASOFT no asume responsabilidad alguna por los daños y
              perjuicios de toda naturaleza que pudieran derivarse del conocimiento que puedan tener
              terceros no autorizados de la clase, condiciones, características y circunstancias del
              uso que EL USUARIO hace de El Portal y del Contenido de El Portal.
              <br />
              <br />
              Sin embargo, EL USUARIO tiene derechos al respecto, para lo cual BIOSFERASOFT se
              remite a lo establecido en su Declaración de Practicas de Certificación.
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: 'left', fontSize: '0.9em', fontWeight: 'bold', pt: 2 }}
            >
              &emsp;&emsp;10.1. Exclusión de garantías y exoneración de responsabilidad por los
              Contenidos:
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              &emsp;&emsp;&emsp;<strong>a. </strong>Calidad- BIOSFERASOFT no controla ni garantiza
              la ausencia de virus ni de otros elementos en los Contenidos que puedan producir
              alteraciones en el sistema informático (software y hardware) o en los documentos
              electrónicos y archivos almacenados en el sistema informático de EL USUARIO. Por lo
              tanto, BIOSFERASOFT no asume responsabilidad alguna por los daños y perjuicios de toda
              naturaleza que puedan derivarse de la presencia de virus o a la presencia de otros
              elementos en los contenidos que puedan producir alteraciones en el sistema
              informático, documentos electrónicos o archivos de EL USUARIO. En consecuencia, EL
              USUARIO debe utilizar sus propios medios para escanear cada elemento que BIOSFERASOFT
              tenga en El Portal y que le autorice a reproducir. b) Licitud, fiabilidad y utilidad-
              BIOSFERASOFT no garantiza la licitud, fiabilidad y utilidad del Contenido de El
              Portal.
              <br />
              <br />
              &emsp;&emsp;&emsp;BIOSFERASOFT no asume responsabilidad alguna por los daños y
              perjuicios de toda naturaleza que puedan derivarse de la transmisión, difusión,
              almacenamiento, puesta a disposición, recepción, obtención o acceso al Contenido de El
              Portal, y en particular, aunque no de modo exclusivo, por los daños y perjuicios que
              puedan derivarse de: (a) el incumplimiento de la ley, la moral y las buenas costumbres
              generalmente aceptadas o el orden público como consecuencia de la transmisión,
              difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso al
              Contenido de El Portal; (b) la infracción de los derechos de propiedad intelectual e
              industrial, de los secretos empresariales, de compromisos contractuales de cualquier
              clase, de los derechos al honor, a la intimidad personal y familiar y a la imagen de
              las personas, de los derechos de propiedad y de toda otra naturaleza pertenecientes a
              un tercero como consecuencia de la transmisión, difusión, almacenamiento, puesta a
              disposición, recepción, obtención o acceso al Contenido de El Portal; (c) la
              realización de actos de competencia desleal y publicidad ilícita como consecuencia de
              la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o
              acceso al contenido de El Portal; (d) la falta de veracidad, exactitud, exhaustividad,
              pertinencia y/o actualidad del Contenido de El Portal; (e) el hecho de que las
              expectativas generadas por el Contenido de El Portal no se consideren adecuadas para
              algún propósito; (f) el incumplimiento, retraso en el cumplimiento, cumplimiento
              defectuoso o terminación por cualquier causa de las obligaciones contraídas por
              terceros y contratos realizados con terceros a través de o con motivo del acceso al
              Contenido de El Portal; (g) los vicios y defectos de toda clase de los contenidos
              transmitidos, difundidos, almacenados, puestos a disposición o de otra forma
              transmitidos o puestos a disposición, recibidos, obtenidos o a los que se haya
              accedido a través de El Portal o de los servicios.
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: 'left', fontSize: '0.9em', fontWeight: 'bold', pt: 2 }}
            >
              &emsp;&emsp;10.2. Exclusión de garantías y exoneración de responsabilidad por los
              servicios y contenidos que se encuentren fuera de El Portal. Contenidos:
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              &emsp;&emsp;&emsp;<strong>a. </strong>El Portal pone o podría poner a disposición de
              EL USUARIO dispositivos técnicos de enlace (tales como, entre otros, vínculos, banners
              o avisos publicitarios, enlaces y similares), directorios y herramientas de búsqueda
              que permiten a EL USUARIO acceder a sitios web pertenecientes a y/o gestionados por
              terceros (en adelante, "Páginas de Enlace"). La instalación de estos enlaces,
              directorios y herramientas de búsqueda en El Portal tiene por único objeto facilitar a
              EL USUARIO la búsqueda y acceso a la información disponible en Internet.
              <br />
              <br />
              &emsp;&emsp;&emsp;<strong>b. </strong>BIOSFERASOFT no ofrece ni comercializa por sí ni
              por medio de terceros los productos y servicios disponibles en las Páginas de Enlace.
              BIOSFERASOFT tampoco controla previamente, aprueba, vigila ni hace propios los
              productos, servicios, contenidos, información, datos, archivos, productos y cualquier
              clase de material existente en las Páginas de Enlace. EL USUARIO, por tanto, debe
              extremar la prudencia en la valoración y utilización de los servicios, información,
              datos, archivos, productos y cualquier clase de material existente en las Páginas de
              Enlace. Biosferasoft no será responsable por cualquier perjuicio que estos enlaces
              puedan provocar al Usuario. &emsp;&emsp;&emsp;<strong>c. </strong>BIOSFERASOFT no
              garantiza ni asume ningún tipo de responsabilidad por los daños y perjuicios de toda
              clase que puedan derivarse de: (a) el funcionamiento, disponibilidad, accesibilidad o
              continuidad de los sitios enlazados; (b) el mantenimiento de los servicios,
              información, datos, archivos, productos y cualquier clase de material existente en los
              sitios enlazados; (c) la prestación o transmisión de los servicios, información,
              datos, archivos, productos y cualquier clase de material existente en los sitios
              enlazados; (d) la calidad, licitud, fiabilidad y utilidad de los servicios,
              información, datos, archivos, productos y cualquier clase de material existente en los
              sitios enlazados, en los mismos términos y con el mismo alcance dispuesto en las
              condiciones generales respecto de los contenidos y de los servicios prestados por
              terceros a través de El Portal.
            </Typography>

            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              &emsp;&emsp;<strong>10.3 </strong>Exclusión de garantías y exoneración de
              responsabilidad por la utilización de El Portal, de los Servicios y de los Contenidos
              por EL USUARIO. BIOSFERASOFT no tiene obligación de controlar y no controla la
              utilización que EL USUARIO hace de El Portal, de los Servicios y de los Contenidos. En
              particular, BIOSFERASOFT no garantiza que EL USUARIO utilice El Portal, los Servicios
              y los Contenidos de conformidad con estos Términos y Condiciones de Uso y, en su caso,
              las Condiciones Particulares aplicables, ni que lo hagan de forma diligente y
              prudente.
              <br />
              <br />
              &emsp;&emsp;BIOSFERASOFT tampoco tiene la obligación de verificar y no verifica la
              identidad de EL USUARIO, ni la veracidad, vigencia, exhaustividad y/o autenticidad de
              los datos que EL USUARIO proporciona sobre sí mismo a otros Usuarios.
              <br />
              <br />
              &emsp;&emsp;BIOSFERASOFT no asume responsabilidad alguna por los daños y perjuicios de
              toda naturaleza que pudieran derivarse de la utilización de los servicios y de los
              contenidos por parte de EL USUARIO o que puedan derivarse de la falta de veracidad,
              vigencia, exhaustividad y/o autenticidad de la información que EL USUARIO proporciona
              a otros usuarios acerca de sí mismos y, en particular, aunque no de forma exclusiva,
              por los daños y perjuicios de toda naturaleza que puedan derivarse de la suplantación
              de la personalidad de un tercero efectuada por un usuario en cualquier clase de
              comunicación realizada a través de El Portal.
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.9em', pt: 2 }}>
              &emsp;<strong>11. </strong>BIOSFERASOFT no concede licencia o autorización de uso de
              ninguna clase sobre sus derechos de propiedad industrial e intelectual o sobre
              cualquier otra propiedad o derecho relacionado con El Portal, los Servicios o los
              Contenidos.
              <br />
              <br />
              &emsp;<strong>12. </strong>BIOSFERASOFT se reserva el derecho a denegar o retirar el
              acceso a El Portal y/o a los Servicios, en cualquier momento y sin necesidad de previo
              aviso a EL USUARIO que incumpla estos Términos y Condiciones de Uso o las Condiciones
              Particulares que resulten de su aplicación.
              <br />
              <br />
              &emsp;<strong>13. </strong>En el caso de que cualquier Usuario o tercero considere que
              cualquier Contenido de El Portal ha sido introducido con violación de derechos de
              propiedad intelectual deberá enviar una notificación por escrito a BIOSFERASOFT
              detallando la supuesta infracción.
              <br />
              <br />
              &emsp;<strong>14. </strong>La prestación del servicio de El Portal y de los demás
              Servicios tiene, en principio, una duración indefinida. No obstante, BIOSFERASOFT está
              autorizada para dar por terminada o suspender la prestación del servicio de El Portal
              y/o de cualquiera de los Servicios en cualquier momento, sin perjuicio de lo que se
              hubiere dispuesto al respecto en las correspondientes Condiciones Particulares. Cuando
              ello sea razonablemente posible, BIOSFERASOFT advertirá previamente la terminación o
              suspensión de la prestación del servicio de El Portal y de los demás Servicios.
              <br />
              <br />
              &emsp;<strong>15. </strong>BIOSFERASOFT y EL USUARIO se obligan a cumplir sus
              obligaciones de buena fe y a solucionar mediante negociaciones amigables las
              eventuales diferencias o controversias que surjan de la ejecución de estas Condiciones
              de Uso. En este supuesto cualquiera de las partes le notificará por escrito a la otra,
              su intención de iniciar conversaciones destinadas a la solución de la misma. Una vez
              recibida la notificación, las partes comenzarán las negociaciones tendientes a
              encontrar una solución. De no llegarse a un acuerdo dentro de los sesenta (60) días
              continuos siguientes a la fecha de acuse de recibo de la notificación escrita de la
              otra parte, la situación se resolverá sometiéndola a la resolución de un Tribunal de
              Arbitraje que se sujetará a lo dispuesto en la Ley de Arbitraje y Mediación, al
              Reglamento del Centro de Arbitraje y Mediación de la Cámara de Comercio de Quito,
              Ecuador y a las siguientes normas: a) Los árbitros serán seleccionados conforme a lo
              establecido en la Ley de Arbitraje y Mediación y fallarán conforme a derecho; b) Las
              partes renuncian a la jurisdicción ordinaria, se obligan a acatar el laudo que expida
              el Tribunal Arbitral y se comprometen a no interponer ningún tipo de recurso en contra
              del laudo arbitral; c) Para la ejecución de medidas cautelares, el Tribunal Arbitral
              está facultado para solicitar el auxilio de funcionarios públicos, judiciales,
              policiales y administrativos sin que sea necesario recurrir a juez ordinario alguno;
              d) El Tribunal Arbitral estará integrado por tres árbitros; e) El procedimiento
              arbitral será confidencial; f) El lugar del arbitraje será en las instalaciones del
              Centro de Arbitraje y Mediación de la Cámara de Comercio de Quito y g) La decisión del
              Tribunal Arbitral tendrá carácter definitivo. La presente cláusula compromisoria
              deberá considerarse un convenio independiente entre las partes y no será, por lo
              tanto, afectada por ningún argumento o declaración de nulidad de cualquier otra de las
              cláusulas previstas en estas Condiciones de Uso.
              <br />
              <br />
              &emsp;<strong>16. </strong>CONDICIONES DEL SERVICIO.- Emisión de Certificados de firma
              electrónica.- La emisión de Certificados, se realizará en () días y horas laborables;
              - Soporte.- Servicio que presta BIOSFERASOFT SAS. para atención al cliente consistente
              en: soporte mediante correo electrónico; a través de su página Web
              https://soporte.biosferasoft.com sobre inquietudes relacionadas con el proceso de
              certificación electrónica en lo que se refiere a certificados de firma y hardware o
              software relacionado; BIOSFERASOFT SAS no brinda soporte sobre software o hardware de
              terceros.
              <br />
              <br />
              &emsp;<strong>17. </strong>Las partes libre y voluntariamente manifiestan que acuerdan
              el uso de medios digitales con fines lícitos y legales; el titular acepta de manera
              voluntaria e irrevocable la emisión de la firma electrónica con todos los efectos
              derivados de su aceptación y uso de acuerdo a lo dispuesto por la Ley de Comercio
              Electrónico, Firmas Electrónicas y Mensajes de Datos con su respectivo reglamento y
              por ende reconoce el valor jurídico conforme lo dispone el cuerpo legal mencionado;
              por lo que autoriza que se proceda según las instrucciones indicadas por BIOSFERASOFT
              SAS.
              <br />
              <br />
              &emsp;<strong>18. </strong>Estas Condiciones de Uso se regirán e interpretarán
              conforme a las leyes de la República de Ecuador.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export { Terms as default } from './Terms'
