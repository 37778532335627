import { IProfile, ISolution, ISolutionProduct } from 'marketplace-common'

export interface IDetailedSolution extends ISolution {
  products: ISolutionProduct[]
  profiles: IProfile[]
}

export enum SOLUTION {
  QUINDE_FEE = 1,
  ALPAQQA = 2,
  NUNA_KEY = 3,
  MICO_DOCS = 4,
  QREATIVEE = 5,
  ADMINISTRATION = 6,
}
