import { ProductLandingLayout, PublicLayout } from 'layouts'
import { ROUTES } from 'lib'
import React, { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const LandingPage = React.lazy(() => import('pages/Landing/Landing'))

const ProductRoutes: RouteObject = {
  path: ROUTES.root,
  element: <ProductLandingLayout />,
  children: [
    {
      path: `${ROUTES.detail}/:urlCode`,
      element: (
        <Suspense fallback={<></>}>
          <LandingPage />
        </Suspense>
      ),
    },
  ],
}

export default ProductRoutes
