import { Box } from '@mui/material'
import { motion } from 'framer-motion'
import React from 'react'
import { SOLUTION_THEMES } from 'styles'
import { ISolutionReference } from 'marketplace-common'

type Props = {}

export const SolutionColors = (props: Props) => {
  const solutionColors = Object.keys(SOLUTION_THEMES).map(
    (key) => SOLUTION_THEMES[key as ISolutionReference].palette.primary
  )
  return (
    <React.Fragment>
      {solutionColors.map((color, index) => (
        <Box
          key={color.main + index}
          sx={{
            width: '100px',
            height: '100px',
            borderRadius: '100%',
            bgcolor: color.main,
            opacity: 0.6,
            position: 'absolute',
            right: `calc(25% - 40px)`,
            top: 'calc(50% - 40px)',
            marginTop: 2.5 * index * (index % 2 === 0 ? 1 : -1) * (Math.random() + 0.65),
            marginRight:
              3 *
              (solutionColors.length - index) *
              (index % 2 === 0 ? 1 : -1) *
              (Math.random() + 0.65),
          }}
          component={motion.div}
          animate={{
            scale: 1.8 + Math.random(),
            x:
              35 *
              (solutionColors.length - index) *
              (index % 2 === 0 ? 1 : -1) *
              (Math.random() + 0.65),
          }}
          transition={{
            repeat: Infinity,
            duration: 1.75,
            repeatType: 'mirror',
            repeatDelay: 1 + Math.random(),
          }}
        />
      ))}
    </React.Fragment>
  )
}
