import { Stack } from '@mui/material'
import { LoginForm } from 'components'

/**
 *
 * @returns The login page
 */

export const Login = () => {
  return (
    <Stack px={5} height="100%">
      <LoginForm />
    </Stack>
  )
}

export { Login as default } from './Login'

