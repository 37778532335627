import { Box, Icon, Paper, Radio, Typography } from '@mui/material'
import React from 'react'
import { PaymentMethod as PaymentMethodTypes } from 'types'

type Props = {
  disabled?: boolean
  paymentMethod: PaymentMethodTypes | undefined
  onChange: (method: PaymentMethodTypes) => void
}

export const PaymentMethod = (props: Props) => {
  const { onChange, paymentMethod, disabled } = props

  return (
    <React.Fragment>
      <Box
        sx={{
          gap: 2,
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          sx={{
            flex: 1,
            width: '100%',
          }}
          onClick={
            !disabled
              ? () => {
                  onChange('card')
                }
              : undefined
          }
        >
          <Box
            sx={{
              height: { xs: '50px', sm: '70px' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingX: 2,
            }}
          >
            <Radio size="small" checked={paymentMethod === 'card'} />
            <Typography variant="body2" sx={{ fontSize: { xs: 13, sm: 15 } }}>
              {'Tarjeta'}
            </Typography>

            <Icon fontSize="medium" color="action">
              payment
            </Icon>
          </Box>
        </Paper>

        <Paper
          sx={{
            flex: 1,
            width: '100%',
          }}
          onClick={
            !disabled
              ? () => {
                  onChange('transfer')
                }
              : undefined
          }
        >
          <Box
            sx={{
              height: { xs: '50px', sm: '70px' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingX: 2,
            }}
          >
            <Radio size="small" checked={paymentMethod === 'transfer'} />
            <Typography variant="body2" sx={{ fontSize: { xs: 13, sm: 15 } }}>
              {'Transferencia'}
            </Typography>
            <Icon fontSize="medium" color="action">
              local_atm
            </Icon>
          </Box>
        </Paper>
      </Box>
    </React.Fragment>
  )
}
