import Box from '@mui/material/Box'
import { DialogAcceptTermsAndCondition } from 'components'
import React from 'react'
import { Outlet } from 'react-router-dom'

type Props = {}

export const SolutionLayout = (props: Props) => {
  return (
    <Box
      width={'100vw'}
      height={'100vh'}
      display={'flex'}
      flexDirection={'column'}
      bgcolor={(theme) => theme.palette.alt?.background}
    >
      <Outlet />
      <DialogAcceptTermsAndCondition />
    </Box>
  )
}
