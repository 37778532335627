import { formTypeValues, IDENTIFICATION_CARD_LENGTH } from 'lib'
import { ICompanyInformation } from 'marketplace-common'
import React from 'react'
import { IValidateErrors } from 'types'
import { fieldValidationDirection, fieldValidationEmail, fieldValidationPhone } from 'utils'

type Hook = {
  noAllFields: (field: any) => boolean
  fieldErrors: IValidateErrors
  invoiceFieldErrors: IValidateErrors
  setFieldErrors: React.Dispatch<React.SetStateAction<IValidateErrors>>
  setInvoiceFieldErrors: React.Dispatch<React.SetStateAction<IValidateErrors>>
  validateCompanyInformation: (comp?: ICompanyInformation) => boolean
  setCompanyInformation: React.Dispatch<React.SetStateAction<ICompanyInformation>>
}

const defaultErrorsFields = {
  name: '',
  middleName: '',
  lastName: '',
  mail: '',
  identification: '',
  phoneNumber: '',
  address: '',
} as IValidateErrors

export const useValidateCompanyInformation = (): Hook => {
  const [companyInformation, setCompanyInformation] = React.useState({} as ICompanyInformation)
  const [fieldErrors, setFieldErrors] = React.useState<IValidateErrors>({} as IValidateErrors)
  const [invoiceFieldErrors, setInvoiceFieldErrors] =
    React.useState<IValidateErrors>(defaultErrorsFields)

  const validateCompanyInformation = (comp?: ICompanyInformation) => {
    const errors: Partial<IValidateErrors> = {}
    const companyInfoToValidate = comp ?? companyInformation
    const trimmedIdentification = companyInfoToValidate.identification

    if (companyInfoToValidate?.name?.trim() === '' || companyInfoToValidate?.name === undefined) {
      errors.name = 'El campo Primer Nombre es requerido'
    } /* else if (!fieldValidationOnlyLetters(companyInfoToValidate?.name?.trim())) {
      errors.name = 'No debe contener números ni caracteres especiales'
    } */

    /*     if (
      companyInfoToValidate?.middleName?.length > 0 &&
      !fieldValidationOnlyLetters(companyInfoToValidate?.middleName?.trim())
    ) {
      errors.middleName = 'No debe contener números ni caracteres especiales'
    }

    if (companyInfoToValidate?.lastName?.trim() === '' || companyInfoToValidate?.lastName === undefined) {
      errors.lastName = 'El campo Apellido es requerido'
    } else if (!fieldValidationOnlyLetters(companyInfoToValidate?.lastName?.trim())) {
      errors.lastName = 'No debe contener números ni caracteres especiales'
    } */

    if (companyInfoToValidate?.mail?.trim() === '' || companyInfoToValidate?.mail === undefined) {
      errors.mail = 'El campo Correo Electrónico es requerido'
    } else if (!fieldValidationEmail(companyInfoToValidate?.mail?.trim())) {
      errors.mail = 'El correo ingresado no es válido'
    }

    if (!trimmedIdentification || trimmedIdentification === undefined) {
      errors.identification = 'El campo Identificación es requerido'
    } else if (trimmedIdentification.length < IDENTIFICATION_CARD_LENGTH) {
      errors.identification = 'Su identificación debe ser mayor o igual a 10 dígitos'
    } /*else if (
      trimmedIdentification.length === RUC_LENGTH &&
      !fieldValidationRuc(trimmedIdentification)
    ) {
      errors.identification = 'RUC inválido'
    } else if (
      trimmedIdentification.length === IDENTIFICATION_CARD_LENGTH &&
      !validateDocument(trimmedIdentification)
    ) {
      errors.identification = 'Su identificación no es válida'
    } else if (
      trimmedIdentification.length === IDENTIFICATION_CARD_LENGTH &&
      !fieldValidationIdentification(trimmedIdentification)
    ) {
      errors.identification = 'Ingrese una identificación válida'
    } */

    if (
      companyInfoToValidate?.phoneNumber?.trim() === '' ||
      companyInfoToValidate?.phoneNumber === undefined
    ) {
      errors.phoneNumber = 'El campo Teléfono es requerido'
    } else if (!fieldValidationPhone(companyInfoToValidate?.phoneNumber?.trim())) {
      errors.phoneNumber = 'No tiene el formato correcto'
    }

    if (
      companyInfoToValidate?.address?.trim() !== undefined &&
      fieldValidationDirection(companyInfoToValidate?.address?.trim())
    ) {
      errors.address = 'La dirección no debe contener [{}[]!¡¿?%$=*+]'
    }

    comp?.informationType === formTypeValues.BILLING
      ? setInvoiceFieldErrors(errors as IValidateErrors)
      : setFieldErrors(errors as IValidateErrors)

    return Object.keys(errors).length === 0
  }

  const noAllFields = (field: any) => {
    const requiredProperties = ['identification', 'name', 'lastName', 'mail', 'phoneNumber']
    for (const key of requiredProperties) {
      if (!field[key]) {
        return false
      }
    }
    return true
  }

  return {
    fieldErrors,
    invoiceFieldErrors,
    setFieldErrors,
    setInvoiceFieldErrors,
    noAllFields,
    setCompanyInformation,
    validateCompanyInformation,
  }
}
