export const ROUTES = {
  none: '',
  root: '/',
  auth: 'auth',
  login: 'login',
  signUp: 'signUp',
  solutions: 'solutions',
  marketplace: 'marketplace',
  explore: 'explore',
  processes: 'processes',
  payments: 'payments',
  user: 'user',
  feedback: 'feedback',
  acquired: 'acquired',
  requests: 'requests',
  refunds: 'refunds',
  contacts: 'contacts',
  requestRefund: 'request-refund',
  salesTeam: 'sales-team',
  configuration: 'configuration',
  account: 'account',
  company: 'company',
  verificationSent: 'verification-sent',
  accountVerified: 'account-verified',
  verificationResend: 'verification-resend',
  forgotPassword: 'forgot-password',
  changePassword: 'change-password',
  support: 'support',
  resendEmailVerification: 'resent-email',
  policy: 'policy',
  terms: 'terms',
  detail: 'detail',
}
