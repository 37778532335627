import { LoadingButton } from '@mui/lab'
import { Box, Paper, TextField, Typography } from '@mui/material'
import { useFeedback } from 'hooks'
import { notificationRequestRefundService } from 'lib'
import React from 'react'
import { INotificationRefund } from 'types'
import { fieldValidationEmail, fieldValidationNumberEc, fieldValidationOnlyLetters } from 'utils'

const validateErrorsDefault = {
  firtsName: '',
  secondName: '',
  lastName: '',
  email: '',
  identification: '',
  numberPhone: '',
  reason: '',
} as INotificationRefund

export const RefundForm = () => {
  const { showMessage } = useFeedback()
  const [notificationRefund, setNotificationRefund] = React.useState<INotificationRefund>(
    {} as INotificationRefund
  )
  const [fieldErrors, setFieldErrors] = React.useState<INotificationRefund>(validateErrorsDefault)
  const [loading, setLoading] = React.useState<boolean>(false)

  const validateCompanyInformation = (notificationRefund: INotificationRefund) => {
    const errors: Partial<INotificationRefund> = {}

    if (notificationRefund.firtsName?.trim() === '' || notificationRefund.firtsName === undefined) {
      errors.firtsName = 'El campo Primer Nombre es requerido'
    } else if (!fieldValidationOnlyLetters(notificationRefund.firtsName?.trim())) {
      errors.firtsName = 'No debe contener números ni caracteres especiales'
    }

    if (
      notificationRefund.secondName?.trim() === '' ||
      notificationRefund.secondName === undefined
    ) {
      errors.secondName = 'El campo Segundo Nombre es requerido'
    } else if (!fieldValidationOnlyLetters(notificationRefund.secondName?.trim())) {
      errors.secondName = 'No debe contener números ni caracteres especiales'
    }

    if (notificationRefund.lastName?.trim() === '' || notificationRefund.lastName === undefined) {
      errors.lastName = 'El campo Apellido es requerido'
    } else if (!fieldValidationOnlyLetters(notificationRefund.lastName?.trim())) {
      errors.lastName = 'No debe contener números ni caracteres especiales'
    }

    if (notificationRefund.email?.trim() === '' || notificationRefund.email === undefined) {
      errors.email = 'El campo Correo Electrónico es requerido'
    } else if (!fieldValidationEmail(notificationRefund.email?.trim())) {
      errors.email = 'El correo ingresado no es válido'
    }

    if (
      notificationRefund.identification?.trim() === '' ||
      notificationRefund.identification === undefined
    ) {
      errors.identification = 'El campo Identificación es requerido'
    }

    if (
      notificationRefund.numberPhone?.trim() === '' ||
      notificationRefund.numberPhone === undefined
    ) {
      errors.numberPhone = 'El campo Número de teléfono es requerido'
    } else if (!fieldValidationNumberEc(notificationRefund.numberPhone?.trim())) {
      errors.numberPhone = 'El Número de teléfono no tiene el formato correcto'
    }

    if (notificationRefund.reason?.trim() === '' || notificationRefund.reason === undefined) {
      errors.reason = 'El campo Motivo es requerido'
    }

    setFieldErrors(errors as INotificationRefund)

    const fieldsAreOk: boolean = Object.keys(errors).length === 0
    return fieldsAreOk
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const valid = validateCompanyInformation(notificationRefund)
    if (!valid) return
    try {
      setLoading(true)
      const notificationRefundRequest = {
        ...notificationRefund,
        username: `${notificationRefund.firtsName} ${notificationRefund.secondName} ${notificationRefund.lastName}`,
      }
      const status = await notificationRequestRefundService(notificationRefundRequest)
      if (status === 200) {
        showMessage(
          'Hemos recibido tu solicitud, nos pondremos en contacto contigo lo más pronto posible',
          'success',
          { horizontal: 'right', vertical: 'top' }
        )
        setNotificationRefund(validateErrorsDefault)
      }
    } catch (error) {
      showMessage('No se pudo enviar tu solicitud, inténtalo de nuevo en unos minutos', 'error', {
        horizontal: 'right',
        vertical: 'top',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleChangeNotificationRefundData = (
    key: keyof INotificationRefund,
    value: INotificationRefund[keyof INotificationRefund]
  ) => {
    setNotificationRefund((prev) => ({ ...prev, [key]: value }))
  }

  return (
    <>
      <Box component="form" onSubmit={handleSubmit} sx={{ mb: 3, px: { xs: 3, md: 4 } }}>
        <Paper
          sx={{
            pb: 3,
            px: 3,
          }}
          elevation={0}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5, py: 3, px: 1.5 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', sm: 'center' },
                gap: 2,
              }}
            >
              <Box sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Primer nombre *'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={notificationRefund.firtsName}
                    onChange={(e) => {
                      handleChangeNotificationRefundData('firtsName', e.target.value)
                      setFieldErrors((prevErrors) => ({
                        ...prevErrors,
                        firtsName: '',
                      }))
                    }}
                  />
                  {fieldErrors.firtsName && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.firtsName}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Segundo nombre *'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={notificationRefund.secondName}
                    onChange={(e) => {
                      handleChangeNotificationRefundData('secondName', e.target.value)
                      setFieldErrors((prevErrors) => ({
                        ...prevErrors,
                        secondName: '',
                      }))
                    }}
                  />
                  {fieldErrors.secondName && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.secondName}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', sm: 'center' },
                gap: 2,
              }}
            >
              <Box sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Apellido *'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={notificationRefund.lastName}
                    onChange={(e) => {
                      handleChangeNotificationRefundData('lastName', e.target.value)
                      setFieldErrors((prevErrors) => ({
                        ...prevErrors,
                        lastName: '',
                      }))
                    }}
                  />
                  {fieldErrors.lastName && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.lastName}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Correo Electrónico'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    type="email"
                    value={notificationRefund.email}
                    onChange={(e) => {
                      handleChangeNotificationRefundData('email', e.target.value)
                      setFieldErrors((prevErrors) => ({
                        ...prevErrors,
                        email: '',
                      }))
                    }}
                  />
                  {fieldErrors.email && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.email}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', sm: 'center' },
                gap: 2,
              }}
            >
              <Box sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Identificación *'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={notificationRefund.identification}
                    onChange={(e) => {
                      const value = e.target.value
                      handleChangeNotificationRefundData(
                        'identification',
                        value.replace(/[^0-9]/g, '')
                      )
                      setFieldErrors((prevErrors) => ({
                        ...prevErrors,
                        identification: '',
                      }))
                    }}
                    inputProps={{ maxLength: 10 }}
                  />
                  {fieldErrors.identification && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.identification}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Número de teléfono *'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={notificationRefund.numberPhone}
                    onChange={(e) => {
                      const value = e.target.value
                      handleChangeNotificationRefundData(
                        'numberPhone',
                        value.replace(/[^0-9]/g, '')
                      )
                      setFieldErrors((prevErrors) => ({
                        ...prevErrors,
                        numberPhone: '',
                      }))
                    }}
                    inputProps={{ maxLength: 10 }}
                  />
                  {fieldErrors.numberPhone && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.numberPhone}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', sm: 'center' },
                gap: 2,
              }}
            >
              <Box sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Motivo *'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    placeholder="Adjunta el número de factura , el producto y el motivo por el cual requieres el reembolso, el asesor se comunicará contigo con los datos que proporcionaste."
                    value={notificationRefund.reason}
                    onChange={(e) => {
                      handleChangeNotificationRefundData('reason', e.target.value)
                      setFieldErrors((prevErrors) => ({
                        ...prevErrors,
                        reason: '',
                      }))
                    }}
                  />

                  {fieldErrors.reason && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.reason}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton
                color="primary"
                type="submit"
                loading={loading}
                variant="contained"
                sx={{
                  px: 3,
                  py: { xs: 1.1 },
                  fontSize: { xs: 15 },
                  fontWeight: 600,
                  width: { xs: '100%', sm: 'calc(50% - 8px)', md: 'calc(33% - 8px)' },
                }}
              >
                {'Enviar'}
              </LoadingButton>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box
        sx={{
          lineHeight: 1,
          mb: 3,
          pb: 3,
          px: { xs: 3, md: 4 },
          fontSize: '9.5px',
          width: '100%',
          height: 100,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            pt: 4,
            pb: 3,
            px: 3,
            mb: 7,
            textAlign: 'justify',
          }}
        >
          <Typography variant="caption" sx={{ textAlign: 'justify' }}>
            Las devoluciones en marketplace aplican únicamente para 48 horas después de haberlas
            adquirido (completado el paso final). Si pasadas estas 48 horas aún desea una devolución
            se podrá abrir un caso especial.
          </Typography>
        </Paper>
      </Box>
    </>
  )
}

export { RefundForm as default } from './Form'
