import React from 'react'
export const ElementSlate = ({
  attributes,
  children,
  element,
}: {
  attributes: any
  children: React.ReactNode
  element: any
}) => {
  const haveLineHeight = React.useMemo(
    () =>
      Array.isArray(children) &&
      (element.children as any[]).some(nodeElement =>
        nodeElement.hasOwnProperty('lineHeight')
      ),
    [element]
  )

  const getLineHeight = React.useMemo(() => {
    const child = (element.children as any[]).find(nodeElement =>
      nodeElement.hasOwnProperty('lineHeight')
    )
    if (!child) {
      return '1em'
    }
    return child.lineHeight
  }, [element])

  const style = {
    textAlign: element.align,
    color: element.color,
    fontSize: element.fontSize,
    lineHeight: haveLineHeight ? getLineHeight : '1em',
  }

  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}
