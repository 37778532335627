import { PublicLayout } from 'layouts'
import { ROUTES } from 'lib'
import React, { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const LoginMainPage = React.lazy(() => import('pages/Auth/Login'))
const SignUpMainPage = React.lazy(() => import('pages/Auth/SignUp'))

const PublicRoutes: RouteObject = {
  path: ROUTES.root,
  element: <PublicLayout />,
  children: [
    {
      path: ROUTES.root,
      element: (
        // <Suspense fallback={<FullscreenLoading />}>
        <Suspense fallback={<></>}>
          <LoginMainPage />
        </Suspense>
      ),
    },
    {
      path: ROUTES.login,
      element: (
        // <Suspense fallback={<FullscreenLoading />}>
        <Suspense fallback={<></>}>
          <LoginMainPage />
        </Suspense>
      ),
    },
    {
      path: ROUTES.signUp,
      element: (
        // <Suspense fallback={<FullscreenLoading />}>
        <Suspense fallback={<></>}>
          <SignUpMainPage />
        </Suspense>
      ),
    },
  ],
}

export default PublicRoutes
