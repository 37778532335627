import axios, { AxiosError } from 'axios'

export const getIpClient = async () => {
  try {
    const response = await axios.get('https://api.ipify.org/?format=json')
    return response.data.ip
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}
