import { Avatar, Box, Button, Icon, Paper, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useAuth, useNavigate } from 'hooks'
import {
  REFERENCE_SPECIAL_PRODUCTS,
  ROUTES
} from 'lib'
import React from 'react'
import { SOLUTION_COLORS } from 'styles'

export const CardPromo = () => {
  const { animatedNavigation } = useNavigate()
  const { user, isAuthenticated } = useAuth()

  const handleShop = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    const process = {
      information: '{}',
      companyId: user.identification,
      securityUserId: user.userId,
    }
    try {
      const encryptRoute = `${ROUTES.processes}/${REFERENCE_SPECIAL_PRODUCTS}`
      animatedNavigation(encryptRoute)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleShopNotLogged = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    try {
      const processNoLoggedRoute = `${ROUTES.processes}/${REFERENCE_SPECIAL_PRODUCTS}`
      animatedNavigation(processNoLoggedRoute)
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <Paper
      sx={{ width: 220, height: 220, cursor: 'pointer', position: 'relative' }}
      elevation={3}
      onClick={isAuthenticated ? handleShop : handleShopNotLogged}
      component={motion.div}
      whileHover={{ scale: 1.1 }}
    >
      <Box
        height={'58%'}
        bgcolor={SOLUTION_COLORS.promo.primary.main}
        sx={{
          borderTopLeftRadius: (theme) => theme.shape.borderRadius,
          borderTopRightRadius: (theme) => theme.shape.borderRadius,
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Avatar
          sx={{
            width: 'calc(202px * 0.375)',
            height: 'calc(202px * 0.375)',
            background: SOLUTION_COLORS.promo.primary.main,
            color: SOLUTION_COLORS.promo.primary.dark,
          }}
        >
          <Icon style={{ fontSize: 60 }}>local_offer</Icon>
        </Avatar>
      </Box>
      <Box
        height={'35%'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '0 auto',
          justifyContent: 'space-between',
          padding: 1,
        }}
      >
        <Box>
          <Typography align="center" sx={{ color: '#341800' }} fontWeight={700}>
            {'Ofertas'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            sx={{ width: 100 }}
            size="small"
            onClick={() => (isAuthenticated ? handleShop : handleShopNotLogged)}
          >
            Comprar
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}
