import { Alert, Box, Icon, IconButton, Stack, TextField, Typography } from '@mui/material'
import { ProcessToolbar } from 'components'
import { AnimatePresence, motion } from 'framer-motion'
import { useCart, useFeedback, useProcess } from 'hooks'
import { getCompanyByCompanyIdOrCompanyName } from 'lib'
import { ICompany } from 'marketplace-common'
import React from 'react'
import { StepReferenceName } from 'types'
import { AccountInfo } from './Info'

export const AccountForm = () => {
  const {
    process,
    handleAssignedCompanyToProcess,
    switchNextStep,
    addEnableSteps,
    handleChangeShopToAnotherAccount,
    shopToAnotherAccount,
    handleCleanAssignedCompanyToProcess,
  } = useProcess()
  const { nonEmptyCart } = useCart()

  const [companyToSearch, setCompanyToSearch] = React.useState('')
  const [companiesFound, setCompaniesFound] = React.useState<ICompany[]>([])
  const [companySelected, setCompanySelected] = React.useState<ICompany>()
  const { showMessage } = useFeedback()

  const [loadingAdd, setLoadingAdd] = React.useState<boolean>(false)

  const handleSearchAccount = async (filter: string) => {
    if (filter.length < 3) {
      showMessage('Debes ingresar al menos 3 caracteres para buscar', 'info')
      return
    } else {
      try {
        setLoadingAdd(true)
        const result = await getCompanyByCompanyIdOrCompanyName(filter)
        setCompaniesFound(result)
        if (result.length === 0) {
          showMessage('No se encontraron resultados', 'info')
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingAdd(false)
      }
    }
  }

  const handleBtnContinue = () => {
    if (!Boolean(companySelected)) {
      showMessage('Debes seleccionar una cuenta para continuar', 'info')
      return
    }
    handleAssignedCompanyToProcess(companySelected!.companyId)
    addEnableSteps(StepReferenceName.ACCOUNT)
    nonEmptyCart && switchNextStep()
  }

  React.useEffect(() => {
    if (!shopToAnotherAccount) {
      handleChangeShopToAnotherAccount()
    }
  }, [])

  const handleFindCompany = async (companyId: string) => {
    try {
      const result = await getCompanyByCompanyIdOrCompanyName(companyId)
      return result[0]
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    const existAssignedCompany = Boolean(process) && Boolean(process!.assignedCompanyId)
    if (existAssignedCompany) {
      handleFindCompany(process!.assignedCompanyId).then((result) => {
        setCompanySelected(result)
      })
    }
  }, [process])

  return (
    <React.Fragment>
      <ProcessToolbar handleBtnContinue={handleBtnContinue} loading={loadingAdd} />
      <Stack
        pb={{ xs: 14, md: 0 }}
        alignItems="center"
        justifyContent="center"
        spacing={3}
        flexDirection={'column'}
        pt={{ xs: 1 }}
        width={{ xs: '100%', md: '100%' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <Icon fontSize="large">manage_accounts</Icon>
          <Typography
            variant="h5"
            fontWeight={900}
            align={'center'}
            sx={{ pt: 1, fontSize: { xs: '1.2rem' }, px: 1 }}
          >
            {`Selecciona la cuenta a la que asociarás la compra`}
          </Typography>
        </Box>

        <Box
          sx={{
            pt: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
          }}
          component={motion.div}
          layout
        >
          {Boolean(companySelected) && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography
                variant="h5"
                align="center"
                fontWeight={700}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  fontSize: { xs: '1.2rem' },
                }}
              >
                <Icon>how_to_reg</Icon> Cuenta seleccionada
              </Typography>

              <AccountInfo
                email={companySelected?.companyName ?? ''}
                identification={companySelected?.companyId ?? ''}
                mask={true}
                onSelect={async () => {
                  setCompanySelected(undefined)
                  await handleCleanAssignedCompanyToProcess()
                }}
                buttonLabel="Eliminar selección"
                icon={'delete'}
              />
            </Box>
          )}
        </Box>

        <Box
          component={motion.div}
          layout
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: companiesFound.length > 0 ? 'flex-start' : 'center',
          }}
          gap={1}
          width={'100%'}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', px: 3 }}
          >
            <TextField
              variant="outlined"
              label={'Identificación, correo'}
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={async () => {
                      await handleSearchAccount(companyToSearch)
                    }}
                  >
                    <Icon>search</Icon>
                  </IconButton>
                ),
              }}
              value={companyToSearch}
              onChange={(e) => setCompanyToSearch(e.target.value)}
              onKeyPress={async (e) => {
                if (e.key === 'Enter') {
                  await handleSearchAccount(companyToSearch)
                }
              }}
              onBlur={async () => {
                if (companyToSearch.length < 3) return
                await handleSearchAccount(companyToSearch)
              }}
            />
            <Alert severity="info" variant="outlined">
              Si deseas asociar tu compra a otra cuenta, ingresa la identificación o correo
              electrónico de la cuenta a la que deseas asociar la compra.
            </Alert>
          </Box>

          {companiesFound.length > 0 && (
            <Box
              px={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h5"
                fontWeight={700}
                align={'center'}
                sx={{ fontSize: { xs: '0.9rem' } }}
              >
                {'Asegúrate de que la cuenta a la que quieres asociar la compra sea la correcta'}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <AnimatePresence>
                  {companiesFound.map((company) => (
                    <Box
                      component={motion.div}
                      initial={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.5 }}
                      key={company.companyId}
                    >
                      <AccountInfo
                        email={company.companyName}
                        identification={company.companyId}
                        mask={true}
                        onSelect={() => {
                          setCompanySelected(company)
                        }}
                        isSelect={
                          (process!.assignedCompanyId !== '' &&
                            process!.assignedCompanyId === company.companyId) ||
                          (Boolean(companySelected) &&
                            companySelected!.companyId === company.companyId)
                        }
                      />
                    </Box>
                  ))}
                </AnimatePresence>
              </Box>
            </Box>
          )}
        </Box>
      </Stack>
    </React.Fragment>
  )
}
